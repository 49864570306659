/* istanbul ignore file */
import { Button, Flex, Heading } from '@kandji-inc/nectar-ui';
import { useContext } from 'react';

import Icon from '../../theme/components/atoms/Icon';
import Loader from '../../theme/components/atoms/Loader';
import Sticky from '../../theme/components/atoms/Sticky';

import './MultiFactorAuthentication.css';

import { i18n } from 'src/i18n';
import { Context, Provider } from './context';
import TableMultiFactorAuthentication from './table';

const Module = () => {
  const { list, auth0UserId, isLoading } = useContext<{
    list: {
      confirmed: boolean;
      created_at: string;
      id: string;
      type: string;
    }[];
    auth0UserId: string;
    isLoading: boolean;
  }>(Context);

  return (
    <Flex
      flow="column"
      alignItems="center"
      css={{
        width: '100%',
        gap: '36px',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        paddingBottom: '64px',
      }}
    >
      <Flex flow="column" gap="md">
        <Sticky
          label="paper-header"
          origin="paper"
          edge="border"
          sticky={false}
        >
          <Heading
            size="4"
            css={{
              fontWeight: 500,
              lineHeight: 'var(--font-line-height-large-24, 24px) /* 150% */',
              letterSpacing: '-0.4px',
              padding: '32px 16px 0 16px',
            }}
          >
            {i18n.t('One-Time Password (OTP)')}
          </Heading>
        </Sticky>
        {auth0UserId ? (
          <>
            {list && !isLoading ? (
              <>
                {list.length > 0 ? (
                  <>
                    <Flex
                      flow="column"
                      gap="md"
                      css={{
                        paddingLeft: '16px',
                      }}
                    >
                      <p>
                        {i18n.t(
                          'Manage your OTP authenticator for email and password authentication.',
                        )}{' '}
                        <Button
                          variant="link"
                          href="https://support.kandji.io/support/solutions/articles/72000559809"
                        >
                          {i18n.t('Learn more')}
                        </Button>
                      </p>
                    </Flex>
                    <Flex
                      flow="column"
                      gap="md"
                      css={{
                        width: '100%',
                      }}
                    >
                      <TableMultiFactorAuthentication />
                    </Flex>
                  </>
                ) : (
                  <div className="paper-body">
                    <div className="node">
                      <div className="paper-block">
                        <div className="paper-copy paper-copy-center">
                          <Icon icon="kandji-hive" />
                          <p>
                            {i18n.t(
                              'There is no OTP Authenticator configured for this account. You will be prompted to set up a new one upon next log in.',
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="paper-body">
                <div className="node">
                  <div className="paper-block">
                    <Loader type="simple" />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="paper-body">
            <div className="node">
              <div className="paper-block">
                <div className="paper-copy paper-copy-center">
                  <Icon icon="kandji-hive" />
                  <p>
                    {i18n.t(
                      "You are viewing a customer's instance of the Company app. You can manage the user's enrollments from within the Admin app.",
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default () => (
  <Provider>
    <Module />
  </Provider>
);
