/* istanbul ignore file */
import { i18n } from 'i18n';
import { DeviceNameStatusTypes } from './DeviceName/DeviceName.types';
import { IPA_VPPStatusTypes } from './IPA_VPP/IPA_VPP.types';
import { ManagedLibraryItemStatusTypes } from './ManagedLibraryItems/ManagedLibraryItems.types';
import { ManagedOSStatusTypes } from './ManagedOS/ManagedOS.types';
import { ProfileStatusTypes } from './Profiles/ProfileStatus.types';
import { RecoveryPasswordStatusTypes } from './RecoveryPassword/RecoveryPassword.types';
import { VPPStatusTypes } from './VPP/VPP.types';
import { WallpaperStatusTypes } from './Wallpaper/Wallpaper.types';

export type LibraryItemLogSerializer = (details: any) => string;

export enum CommonStatusTypes {
  LIBRARY_ITEM_EXCLUDED_AUDIT = 'library_item_excluded_audit',
}

export interface LibraryItemStatusSerializerMap {
  Default: LibraryItemLogSerializer;
  [key: string]: LibraryItemLogSerializer;
}

export const LibraryItemStatuses = {
  ...CommonStatusTypes,
  ...ProfileStatusTypes,
  ...DeviceNameStatusTypes,
  ...RecoveryPasswordStatusTypes,
  ...WallpaperStatusTypes,
  ...IPA_VPPStatusTypes,
  ...ManagedOSStatusTypes,
  ...VPPStatusTypes,
  ...ManagedLibraryItemStatusTypes,
} as const;

export type LibraryItemStatusKeys = keyof typeof LibraryItemStatuses;
export type LibraryItemStatusTypes =
  (typeof LibraryItemStatuses)[LibraryItemStatusKeys];

export interface LibraryItemStatusContent {
  type: LibraryItemStatusTypes;
  version: number;
  details?: any;
}

export interface LibraryItemExcludedStatusContent {
  type: LibraryItemStatusTypes;
  version: number;
}

export enum LogDetailKeys {
  CommandCompleted = 'command_completed',
  CommandIssued = 'command_issued',
  PayloadIdentifier = 'payload_identifier',
  PayloadUUID = 'payload_uuid',
  ProfileUUID = 'profile_uuid',
  ProfileName = 'profile_name',
  CommandFailed = 'command_failed',
  Reason = 'reason',
  ErrorChain = 'error_chain',
  DeviceName = 'device_name',
  Hostname = 'hostname',
}

export const LogDetailLabels = {
  [LogDetailKeys.CommandCompleted]: () => i18n.t('Command Completed'),
  [LogDetailKeys.CommandIssued]: () => i18n.t('Command Issued'),
  [LogDetailKeys.CommandFailed]: () => i18n.t('Command Failed'),
  [LogDetailKeys.PayloadIdentifier]: () => i18n.t('Payload Identifier'),
  [LogDetailKeys.PayloadUUID]: () => i18n.t('Payload UUID'),
  [LogDetailKeys.ProfileUUID]: () => i18n.t('Profile UUID'),
  [LogDetailKeys.ProfileName]: () => i18n.t('Profile Name'),
  [LogDetailKeys.Reason]: () => i18n.t('Reason'),
  [LogDetailKeys.ErrorChain]: () => i18n.t('Error Chain'),
  [LogDetailKeys.DeviceName]: () => i18n.t('Device name'),
  [LogDetailKeys.Hostname]: () => i18n.t('Host name'),
};
