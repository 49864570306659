import {
  Banner,
  Button,
  Checkbox,
  Flex,
  LazyScriptEditor,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useState, memo } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const ScriptDetailsCard = (props) => {
  const { setting, update, isDisabled, isSubmitted } = props;
  const [withRemediationScript, setWithRemediationScript] = useState(
    !!setting.remediationScript,
  );
  const [isTouched, setIsTouched] = useState(false);
  const fieldsToValidate = ['auditScript'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useEffect(() => {
    if (!setting.auditScript) {
      /* istanbul ignore next */
      onInvalidate(0)(i18n.t('Required'));
    } else {
      onInvalidate(0)(false);
    }
  }, [setting.auditScript]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Script Details')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Add your Custom Script, customize with a remediation script and specify restart requirements below.',
          )}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <div style={{ gridColumn: '1/3' }}>
            <p className="b-txt-bold b-mb1">{i18n.t('Audit Script')}</p>
            <Banner theme="info" kind="block" className="b-mb">
              <p>
                {i18n.t(
                  "A script's reported status depends on the exit code. An exit code of 0 means the item will pass, otherwise an alert will be triggered.",
                )}
              </p>
            </Banner>
            <div onBlur={() => setIsTouched(true)} ref={refs[0]}>
              <LazyScriptEditor
                placeholder={i18n.t('Enter your script here')}
                value={setting.auditScript}
                onChange={(value) => update('auditScript', value)}
                language="shell"
                options={{ readOnly: isDisabled }}
                error={((isSubmitted || isTouched) && invalidations[0]) || ''}
                className={isDisabled && 'k-mobile-config-info__preview'}
              />
            </div>
            {!withRemediationScript && (
              <Button
                icon="circle-plus"
                kind="link"
                className="b-mt2"
                onClick={() => setWithRemediationScript(true)}
                isDisabled={isDisabled}
              >
                {i18n.t('Add Remediation Script')}
              </Button>
            )}
            {withRemediationScript && (
              <>
                <Flex
                  justify="space-between"
                  align="center"
                  className="b-txt b-mb1 b-mt2"
                >
                  <div>{i18n.t('Remediation Script')}</div>
                  <Button
                    onClick={() => {
                      setWithRemediationScript(false);
                      update('remediationScript', '');
                    }}
                    isDisabled={isDisabled}
                    icon="trash-can"
                    kind="link"
                    theme="error"
                  />
                </Flex>
                <Banner theme="info" kind="block" className="b-mb1">
                  <p>
                    {i18n.t(
                      'Remediation scripts are executed only when audit scripts fail, which is determined if a script\'s exit code is anything but 0. Upon success of a remediation script, a status of "remediated" will be shown. Should the script fail, an alert will be triggered and enforcement will stop until the Library Item is flushed.',
                    )}
                  </p>
                </Banner>
                <LazyScriptEditor
                  placeholder={i18n.t('Enter your script here')}
                  value={setting.remediationScript}
                  onChange={(value) => update('remediationScript', value)}
                  language="shell"
                  options={{ readOnly: isDisabled }}
                  className={isDisabled && 'k-mobile-config-info__preview'}
                />
              </>
            )}
          </div>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              defaultChecked
              checked={setting.restart}
              label={i18n.t('Restart after successful execution')}
              onChange={() => update('restart', (p) => !p)}
              isDisabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Require a restart on the device after successful execution. End users will be given a 30 minute countdown before a restart occurs.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(ScriptDetailsCard);
