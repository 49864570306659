import {
  Button,
  sidePanel as SidePanel,
  TextInput,
  setClass,
  // FontIcon,
  // urlValidator,
} from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import React, { useState, useEffect } from 'react';
import { parse } from 'uri-js';
import uuidv4 from 'uuid/v4';

import { i18n } from 'src/i18n';
import { rocketIcon } from '../../default-icons';
import useShadowOnScroll from '../customize-panel/use-shadow-on-scroll';
import useScreenFormValidation from '../use-screen-form-validation.hook';
import LinkPreviews from './link-previews';

const newLink = () => ({
  icon: rocketIcon,
  uuid: uuidv4(),
  title: '',
  titlePlaceholder: i18n.t('Add a title for this resource'),
  helperText: '',
  helperTextPlaceholder: i18n.t(
    'Add a short helpful description for your end users.',
  ),
  buttonText: '',
  buttonTextPlaceholder: i18n.t('Open, Launch, Install...'),
  buttonUrl: '',
  buttonUrlPlaceholder: 'https://www.kandji.io',
  type: 'generic',
});

const SuccessSidePanel = (props) => {
  const {
    model,
    setModel,
    isDarkMode,
    disabled,
    initialStateCopy,
    beforeEditModel,
    onValidations = () => {},
    itemId = '',
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isAnyLinkInvalid, setIsAnyLinkInvalid] = useState(false);
  const [isAnyInvalid, setIsAnyInvalid] = useState(false);

  const { isShowHeaderShadow, setBody } = useShadowOnScroll();

  /* check if links have changed */
  useEffect(() => {
    const prev = deepcopy(initialStateCopy.successScreen);
    const next = deepcopy(model.successScreen);
    delete prev.isActive;
    delete next.isActive;
    const isSame = JSON.stringify(prev) === JSON.stringify(next);
    setIsUpdated(!isSame);
  }, [model]);

  /* get self service info, if user has set, update model */
  // useEffect(() => {
  //   api(URL_COMPANY_SELF_SERVICE_CONFIG)
  //     .get()
  //     .then((r) => r.data)
  //     .then((d) => {
  //       setModel((p) => {
  //         const c = { ...p };
  //         const lks = c.successScreen.links;
  //         const ssLink = lks.find((v) => v.type === 'self-service');
  //         /* if we are making the item for the first time */
  //         if (ssLink && !itemId) {
  //           if (d.branding_text) {
  //             ssLink.title = d.branding_text.substring(0, 25);
  //           }
  //           if (d.branding_subtext) {
  //             ssLink.helperText = d.branding_subtext.substring(0, 65);
  //           }
  //           if (d.branding_image_url) {
  //             ssLink.icon = {};
  //             ssLink.iconS3Key = d.branding_image_s3key;
  //             ssLink.iconUrl = d.branding_image_url;
  //           }
  //         }
  //         return c;
  //       });
  //     })
  //     .catch(console.log);
  // }, []);

  const restoreAll = () => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...deepcopy(initialStateCopy.successScreen),
        isActive: p.successScreen.isActive,
      },
    }));
  };

  const onCancel = () => {
    setIsOpen(false);
    setModel((p) => ({
      ...p,
      successScreen: deepcopy(beforeEditModel.successScreen),
    }));
  };

  const addLink = () => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        links: [...p.successScreen.links, newLink()],
      },
    }));
  };

  const removeLink = (e, sLink) => {
    e.preventDefault();
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        links: p.successScreen.links.filter((v) => v.uuid !== sLink.uuid),
      },
    }));
  };

  const updateScreen = (field, value) => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        [field]: value,
      },
    }));
  };

  const { isFormInvalid, validator, onInvalidate } = useScreenFormValidation({
    inputs: 2,
  });

  const onValidationsLinks = (r) => {
    setIsAnyLinkInvalid(r.isInvalid);
  };

  useEffect(() => {
    setIsAnyInvalid(isFormInvalid || isAnyLinkInvalid);
  }, [isFormInvalid, isAnyLinkInvalid]);

  useEffect(() => {
    onValidations({
      name: 'successScreen',
      isInvalid: isAnyInvalid,
    });
  }, [isAnyInvalid]);

  /* have to add this, to prevent saving without valid url */
  const isAnyInvalidUrl = model.successScreen.links
    .filter((l) => l.type !== 'self-service')
    .some((l) => !l.buttonUrl);

  return (
    <div>
      <Button disabled={disabled} onClick={() => setIsOpen(true)}>
        {i18n.t(`Customize`)}
      </Button>
      <SidePanel isVisible={isOpen} size="wide">
        <div className="b-side-panel-layout">
          <div
            className={setClass(
              'b-side-panel-layout__header',
              isShowHeaderShadow && '--with-shadow',
            )}
          >
            <h2 className="b-h2">{i18n.t(`Customize Complete screen`)}</h2>
          </div>
          <div className="b-side-panel-layout__body" ref={setBody}>
            <h3 className="b-h3 b-mb">{i18n.t(`General`)}</h3>
            <div className="b-form-grid">
              <TextInput
                value={model.successScreen.header}
                label={i18n.t('Header')}
                maxLength={35}
                validator={validator(35)}
                onInvalidate={onInvalidate(0)}
                fieldsGrid
                onChange={(e) => {
                  e.persist();
                  if (!e.target.value.includes('\n')) {
                    updateScreen('header', e.target.value);
                  }
                }}
              />
              <TextInput
                value={model.successScreen.subHeader}
                style={{ minHeight: '85px' }}
                textArea
                label={i18n.t('Subheader')}
                maxLength={205}
                validator={validator(205)}
                onInvalidate={onInvalidate(1)}
                fieldsGrid
                onChange={(e) => {
                  e.persist();
                  if (!e.target.value.includes('\n')) {
                    updateScreen('subHeader', e.target.value);
                  }
                }}
              />
            </div>
            <div>
              <h3 className="b-h3 b-mt b-mb2">{i18n.t(`Links`)}</h3>
              <LinkPreviews
                isOpen={isOpen}
                model={model}
                setModel={setModel}
                addLink={addLink}
                links={model.successScreen.links}
                isEditing
                removeLink={removeLink}
                onValidations={onValidationsLinks}
              />
            </div>
          </div>
          <div className="b-side-panel-layout__footer">
            <div className="b-flex-btw">
              <Button onClick={restoreAll} disabled={!isUpdated} kind="link">
                {i18n.t(`Restore defaults`)}
              </Button>
              <div className="b-grid-ctas">
                <Button kind="outline" onClick={onCancel}>
                  {i18n.t(`Cancel`)}
                </Button>
                <Button
                  disabled={isAnyInvalid || isAnyInvalidUrl}
                  onClick={() => setIsOpen(false)}
                >
                  {i18n.t(`Done`)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default SuccessSidePanel;
