import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import ActivationLockRow from './activation-lock-row';

const Vision = (props) => {
  const { setting, update, isDisabled } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Vision')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Configure visionOS devices to enroll using Automated Device Enrollment and customize Activation Lock',
          )}
        </p>
      </Setting.SubHeader>
      {setting.isEnabled && (
        <Setting.Rows>
          <ActivationLockRow {...props} />
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default Vision;
