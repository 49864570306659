import { formatDeviceOptions } from '../../data-service/library-item/devices';
import { deviceTypes } from '../../library/common';
import type { LockScreenMessageModel } from './lock-screen-message.types';

const initialState: LockScreenMessageModel = {
  id: '',
  iconSrc: '',
  name: '',
  isActive: true,
  isNameValid: false,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  skip_blueprint_conflict: false,
  type: 'lockscreenmessage',
  supportsRules: true,
  rules: undefined,
  lockScreenFootnote: '',
  nameValidationResult: [],
  blueprints: [],
  devices: formatDeviceOptions([deviceTypes.IPAD, deviceTypes.IPHONE]),
  setting: {},
};

export default initialState;
