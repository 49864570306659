import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Banner,
  Box,
  Button,
  Code,
  Dialog,
  Flex,
  Icon,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { links } from 'src/app/common/constants';
import { isPinEnabled } from 'src/app/components/common/helpers';
import { getPin } from 'src/app/components/computer/api';

import { i18n } from 'i18n';
import history from 'src/app/router/history';

const ComputerDelete = (props) => {
  const {
    decreaseBlueprintComputersCount,
    deleteComputers,
    getBlueprints,
    info,
    name,
    onHide,
    setComputers,
  } = props;
  const {
    activationLockSupported,
    blueprintId,
    computerId,
    computers,
    deviceFamily,
    erased,
    locked,
    model,
    osVersion,
  } = info;

  const [isLoading, setIsLoading] = useState(true);
  const [isPinEnabledOnDevice, setIsPinEnabledOnDevice] = useState();
  const [pin, setPin] = useState();
  const [confirmationText, setConfirmationText] = useState();
  const DELETE_WORD = i18n.t('DELETE');

  useEffect(() => retrievePin(), []);

  const retrievePin = /* istanbul ignore next */ () => {
    if (computerId && (erased || locked)) {
      getPin(computerId)
        .then((res) => {
          setPin(res.pin);
          setIsPinEnabledOnDevice(
            isPinEnabled(
              deviceFamily,
              osVersion,
              model,
              activationLockSupported,
            ),
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toaster(i18n.t('Failed to get the PIN code'));
        });
    }

    setIsLoading(false);
  };

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster(i18n.t('Copied to clipboard.'));
  };

  const onDelete = /* istanbul ignore next */ () => {
    const ids = computers;

    if (!ids.length) {
      return null;
    }

    setIsLoading(true);
    const newComputerList = computers.filter(
      (computer) => !ids.includes(computer.id),
    );

    deleteComputers(ids).then(
      () => {
        getBlueprints()
          .then(() => {
            if (blueprintId) {
              decreaseBlueprintComputersCount(ids.length);
            }

            if (computerId) {
              onHide();
              setIsLoading(false);
              history.push(links.devices);
              toaster(
                i18n.t(`The device record {name} has been deleted.`, { name }),
              );
            } else {
              onHide();
              setIsLoading(false);
              toaster(i18n.t('Selected devices were deleted.'));
            }
          })
          .then(() => {
            setComputers(newComputerList);
            if (info.fetchComputers) {
              info.fetchComputers();
            }
          });
      },
      (err) => {
        onHide();
        setIsLoading(false);
        toaster(err.message);
      },
    );

    return null;
  };

  const title =
    computers.length > 1
      ? i18n.t('Delete device records')
      : erased
        ? i18n.t('This device has been erased')
        : i18n.t('Delete device record');

  const content = (
    <Flex flow="column" gap="lg">
      <Banner
        theme="warning"
        text={
          <Text>
            {i18n.t(
              'Deleting the device {rowLength, plural, one {record} other {records}}',
              { rowLength: computers.length },
            )}
            {name && (
              <Text
                css={{
                  fontWeight: '$medium',
                  display: 'inline-block',
                }}
              >
                {name}
              </Text>
            )}{' '}
            {i18n.t('cannot be undone.')}
          </Text>
        }
      />

      {isPinEnabledOnDevice && (erased || locked) && (
        <Banner
          theme="warning"
          text={
            <Text>
              {i18n.t(
                'This device has been {erased}, and cannot be used again unless it is unlocked with its PIN code.',
                { erased: erased ? 'erased' : 'locked' },
              )}
            </Text>
          }
        />
      )}

      <Text>
        {i18n.t(
          'All history will be lost, and {rowLength, plural, one {the device} other {devices}} will need to be re-enrolled into Kandji for future use.',
          { rowLength: computers.length },
        )}
      </Text>

      {!erased && !locked && (
        <Flex flow="column" gap="md">
          <Text>
            {i18n.t(
              'All device secrets that may prevent anyone from using {rowLength, plural, one {this device} other {these devices}} will be lost, including:',
              { rowLength: computers.length },
            )}
          </Text>
          <ul>
            <li>
              {i18n.t(
                'Device lock PIN {rowLength, plural, one {code} other {codes}}',
                { rowLength: computers.length },
              )}
            </li>
            <li>
              {i18n.t(
                'Recovery {rowLength, plural, one {password} other {passwords}}',
                { rowLength: computers.length },
              )}
            </li>
            <li>
              {i18n.t(
                'FileVault Recovery {rowLength, plural, one {key} other {keys}}',
                { rowLength: computers.length },
              )}
            </li>
            <li>
              {i18n.t(
                'Activation Lock Bypass {rowLength, plural, one {code} other {codes}}',
                { rowLength: computers.length },
              )}
            </li>
          </ul>
        </Flex>
      )}

      <Flex
        flow="column"
        gap="lg"
        css={{
          paddingTop: '$4',
          borderTop: '1px solid $neutral20',
        }}
      >
        {isPinEnabledOnDevice && pin && (
          <Flex flow="column" gap="md">
            <Text>{i18n.t('Copy and store the PIN code')}</Text>
            <Flex gap="sm">
              <Code>{pin}</Code>
              <Box
                css={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleOnCopy(pin)}
                data-testid="copy-pin"
              >
                <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
              </Box>
            </Flex>
          </Flex>
        )}

        <Text>
          {i18n.ut(
            'Type <b>{deleteWord}</b> to permanently delete the device {rowLength, plural, one {record} other {records}}',
            { rowLength: computers.length, deleteWord: DELETE_WORD },
          )}
        </Text>

        <TextField
          placeholder={DELETE_WORD}
          onChange={(e) => setConfirmationText(e.target.value)}
        />

        <Text variant="subtle" size="1">
          {i18n.t(
            'I understand that the selected device {rowLength, plural, one {record} other {records}}, all related device record information, and all device secrets will be destroyed. This cannot be undone.',
            { rowLength: computers.length },
          )}
        </Text>

        {isPinEnabledOnDevice && (erased || locked) && (
          <Text variant="subtle" size="1">
            {i18n.t(
              'I have securely stored the PIN code needed to unlock this device, or I no longer have access to the device. I understand that this PIN will no longer be available in Kandji.',
            )}
          </Text>
        )}
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        {i18n.t('Cancel')}
      </Button>
      <Button
        variant="danger"
        loading={isLoading}
        disabled={confirmationText !== DELETE_WORD}
        onClick={onDelete}
      >
        {isLoading
          ? i18n.t('Deleting...')
          : i18n.t(
              `Delete Device {rowLength, plural, one {Record} other {Records}}`,
              { rowLength: computers.length },
            )}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={title}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerDelete;
