/* istanbul ignore file */
import { Button, Select } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import './common.css';
import { i18n } from 'src/i18n';

const SecurityAuditingFlags = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details || []);

  const prefixOptions = [
    { label: i18n.t('None'), value: 'none' },
    { label: i18n.t('Record successful events (+)'), value: '+' },
    { label: i18n.t('Record failed events (-)'), value: '-' },
    {
      label: i18n.t('Record neither successful or failed events (^)'),
      value: '^',
    },
    { label: i18n.t('Do not record successful events (^+)'), value: '^+' },
    { label: i18n.t('Do not record failed events (^-)'), value: '^-' },
  ];

  const flagOptions = [
    { label: i18n.t('File read (fr)'), value: 'fr' },
    { label: i18n.t('File write (fw)'), value: 'fw' },
    { label: i18n.t('File attribute access (fa)'), value: 'fa' },
    { label: i18n.t('File attribute modify (fm)'), value: 'fm' },
    { label: i18n.t('File create (fc)'), value: 'fc' },
    { label: i18n.t('File delete (fd)'), value: 'fd' },
    { label: i18n.t('File close (cl)'), value: 'cl' },
    { label: i18n.t('Process (pc)'), value: 'pc' },
    { label: i18n.t('Network (nt)'), value: 'nt' },
    { label: i18n.t('IPC operations (ip)'), value: 'ip' },
    { label: i18n.t('Non attributable (na)'), value: 'na' },
    { label: i18n.t('Administrative'), value: 'ad' },
    { label: i18n.t('Login / Logout (lo)'), value: 'lo' },
    { label: i18n.t('Authentication and authorization (aa)'), value: 'aa' },
    { label: i18n.t('Application (ap)'), value: 'ap' },
    { label: i18n.t('ioctl (io)'), value: 'io' },
    { label: i18n.t('Program execution (ex)'), value: 'ex' },
    { label: i18n.t('Miscellaneous (or)'), value: 'or' },
    { label: i18n.t('All flags set (all)'), value: 'all' },
  ];

  const {
    SecurityAuditingFlags: { displayInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      update({ details: value, isInvalid: !value.length });
    }
  }, [value]);

  const remainingFlags = flagOptions.filter(
    (f) => !value.find((d) => d.flag === f.value),
  );

  const onAdd = () =>
    setValue((prev) => {
      return [
        ...(prev || []),
        {
          prefix: prefixOptions[0].value,
          flag: remainingFlags[0].value,
        },
      ];
    });

  const onTrash = (id) =>
    setValue((prev) => [...prev.filter((_, idx) => idx !== id)]);

  return (
    <div className="">
      <div className="b-flex-vg1 b-mb1">
        {value.map((data, idx) => {
          return (
            <div
              key={`${data.prefix}_${data.flag}`}
              className="b-flex-vc b-flex-g1"
            >
              <div className="b-flex-vg1 b-flex1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
                  {i18n.t('Prefix:')}
                </p>
                <Select
                  options={prefixOptions}
                  disabled={isDisabled}
                  value={prefixOptions.find((p) => p.value === data.prefix)}
                  onChange={(v) =>
                    setValue((prev) => {
                      prev[idx].prefix = v.value;
                      return [...prev];
                    })
                  }
                />
              </div>
              <div className="b-flex-vg1 b-flex1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
                  {i18n.t('Flag:')}
                </p>
                <Select
                  options={remainingFlags}
                  disabled={isDisabled}
                  value={flagOptions.find((p) => p.value === data.flag)}
                  onChange={(v) =>
                    setValue((prev) => {
                      prev[idx].flag = v.value;
                      return [...prev];
                    })
                  }
                />
              </div>
              <Button
                className="bl-p-align-self-end"
                disabled={isDisabled}
                kind="link"
                icon="trash-can"
                theme="error"
                onClick={() => onTrash(idx)}
              />
            </div>
          );
        })}
      </div>
      <Button
        disabled={!remainingFlags.length || isDisabled}
        onClick={onAdd}
        icon="plus"
      >
        {i18n.t('Add')}
      </Button>
      {displayInvalid(!value.length)}
    </div>
  );
};

export default SecurityAuditingFlags;
