import { Button, setClass } from '@kandji-inc/bumblebee';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import './scss/actions.scss';
import { i18n } from 'src/i18n';

const Actions = (props: any) => {
  const {
    style,
    className,
    itemId,
    pageState,
    onSave,
    onDelete,
    onEdit,
    onCancel,
    onClose,
    isEditable,
  } = props;

  return (
    <div style={style} className={setClass('b-library__actions', className)}>
      <div className="b-library__actions-inner">
        <div className="b-library__actions-secondary">
          {pageState.isEditing && onDelete && !pageState.isAdding && (
            <Button
              disabled={pageState.isSaving || !itemId}
              kind="link"
              theme="error"
              onClick={onDelete}
            >
              {i18n.t('Delete')}
            </Button>
          )}
        </div>
        <div className="b-library__actions-primary b-grid-ctas">
          {pageState.isEditing ? (
            <Button
              isDisabled={pageState.isSaving}
              kind="outline"
              onClick={pageState.isAdding ? onClose : onCancel}
            >
              {i18n.t('Cancel')}
            </Button>
          ) : (
            <Button kind="outline" onClick={onClose}>
              {i18n.t('Close')}
            </Button>
          )}
          {isEditable &&
            (pageState.isEditing ? (
              <Button
                onClick={onSave}
                iconPlacement="right"
                disabled={!pageState.isValid}
              >
                {i18n.t('Save')}
              </Button>
            ) : (
              <Button onClick={onEdit}>{i18n.t('Edit')}</Button>
            ))}
        </div>
      </div>
    </div>
  );
};

Actions.propTypes = {
  style: object,
  className: string,
  onSave: func.isRequired,
  onEdit: func.isRequired,
  onCancel: func.isRequired,
  onDelete: func,
  onClose: func.isRequired,
  itemId: string,
  pageState: object.isRequired,
  isEditable: bool,
};

Actions.defaultProps = {
  style: {},
  className: null,
  onDelete: null,
  isEditable: true,
  itemId: null,
};

export default Actions;
