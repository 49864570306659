/* istanbul ignore file */
import { create } from 'zustand';
import {
  defaultAllVulnerabilitiesFilter,
  defaultDeviceVulnerabilitiesFilter,
} from './constants';
import type {
  AllVulnerabilitiesFilter,
  DeviceVulnerabilitiesFilter,
} from './vulnerability.types';

type State = {
  allVulnerabilitiesFilter: AllVulnerabilitiesFilter;
  deviceVulnerabilitiesFilter: DeviceVulnerabilitiesFilter;
};

type Action = {
  setAllVulnerabilitiesFilter: (
    filter: Partial<State['allVulnerabilitiesFilter']>,
  ) => void;
  setDeviceVulnerabilitiesFilter: (
    filter: Partial<State['deviceVulnerabilitiesFilter']>,
  ) => void;
};

const useVulnerability = create<Partial<State> & Partial<Action>>((set) => ({
  allVulnerabilitiesFilter: defaultAllVulnerabilitiesFilter,
  deviceVulnerabilitiesFilter: defaultDeviceVulnerabilitiesFilter,

  setAllVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      allVulnerabilitiesFilter: {
        ...state.allVulnerabilitiesFilter,
        ...filter,
      },
    })),
  setDeviceVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      deviceVulnerabilitiesFilter: {
        ...state.deviceVulnerabilitiesFilter,
        ...filter,
      },
    })),
}));

export type { State as VulnerabilityState };
export default useVulnerability;
