import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { AppMissingDetails } from '../IPA_VPP.types';

function serializerV1(details: AppMissingDetails) {
  const { app_name, version, bundle_id } = details;

  return new LibraryItemStatusLog()
    .addRow(i18n.t('App appears to be missing:'))
    .addRow(i18n.t('App Name'), app_name)
    .addRow(i18n.t('Version'), version)
    .addRow(i18n.t('Bundle Identifier'), bundle_id)
    .addRow(i18n.t('Attempting to re-install the app...'))
    .toString();
}

export const AppMissingAudit = {
  Default: serializerV1,
  1: serializerV1,
};
