/* istanbul ignore file - tested by the class tests */
import { ManagedOSIncompatibleDetails } from '../ManagedOS.types';

function serializerV1(details: Partial<ManagedOSIncompatibleDetails>) {
  const { reason, device_os_version, device_family, minimum_os_version } =
    details;

  return new ManagedOSIncompatibleDetails(
    reason,
    device_os_version,
    device_family,
    minimum_os_version,
  ).get_message();
}

export const ManagedOSIncompatibleSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
