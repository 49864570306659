import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: {} | null | undefined) {
  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        'The app has not reported as installed for over 3 hours. Kandji will re-attempt installation on the next daily check-in.',
      ),
    )
    .toString();
}

export const InstallAppStuckAudit = {
  Default: serializerV1,
  1: serializerV1,
};
