import { i18n } from 'src/i18n';
export const descriptionCharCount = 9999;

export const subcategoryCharCount = 50;

export const USER_TAG_CATEGORY = () => i18n.t('Other');

export const TAGS = () => [
  {
    label: i18n.t('Compliance'),
    value: 'Compliance',
  },
  {
    label: i18n.t('Reporting & Insights'),
    value: 'Reporting & Insights',
  },
  {
    label: i18n.t('End-user Onboarding'),
    value: 'End-user Onboarding',
  },
  {
    label: i18n.t('Integrations'),
    value: 'Integrations',
  },
  {
    label: i18n.t('Library Item Configuration'),
    value: 'Library Item Configuration',
  },
  {
    label: i18n.t('Device Information'),
    value: 'Device Information',
  },
  {
    label: i18n.t('API Enhancements'),
    value: 'API Enhancements',
  },
  {
    label: i18n.t('Device Enrollment'),
    value: 'Device Enrollment',
  },
  {
    label: i18n.t('Blueprint Configuration'),
    value: 'Blueprint Configuration',
  },
  {
    label: i18n.t('End-user Self Service'),
    value: 'End-user Self Service',
  },
  {
    label: i18n.t('Authentication & Access Management'),
    value: 'Authentication & Access Management',
  },
  {
    label: i18n.t('End-user Notification'),
    value: 'End-user Notification',
  },
  {
    label: i18n.t('Endpoint Security'),
    value: 'Endpoint Security',
  },
  {
    label: USER_TAG_CATEGORY(),
    value: 'Other',
  },
];

export const TAGS_SORTED = () =>
  TAGS().sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA === USER_TAG_CATEGORY().toLowerCase()) {
      return 1;
    }
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
