import { useQueries } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_BLUEPRINTS, URL_COMPUTERS } from 'app/api/urls';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { integrationsBackendService } from 'features/integrations/data-service/integrations-backend-service';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { useFeature as useIntegrationsFeature } from 'features/integrations/hooks';
import { applyFiltersAndOrdering as filterIntegrations } from 'features/integrations/overview/calc';
import { newLibraryItemService } from 'features/library-items/data-service/library-item/new-library-item-service';
import { transformLibraryItems } from 'features/library-items/library/api/transformer';
import { getFilteredItems } from 'features/library-items/library/common';
import { libraryItemsOrder } from 'features/library-items/library/library';
import * as React from 'react';

const SEARCH_LIMIT = 100;

const getComputers = (search: string) =>
  api(URL_COMPUTERS).get({
    search,
    sizePerPage: SEARCH_LIMIT,
  });

const getBlueprints = () => api(URL_BLUEPRINTS).get({});

const DEVICE_FAMILIES = ['Mac', 'iPhone', 'iPad', 'Apple TV'];

export const useUniversalSearchQueries = ({ search }: { search: string }) => {
  const environment = React.useContext(EnvironmentContext);
  const account = React.useContext(AccountContext);
  const isThreatEnabled =
    account?.currentCompany?.feature_configuration?.edr?.enabled;
  const searchTerm = search.trim().toLowerCase();
  const { mapCategoryType } = useIntegrationsFeature();

  const queries = [
    {
      queryKey: ['universal-search-devices', searchTerm],
      queryFn: () => getComputers(searchTerm),
      enabled: search.length > 0,
    },
    {
      queryKey: ['universal-search-blueprints'],
      queryFn: () => getBlueprints(),
    },
    {
      queryKey: ['universal-search-library-items'],
      queryFn: () => newLibraryItemService.v2List(),
    },
    {
      queryKey: ['universal-search-integrations-main'],
      queryFn: () => newIntegrationsService.listIntegrations(''),
    },
    {
      queryKey: ['universal-search-integrations-backend'],
      queryFn: () =>
        integrationsBackendService.getIntegrations({ page: '0', size: '100' }),
    },
  ];

  const results = useQueries({ queries });

  const isPending =
    searchTerm.length > 0 && results.some((result) => result.isPending);

  const devices = results[0].data?.data?.results ?? [];

  const blueprints = (results[1].data?.data ?? [])
    .filter(({ name }) => name.toLowerCase().includes(searchTerm))
    .slice(0, SEARCH_LIMIT);

  const libItemsOrder = React.useMemo(() => {
    return libraryItemsOrder(isThreatEnabled);
  }, [isThreatEnabled]);

  const transformedLibraryItems = transformLibraryItems(
    results[2].data?.data ?? [],
    { environment, account },
    true,
  );
  const filteredLibraryItems = getFilteredItems(
    transformedLibraryItems,
    { searchTerm, devices: DEVICE_FAMILIES, category: '' },
    libItemsOrder,
    (i) => i.defaultConfiguration.isHidden({ account, environment }),
  );
  const libraryItems = filteredLibraryItems
    .reduce((acc, { data }) => {
      return [...acc, ...data];
    }, [])
    .slice(0, SEARCH_LIMIT);

  const integrationsFetching = results[3].isFetching || results[4].isFetching;
  const integrationsMain = results[3].data?.data?.results ?? [];
  const integrationsBackend = results[4].data?.data?.items ?? [];
  const integrations = integrationsFetching
    ? []
    : filterIntegrations(
        [...integrationsMain, ...integrationsBackend].map((integration) => {
          const category = Object.entries(mapCategoryType).find(([, types]) =>
            (types as string[]).includes(integration?.type),
          )?.[0];
          return category ? { ...integration, category } : null;
        }),
        { search },
      ).slice(0, SEARCH_LIMIT);

  return {
    data: {
      devices,
      blueprints,
      libraryItems,
      integrations,
    },
    isPending,
  };
};
