import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: null | undefined | {}) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('The device name was excluded.'))
    .toString();
}

export const DeviceNameExcludedSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
