import React, { useCallback, useContext } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { i18n } from 'i18n';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import type { SafariExtensionsProps } from './safari-extensions.types';
import AllExtensions from './sections/AllExtensions';
import SpecificExtensions from './sections/SpecificExtensions';
import { safariExtensionsService } from './service/safari-extensions-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSafariExtensionsService from './service/use-safari-extensions-service';

const SafariExtensionsPage = (props: SafariExtensionsProps) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const specificExtensionsUpdate = useCallback(
    update('specificExtensions', setModel),
    [],
  );
  const allExtensionsUpdate = useCallback(
    update('allExtensions', setModel),
    [],
  );

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements.map((os) =>
          i18n.t('{os} (Requires Supervision)', { os }),
        ),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={safariExtensionsService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      {/* insert section components here */}
      <AllExtensions
        settings={model.allExtensions}
        isDisabled={pageState.isDisabled}
        update={allExtensionsUpdate}
        validationDep={validationDep}
      />
      <SpecificExtensions
        settings={model.specificExtensions}
        isDisabled={pageState.isDisabled}
        update={specificExtensionsUpdate}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const SafariExtensions = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useSafariExtensionsService,
  });

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <SafariExtensionsPage {...pageProps} testId="safari-extensions-v2" />;
};

export default SafariExtensions;
