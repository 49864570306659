import { Toaster as toaster } from '@kandji-inc/bumblebee';

import {
  Banner,
  Button,
  Dialog,
  Flex,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';

const trimWhitespaces = (str) => str.trim().replace(/\s+/g, ' ');

const Modal = (props) => {
  const { isOpen, setIsOpen, computer, updateComputerFields } = props;

  const [isWorking, setIsWorking] = useState(false);

  const [initial, setInitial] = useState(undefined);
  const [value, setValue] = useState(undefined);

  const { id, asset_tag } = computer;

  const maxLength = 100;
  const isSaveDisabled =
    isWorking || initial === value || value.length > maxLength;

  const onChange = ({ target }) => setValue(target.value);

  const onClose = () => {
    setIsOpen(false);
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = async () => {
    try {
      const editedValue = trimWhitespaces(value) || null;

      setIsWorking(true);

      await updateComputerFields(id, { asset_tag: editedValue });

      setIsWorking(false);

      toaster(i18n.t('Asset tag updated.'));

      onClose();
    } catch (error) {
      console.error(error);

      setIsWorking(false);

      toaster(i18n.common.error());

      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const assetTag = asset_tag || '';

      setInitial(assetTag);
      setValue(assetTag);
    }
  }, [isOpen]);

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        {i18n.t(
          'Assign an asset tag to help identify this device in your organization.',
        )}
      </Text>
      <Banner
        theme="warning"
        text={i18n.t(
          'If leveraging the asset tag Assignment Rule, changing the asset tag may affect the Library Items assigned to this device.',
        )}
      />
      <TextField
        label={i18n.t('Asset tag')}
        value={value}
        onChange={onChange}
        hint={{
          charCounter: {
            count: value?.length,
            max: maxLength,
          },
        }}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button variant="subtle" onClick={onCancel}>
        {i18n.t('Cancel')}
      </Button>
      <Button
        variant="primary"
        loading={isWorking}
        disabled={isSaveDisabled}
        onClick={onSave}
      >
        {i18n.t('Save')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      title={i18n.t('Set asset tag')}
      content={content}
      footer={footer}
    />
  );
};

Modal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  computer: object.isRequired,
  updateComputerFields: func.isRequired,
};

export default Modal;
