import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type AppAppleStatusDetails,
  ManagedAppStatuses,
} from '../IPA_VPP.types';

function serializerV1(details: AppAppleStatusDetails) {
  const { app_status } = details;

  return new LibraryItemStatusLog()
    .addRow(ManagedAppStatuses(app_status))
    .toString();
}

export const InstallInProgressAudit = {
  Default: serializerV1,
  1: serializerV1,
};
