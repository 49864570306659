/* istanbul ignore file */
import './actions.css';

import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { LiftoffUploader } from 'components/common/s3-upload';
import deepcopy from 'deepcopy';

import DeleteModal from 'features/library-items/common/library-item-page/delete-modal';

import {
  transformFromApi,
  transformToApi,
} from 'features/library-items/data-service/library-item/transformers';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import _ from 'lodash';

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { kandjiSetupService } from '../service/kandji-setup-service';

import { i18n } from 'i18n';
import isAnyEmpty from './is-any-empty';

const Actions = (props) => {
  const {
    setBeforeEditModel,
    beforeEditModel,
    model,
    setModel,
    isLoadingBpOptions,
    blueprintConflicts,
    canEdit,
    isDeleteModalOpen,
    onDeleteModalOpen,
    onDeleteModalClose,
  } = props;

  const {
    isEditingPage,
    setIsEditingPage,
    itemId,
    itemType,
    isAdding,
    isSaving,
    setIsSaving,
    isAnyInputsInvalid,
    previousPage,
  } = useContext(KandjiSetupCtx);

  const history = useHistory();
  const { state } = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [enrollOrScreenNotSet, setEnrollOrScreenNotSet] = useState(true);

  const goBack = () => {
    // Route to another section if the user came from somewhere like /blueprints, or
    // just go back to the library items list:

    // Note: previousPage is set from a react-router-dom Link component's `to` prop
    // by passing it an object to set on the router state
    history.push(previousPage || '/library');
  };

  const reset = (isAdding = false) => {
    setIsSaving(false);
    if (!isAdding) {
      setIsEditingPage(false);
    }
  };

  const cancel = () => {
    setModel(beforeEditModel);
    reset();
  };
  const close = () => goBack();

  const uploadLogos = async () => {
    const { logo, logoUrl, logoDark, logoDarkUrl } = model.general;
    const objectForm = {};

    if (!logoUrl) {
      objectForm.logo = logo.file;
    }

    /* logo dark is optional */
    if (!logoDarkUrl && logoDark.file) {
      objectForm.logoDark = logoDark.file;
    }

    model.successScreen.links.forEach((link) => {
      if (!link.iconUrl) {
        objectForm[link.uuid] = link.icon.file;
      }
    });

    if (!Object.keys(objectForm).length) {
      return Promise.resolve();
    }

    return Promise.all(
      Object.keys(objectForm).map((key) => {
        const file = objectForm[key];

        return (
          file &&
          // Get presigned URL from the backend
          LiftoffUploader.getS3UploadData([{ name: file.name }])
            .then((s3Data) =>
              // Upload the file
              LiftoffUploader.upload({
                file,
                s3Data: s3Data.data.files[0].s3_post_data,
              }).then(() => s3Data),
            )
            .then((s3Data) => {
              // Update the model with the s3 key
              setModel((prev) => {
                const updated = { ...prev };
                const s3Key = s3Data.data.files[0].s3_post_data.fields.key;

                if (key === 'logo') {
                  updated.general.logo = {};
                  updated.general.logoS3Key = s3Key;
                } else if (key === 'logoDark') {
                  updated.general.logoDark = {};
                  updated.general.logoDarkS3Key = s3Key;
                } else {
                  const linkIdx = updated.successScreen.links.findIndex(
                    (l) => l.uuid === key,
                  );
                  if (linkIdx >= 0) {
                    updated.successScreen.links[linkIdx].icon = {};
                    updated.successScreen.links[linkIdx].iconS3Key = s3Key;
                  }
                }

                return updated;
              });
            })
        );
      }),
    );
  };

  let canSave = true;

  const save = async () => {
    // @TODO remove this hack to trigger validation once we switch to the common LI Summary component:
    document?.querySelector('#liftoff-title')?.focus();
    document?.querySelector('#liftoff-title')?.blur();

    const [canContinue, resolvedBps] = await blueprintConflicts.checkConflicts({
      type: 'kandji-setup',
      blueprints: model.blueprints,
      itemId: model.id,
      setBlueprints: (blueprints) => setModel((p) => ({ ...p, blueprints })),
      onError: () => toaster(i18n.t('There was issue adding your Blueprints')),
    });

    if (canContinue) {
      setIsSaving(true);

      const modelWithResolvedBps = {
        ...model,
        blueprints: resolvedBps,
        skip_blueprint_conflict: true,
      };

      if (isAdding) {
        uploadLogos(modelWithResolvedBps)
          .then(() => {
            const data = transformToApi(modelWithResolvedBps);
            canSave = !isAnyEmpty(data.data, {
              ignore: [
                'logo',
                'logoUrl',
                'icon',
                'iconUrl',
                'logoDarkUrl',
                'logoDark',
                'logoDarkS3Key',
                'logoWidth',
                'logoHeight',
                'id',
                'logoDarkWidth',
                'logoDarkHeight',
              ],
            });
            if (!canSave) {
              toaster(
                i18n.t('All required fields must be set, please try again'),
              );
              return true;
            }
            return kandjiSetupService
              .create(data)
              .then((r) => history.push(`/library/${itemType}/${r.data.id}`));
          })
          .catch((e) => {
            const iconUploadErr = _.find(e.response.data, 'error');
            setIsSaving(false);
            // i18n:fix
            toaster(
              i18n.t("Couldn't create Library Item, please try again.") +
                '\n' +
                iconUploadErr?.error
                ? i18n.t('File extension not allowed.')
                : '',
            );
          })
          .finally(() => reset(true));
      } else {
        uploadLogos(modelWithResolvedBps)
          .then((r) => {
            const data = transformToApi(modelWithResolvedBps);
            canSave = !isAnyEmpty(data.data, {
              ignore: [
                'logo',
                'logoUrl',
                'icon',
                'iconUrl',
                'logoDarkUrl',
                'logoDark',
                'logoDarkS3Key',
                'logoWidth',
                'logoHeight',
                'logoDarkWidth',
                'logoDarkHeight',
              ],
            });

            if (!canSave) {
              toaster(
                i18n.t('All required fields must be set, please try again'),
              );
              setIsSaving(false);
              return false;
            }
            return kandjiSetupService.patch(itemId, data);
          })
          .then(async (r) => {
            if (r) {
              const transformed = await transformFromApi(r.data);
              setModel(transformed.data);
              toaster(i18n.t('Updated Library Item successfully!'));
              reset();
            }
          })
          .catch((e) => {
            const iconUploadErr = _.find(e.response?.data, 'error');
            setIsSaving(false);
            toaster(
              i18n.t("Couldn't update Library Item, please try again.") +
                '\n' +
                iconUploadErr?.error
                ? i18n.t('File extension not allowed.')
                : '',
            );
          });
      }
    }
  };

  const remove = () => {
    setIsDeleting(true);
    kandjiSetupService
      .remove(itemId)
      .then(goBack)
      .catch((e) => {
        toaster(i18n.t("couldn't remove Library Item."));
      });
  };

  const onDeleteModalConfirm = () => remove();

  useEffect(() => {
    if (state?.isDuplicate) {
      setIsEditingPage(true);

      // Remove `isDuplicate` from location state so that the initial
      // editing state for duplicate items does not persist on refresh
      const newState = state;
      delete newState.isDuplicate;
      window.history.replaceState(newState, document.title);
    }
  }, [model?.id]);

  // Necessary for duplication as to update the `beforeEditModel` state
  // immediately after being redirected to the clone.
  useEffect(() => {
    setBeforeEditModel(deepcopy(model));
  }, [model?.id]);

  useEffect(() => {
    setEnrollOrScreenNotSet(
      model.general.enrollmentTrigger === '' ||
        model.general.displayMode === '',
    );
  }, [model]);

  return (
    <div className="k-library__actions">
      <div className="k-library__actions-inner">
        <div className="k-library__actions-secondary">
          {canEdit && isEditingPage && (
            <Button
              style={{ display: isAdding || !model.id ? 'none' : 'block' }}
              disabled={!isEditingPage || isSaving || !model.id}
              kind="link"
              theme="error"
              onClick={onDeleteModalOpen}
            >
              {i18n.t('Delete')}
            </Button>
          )}
        </div>
        <div className="k-library__actions-primary b-grid-ctas">
          {canEdit && isEditingPage ? (
            <Button
              isDisabled={isSaving}
              kind="outline"
              onClick={isAdding ? close : cancel}
            >
              {i18n.t('Cancel')}
            </Button>
          ) : (
            <Button kind="outline" onClick={close}>
              {i18n.t('Close')}
            </Button>
          )}
          {canEdit && isEditingPage && (
            <Button
              isProgress={isSaving}
              onClick={save}
              disabled={
                blueprintConflicts.conflictsState.showModal ||
                blueprintConflicts.conflictsState.isChecking ||
                enrollOrScreenNotSet
              }
              icon={isSaving ? 'arrows-rotate' : ''}
              iconPlacement="right"
            >
              {i18n.t('Save')}
            </Button>
          )}
          {canEdit && !isEditingPage && (
            <Button
              onClick={() => {
                setIsEditingPage(true);
                setBeforeEditModel(deepcopy(model));
              }}
              disabled={!model.blueprints || isLoadingBpOptions}
            >
              {i18n.t('Edit')}
            </Button>
          )}
        </div>
      </div>

      <DeleteModal
        itemName={model?.name}
        itemType="Liftoff"
        isDeleting={isDeleting}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onConfirm={onDeleteModalConfirm}
      />
    </div>
  );
};

export default Actions;
