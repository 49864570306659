/* istanbul ignore file */
import './styles.css';

import { Flex, Heading, Tabs } from '@kandji-inc/nectar-ui';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import MultiFactorAuthentication from 'features/multi-factor-authentication';
import UserProfile from 'features/user-profile';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { i18n } from 'src/i18n';

const layoutOverrides = {
  tabs: {
    marginLeft: '24px',
  },
  container: {
    margin: '0 calc(var(--k-main-layout-side-padding) * -1)',
    height: '100%',
  },
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
};

const UserProfilePage = () => {
  useWhiteBackground();
  const { isEmailPasswordAuth0 } = useContext(AccountContext);
  const interfaceContext = useContext(InterfaceContext);
  const history = useHistory();
  const location = useLocation();

  // Get current tab from URL
  const getCurrentTab = () => {
    if (location.pathname.endsWith('/mfa')) {
      return 'multi-factor-authentication';
    }
    return 'general';
  };

  const tabs = [
    {
      label: i18n.t('General'),
      tabId: 'general',
      css: layoutOverrides.tabs,
      onClick: () => history.push('/user-profile'),
    },
  ];

  if (isEmailPasswordAuth0) {
    tabs.push({
      label: i18n.t('Multi-Factor Authentication'),
      tabId: 'multi-factor-authentication',
      css: layoutOverrides.tabs,
      onClick: () => history.push('/user-profile/mfa'),
    });
  }

  return (
    <Flex flow="column" gap="2" css={layoutOverrides.container}>
      <Flex
        css={{
          ...layoutOverrides.content,
          borderBottom: !isEmailPasswordAuth0 ? '1px solid $neutral20' : 'none',
          '& h2': {
            p: !isEmailPasswordAuth0
              ? '16px 24px 16px 0px'
              : '32px 0px 16px 0px',
          },
          position: 'sticky',
          top: `${interfaceContext.bannerTopOffset}px`,
          zIndex: 10,
          backgroundColor: '$neutral0',
        }}
      >
        <Heading as="h2" size="1">
          {i18n.t('Profile')}
        </Heading>
      </Flex>
      {isEmailPasswordAuth0 ? (
        <Tabs.Container
          tabs={tabs}
          tabId={getCurrentTab()}
          css={{
            [`& ${Tabs.Styled.Group}`]: {
              position: 'sticky',
              top: `${interfaceContext.bannerTopOffset + 72}px`,
              zIndex: 10,
              backgroundColor: '$neutral0',
            },
          }}
        >
          <Tabs.Content tabId="general">
            <UserProfile />
          </Tabs.Content>
          <Tabs.Content tabId="multi-factor-authentication">
            <MultiFactorAuthentication />
          </Tabs.Content>
        </Tabs.Container>
      ) : (
        <UserProfile />
      )}
    </Flex>
  );
};

export default UserProfilePage;
