/* istanbul ignore file - tested by the class tests */
import { ManagedOSPassDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSPassDetails) {
  const {
    library_item_name,
    is_version_updated,
    is_self_service,
    no_manage_updates,
  } = details;

  return new ManagedOSPassDetails(
    library_item_name,
    is_version_updated,
    is_self_service,
    no_manage_updates,
  ).get_message();
}

export const ManagedOSPassSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
