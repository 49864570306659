import { TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { i18n } from 'src/i18n';

const SingleNumberInput = (props) => {
  const {
    param,
    update,
    isDisabled,
    defaultValue,
    min,
    max,
    label,
    validations,
    validationKey,
  } = props;
  const { details } = param;
  const [value, setValue] = useState(isNaN(details) ? defaultValue : details);
  const isInRange =
    (isNaN(min) || parseInt(value) >= min) &&
    (isNaN(max) || parseInt(value) <= max);

  const {
    [validationKey]: { syncInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(isNaN(details) ? defaultValue : details);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        if (!details && isNaN(details)) {
          update({ details: defaultValue });
        }

        update({ details: parseInt(value), isInvalid: !isInRange });
      }, 250);

      return () => {
        clearTimeout(time);
      };
    }
  }, [value]);

  const existingValidators = (v) => [
    {
      message: i18n.t('Required'),
      invalid: () => !v,
      trigger: ['onBlur', 'onInput', isDisabled],
    },
    {
      message: i18n.t('Value not in range: {valueMap}', {
        valueMap: [
          { label: 'Min', value: min },
          { label: 'Max', value: max },
        ]
          .map((n) => (isNaN(n.value) ? null : `${n.label}: ${n.value}`))
          .join(' - '),
      }),
      invalid: () => !isInRange,
      trigger: ['onBlur', 'onInput', isDisabled],
    },
  ];

  return (
    <div className="b-mb1" style={{ maxWidth: '65%' }}>
      <TextInput
        fieldsGrid
        type="number"
        disabled={isDisabled}
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        validator={existingValidators}
        {...syncInvalid(!isInRange, {
          key: param.parameter.id,
        })}
      />
    </div>
  );
};

export default SingleNumberInput;
