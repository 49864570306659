import { i18n } from 'i18n';
import type { ManagedLibraryItemInstallFailedDetails } from '../ManagedLibraryItems.types';

function getFailedMessage(num_attempts: number) {
  const failedDetailsMap = {
    1: i18n.t(
      'Installation has failed. Kandji will attempt installation up to two (2) more times.',
    ),
    2: i18n.t(
      'Installation has failed two (2) times in a row. Kandji will attempt installation one (1) more time.',
    ),
    3: i18n.t(
      'Installation has failed three (3) times in a row. Please flush this Library Item if you want Kandji to attempt installation again.',
    ),
  };

  return failedDetailsMap[num_attempts] != null
    ? failedDetailsMap[num_attempts]
    : '';
}

function serializerV1(
  details: Partial<ManagedLibraryItemInstallFailedDetails>,
) {
  const { num_attempts } = details;

  return getFailedMessage(num_attempts);
}

export const InstallFailedAudit = {
  Default: serializerV1,
  1: serializerV1,
};
