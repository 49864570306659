import { Button, Flex, styled } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';
import type { ReactNode } from 'react';

const Container = styled(Flex, {
  backgroundColor: '$neutral0',
  boxShadow: 'var(--shadows-inset_border_left_1) var(--colors-neutral30)',
  flexDirection: 'column',
  gap: '$4',
  height: 'calc(100% - 210px)',
  width: '448px',
  padding: '$3 $5',
  position: 'fixed',
  right: 0,
  zIndex: 3,
});

const Header = styled(Flex, {
  alignItems: 'center',
  gap: '$2',
  justifyContent: 'space-between',
});

const Body = styled(Flex, {
  flex: 1,
  overflowY: 'scroll',
});

type SplitViewProps = {
  header: ReactNode;
  body: ReactNode;
  onClose: () => void;
  css?: CSS;
};

const EDRSplitView = (props: SplitViewProps) => {
  const { header, body, onClose, css } = props;

  return (
    <Container css={css}>
      <Header>
        {header}
        <Button
          compact
          variant="subtle"
          onClick={onClose}
          icon={{ name: 'fa-xmark-large-close' }}
          css={{
            svg: { width: 20, height: 20 },
            padding: '$2',
            alignSelf: 'start',
          }}
          data-testid="side-panel-close-button"
        />
      </Header>

      <Body>
        <Flex css={{ flexDirection: 'column' }}>{body}</Flex>
      </Body>
    </Container>
  );
};

export { EDRSplitView };
