import { Buffer } from 'buffer/';
import queryString from 'query-string';
import featureFlags from 'src/config/feature-flags';
import { fetchEncodedConfig, validateEnrollmentCode } from '../api';
import { decodeMetadata } from './decodeMetadata';
import { setUserToSubdomain } from './setUserToSubdomain';

export function transformIfLocal(url: string) {
  const domain = window.location.origin;
  const isLocalhost =
    domain && (domain.includes('localhost:') || domain.includes('127.0.0.1:'));
  return isLocalhost ? url.replace(/https:\/\/([^|/]*)/g, domain) : url;
}

export async function validateAndDownloadConfig(
  accessCode: string,
  locale: any,
): Promise<{ downloadUrl: string; blueprintId: string; redirect: boolean }> {
  const queryParams = queryString.parse(window.location.search);
  const metadata = decodeMetadata((queryParams.metadata as string) ?? '');

  const { callback_url: authUrl } = metadata;

  await setUserToSubdomain();

  const manualEnrollmentV2Enabled = featureFlags.getFlag(
    'core_080924_manual_enrollment_v2',
  );

  const { blueprint_id: blueprintId } =
    await validateEnrollmentCode(accessCode);

  if (!manualEnrollmentV2Enabled) {
    return {
      blueprintId,
      downloadUrl: null,
      redirect: false,
    };
  }

  const { base64encodedProfile, url: redirectUrl } = await fetchEncodedConfig(
    blueprintId,
    accessCode,
    authUrl,
  );

  if (redirectUrl) {
    const url = new URL(transformIfLocal(redirectUrl));

    if (locale != null) {
      url.searchParams.append('locale', locale);
    }

    window.location.href = url.toString();
    return { downloadUrl: null, blueprintId, redirect: true };
  }

  const configBufferArray = Buffer.from(base64encodedProfile, 'base64');
  const configBlob = new Blob([configBufferArray], {
    type: 'application/x-apple-aspen-config',
  });

  return {
    downloadUrl: URL.createObjectURL(configBlob),
    blueprintId,
    redirect: false,
  };
}
