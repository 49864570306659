/* istanbul ignore file - tested by the class tests */
import { ManagedOSCommandDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSCommandDetails) {
  const {
    state,
    library_item_name,
    download_percent,
    is_downloaded,
    error_status,
  } = details;

  return new ManagedOSCommandDetails(
    state,
    library_item_name,
    download_percent,
    is_downloaded,
    error_status,
  ).get_message();
}

export const ManagedOSCommandSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
