import { defaultFilters } from 'app/common/constants';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import React from 'react';
import history from '../../router/history';
import AwesomeButtonGroup from '../interface/buttons/AwesomeButtonGroup';
import { PaginationPanel } from './PaginationPanel';

const queryString = require('query-string');

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.serverSidePagination = false;
    this.serverSideSorting = false;
    this.processAfterDataFetched = null;
    this.UNSAFE_componentWillReceivePropsAdditionalCondition = null;
    this.UNSAFE_afterComponentWillReceiveProps = null;

    this.selectedRows = new Set();
    this.selectRowId = '';
    const activeTableFilters =
      JSON.parse(localStorage.getItem('activeTableFilters')) || {};
    const queryParams = props.location
      ? queryString.parse(props.location.search, { arrayFormat: 'bracket' })
      : {};
    this.state = {
      data: [],
      currPage: queryParams.page || defaultFilters.page,
      totalItems: 0,
      pageSizes: [25, 50, 100, 300],
      sizePerPage: queryParams.sizePerPage || defaultFilters.sizePerPage,
      next: null,
      prev: null,
      isLoading: false,
    };
    this.defaultOptions = {
      defaultSortName: 'id', // default sort column name
      defaultSortOrder: 'asc', // default sort order
      sizePerPage: activeTableFilters.sizePerPage || 25, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: '\uf104', // Previous page button text
      nextPage: '\uf105', // Next page button text
      firstPage: '\uf100', // First page button text
      lastPage: '\uf101', // Last page button text
      paginationShowsTotal: false, // Accept bool or function
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      hideSizePerPage: true,
      alwaysShowAllBtns: true, // Always show next and previous button
      onPageChange: this.onPageChange.bind(this),
      page: this.state.currPage,
      btnGroup: this.renderActionButtons.bind(this),
      toolBar: this.createCustomToolBar,
      paginationPanel: this.renderPaginationPanel,
      sizePerPageDropDown: this.renderSizePerPageDropDownAndCounts,
      savePageToCache: this.savePageToCache,
      getPageFromCache: this.getPageFromCache,
      initCurrPage: this.initCurrPage,
      resultsString: i18n.t('Results'), // very strange thing
    };
    this.selectRowProp = {
      mode: 'checkbox',
      onSelect: this.onRowSelect.bind(this),
      onSelectAll: this.onSelectAll.bind(this),
      columnWidth: '40px',
      bgColor: '#f3f9ff',
      customComponent: this.customCheckbox,
    };

    this.serverSireSorting = this.serverSireSorting.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const search = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    if (this.serverSideSorting) {
      this.serverSireSorting(search.ordering);
    }
    if (typeof this.fetchFunc === 'function') {
      this.fetchData(true);
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { location } = this.props;
    if (
      !isEqual(next.location, location) ||
      (this.UNSAFE_componentWillReceivePropsAdditionalCondition &&
        this.UNSAFE_componentWillReceivePropsAdditionalCondition())
    ) {
      const nextSearch = queryString.parse(next.location.search, {
        arrayFormat: 'bracket',
      });
      if (this.serverSideSorting) {
        this.serverSireSorting(nextSearch.ordering);
      }
      const pageNumber = nextSearch.page || defaultFilters.page;
      this.setState(
        {
          sizePerPage:
            nextSearch.sizePerPage ||
            this.defaultPageSize ||
            defaultFilters.sizePerPage,
          currPage: parseInt(pageNumber, 10),
        },
        () => {
          this.fetchData(true);
          if (this.UNSAFE_afterComponentWillReceiveProps) {
            this.UNSAFE_afterComponentWillReceiveProps();
          }
        },
      );
    }
  }

  makeQueryParams = () =>
    queryString.parse(location.search, { arrayFormat: 'bracket' });

  serverSireSorting = (ordering) => {
    if (this.serverSideSorting) {
      if (ordering) {
        this.defaultOptions.sortName = ordering.replace('-', '');
        if (ordering.startsWith('-')) {
          this.defaultOptions.sortOrder = 'desc';
        } else {
          this.defaultOptions.sortOrder = 'asc';
        }
      }
    }
  };

  fetchData = (clearPrev) => {
    if (!this.fetchFunc) {
      return null;
    }

    const { sizePerPage, data } = this.state;

    if (clearPrev) {
      this.setState({ params: [], isLoading: true });
      this.last = null;
      this.lastPage = null;
    }

    this.setState({ isLoading: true }, () => {
      this.fetchFunc(this.makeQueryParams())
        .then(({ results, next, previous, count }) => {
          if (this.processAfterDataFetched) {
            this.processAfterDataFetched();
          }

          this.setState(
            {
              data: clearPrev ? results : [...data, ...results],
              isLoading: false,
              lastPage: Math.ceil(count / sizePerPage),
              prev: previous,
              totalItems: count,
              next,
            },
            () => {
              /* istanbul ignore next */
              if (this.props.scrollY) {
                window.scrollTo(0, this.props.scrollY);
                this.props.setPageScroll(null);
              }
            },
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
    return null;
  };

  changeFilterInUrl = (filters) => {
    const { location, match } = this.props;
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    if (isEqual(filters, queryParams)) {
      return null;
    }
    const newFilters = queryString.stringify(
      { ...queryParams, ...filters },
      { arrayFormat: 'bracket' },
    );
    history.push(`${location.pathname}?${newFilters}`);
    return null;
  };

  onPageChange(page) {
    if (this.refs.table) {
      this.refs.table.cleanSelected();
      this.state.selectedRows.clear();
    }
    this.savePageToCache(page);
  }

  getPageFromCache = () => {
    const page = localStorage.getItem('pagination.currentPage');
    return page ? parseInt(page, 10) : null;
  };

  savePageToCache = (page) => {
    if (window.location.pathname !== '/blueprints') {
      localStorage.setItem('pagination.currentPage', page);
    }
  };

  renderActionButtons() {
    return null;
  }

  onRowSelect(row, isSelected) {
    const { selectedRows } = this.state;
    if (isSelected) {
      selectedRows.add(row[this.selectRowId]);
    } else {
      selectedRows.delete(row[this.selectRowId]);
    }
    this.setState({ selectedRows });
  }

  onSelectAll(isSelected, rows) {
    const { selectedRows } = this.state;
    if (isSelected) {
      rows.map((row) => selectedRows.add(row[this.selectRowId]));
    } else {
      selectedRows.clear();
    }
    this.setState({ selectedRows });
  }

  createCustomToolBar = (props) => (
    <div className="d-flex" style={{ width: '100%', flexWrap: 'wrap' }}>
      <div className="col-sm-12 col-md-12 col-lg-10 results-filters">
        {props.components.btnGroup}
      </div>
      <div className="col-sm-12 col-md-12 col-lg-2">
        {props.components.searchPanel}
      </div>
    </div>
  );

  renderSizePerPageDropDownAndCounts = (props) => {
    const { pageSizes, sizePerPage } = this.state;
    return (
      <AwesomeButtonGroup
        options={pageSizes}
        numerableOptions
        selected={Number(
          this.serverSidePagination ? sizePerPage : props.currSizePerPage,
        )}
        onChange={(value) => {
          if (this.serverSidePagination) {
            this.changeFilterInUrl({ sizePerPage: Number(value), page: 1 });
          } else {
            props.changeSizePerPage(Number(value));
          }
        }}
        label={i18n.t('Results Per Page')}
      />
    );
  };

  // revisit to see if both sides of the ternaries are ever even used
  renderPaginationPanel = ({
    components,
    sizePerPage: sizePerPageProp,
    dataSize,
    currPage: currPageProp,
    totalPages,
    resultsString,
    changePage,
  }) => {
    const { sizePerPage, totalItems, currPage, lastPage } = this.state;
    const { serverSidePagination } = this;
    const { changeFilterInUrl } = this;
    const { sizePerPageDropdown } = components;
    return (
      <PaginationPanel
        sizePerPageNode={sizePerPageDropdown}
        sizePerPage={Number(
          serverSidePagination ? sizePerPage : sizePerPageProp,
        )}
        totalItems={serverSidePagination ? totalItems : dataSize}
        currPage={serverSidePagination ? currPage : currPageProp}
        lastPage={serverSidePagination ? lastPage : totalPages}
        labelName={resultsString}
        changeFilterInUrl={
          serverSidePagination ? changeFilterInUrl : (e) => changePage(e.page)
        }
      />
    );
  };

  customCheckbox = ({
    type,
    checked,
    disabled,
    onChange,
    rowIndex,
    hidden = false,
  }) => (
    <div
      className="checkbox-custom"
      style={{ visibility: hidden ? 'hidden' : 'visible' }}
    >
      <label htmlFor={`checkbox${rowIndex}`} className="checkbox-container">
        <input
          type={type}
          name={`checkbox${rowIndex}`}
          id={`checkbox${rowIndex}`}
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e, rowIndex)}
        />
        <span className="checkmark" />
      </label>
    </div>
  );
}

export default Table;
