/* istanbul ignore file */
import { ManagedLibraryItemStatusDetails } from '../ManagedLibraryItems.types';

function serializerV1(details: Partial<ManagedLibraryItemStatusDetails>) {
  const {
    managed_library_item_type,
    newest_version,
    newest_bundle_version,
    installed_version,
    installed_bundle_version,
    is_up_to_date,
    is_newer,
    app_name,
    app_installed_version_name,
    newest_version_display,
    minimum_app_version_display,
    version_enforcement,
    show_in_self_service,
    is_continuously_enforced_on_update_only,
    enforcement_delay,
    is_enforcement_active,
    enforcement_date,
  } = details;

  return new ManagedLibraryItemStatusDetails(
    managed_library_item_type,
    newest_version,
    newest_bundle_version,
    installed_version,
    installed_bundle_version,
    is_up_to_date,
    is_newer,
    app_name,
    app_installed_version_name,
    newest_version_display,
    minimum_app_version_display,
    version_enforcement,
    show_in_self_service,
    is_continuously_enforced_on_update_only,
    enforcement_delay,
    is_enforcement_active,
    enforcement_date,
  ).get_message();
}

export const OutdatedAuditLog = {
  Default: serializerV1,
  1: serializerV1,
};
