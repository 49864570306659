import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getFileVaultColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const fileValutSchema = schemas.find((schema) => schema.uri === 'filevault');

  if (!fileValutSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...fileValutSchema.schema.properties,
  };

  const fileVaultColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    fileVaultColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    fileVaultColumnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.status.title,
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.key_type, {
      id: 'key_type',
      meta: {
        displayName: combinedProperties.key_type.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          fileValutSchema.schema?.definitions.KeyType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.key_escrowed, {
      id: 'key_escrowed',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.key_escrowed.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.regeneration_needed, {
      id: 'regeneration_needed',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.regeneration_needed.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.scheduled_key_rotation, {
      id: 'scheduled_key_rotation',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.scheduled_key_rotation.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
