import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import config from 'features/library-items/library/library-item-configurations/items/profile';
import { useCallback } from 'react';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { i18n } from 'src/i18n';
/* istanbul ignore */
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import initialState from './initial-state';
import General from './sections/General';
import { lsmService } from './service/lsm-service';
import { transformFromApi, transformToApi } from './service/lsm-transformers';
import useService from './service/use-lsm-service';

const LockScreenMessagePage = (props) => {
  const { model, setModel, pageState, handlers } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const itemConfig = config.LockScreenMessage;

  /* istanbul ignore next */
  const updateSetting = useCallback(update('setting', setModel), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig
          .getOsRequirements()
          .map((os) => `${os} (${i18n.t('Requires Supervision')})`),
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      service={lsmService}
      defaultIcon={itemConfig.icon}
      supportsDuplication
      supportsRules
      supportsInstallOn
    >
      <General
        isDisabled={pageState.isDisabled}
        lockScreenFootnote={model.lockScreenFootnote}
        validationDep={validationDep}
        setModel={setModel}
        updateSetting={updateSetting}
      />
    </LibraryItemPage>
  );
};

const LockScreenMessage = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService,
  });
  /* istanbul ignore next */
  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LockScreenMessagePage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="lock-screen-message"
    />
  );
};

export default LockScreenMessage;
