import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Status,
  StatusLabel,
} from 'app/components/library/libraryItemStatusTab/LibraryItemStatusTab.styles';
import { i18n } from 'i18n';
import { pick } from 'lodash';
import React from 'react';
import Const from '../../../../app/components/common/BootstrapTable/Const';
import { newLibraryItemService } from '../../data-service/library-item/new-library-item-service';

function transformFromOldApi(apiData) {
  return {
    ...apiData,
    data: {
      ...apiData.data,
      results: apiData.data.results.map((statusData) => ({
        id: statusData.status_id,
        reported_at: statusData.date,
        ...pick(statusData, [
          'status',
          'log',
          'log_content',
          'log_file_url',
          'last_audit_run',
          'last_audit_log',
          'last_audit_log_content',
          'control_log',
          'control_reported_at',
          'type',
          'identifier',
        ]),
        computer: {
          id: statusData.computer_id,
          name: statusData.computer_name,
        },
        blueprint: {
          id: statusData.blueprint_id,
          name: statusData.blueprint_name,
          rulesPresent: statusData.rules_present,
        },
      })),
    },
  };
}

const ORDERING_FIELDS = {
  toApi: {
    blueprint: 'blueprint_name',
    computer: 'computer_name',
    reported_at: 'date',
  },
  fromApi: {
    blueprint_name: 'blueprint',
    computer_name: 'computer',
    date: 'reported_at',
  },
};

function parseApiOrdering(ordering) {
  const sortOrder = Const[ordering.startsWith('-') ? 'SORT_DESC' : 'SORT_ASC'];
  const noSign = ordering.replace('-', '');
  const sortName = ORDERING_FIELDS.fromApi[noSign] || noSign;
  return { sortOrder, sortName };
}

function composeApiOrdering(sortField, order) {
  const sign = order === Const.SORT_DESC ? '-' : '';
  return `${sign}${ORDERING_FIELDS.toApi[sortField] || sortField}`;
}

const STATUSES_MAPPING = {
  PENDING: ['PENDING', 'pending', 'INSTALLING', 'installing'],
  CHANGES_PENDING: ['CHANGES_PENDING'],
  ERROR: ['ERROR', 'failed'],
  PASS: ['PASS', 'success'],
  AVAILABLE: ['AVAILABLE', 'available'],
  EXCLUDED: ['EXCLUDED', 'excluded'],
  DOWNLOADING: ['DOWNLOADING'],
  CACHED: ['CACHED'],
  INSTALLING: ['INSTALLING'],
};
const STATUSES = {
  ALL: 'ALL',
  PENDING: 'PENDING',
  CHANGES_PENDING: 'CHANGES_PENDING',
  INCOMPATIBLE: 'INCOMPATIBLE',
  PASS: 'PASS',
  AVAILABLE: 'AVAILABLE',
  REMEDIATED: 'REMEDIATED',
  ERROR: 'ERROR',
  EXCLUDED: 'EXCLUDED',
  DOWNLOADING: 'DOWNLOADING',
  CACHED: 'CACHED',
  INSTALLING: 'INSTALLING',
};
// TODO: (LIT-279) Remediated and Incompatible are probably gonna be removed
const NAME_MAPPING = {
  ALL: 'All Statuses',
  PENDING: 'Pending',
  CHANGES_PENDING: 'Changes Pending',
  INCOMPATIBLE: 'Incompatible',
  PASS: 'Pass',
  AVAILABLE: 'Available',
  REMEDIATED: 'Remediated',
  ERROR: 'Error',
  EXCLUDED: 'Excluded',
  CACHED: 'Cached',
  DOWNLOADING: 'Downloading',
  INSTALLING: 'Installing',
};
const COLOUR_MAPPING = {
  PENDING: '#D3D3D3',
  CHANGES_PENDING: '#D3D3D3',
  INCOMPATIBLE: '#D3D3D3',
  PASS: '#52C548',
  AVAILABLE: '#D3D3D3',
  REMEDIATED: '#3087E0',
  ERROR: '#FF8A00',
  EXCLUDED: '#D3D3D3',
};
const FLUSH_BUTTON_STYLE = {
  height: 26,
  width: 80,
  borderWidth: 1,
  minWidth: 80,
  padding: 0,
  margin: 0,
};

function formatStatus(status, row, setStatusCounts, forceReload) {
  function flushStatus(e) {
    e.stopPropagation();
    newLibraryItemService
      .flushStatusesById([row.id])
      .then(() => {
        setStatusCounts((curr) => {
          // TODO: Everything from here to the delimiter can be removed when statuses
          //  are refactored at the backend (LIT-279)
          if (!Object.values(STATUSES).includes(status)) {
            const newStatusCounts = { ...curr };
            Object.entries(STATUSES_MAPPING).forEach(([name, variants]) => {
              if (variants.includes(status)) {
                newStatusCounts[name] -= 1;
              }
            });
            newStatusCounts.PENDING += 1;
            return newStatusCounts;
          }
          // =================== REMOVE TO THIS POINT ========================

          return {
            ...curr,
            PENDING: curr.PENDING + 1,
            [status]: curr[status] - 1,
          };
        });
        forceReload();
      })
      .catch(() => toaster(i18n.common.error()));
  }

  // TODO: Everything from here to the delimiter can be removed when statuses
  //  are refactored at the backend (LIT-279)
  if (!Object.values(STATUSES).includes(status)) {
    const config = { name: '', colour: '', withFlush: false };
    switch (status) {
      case 'INSTALLING':
      case 'installing':
      case 'pending':
        config.name = NAME_MAPPING.PENDING;
        config.colour = COLOUR_MAPPING.PENDING;
        break;
      case 'success':
        config.name = NAME_MAPPING.PASS;
        config.colour = COLOUR_MAPPING.PASS;
        config.withFlush = true;
        break;
      case 'available':
        config.name = NAME_MAPPING.AVAILABLE;
        config.colour = COLOUR_MAPPING.AVAILABLE;
        break;
      case 'failed':
        config.name = NAME_MAPPING.ERROR;
        config.colour = COLOUR_MAPPING.ERROR;
        config.withFlush = true;
        break;
      case 'excluded':
        config.name = NAME_MAPPING.EXCLUDED;
        config.colour = COLOUR_MAPPING.EXCLUDED;
        config.withFlush = true;
        break;
      default:
    }

    return (
      <Status iColor={config.colour}>
        <StatusLabel>
          <i
            className={`fas fa${
              status === STATUSES.INCOMPATIBLE ? '-times' : ''
            }-circle`}
          />
          {config.name}
        </StatusLabel>
        {config.withFlush && (
          <Button kind="outline" theme="dark" onClick={flushStatus}>
            Flush
          </Button>
        )}
      </Status>
    );
  }
  // =================== REMOVE TO THIS POINT ========================

  return (
    <Status iColor={COLOUR_MAPPING[status]}>
      <StatusLabel>
        <i
          className={`fas fa${
            status === STATUSES.INCOMPATIBLE ? '-times' : ''
          }-circle`}
        />
        {NAME_MAPPING[status]}
      </StatusLabel>
      {[
        STATUSES.ERROR,
        STATUSES.PASS,
        STATUSES.REMEDIATED,
        STATUSES.EXCLUDED,
      ].includes(status) && (
        <Button kind="outline" theme="dark" onClick={flushStatus}>
          Flush
        </Button>
      )}
    </Status>
  );
}

function getNewStatusTabProps(model, setModel, blueprints = []) {
  return {
    blueprints,
    statusCounts: model.statusCounts,
    itemId: model.id,
    tabid: 'Status',
    setStatusCounts: (upd) =>
      setModel((curr) => ({
        ...curr,
        statusCounts: typeof upd === 'function' ? upd(curr.statusCounts) : upd,
      })),
  };
}

export {
  composeApiOrdering,
  formatStatus,
  getNewStatusTabProps,
  NAME_MAPPING,
  ORDERING_FIELDS,
  parseApiOrdering,
  STATUSES,
  transformFromOldApi,
};
