import { i18n } from 'i18n';
/* istanbul ignore file - tested in BE */
import {
  type LibraryItemStatusContent,
  LibraryItemStatuses,
} from '../CommonStatus.types';

export enum ManagedOSStatusTypes {
  MANAGED_OS_INCOMPATIBLE = 'managed_os_incompatible',
  MANAGED_OS_PASS = 'managed_os_pass',
  MANAGED_OS_PENDING = 'managed_os_pending',
  MANAGED_OS_ERROR = 'managed_os_error',
  MANAGED_OS_COMMAND = 'managed_os_command',
  MANAGED_OS_UPDATE = 'managed_os_update',
  MANAGED_OS_AUDIT = 'managed_os_audit',
  MANAGED_OS_DDM_AUDIT = 'managed_os_ddm_audit',
}

export enum ManagedOSIncompatibleReasons {
  UNSUPERVISED = 'unsupervised',
  MINIMUM_OS_VERSION = 'minimum_os_version',
}

export class ManagedOSIncompatibleDetails {
  reason: ManagedOSIncompatibleReasons;
  device_os_version?: string;
  device_family?: string;
  minimum_os_version?: string;

  constructor(
    reason: ManagedOSIncompatibleReasons,
    device_os_version?: string,
    device_family?: string,
    minimum_os_version?: string,
  ) {
    this.reason = reason;
    this.device_os_version = device_os_version;
    this.device_family = device_family;
    this.minimum_os_version = minimum_os_version;
  }

  get_message(): string {
    if (this.reason === ManagedOSIncompatibleReasons.UNSUPERVISED) {
      return i18n.t(
        '{device_family} family devices need to be supervised for managed os updates.',
        { device_family: this.device_family },
      );
    }

    if (this.reason === ManagedOSIncompatibleReasons.MINIMUM_OS_VERSION) {
      return i18n.t(
        'The device does not meet the minimum OS version requirements for release.',
      );
    }

    return i18n.t('Device is incompatible.');
  }

  get_details(): string {
    if (this.reason === ManagedOSIncompatibleReasons.MINIMUM_OS_VERSION) {
      return i18n.t(
        'Device OS version {device_os_version} is below the minimum required version {minimum_os_version} for managed os updates.',
        {
          device_os_version: this.device_os_version,
          minimum_os_version: this.minimum_os_version,
        },
      );
    }

    return '';
  }
}

export class ManagedOSPassDetails {
  library_item_name: string;
  is_version_updated: boolean = false;
  is_self_service: boolean = false;
  no_manage_updates: boolean = false;

  constructor(
    library_item_name: string,
    is_version_updated: boolean = false,
    is_self_service: boolean = false,
    no_manage_updates: boolean = false,
  ) {
    this.library_item_name = library_item_name;
    this.is_version_updated = is_version_updated;
    this.is_self_service = is_self_service;
    this.no_manage_updates = no_manage_updates;
  }

  get_message(): string {
    if (this.no_manage_updates) {
      return i18n.t('Library item is set to "Do not manage updates."');
    }

    if (this.is_self_service) {
      return i18n.t('Library item is set to Self-Service.');
    }

    if (this.is_version_updated) {
      return i18n.t('{library_item_name} successfully installed.', {
        library_item_name: this.library_item_name,
      });
    }

    return i18n.t('The device satisfies current Library Item requirements.');
  }
}

enum ManagedOSPendingReasons {
  RTC_NOT_CONFIGURED = 'rtc_not_configured',
  PRODUCT_KEY_MISSING = 'product_key_missing',
  RESTART = 'restart',
  BOOTSTRAP_TOKEN_MISSING = 'bootstrap_token_missing',
  ENFORCEMENT_DETAILS_CHANGED = 'enforcement_details_changed',
  ENFORCEMENT_CRITERIA_CHANGED = 'enforcement_criteria_changed',
  OS_UPDATE = 'os_update',
}

export class ManagedOSPendingDetails {
  reason: ManagedOSPendingReasons;

  constructor(reason: ManagedOSPendingReasons) {
    this.reason = reason;
  }

  get_message(): string {
    switch (this.reason) {
      case ManagedOSPendingReasons.PRODUCT_KEY_MISSING:
        return i18n.t(
          'Product key not detected. Managed OS will re-attempt later.',
        );
      case ManagedOSPendingReasons.RTC_NOT_CONFIGURED:
        return i18n.t(
          'Device is not yet configured for RTC. Managed OS will re-attempt later.',
        );
      case ManagedOSPendingReasons.BOOTSTRAP_TOKEN_MISSING:
        return i18n.t(
          "Waiting for device's bootstrap token. Managed OS will re-attempt later.",
        );
      case ManagedOSPendingReasons.RESTART:
        return i18n.t('Managed OS is restarting process.');
      case ManagedOSPendingReasons.ENFORCEMENT_DETAILS_CHANGED:
        return i18n.t(
          'Enforcement rules have changed. Managed OS will re-attempt later.',
        );
      case ManagedOSPendingReasons.ENFORCEMENT_CRITERIA_CHANGED:
        return i18n.t(
          'Enforcement criteria has changed. Managed OS will attempt to remediate.',
        );
      default:
        return '';
    }
  }
}

enum ManagedOSErrorCause {
  GRACE_PERIOD_EXCEEDED = 'grace_period_exceeded',
  PRODUCT_KEY_STATUS_MISSING = 'product_key_status_missing',
  ERROR_EVALUATING_STATUS = 'error_evaluating_status',
  GENERIC_EXCEPTION = 'generic_exception',
  FAILED_TO_CONTACT_AGENT = 'failed_to_contact_agent',
  COMMAND_ERROR = 'command_error',
  OS_COMMAND_UNKNOWN = 'os_command_unknown',
  AGENT_INTEL_INSTALL_ERROR = 'agent_intel_install_error',
  AGENT_INTEL_APPLY_ERROR = 'agent_intel_apply_error',
  API_FALLBACK = 'api_fallback',
}

function getHumanReadableError(key: string) {
  const HUMAN_READABLE_OS_UPDATE_ERROR_MAP: { [key: string]: string } = {
    Error: i18n.t('There was an error while performing this action.'),
    DownloadFailed: i18n.t('The download has failed.'),
    DownloadRequiresComputer: i18n.t(
      'The device must be connected to a computer to download this update.',
    ),
    DownloadInsufficientSpace: i18n.t(
      'There is not enough space to download the update.',
    ),
    DownloadInsufficientPower: i18n.t(
      'There is not enough power to download the update.',
    ),
    DownloadInsufficientNetwork: i18n.t(
      'There is insufficient network capacity to download the update.',
    ),
    InstallInsufficientSpace: i18n.t(
      'There is not enough space to install the update.',
    ),
    InstallInsufficientPower: i18n.t(
      'There is not enough power to install the update.',
    ),
    InstallPhoneCallInProgress: i18n.t(
      'Installation has been rejected because a phone call is in progress.',
    ),
    InstallFailed: i18n.t('Installation has failed for an unspecified reason.'),
  };

  return HUMAN_READABLE_OS_UPDATE_ERROR_MAP[key];
}

export class CommandErrorInfo {
  status: string;
  command?: string;
  wifi_error: boolean = false;
  error_description: string = '';

  constructor(
    status: string,
    command?: string,
    wifi_error: boolean = false,
    error_description: string = '',
  ) {
    this.status = status;
    this.command = command;
    this.wifi_error = wifi_error;
    this.error_description = error_description;
  }

  get_message(): string {
    const message = this.command ? `${getHumanReadableError(this.status)}` : '';
    const details = this.wifi_error
      ? i18n.t(
          'Download failed because device requires Wi-Fi to download the update.',
        )
      : this.error_description;
    return message ? `${message}\n${details}` : details;
  }
}

export class ManagedOSErrorDetails {
  cause: ManagedOSErrorCause;
  grace_period?: number;
  fallback: boolean = false;
  fallback_after_grace_period: boolean = false;
  exception_message?: string;
  command_error?: CommandErrorInfo;
  library_item_name?: string;

  constructor(
    cause: ManagedOSErrorCause,
    grace_period?: number,
    fallback: boolean = false,
    fallback_after_grace_period: boolean = false,
    exception_message?: string,
    command_error?: CommandErrorInfo,
    library_item_name?: string,
  ) {
    this.cause = cause;
    this.grace_period = grace_period;
    this.fallback = fallback;
    this.fallback_after_grace_period = fallback_after_grace_period;
    this.exception_message = exception_message;
    this.command_error = command_error;
    this.library_item_name = library_item_name;
  }

  private _add_fallback_suffix(): string {
    if (this.fallback_after_grace_period) {
      return i18n.t('Device will fallback within {grace_period} hours.', {
        grace_period: this.grace_period,
      });
    }
    return this.fallback ? i18n.t(' Fallback has been requested.') : '';
  }

  get_message(): string {
    let message = '';

    switch (this.cause) {
      case ManagedOSErrorCause.GRACE_PERIOD_EXCEEDED:
        return i18n.t(
          'Managed OS enforcement with MDM has exceeded {grace_period} hours. Fallback to System Settings requested from Kandji Agent.',
          { grace_period: this.grace_period },
        );
      case ManagedOSErrorCause.PRODUCT_KEY_STATUS_MISSING:
        return i18n.t(
          'No product key status found. Managed OS will attempt to remediate.',
        );
      case ManagedOSErrorCause.ERROR_EVALUATING_STATUS:
        message = i18n.t(
          'There was an error with evaluating status from device.',
        );
        break;
      case ManagedOSErrorCause.GENERIC_EXCEPTION:
        message = i18n.t('An unexpected error has occurred.');
        break;
      case ManagedOSErrorCause.FAILED_TO_CONTACT_AGENT:
        message = i18n.t('Failed to request Agent to prompt for enforcement.');
        break;
      case ManagedOSErrorCause.COMMAND_ERROR:
        if (this.command_error) {
          message = this.command_error.command
            ? i18n.t(
                '{command_error_command} command failed for `{library_item_name}`.',
                {
                  command_error_command: this.command_error.command,
                  library_item_name: this.library_item_name,
                },
              )
            : '';
          const command_error_message = this.command_error.get_message();
          return message
            ? `${message}\n${command_error_message}`
            : command_error_message;
        }
        break;
      case ManagedOSErrorCause.OS_COMMAND_UNKNOWN:
        message = i18n.t(
          'OSUpdateStatus reported an error but did not provide details.',
        );
        break;
      case ManagedOSErrorCause.AGENT_INTEL_INSTALL_ERROR:
        message = i18n.t('Failed to install. Please see agent logs.');
        break;
      case ManagedOSErrorCause.AGENT_INTEL_APPLY_ERROR:
        message = i18n.t('Failed to apply. Please see agent logs.');
        break;
      case ManagedOSErrorCause.API_FALLBACK:
        message = i18n.t('Fallback requested via API.');
        break;
    }

    return message + this._add_fallback_suffix();
  }
}

enum ManagedOSInstallState {
  DOWNLOADING = 'downloading',
  INSTALLING = 'installing',
  FAILED = 'failed',
  CACHED = 'cached',
  CACHED_REPORTED_INSTALLING = 'cached_reported_installing',
  CACHED_IDLE = 'cached_idle',
  ERROR = 'error',
  IDLE = 'idle',
  DOWNLOAD_INITIATED = 'download_initiated',
  INSTALL_REQUESTED = 'install_requested',
  FALLBACK_MODE = 'fallback_mode',
  INSTALL_INITIATED = 'install_initiated',
}

export class ManagedOSCommandDetails {
  download_percent: number = 0.0;
  is_downloaded: boolean = false;
  state: ManagedOSInstallState;
  error_status?: string;
  library_item_name: string;

  constructor(
    state: ManagedOSInstallState,
    library_item_name: string,
    download_percent: number = 0.0,
    is_downloaded: boolean = false,
    error_status?: string,
  ) {
    this.state = state;
    this.library_item_name = library_item_name;
    this.download_percent = download_percent;
    this.is_downloaded = is_downloaded;
    this.error_status = error_status;
  }

  get_message(): string {
    switch (this.state) {
      case ManagedOSInstallState.CACHED:
        return i18n.t('The software update is cached.');
      case ManagedOSInstallState.CACHED_REPORTED_INSTALLING:
        return i18n.t('Managed OS is cached.');
      case ManagedOSInstallState.INSTALL_REQUESTED:
        return i18n.t('Install has been requested.');
      case ManagedOSInstallState.INSTALL_INITIATED:
        return i18n.t('Install initiated.');
      case ManagedOSInstallState.DOWNLOAD_INITIATED:
        return i18n.t('{library_item_name} downloading has been initiated.', {
          library_item_name: this.library_item_name,
        });
      case ManagedOSInstallState.FALLBACK_MODE:
        return i18n.t(
          'Kandji has detected an issue with the OS Update. Fallback mode triggered. Requesting user to start the update in System Settings.',
        );
      case ManagedOSInstallState.DOWNLOADING:
        return this._prepare_log_from_status(
          i18n.t('The software update is being downloaded.'),
        );
      case ManagedOSInstallState.INSTALLING:
        return this._prepare_log_from_status(
          i18n.t('The software update is being installed.'),
        );
      case ManagedOSInstallState.IDLE:
        return this._prepare_log_from_status(
          i18n.t('No action is being taken on this software update.'),
        );
      default:
        return '';
    }
  }

  private _prepare_log_from_status(status: string): string {
    let log = i18n.t('OS Update status is `{status}`.', { status });
    log += i18n.t(
      '\nPercentage of download that is complete: {download_percent}%',
      { download_percent: this.download_percent },
    );
    log += i18n.t('\nDownload Completed: {is_downloaded}.', {
      is_downloaded: this.is_downloaded ? i18n.t('Yes') : i18n.t('No'),
    });
    return log;
  }
}

export enum ManagedOSUpdateAction {
  AGENT_REQUESTED = 'agent_requested',
  ATTEMPT_SCHEDULED = 'attempt_scheduled',
  INTEL_INSTALLER_POWER_EVENT = 'intel_installer_power_event',
}

export class ManagedOSUpdateDetails {
  action: ManagedOSUpdateAction;
  is_connected_to_power: boolean = false;
  battery_remaining: number = 0;
  scheduled_time?: string;

  constructor(
    action: ManagedOSUpdateAction,
    is_connected_to_power: boolean = false,
    battery_remaining: number = 0,
    scheduled_time?: string,
  ) {
    this.action = action;
    this.is_connected_to_power = is_connected_to_power;
    this.battery_remaining = battery_remaining;
    this.scheduled_time = scheduled_time;
  }

  get_message(): string {
    switch (this.action) {
      case ManagedOSUpdateAction.AGENT_REQUESTED:
        return i18n.t('Agent has been requested.');
      case ManagedOSUpdateAction.INTEL_INSTALLER_POWER_EVENT:
        return i18n.t(
          'Install is in progress.\nConnected to power: {is_connected_to_power }\nBattery Remaining: {battery_remaining}%',
          {
            battery_remaining: this.battery_remaining,
            is_connected_to_power: this.is_connected_to_power
              ? i18n.t('Yes')
              : i18n.t('No'),
          },
        );
      case ManagedOSUpdateAction.ATTEMPT_SCHEDULED:
        return i18n.t(
          'Managed OS will prompt again for this device at around {scheduled_time} if the update has not yet been applied.',
          { scheduled_time: this.scheduled_time },
        );
      default:
        return '';
    }
  }
}

export enum ManagedOSDDMInstallState {
  PREPARED = 'prepared',
  INSTALLING = 'installing',
  DOWNLOADING = 'downloading',
  FAILED = 'failed',
}

export class ManagedOSDDMAuditDetail {
  ddm_state: ManagedOSDDMInstallState;

  constructor(ddm_state: ManagedOSDDMInstallState) {
    this.ddm_state = ddm_state;
  }

  get_message(): string {
    switch (this.ddm_state) {
      case ManagedOSDDMInstallState.FAILED:
        return i18n.t('Installation has failed.');
      case ManagedOSDDMInstallState.PREPARED:
        return i18n.t('The software update is prepared.');
      case ManagedOSDDMInstallState.INSTALLING:
        return i18n.t('The software update is being installed.');
      case ManagedOSDDMInstallState.DOWNLOADING:
        return i18n.t('The software update is being downloaded.');
      default:
        return '';
    }
  }
}

export class ManagedOSDDMAuditLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_DDM_AUDIT;
  version: number = 1;
  details: ManagedOSDDMAuditDetail;

  constructor(details: ManagedOSDDMAuditDetail) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSAuditDetails {
  human_readable_os_version: string;
  target_version: string;
  up_to_date: boolean;
  newer_version_installed: boolean;
  library_item_name: string;
  should_pre_cache: boolean;
  enforcement_delay?: number;
  enforce_after?: string;
  enforcement_time?: string;
  enforcement_timezone?: string;

  constructor(
    human_readable_os_version: string,
    target_version: string,
    up_to_date: boolean,
    newer_version_installed: boolean,
    library_item_name: string,
    should_pre_cache: boolean,
    enforcement_delay?: number,
    enforce_after?: string,
    enforcement_time?: string,
    enforcement_timezone?: string,
  ) {
    this.human_readable_os_version = human_readable_os_version;
    this.target_version = target_version;
    this.up_to_date = up_to_date;
    this.newer_version_installed = newer_version_installed;
    this.library_item_name = library_item_name;
    this.should_pre_cache = should_pre_cache;
    this.enforcement_delay = enforcement_delay;
    this.enforce_after = enforce_after;
    this.enforcement_time = enforcement_time;
    this.enforcement_timezone = enforcement_timezone;
  }

  get_message(): string {
    let version_state = '';
    if (this.up_to_date) {
      version_state = i18n.t(' and up to date');
      if (this.newer_version_installed) {
        version_state = i18n.t(', which is newer than {library_item_name}', {
          library_item_name: this.library_item_name,
        });
      }
    }

    const os_status = i18n.t(
      '{human_readable_os_version} is installed{version_state}.',
      {
        human_readable_os_version: this.human_readable_os_version,
        version_state,
      },
    );
    let install_status = this.up_to_date
      ? ''
      : i18n.t(
          'A newer version ({target_version}) of {library_item_name} is available. ',
          {
            target_version: this.target_version,
            library_item_name: this.library_item_name,
          },
        );

    if (this.enforcement_delay) {
      install_status += i18n.t(
        'Kandji is set to automatically enforce updates for {library_item_name} {enforcement_delay} days after they are released.',
        {
          library_item_name: this.library_item_name,
          enforcement_delay: this.enforcement_delay,
        },
      );
    } else if (this.enforce_after) {
      install_status += i18n.t(
        'Kandji is set to enforce version ({target_version}) of {library_item_name}',
        {
          target_version: this.target_version,
          library_item_name: this.library_item_name,
        },
      );
      if (this.should_pre_cache) {
        install_status += i18n.t(
          ' starting on {enforce_after} at {enforcement_time} ({enforcement_timezone}).',
          {
            enforce_after: this.enforce_after,
            enforcement_timezone: this.enforcement_timezone,
          },
        );
      }
    } else {
      install_status += i18n.t(
        'Kandji is not set to enforce updates for {library_item_name}.',
        { library_item_name: this.library_item_name },
      );
    }

    return `${os_status}\n${install_status}`;
  }
}

export class ManagedOSAuditLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_AUDIT;
  version: number = 1;
  details: ManagedOSAuditDetails;

  constructor(details: ManagedOSAuditDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSCommandStatusLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_COMMAND;
  version: number = 1;
  details: ManagedOSCommandDetails;

  constructor(details: ManagedOSCommandDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSIncompatibleStatusLog
  implements LibraryItemStatusContent
{
  type = LibraryItemStatuses.MANAGED_OS_INCOMPATIBLE;
  version: number = 1;
  details: ManagedOSIncompatibleDetails;

  constructor(details: ManagedOSIncompatibleDetails) {
    this.details = details;
  }
}

export class ManagedOSPassStatusLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_PASS;
  version: number = 1;
  details: ManagedOSPassDetails;

  constructor(details: ManagedOSPassDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSErrorStatusLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_ERROR;
  version: number = 1;
  details: ManagedOSErrorDetails;

  constructor(details: ManagedOSErrorDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSPendingStatusLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_PENDING;
  version: number = 1;
  details: ManagedOSPendingDetails;

  constructor(details: ManagedOSPendingDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}

export class ManagedOSUpdateStatusLog implements LibraryItemStatusContent {
  type = LibraryItemStatuses.MANAGED_OS_UPDATE;
  version: number = 1;
  details: ManagedOSUpdateDetails;

  constructor(details: ManagedOSUpdateDetails) {
    this.details = details;
  }

  get_message(): string {
    return this.details.get_message();
  }
}
