import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type RecoveryPasswordCommonStatusDetails,
  getRecoveryPasswordType,
} from '../RecoveryPassword.types';

function serializerV1(details: RecoveryPasswordCommonStatusDetails) {
  const { password_type } = details;

  return new LibraryItemStatusLog()
    .addRow(
      i18n.t("{password_type} password wasn't set.", {
        password_type: getRecoveryPasswordType(password_type),
      }),
    )
    .toString();
}

export const RecoveryPasswordSetFailed = {
  Default: serializerV1,
  1: serializerV1,
};
