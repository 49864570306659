import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { memo } from 'react';

import { i18n } from 'i18n';
import { Setting } from 'src/features/library-items/template';
import type { AllExtensionsProps } from '../../safari-extensions.types';
import ExtentionSettings from '../components/ExtensionSettings';

const AllExtensions = (props: AllExtensionsProps) => {
  const { update, isDisabled, settings, validationDep } = props;

  const MAX_DOMAINS = 20;
  const allowedDomainsIdentifiers = new Array(MAX_DOMAINS)
    .fill(0)
    .map((_, idx) => `*_allowed_domain_${idx}`);

  const deniedDomainsIdentifiers = new Array(MAX_DOMAINS)
    .fill(0)
    .map((_, idx) => `*_denied_domain_${idx}`);

  const extensionIdentifiers = [
    ...allowedDomainsIdentifiers,
    ...deniedDomainsIdentifiers,
  ];

  const { refs, onInvalidate } = useInputsValidators(
    extensionIdentifiers,
    update,
  );

  useRemoveValidationOnUnmount(extensionIdentifiers, update);

  return (
    <Setting.Card>
      <Setting.Header
        style={{
          paddingBottom: `${
            /* istanbul ignore next */ settings.isManaged
              ? 'inherit'
              : 'var(--b-gap)'
          }`,
        }}
      >
        <h3 className="b-h3">{i18n.t('Configuration for all extensions')}</h3>
        <Setting.HeaderToggle
          isEnabled={settings.isManaged}
          onChange={() => update('isManaged', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      {settings.isManaged && (
        <>
          <Setting.SubHeader>
            {i18n.ut(
              'Configure global settings for all Safari Extensions. <a href="https://support.kandji.io/support/solutions/articles/72000632882" target="_blank" rel="noreferrer">Learn more</a>',
            )}
          </Setting.SubHeader>
          <Setting.Rows>
            <ExtentionSettings
              isDisabled={isDisabled}
              update={update}
              settings={settings}
              validationDep={validationDep}
              validationRefs={refs}
              allowedDomainsIdentifiers={allowedDomainsIdentifiers}
              deniedDomainsIdentifiers={deniedDomainsIdentifiers}
              onInvalidate={(identifier, isInvalid) =>
                onInvalidate(identifier)(isInvalid)
              }
            />
          </Setting.Rows>
        </>
      )}
    </Setting.Card>
  );
};

export default memo(AllExtensions);
