import { useInputsValidators, useIsChanged } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import { Box, Flex, Hint, Text, styled } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import KandjiSupportLink from 'src/app/components/common/KandjiSupportLink';
import { LockScreenLexical } from './LockScreenLexical';
import OrientationSwitcher from './OrientationSwitcher';
import './MessageConfig.css';
import type { LockScreenMessageModel } from '../lock-screen-message.types';

const UpperText = styled(Flex, {
  marginTop: '20px',
  justifyContent: 'space-between',
});

const HelpMessage = styled(Box, {
  width: '47%',
  float: 'right',
  marginTop: '14px',
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

const HintContainer = styled(Box, {
  marginTop: '5px',
});

const DeviceTypes = {
  IPAD_PORTRAIT: 'IPAD_PORTRAIT',
  IPAD_LANDSCAPE: 'IPAD_LANDSCAPE',
  IPHONE: 'IPHONE',
};

const DeviceNames = {
  IPAD_PORTRAIT: () => i18n.t('iPad'),
  IPAD_LANDSCAPE: () => i18n.t('iPad'),
  IPHONE: () => i18n.t('iPhone'),
};

const deviceClassMap = {
  [DeviceTypes.IPHONE]: 'iphone-frame',
  [DeviceTypes.IPAD_PORTRAIT]: 'ipad-portrait-frame',
  [DeviceTypes.IPAD_LANDSCAPE]: 'ipad-landscape-frame',
};

const General = (props) => {
  const {
    lockScreenFootnote,
    isDisabled,
    validationDep,
    setModel,
    updateSetting,
  } = props;
  const [previewFootnote, setPreviewFootnote] = useState(
    lockScreenFootnote || 'Your message here',
  );

  const [orientation, setOrientation] = useState(DeviceTypes.IPHONE);
  const charLimit = orientation === DeviceTypes.IPHONE ? 60 : 160;
  const charCount = lockScreenFootnote ? lockScreenFootnote.length : 0;

  const fieldsToValidate = ['lockScreenFootnote'];

  const update = useCallback(
    (value) => {
      setModel((prevModel) => ({
        ...prevModel,
        lockScreenFootnote: value,
      }));
    },
    [setModel],
  );

  const { onInvalidate, refs } = useInputsValidators(
    fieldsToValidate,
    updateSetting,
  );

  const isSubmitted = useIsChanged(validationDep);
  useEffect(() => {
    onInvalidate('lockScreenFootnote')(!lockScreenFootnote);
  }, [lockScreenFootnote]);

  const handleUpdate = useCallback(
    (value: any) => {
      update(String(value));
    },
    [update],
  );

  const handleEditorChange = (newEditorState: string) => {
    if (newEditorState.length < 200) {
      handleUpdate(newEditorState);
      setPreviewFootnote(newEditorState);
    }
  };

  const formattedText =
    previewFootnote.length > charLimit
      ? {
          placeholder: false,
          text: `${previewFootnote.substring(0, charLimit)}...`,
        }
      : {
          placeholder: previewFootnote.length === 0,
          text:
            previewFootnote.length === 0
              ? 'Your message here'
              : previewFootnote,
        };

  const hintLabel =
    previewFootnote.length > charLimit
      ? i18n.t('This message may get truncated on {deviceName}', {
          deviceName: DeviceNames[orientation](),
        })
      : '';

  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000636327"
      text={i18n.t('Learn more')}
      className="b-alink"
    />
  );

  const globalVarsLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000560519-global-variables"
      text={i18n.t('Kandji Global Variables')}
      className="b-alink"
    />
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows style={{ borderBottom: 'solid 1px #efefef' }}>
        <p className="b-txt the-recommended">
          {i18n.t(
            'Enter a custom message that will appear on the Lock Screen of iPhone and iPad (and on the login screen of Shared iPad). You can use Kandji Global Variables by typing a dollar sign ($) to make the message dynamic and adapted to each device.',
          )}{' '}
          {supportLink}
        </p>
      </Setting.Rows>

      <Box
        id="Settings"
        style={{ padding: 'var(--b-gap2)', marginTop: '-20px' }}
      >
        <UpperText>
          <Box style={{ width: '50%' }}>
            <Text
              variant="secondary"
              css={{ fontWeight: '$medium', marginBottom: '$1' }}
            >
              {i18n.t('Lock Screen message')}
            </Text>
            <Box ref={refs.lockScreenFootnote} css={{ width: '404px' }}>
              <LockScreenLexical
                handleEditorChange={handleEditorChange}
                lockScreenFootnote={lockScreenFootnote}
                isDisabled={isDisabled}
                isValid={!isSubmitted || lockScreenFootnote.length > 0}
              />
              {lockScreenFootnote.length > 0 && (
                <HintContainer>
                  <Hint
                    label={hintLabel}
                    variant={charCount < charLimit ? 'default' : 'warning'}
                    charCounter={{ count: charCount, max: charLimit }}
                  />
                </HintContainer>
              )}
            </Box>
          </Box>

          <HelpMessage>
            <Text
              variant="secondary"
              css={{
                fontSize: '$1',
              }}
            >
              {i18n.t(
                'This message will truncate, beginning around 60 characters for iPhone and around 160 characters for iPad. Use the preview below to estimate how your message may appear on a device.',
              )}
            </Text>
            <Text
              variant="secondary"
              css={{
                fontSize: '$1',
              }}
            >
              {i18n.t(
                'If you use Kandji Global Variables, take into account that their contents will vary in length and will impact how much of your message will remain visible on devices.',
              )}{' '}
              {globalVarsLink}
            </Text>
          </HelpMessage>
        </UpperText>

        <Box id="Settings" className="settings-box">
          <Box>
            <br />
            <Box className="outer-preview-box">
              <Box className="inner-preview-box">
                <Box className="preview-text">{i18n.t('Preview')}</Box>
                <Box className="lsm__switcher-container switcher-container">
                  <OrientationSwitcher
                    disabled={isDisabled}
                    orientation={orientation}
                    setOrientation={setOrientation}
                  />
                </Box>
                <Box className="lsm_device-container-lsm">
                  <Box className="lsm_device-wrapper">
                    <Box className="lsm_device-frame-container device-frame-container">
                      <Box className={deviceClassMap[orientation]}>
                        <Box className="lockscreen-text">
                          {i18n.t('Swipe up to open')}
                        </Box>
                        <Box className="lockscreen-message">
                          {formattedText.text}
                        </Box>
                        <Box className="swipe-bar" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Setting.Card>
  );
};

export default General;
