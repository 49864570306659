import {
  Checkbox,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import React, { useEffect, createRef } from 'react';
import { i18n } from 'src/i18n';
import './general-card.scss';

import { Setting } from 'features/library-items/template';

import WifiService from '../service/wifi-service';

const keys = WifiService.keys.general;
const fieldsToValidate = [keys.ssid];

const GeneralCard = ({ setting, update, isDisabled, validationDep }) => {
  const ipv6Options = [
    { label: i18n.t('Enable IPv6'), value: true },
    { label: i18n.t('Disable IPv6'), value: false },
  ];

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useEffect(() => {
    onInvalidate(keys.ssid)(!setting[keys.ssid]);
  }, [setting[keys.ssid]]);

  const withIpV6 = setting[keys.withIpV6];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Declare the network name, general settings, and behavior of the device on the network',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558737"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more')}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p ref={refs[keys.ipv6]} className="b-txt">
              {i18n.t('Service Set Identifier (SSID)')}
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light" ref={refs[keys.ssid]}>
              {i18n.t(
                'Specify the SSID (network name) of the network you want devices to join.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextInput
              disabled={isDisabled}
              value={setting[keys.ssid]}
              onChange={(e) => update(keys.ssid, e.target.value)}
              compact
              placeholder={i18n.t('AccuhiveWiFiSecure')}
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  message: i18n.t('Required'),
                  trigger: ['onBlur', validationDep],
                }),
              ]}
              onInvalidate={onInvalidate(keys.ssid)}
              removeValidationOnUnmount
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.autoJoin]}
              onChange={() => update(keys.autoJoin, (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Auto join network')}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Automatically join this wireless network.')}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.hiddenNetwork]}
              onChange={() => update(keys.hiddenNetwork, (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Hidden network')}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Specify whether the network broadcasts its SSID. Hidden networks are not standards-compliant and may have compatibility problems.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.withIpV6]}
              onChange={() => update(keys.withIpV6, (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('IPv6')}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Enable or disable IPv6 on this interface.')}
            </p>
          </Setting.Helpers>
          {withIpV6 && (
            <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
              <Setting.SecondaryControlsRow>
                <Select
                  isDisabled={isDisabled}
                  options={ipv6Options}
                  compact
                  onChange={(v) => update(keys.ipv6, v.value)}
                  value={ipv6Options.find(
                    (option) => option.value === setting[keys.ipv6],
                  )}
                />
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.disableCaptiveNetworkPriority]}
              onChange={() =>
                update(keys.disableCaptiveNetworkPriority, (p) => !p)
              }
              disabled={isDisabled}
              label={i18n.t('Disable captive network detection')}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'If enabled, Captive Network Assistant will be bypassed when the device connects to the network. Available in iOS 10.0 and later.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.disableMacAddressRandomization]}
              onChange={() =>
                update(keys.disableMacAddressRandomization, (p) => !p)
              }
              disabled={isDisabled}
              label={i18n.t('Disable MAC address randomization')}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'If enabled, the MAC address will not be randomized when joining this network on devices running iOS 14+ and later, and macOS 15 and later.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default React.memo(GeneralCard);
