import propTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { compose } from 'redux';

import '../assets/styles/app.scss';

import { store } from 'app/_store/rootStore';
import AuthRouter from '../app/router';
import RootProvider from '../contexts/root';
import withAuth0 from '../hocs/withAuth0';

import Environment from './Environment';
import FreshChat from './FreshChat';
import Meta from './Meta';
import Override from './Override';

const Providers = () => (
  <RootProvider>
    <Environment />
    <Meta />
    <FreshChat />
    <AuthRouter />
    <Override />
  </RootProvider>
);

const AppProviders = compose(withAuth0)(Providers);

const ReduxProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

ReduxProvider.propTypes = {
  children: propTypes.node.isRequired,
};

const App = () => (
  <ReduxProvider>
    <AppProviders />
  </ReduxProvider>
);

export default App;
