import {
  Checkbox,
  TextInput,
  Uploader,
  fileToBase64,
  onEmptyBlurValidator,
  updateProgressPercent,
  useIsChanged,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useMemo, useState } from 'react';

import { i18n } from 'src/i18n';
import WifiService from '../../../service/wifi-service';
import certPng from './cert.png';

const keys = WifiService.keys.authentication.pkcs;

const DrawerContent = ({
  setting,
  update,
  refs,
  onInvalidate,
  invalidations,
  validationDep,
  triggerValidate,
}) => {
  // const fileValDep = useMemo(() => ({}), [validationDep, triggerValidate]);
  // const showFileErr = useIsChanged(fileValDep);
  const isSubmitted = useIsChanged(triggerValidate);
  const [uploadCancelFn, setUploadCancelFn] = useState(null);

  useEffect(() => {
    const error = !setting[keys.file] && 'Required';
    onInvalidate(keys.file)(error);
  }, [setting[keys.file]]);

  const forceWithFile = useMemo(
    () =>
      (setting[keys.file]?.name && {
        file: {
          name: setting[keys.file].name,
          size: setting[keys.file].size,
        },
      }) ||
      null,
    [setting[keys.file]],
  );

  return (
    <div className="k-wifiv2-protocols-pkcs-drawer">
      <p className="b-txt k-wifiv2-protocols-pkcs-drawer__header">
        {i18n.t(
          `Upload an identity certificate to be used when connecting to the network`,
        )}
      </p>
      <div className="k-ss2-drawer-section --branding">
        <div className="k-ss2-drawer-setting k-wifiv2-protocols-pkcs-drawer__row">
          <div className="k-ss2-drawer-setting-with-toggle">
            <div ref={refs[keys.file]}>
              <p className="b-txt">{i18n.t(`Certificate`)}</p>
            </div>
          </div>
          <div className="k-ss2-drawer-setting-extra1">
            <Uploader
              withError={(isSubmitted && invalidations[keys.file]) || ''}
              onUpload={async (file, updateProgress) => {
                const base64 = await fileToBase64(file, {
                  onReaderLoadStart: ({ reader }) =>
                    setUploadCancelFn(() => () => reader.abort()),
                  onReaderLoad: ({ resolveParsedBase64 }) => {
                    updateProgressPercent(updateProgress, {
                      onDone: resolveParsedBase64,
                    });
                  },
                });

                return Promise.resolve({ base64 });
              }}
              onUploaded={(file, { data }) => {
                const { base64 } = data;
                update(keys.file, (p) => ({
                  ...p,
                  name: file.name,
                  size: file.size,
                  uploadedAt: Date.now(),
                  base64,
                }));
              }}
              onError={(error) => {
                if (error) {
                  onInvalidate(keys.file)(true);
                }
              }}
              onCancel={uploadCancelFn}
              onDelete={() => {
                setUploadCancelFn(null);
                update(keys.file, null);
              }}
              allowedTypes={['.p12']}
              className="b-mb2"
              isDisabled={false}
              uploadInstructions={
                <span>
                  {i18n.ut(`Drag file here or
                  <span class="b-alink">click to upload</span>`)}
                </span>
              }
              fileIcons={{
                '.p12': (
                  <img
                    src={certPng}
                    alt={i18n.t('.p12 Certificate')}
                    style={{ objectFit: 'contain' }}
                  />
                ),
              }}
              maxFileSize={5e9}
              forceWithFile={forceWithFile}
            />
          </div>
        </div>
        <div className="k-ss2-drawer-setting k-wifiv2-protocols-pkcs-drawer__row">
          <div>
            <p className="b-txt k-wifiv2-protocols-pkcs-drawer__row-header">
              {i18n.t(`Password`)}
            </p>
            <p className="b-txt-light k-wifiv2-protocols-pkcs-drawer__row-desc">
              {i18n.t(`The password used to secure certificate credentials`)}
            </p>
          </div>
          <div
            className="k-wifiv2-protocols-pkcs-drawer__row-input"
            ref={refs[keys.password]}
          >
            <TextInput
              fieldsGrid="k-ss2-input-grid"
              type="password"
              value={setting[keys.password]}
              onChange={(e) => update(keys.password, e.target.value)}
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  message: i18n.t('Required'),
                  trigger: ['onBlur', validationDep, triggerValidate],
                }),
              ]}
              onInvalidate={onInvalidate(keys.password)}
              removeValidationOnUnmount
              data-testid="drawer-content-password"
            />
          </div>
        </div>
        <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row">
          <div className="k-wifiv2-protocols-pkcs-drawer__row-checkbox">
            <Checkbox
              checked={setting[keys.allowAccessToThePrivateKey]}
              onChange={() =>
                update(keys.allowAccessToThePrivateKey, (p) => !p)
              }
              label={i18n.t('Allow apps to access the private key')}
              testId="drawer-content-private-key"
            />
          </div>
          <div className="b-txt-light k-wifiv2-protocols-pkcs-drawer__row-checkbox-desc">
            {i18n.t(`If enabled, all apps have access to the private key`)}
          </div>
        </div>
        <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row">
          <div className="k-wifiv2-protocols-pkcs-drawer__row-checkbox">
            <Checkbox
              checked={setting[keys.preventExtraction]}
              onChange={() => update(keys.preventExtraction, (p) => !p)}
              label={i18n.t(
                'Prevent the private data from being extracted in the keychain',
              )}
              testId="drawer-content-prevent-extract"
            />
          </div>
          <div className="b-txt-light k-wifiv2-protocols-pkcs-drawer__row-checkbox-desc">
            {i18n.t(
              `By default, private key data is extractable in the keychain`,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerContent;
