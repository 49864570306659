import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  DateCell,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getFileVaultColumns = ({
  fileVaultSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = fileVaultSchema.schema;
  const fileVaultColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    fileVaultColumnHelper.accessor(
      (row) => row[`${fileVaultSchema.uri}.status`],
      {
        id: `${fileVaultSchema.uri}.status`,
        cell: OnOffCell,
        meta: {
          displayName: properties.status.title,
          displayIcon: getIconName(fileVaultSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    fileVaultColumnHelper.accessor(
      (row) => row[`${fileVaultSchema.uri}.key_type`],
      {
        id: `${fileVaultSchema.uri}.key_type`,
        meta: {
          displayName: properties.key_type.title,
          displayIcon: getIconName(fileVaultSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            fileVaultSchema.schema?.definitions.KeyType.enum || [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    fileVaultColumnHelper.accessor(
      (row) => row[`${fileVaultSchema.uri}.key_escrowed`],
      {
        id: `${fileVaultSchema.uri}.key_escrowed`,
        cell: YesNoCell,
        meta: {
          displayName: properties.key_escrowed.title,
          displayIcon: getIconName(fileVaultSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    fileVaultColumnHelper.accessor(
      (row) => row[`${fileVaultSchema.uri}.regeneration_needed`],
      {
        id: `${fileVaultSchema.uri}.regeneration_needed`,
        cell: YesNoCell,
        meta: {
          displayName: properties.regeneration_needed.title,
          displayIcon: getIconName(fileVaultSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    fileVaultColumnHelper.accessor(
      (row) => row[`${fileVaultSchema.uri}.scheduled_key_rotation`],
      {
        id: `${fileVaultSchema.uri}.scheduled_key_rotation`,
        cell: DateCell,
        meta: {
          displayName: properties.scheduled_key_rotation.title,
          displayIcon: getIconName(fileVaultSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(fileVaultSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
