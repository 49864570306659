import { Button, Select, TextInput } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import React, { useEffect, useState } from 'react';
import { i18n } from 'src/i18n';

const defaultUsersGroups = {
  users: [],
  groups: [],
};

const UsersAndGroupsRestriction = (props) => {
  const { param, update, isDisabled, disableOptionLabel, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details);
  const [isInvalid, setIsInvalid] = useState({
    users: details?.users.map(() => false) || [],
    groups: details?.groups.map(() => false) || [],
  });

  const sharingOptions = [
    { label: disableOptionLabel, value: 'none' },
    { label: i18n.t('Restrict to users and groups'), value: 'users' },
  ];

  const {
    UsersAndGroupsRestriction_users: validateUsers,
    UsersAndGroupsRestriction_groups: validateGroups,
    UsersAndGroupsRestriction_userInput: validateUserInput,
    UsersAndGroupsRestriction_groupInput: validateGroupInput,
  } = validations;

  const resetButtonMargin = (
    errMsgEl,
    { dataAttrsTuples: [[_, dataNameValue]] },
  ) => {
    const prevSiblingEl = errMsgEl.previousElementSibling;

    if (!prevSiblingEl) {
      return;
    }

    prevSiblingEl.setAttribute(
      `data-sync-validation-${dataNameValue}-link`,
      dataNameValue,
    );
    prevSiblingEl.style.marginBottom = 0;
  };

  const modifyButtonMargin = (
    _nullRef,
    { dataAttrsTuples: [[_, dataNameValue]] },
  ) => {
    const prevSiblingEl = document.querySelector(
      `[data-sync-validation-${dataNameValue}-link="${dataNameValue}"]`,
    );

    if (prevSiblingEl) {
      prevSiblingEl.style.marginBottom = '';
    }
  };

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
      setIsInvalid({
        users: details?.users.map(() => false) || [],
        groups: details?.groups.map(() => false) || [],
      });
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        if (!value) {
          update(null, ['details', 'isInvalid']);
        } else {
          update({
            details: value,
            isInvalid: [...isInvalid.users, ...isInvalid.groups].some(Boolean),
          });
        }
      }, 250);
      return () => clearTimeout(time);
    }
  }, [value]);

  const onAdd = (addUser) =>
    setValue((prev) => ({
      ...prev,
      ...(addUser
        ? { users: [...prev.users, ''] }
        : { groups: [...prev.groups, ''] }),
    }));
  const onTrash = (i, delUser) => {
    setValue((prev) => ({
      ...prev,
      ...(delUser
        ? { users: prev.users.filter((_, idx) => i !== idx) }
        : { groups: prev.groups.filter((_, idx) => i !== idx) }),
    }));
  };

  const emptyValidator = (v) => [
    {
      message: i18n.t('Required'),
      invalid: () => !v,
      trigger: ['onBlur'],
    },
  ];

  return (
    <div className="">
      <Select
        className="b-mb1"
        disabled={isDisabled}
        options={sharingOptions}
        value={sharingOptions[value?.users ? 1 : 0]}
        onChange={(v) => {
          setValue(v.value === 'none' ? null : deepcopy(defaultUsersGroups));
        }}
      />

      {value?.users && (
        <div className="b-flex b-flex-g1">
          <div className="b-flex-vg3 b-flex1">
            {value.users.map((user, idx) => {
              return (
                <div key={idx} className="b-flex b-flex-g1">
                  <div className="b-flex1">
                    <TextInput
                      disabled={isDisabled}
                      fieldsGrid
                      label={`User #${idx + 1}`}
                      value={user}
                      onChange={(e) => {
                        const val = e.target.value;
                        setValue((prev) => ({
                          ...prev,
                          users: [
                            ...prev.users.map((u, i) => (i === idx ? val : u)),
                          ],
                        }));
                      }}
                      validator={emptyValidator}
                      {...validateUserInput.syncInvalid(!value.users[idx], {
                        key: `user-input-${param.parameter.id}-${idx}`,
                      })}
                    />
                  </div>
                  <Button
                    className="bl-p-align-self-end"
                    disabled={isDisabled}
                    kind="link"
                    theme="error"
                    icon="trash-can"
                    onClick={() => onTrash(idx, true)}
                  />
                </div>
              );
            })}
            <Button
              style={{ marginTop: 'auto' }}
              disabled={isDisabled}
              icon="plus"
              onClick={() => onAdd(true)}
            >
              Add user
            </Button>
            {validateUsers.displayInvalid(
              isInvalid.users && !value.users.length && !value.groups.length,
              {
                key: `users-restrictions-${param.parameter.id}`,
                imperativeHandleElement: resetButtonMargin,
                imperativeOnElementUnmount: modifyButtonMargin,
              },
            )}
          </div>
          <div className="b-flex-vg3 b-flex1">
            {value.groups.map((group, idx) => {
              return (
                <div key={idx} className="b-flex b-flex-g1">
                  <div className="b-flex1">
                    <TextInput
                      disabled={isDisabled}
                      fieldsGrid
                      label={`Group #${idx + 1}`}
                      value={group}
                      onChange={(e) => {
                        const val = e.target.value;
                        setValue((prev) => ({
                          ...prev,
                          groups: [
                            ...prev.groups.map((u, i) => (i === idx ? val : u)),
                          ],
                        }));
                      }}
                      validator={emptyValidator}
                      {...validateGroupInput.syncInvalid(!value.groups[idx], {
                        key: `group-input-${param.parameter.id}-${idx}`,
                      })}
                    />
                  </div>
                  <Button
                    className="bl-p-align-self-end"
                    disabled={isDisabled}
                    kind="link"
                    theme="error"
                    icon="trash-can"
                    onClick={() => onTrash(idx)}
                  />
                </div>
              );
            })}
            <Button
              style={{ marginTop: 'auto' }}
              disabled={isDisabled}
              icon="plus"
              onClick={() => onAdd()}
            >
              Add group
            </Button>
            {validateGroups.displayInvalid(
              isInvalid.groups && !value.groups.length && !value.users.length,
              {
                key: `groups-restrictions-${param.parameter.id}`,
                imperativeHandleElement: resetButtonMargin,
                imperativeOnElementUnmount: modifyButtonMargin,
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersAndGroupsRestriction;
