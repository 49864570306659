import _get from 'lodash/get';
import propTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchAuth0Credentials } from 'app/_actions/account';
import history from '../app/router/history';
import { Auth0Provider, createAuth0Client } from '../auth0';
import { auth0Domain, auth0RedirectUri } from '../config';

const mapStateToProps = ({ auth0 }) => ({
  clientId: auth0.clientId,
  organizationId: auth0.organizationId,
  connectionName: auth0.connectionName,
  audience: auth0.audience,
  locale: auth0.locale,
});

const mapDispatchToProps = {
  fetchAuth0Credentials,
};

const onRedirectCallback = (appState) => {
  // When using Hash Router, use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const getSubdomain = () => {
  const [subdomain] = window.location.hostname
    .split('.')
    .filter((str) => str !== 'www');
  return subdomain;
};

function withAuth0(BaseComponent) {
  function Auth0Wrapper({
    clientId = '',
    organizationId = '',
    connectionName = '',
    audience = '',
    locale = '',
    fetchAuth0Credentials: fetchCreds,
  }) {
    const [auth0Client, setAuth0Client] = useState(null);

    useEffect(() => {
      const subdomain = getSubdomain();
      const queryParams = queryString.parse(window.location.search);
      const metadata = _get(queryParams, 'metadata', '');
      const queryLocale = _get(queryParams, 'locale', '');
      const path = window.location.pathname;
      fetchCreds({ subdomain, metadata, locale: queryLocale, path });
    }, []);

    useEffect(() => {
      if (clientId) {
        const client = createAuth0Client({
          audience,
          clientId,
          organizationId,
          connectionName,
          domain: auth0Domain,
          redirectUri: auth0RedirectUri,
          // standard claims
          scope: `openid profile email`,
          // for incognito / safari we need to persist
          cacheLocation: 'localstorage',
          locale,
        });
        setAuth0Client(client);
      }
    }, [clientId, organizationId, connectionName, audience, locale]);

    return (
      <Auth0Provider
        auth0Client={auth0Client}
        onRedirectCallback={onRedirectCallback}
      >
        <BaseComponent />
      </Auth0Provider>
    );
  }
  Auth0Wrapper.propTypes = {
    clientId: propTypes.string,
    organizationId: propTypes.string,
    connectionName: propTypes.string,
    audience: propTypes.string,
    locale: propTypes.string,
    fetchAuth0Credentials: propTypes.func.isRequired,
  };
  return connect(mapStateToProps, mapDispatchToProps)(Auth0Wrapper);
}

export default withAuth0;
