import {
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Button } from '@kandji-inc/nectar-ui';
import React, { memo, useCallback } from 'react';

import { Setting } from 'src/features/library-items/template';
import { i18n } from 'src/i18n';
import ExtentionSettings from '../components/ExtensionSettings';

const Extension = (props) => {
  const { update, isDisabled, extension, validationDep, isSubmitted } = props;

  const MAX_DOMAINS = 20;
  const allowedDomainsIdentifiers = new Array(MAX_DOMAINS)
    .fill(0)
    .map((_, idx) => `${extension.id}_allowed_domain_${idx}`);

  const deniedDomainsIdentifiers = new Array(MAX_DOMAINS)
    .fill(0)
    .map((_, idx) => `${extension.id}_denied_domain_${idx}`);

  const extensionIdentifiers = [
    `${extension.id}_BundleId`,
    `${extension.id}_TeamId`,
    ...allowedDomainsIdentifiers,
    ...deniedDomainsIdentifiers,
  ];

  const { refs, onInvalidate } = useInputsValidators(
    extensionIdentifiers,
    update,
  );

  useRemoveValidationOnUnmount(extensionIdentifiers, update);

  const onRemove = (id) => {
    update('extensions', (prev) =>
      prev.filter(({ id: existingId }) => existingId !== id),
    );
  };

  const onUpdateExtension = (key, value) => {
    update('extensions', (prev) =>
      prev.map((ext) => {
        /* istanbul ignore next */
        if (ext.id === extension.id) {
          return {
            ...ext,
            [key]: value,
          };
        }
        return ext;
      }),
    );
  };

  const onUpdateExtSettings = useCallback(
    /* istanbul ignore next */ (key, value) => {
      update('extensions', (prev) =>
        prev.map((ext) => {
          if (ext.id === extension.id) {
            return {
              ...ext,
              [key]: typeof value === 'function' ? value(ext[key]) : value,
            };
          }
          return ext;
        }),
      );
    },
    [],
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Specific extension configuration</h3>
        {!isDisabled && (
          <Button
            compact
            variant="subtleDanger"
            icon={{ name: 'trash-can' }}
            onClick={() => onRemove(extension.id)}
            data-testid="remove-extension"
          >
            {i18n.t('Remove')}
          </Button>
        )}
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('Bundle ID')}</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Bundle ID of the extension')}.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <div ref={refs[extensionIdentifiers[0]]}>
              <TextInput
                placeholder={i18n.t('Enter Bundle ID')}
                value={extension.BundleId}
                onChange={(e) => {
                  onUpdateExtension('BundleId', e.target.value);
                }}
                disabled={isDisabled}
                validator={(v) => {
                  return [
                    onEmptyBlurValidator(v, {
                      message: i18n.t('Required'),
                      trigger: [
                        'onBlur',
                        .../*istanbul ignore next */ (isSubmitted
                          ? ['onInput', 'onMount']
                          : []),
                        validationDep,
                      ],
                    }),
                  ];
                }}
                onInvalidate={onInvalidate(0)}
                data-testid="bundleid-input"
              />
            </div>
          </Setting.Controls>
        </Setting.Row>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('Team ID')}</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Apple Developer Team ID of the developer of the extension.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <div ref={refs[extensionIdentifiers[1]]}>
              <TextInput
                placeholder={i18n.t('Enter Team ID')}
                value={extension.TeamId}
                onChange={(e) => {
                  onUpdateExtension('TeamId', e.target.value);
                }}
                disabled={isDisabled}
                validator={(v) => {
                  return [
                    onEmptyBlurValidator(v, {
                      message: i18n.t('Required'),
                      trigger: [
                        'onBlur',
                        ...(isSubmitted ? ['onInput', 'onMount'] : []),
                        validationDep,
                      ],
                    }),
                  ];
                }}
                onInvalidate={onInvalidate(1)}
                data-testid="teamid-input"
              />
            </div>
          </Setting.Controls>
        </Setting.Row>

        <ExtentionSettings
          isDisabled={isDisabled}
          update={onUpdateExtSettings}
          settings={extension}
          validationDep={validationDep}
          validationRefs={refs}
          allowedDomainsIdentifiers={allowedDomainsIdentifiers}
          deniedDomainsIdentifiers={deniedDomainsIdentifiers}
          onInvalidate={(identifier, isInvalid) =>
            onInvalidate(identifier)(isInvalid)
          }
        />
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(Extension);
