import type {
  LibraryItemExcludedStatusContent,
  LibraryItemStatusContent,
} from '../CommonStatus.types';

export enum WallpaperStatusTypes {
  WALLPAPER_EXCLUDED_AUDIT = 'wallpaper_excluded_audit',
  WALLPAPER_SETTINGS_FAILURE_AUDIT = 'wallpaper_settings_failure',
  WALLPAPER_SETTINGS_SUCCESS_AUDIT = 'wallpaper_settings_success',
  WALLPAPER_SETTINGS_PENDING_AUDIT = 'wallpaper_settings_pending',
  WALLPAPER_SETTINGS_INCOMPATIBLE_AUDIT = 'wallpaper_settings_incompatible',
}

export interface WallpaperSettingsCommonStatusDetails {
  where: number[];
}

export interface ExcludedWallpaperStatusAuditLog
  extends LibraryItemExcludedStatusContent {
  type: WallpaperStatusTypes.WALLPAPER_EXCLUDED_AUDIT;
  version: number;
}

export interface WallpaperSettingsIncompatibleStatusAuditLog
  extends LibraryItemStatusContent {
  type: WallpaperStatusTypes.WALLPAPER_SETTINGS_INCOMPATIBLE_AUDIT;
  version: number;
  details: null;
}

export interface WallpaperSettingsPendingDetails
  extends WallpaperSettingsCommonStatusDetails {}

export interface WallpaperSettingsPendingStatusAuditLog
  extends LibraryItemStatusContent {
  type: WallpaperStatusTypes.WALLPAPER_SETTINGS_PENDING_AUDIT;
  version: number;
  details: WallpaperSettingsPendingDetails;
}

export interface WallpaperSettingsSuccessDetails
  extends WallpaperSettingsCommonStatusDetails {}

export interface WallpaperSettingsSuccessAuditLog
  extends LibraryItemStatusContent {
  type: WallpaperStatusTypes.WALLPAPER_SETTINGS_SUCCESS_AUDIT;
  version: number;
  details: WallpaperSettingsSuccessDetails;
}

export interface WallpaperSettingsItemErrorDetails {
  localized_description?: string;
  us_english_description?: string;
}

export interface WallpaperSettingsFailureDetails
  extends WallpaperSettingsCommonStatusDetails {
  error_chain?: WallpaperSettingsItemErrorDetails[];
}

export interface WallpaperSettingsFailureAuditLog
  extends LibraryItemStatusContent {
  type: WallpaperStatusTypes.WALLPAPER_SETTINGS_FAILURE_AUDIT;
  version: number;
  details: WallpaperSettingsFailureDetails;
}

export type WallpaperStatusAuditLog =
  | ExcludedWallpaperStatusAuditLog
  | WallpaperSettingsIncompatibleStatusAuditLog
  | WallpaperSettingsPendingStatusAuditLog
  | WallpaperSettingsSuccessAuditLog
  | WallpaperSettingsFailureAuditLog;
