/* istanbul ignore file */
import React, { useContext, useCallback } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import type { ScepProps } from './scep.types';
import General from './sections/General';
import Key from './sections/Key';
import Options from './sections/Options';
import Subject from './sections/Subject';
import { scepService } from './service/scep-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useScepService from './service/use-scep-service';

const ScepPage = (props: ScepProps) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);
  const subjectUpdate = useCallback(update('subject', setModel), []);
  const keyUpdate = useCallback(update('key', setModel), []);
  const optionsUpdate = useCallback(update('options', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={scepService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsDuplication
      supportsInstallOn
    >
      <General
        settings={model.general}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        update={generalUpdate}
      />
      <Subject
        settings={model.subject}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        update={subjectUpdate}
      />
      <Key
        settings={model.key}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        update={keyUpdate}
      />
      <Options
        settings={model.options}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        update={optionsUpdate}
      />
    </LibraryItemPage>
  );
};

const Scep = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useScepService,
  });

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <ScepPage {...pageProps} testId="scep-v2" />;
};

export default Scep;
