import deepcopy from 'deepcopy';
import type { Edge, Node } from 'reactflow';
import { v4 as uuid } from 'uuid';
/* istanbul ignore file */
import { create } from 'zustand';

import type {
  Blueprint,
  DraggableLibraryItem,
  LibraryItem,
  LibraryItemFilter,
  LibraryItemFilterDevice,
  LibraryItemFilterSort,
  MapSearchResults,
  MapSelectedDevice,
  SelectedAssignmentLibraryItem,
  ValidationErrors,
} from './blueprint-flow.types';

import { initialValidationErrors } from './initial-state';

type State = {
  blueprint: Blueprint;
  model: Blueprint;
  isAddingBlueprint: boolean;
  isLoadingMap: boolean;
  libraryItems: Array<LibraryItem>;
  isLoadingLibraryItems: boolean;
  simpleMap: {
    nodes: Node[];
    edges: Edge[];
  };
  isSimpleMap: boolean;

  isReactFlowReady: boolean;
  isEditingAssignments: boolean;
  isOptionPressed: boolean;
  conflictingLibraryItems: Array<LibraryItem['flowId']>;
  selectedAssignmentLibraryItems: SelectedAssignmentLibraryItem;
  draggingLibraryItems: {
    origin: LibraryItem['origin'];
    items: Array<DraggableLibraryItem>;
  };
  assignmentsLibrarySort: LibraryItemFilterSort;
  assignmentsLibraryTypesFilter: Array<string>;
  assignmentsLibraryDevicesFilter: Array<LibraryItemFilterDevice>;
  libraryItemFilter: LibraryItemFilter;
  facetMap: any;
  validationErrors: ValidationErrors;
  notificationPosition: string;
  graphItemsExpansion: 'expand' | 'collapse';
  selectedDevice: MapSelectedDevice;
  mapSearchTerm: string;
  activeMatches: MapSearchResults;

  isEditingParameters: boolean;
  isDeletingNode: boolean;

  countOfUserDirectoryIntegrations: number;
  hasUserDirectoryIntegration: boolean;
  isLibraryItemExcludedFromSelectedDevice: (libraryId: string) => boolean;
  libraryItemExclusionsCount: (libraryId: string) => number;
  selectedExclusionLibraryItemId: LibraryItem['id'];
};

type Action = {
  setIsSimpleMap: (is: boolean) => void;
  setSimpleMap: (simpleMap: State['simpleMap']) => void;
  setBlueprint: (blueprint?: State['blueprint'], reset?: boolean) => void;
  setBlueprintModel: (
    blueprint:
      | State['blueprint']
      | ((prev: State['blueprint']) => State['blueprint']),
  ) => void;
  setIsAddingBlueprint: (is: boolean) => void;
  setIsLoadingMap: (isLoading: boolean) => void;
  setLibraryItems: (items: Array<LibraryItem>, isLoading?: boolean) => void;
  addSimpleMapLibraryItem: (item: DraggableLibraryItem) => void;
  removeSimpleMapLibraryItem: (itemId: string) => void;
  setIsLoadingLibraryItems: (isLoading: boolean) => void;
  setIsReactFlowReady: (isReady: boolean) => void;
  setIsEditingAssignments: (
    isEditingAssignments: boolean,
    resetModel?: boolean,
  ) => void;
  setIsOptionPressed: (is: boolean) => void;
  setConflictingLibraryItems: (items: Array<LibraryItem['flowId']>) => void;
  setSelectedAssignmentLibraryItems: (
    selected:
      | SelectedAssignmentLibraryItem
      | ((
          prev: State['selectedAssignmentLibraryItems'],
        ) => State['selectedAssignmentLibraryItems']),
  ) => void;
  clearSelectedAssignmentLibraryItems: () => void;
  setDraggingLibraryItems: (opts: {
    origin: LibraryItem['origin'];
    items: Array<DraggableLibraryItem>;
  }) => void;
  setLibraryItemFilter: (filter: LibraryItemFilter) => void;
  setAssignmentsLibrarySort: (sort: LibraryItemFilterSort) => void;
  setAssignmentsLibraryTypesFilter: (types: Array<string>) => void;
  setAssignmentsLibraryDevicesFilter: (
    devices: Array<LibraryItemFilterDevice>,
  ) => void;
  setFacetMap: (facet: any) => void;
  setValidationErrors: (errors: ValidationErrors) => void;
  setNotificationPosition: (position: string) => void;
  generateNewFlowId: (item: DraggableLibraryItem) => void;
  getFacets: () => void;
  setGraphItemsExpansion: (kind: 'expand' | 'collapse') => void;
  setSelectedDevice: (
    selected:
      | MapSelectedDevice
      | ((prev: State['selectedDevice']) => State['selectedDevice']),
  ) => void;
  setMapSearchTerm: (term: string) => void;
  setActiveMatches: (
    matches:
      | MapSearchResults
      | ((prev: State['activeMatches']) => State['activeMatches']),
  ) => void;

  setIsEditingParameters: (isEditingParameters: boolean) => void;
  setIsDeletingNode: (
    isDeleting: boolean,
    conditionalDescendants: string[],
  ) => void;
  descendantsToBeDeleted: string[];

  setCountOfUserDirectoryIntegrations: (count: number) => void;
  setHasUserDirectoryIntegration: (
    hasUserDirectoryIntegration: boolean,
  ) => void;
  setDeviceExclusions: (
    exclusions: Blueprint['library_item_exclusions'],
  ) => void;
  setSelectedExclusionLibraryItemId: (libraryId: LibraryItem['id']) => void;
};

const useBlueprintFlow = create<Partial<State> & Partial<Action>>(
  (set, get) => ({
    blueprint: null,
    model: null,
    isAddingBlueprint: true,
    isLoadingMap: true,

    // Assignments
    isReactFlowReady: false,
    isEditingAssignments: false,
    isOptionPressed: false,
    simpleMap: {
      nodes: [],
      edges: [],
    },
    isSimpleMap: false,
    isLibraryItemExcludedFromSelectedDevice: (libraryId) => {
      const exclusions = get().model.library_item_exclusions;
      const selectedDevice = get().selectedDevice;
      return exclusions.some(
        (exclusion) =>
          exclusion.library_item_id === libraryId &&
          exclusion.computer_ids.some(
            (id) => id === selectedDevice?.device?.id,
          ),
      );
    },
    libraryItemExclusionsCount: (libraryId) => {
      const exclusions = get().model.library_item_exclusions;

      return (
        exclusions.find((exclusion) => exclusion.library_item_id === libraryId)
          ?.computer_ids.length || 0
      );
    },
    conflictingLibraryItems: [],
    selectedAssignmentLibraryItems: {
      origin: null,
      items: {},
      lastItemClicked: [],
    },
    draggingLibraryItems: {
      origin: null,
      items: [],
    },
    libraryItems: [],
    isLoadingLibraryItems: true,
    assignmentsLibrarySort: 'li_name_az',
    assignmentsLibraryTypesFilter: [],
    assignmentsLibraryDevicesFilter: [],
    libraryItemFilter: {
      term: '',
      sort: 'li_name_az',
      devices: [],
      types: [],
      blueprints: [],
      onMap: null,
    },
    facetMap: {},
    validationErrors: deepcopy(initialValidationErrors),
    notificationPosition: '',
    graphItemsExpansion: null,
    isDeletingNode: false,
    descendantsToBeDeleted: [],
    selectedDevice: {
      device: null,
      devicePath: null,
    },
    mapSearchTerm: '',
    activeMatches: {
      currentMatchIndex: 0,
      matches: [],
    },

    countOfUserDirectoryIntegrations: 0,
    hasUserDirectoryIntegration: false,
    // END Assignments

    // Parameters
    isEditingParameters: false,
    // END Parameters

    selectedExclusionLibraryItemId: null,

    setIsSimpleMap: (is) => set(() => ({ isSimpleMap: is })),

    setSimpleMap: (simpleMap) => set(() => ({ simpleMap })),

    setBlueprint: (blueprint, reset = false) => {
      const isBlueprintSimple = !blueprint?.nodes?.some((node) =>
        ['assignment', 'conditional'].includes(node.type),
      );

      return set(() =>
        reset
          ? {
              blueprint: null,
              isAddingBlueprint: true,
              model: null,
              simpleMap: {
                nodes: [],
                edges: [],
              },
            }
          : {
              blueprint,
              model: deepcopy(blueprint),
              simpleMap: {
                nodes: blueprint.nodes,
                edges: blueprint.edges,
              },
              isSimpleMap: isBlueprintSimple,
            },
      );
    },
    setBlueprintModel: (model) =>
      set((prev) => ({
        model: typeof model === 'function' ? model(prev.model) : model,
      })),
    setIsAddingBlueprint: (is) => set(() => ({ isAddingBlueprint: is })),
    setIsLoadingMap: (is) => set(() => ({ isLoadingMap: is })),
    setLibraryItems: (items, isLoading = false) =>
      set(() => ({ isLoadingLibraryItems: isLoading, libraryItems: items })),
    addSimpleMapLibraryItem: (item) => {
      const currentSimpleMap = get().simpleMap;

      return set(() => ({
        simpleMap: {
          ...currentSimpleMap,
          nodes: currentSimpleMap?.nodes?.map((node) => {
            if (node.type === 'start') {
              return {
                ...node,
                data: {
                  ...node.data,
                  items: node.data?.items?.concat(item),
                },
              };
            }

            return node;
          }),
        },
      }));
    },
    removeSimpleMapLibraryItem: (itemId: string) => {
      const currentSimpleMap = get().simpleMap;
      return set(() => ({
        simpleMap: {
          ...currentSimpleMap,
          nodes: currentSimpleMap?.nodes?.map((node) => {
            if (node.type === 'start') {
              return {
                ...node,
                data: {
                  ...node.data,
                  items: node.data?.items?.filter(
                    (currItem) => currItem.id !== itemId,
                  ),
                },
              };
            }

            return node;
          }),
        },
      }));
    },
    setIsLoadingLibraryItems: (is) =>
      set(() => ({ isLoadingLibraryItems: is })),
    setIsEditingAssignments: (isEditingAssignments, resetModel = false) => {
      if (isEditingAssignments || resetModel) {
        return set((prev) => ({
          isEditingAssignments,
          model: deepcopy(prev.blueprint),
        }));
      }

      return set(() => ({
        isEditingAssignments,
      }));
    },
    setIsOptionPressed: (is) => set(() => ({ isOptionPressed: is })),
    setConflictingLibraryItems: (items) =>
      set(() => ({ conflictingLibraryItems: items })),
    setIsReactFlowReady: (is) => set(() => ({ isReactFlowReady: is })),
    setSelectedAssignmentLibraryItems: (selected) =>
      set((prev) => ({
        selectedAssignmentLibraryItems:
          typeof selected === 'function'
            ? selected(prev.selectedAssignmentLibraryItems)
            : selected,
      })),
    clearSelectedAssignmentLibraryItems: () =>
      set(() => ({
        selectedAssignmentLibraryItems: {
          origin: null,
          items: {},
          lastItemClicked: [],
        },
      })),
    setDraggingLibraryItems: (items) =>
      set(() => ({ draggingLibraryItems: items })),
    setLibraryItemFilter: (filter) =>
      set(() => ({ libraryItemFilter: filter })),
    setAssignmentsLibrarySort: (sort) =>
      set(() => ({ assignmentsLibrarySort: sort })),
    setAssignmentsLibraryTypesFilter: (types) =>
      set(() => ({ assignmentsLibraryTypesFilter: types })),
    setAssignmentsLibraryDevicesFilter: (devices) =>
      set(() => ({ assignmentsLibraryDevicesFilter: devices })),
    setFacetMap: (facetMap) => set(() => ({ facetMap })),
    setValidationErrors: (validationErrors) =>
      set(() => ({ validationErrors })),
    setNotificationPosition: (notificationPosition) =>
      set(() => ({ notificationPosition })),
    generateNewFlowId: (item) =>
      set((prev) => ({
        libraryItems: prev.libraryItems.map((prevItem) => {
          if (prevItem.id === item.data.id) {
            return { ...prevItem, flowId: uuid() };
          }
          return prevItem;
        }),
      })),
    setGraphItemsExpansion: (kind) => set({ graphItemsExpansion: kind }),
    setSelectedDevice: (selected) =>
      set((prev) => ({
        selectedDevice:
          typeof selected === 'function'
            ? selected(prev.selectedDevice)
            : selected,
      })),
    setMapSearchTerm: (term) => set(() => ({ mapSearchTerm: term })),
    setActiveMatches: (matches) =>
      set((prev) => ({
        activeMatches:
          typeof matches === 'function' ? matches(prev.activeMatches) : matches,
      })),

    setIsEditingParameters: (isEditingParameters) =>
      set(() => ({ isEditingParameters })),

    setIsDeletingNode: (isDeletingNode, conditionalDescendants: string[]) =>
      set({
        isDeletingNode,
        descendantsToBeDeleted: isDeletingNode ? conditionalDescendants : [],
      }),

    setCountOfUserDirectoryIntegrations: (count) =>
      set(() => ({ countOfUserDirectoryIntegrations: count })),
    setHasUserDirectoryIntegration: (has) =>
      set(() => ({ hasUserDirectoryIntegration: has })),
    setDeviceExclusions: (exclusions: Blueprint['library_item_exclusions']) => {
      set((prev) => ({
        model: {
          ...prev.model,
          library_item_exclusions: exclusions,
        },
      }));
    },
    setSelectedExclusionLibraryItemId: (libraryId) => {
      set(() => ({ selectedExclusionLibraryItemId: libraryId }));
    },
  }),
);

export default useBlueprintFlow;
