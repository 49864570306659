import { i18n } from 'i18n';

export enum ManagedLibraryItemStatusTypes {
  MANAGED_LIBRARY_ITEM_MISSING_AUDIT = 'managed_library_item_missing_audit',
  MANAGED_LIBRARY_ITEM_CURRENT_AUDIT = 'managed_library_item_current_audit',
  MANAGED_LIBRARY_ITEM_OUTDATED_AUDIT = 'managed_library_item_outdated_audit',
  MANAGED_LIBRARY_ITEM_INSTALL_FAILED_AUDIT = 'managed_library_item_install_failed_audit',
}

export enum ManagedLibraryItemTypes {
  MACOS_RELEASE = 'macos-release',
}

export enum ManagedLibraryItemVersionEnforcement {
  ANY = 'any',
  NEWEST = 'newest',
  CUSTOM = 'custom',
}

export class ManagedLibraryItemStatusDetails {
  managed_library_item_type: string;
  newest_version: string;
  newest_bundle_version: string;
  installed_version: string;
  installed_bundle_version: string;
  is_up_to_date: boolean;
  is_newer: boolean;
  app_name: string;
  app_installed_version_name: string;
  newest_version_display: string;
  minimum_app_version_display: string;
  version_enforcement: string;
  show_in_self_service: boolean;
  is_continuously_enforced_on_update_only: boolean;
  enforcement_delay?: string | null;
  is_enforcement_active?: boolean | null;
  enforcement_date: string;

  constructor(
    managed_library_item_type: string,
    newest_version: string,
    newest_bundle_version: string,
    installed_version: string,
    installed_bundle_version: string,
    is_up_to_date: boolean,
    is_newer: boolean,
    app_name: string,
    app_installed_version_name: string,
    newest_version_display: string,
    minimum_app_version_display: string,
    version_enforcement: string,
    show_in_self_service: boolean,
    is_continuously_enforced_on_update_only: boolean,
    enforcement_delay: string | null,
    is_enforcement_active: boolean | null,
    enforcement_date: string,
  ) {
    this.managed_library_item_type = managed_library_item_type;
    this.newest_version = newest_version;
    this.newest_bundle_version = newest_bundle_version;
    this.installed_version = installed_version;
    this.installed_bundle_version = installed_bundle_version;
    this.is_up_to_date = is_up_to_date;
    this.is_newer = is_newer;
    this.app_name = app_name;
    this.app_installed_version_name = app_installed_version_name;
    this.newest_version_display = newest_version_display;
    this.minimum_app_version_display = minimum_app_version_display;
    this.version_enforcement = version_enforcement;
    this.show_in_self_service = show_in_self_service;
    this.is_continuously_enforced_on_update_only =
      is_continuously_enforced_on_update_only;
    this.enforcement_delay = enforcement_delay;
    this.is_enforcement_active = is_enforcement_active;
    this.enforcement_date = enforcement_date;
  }

  get_message(): string {
    let app_status: string;
    let install_status: string;

    if (!this.installed_version) {
      if (this.is_continuously_enforced_on_update_only) {
        return i18n.t(
          '{app_name} is not currently installed; nothing to update in update only mode.',
          { app_name: this.app_name },
        );
      } else if (this.show_in_self_service) {
        return i18n.t(
          '{app_name} is waiting for install through Self Service.',
          { app_name: this.app_name },
        );
      } else {
        if (
          this.managed_library_item_type ===
          ManagedLibraryItemTypes.MACOS_RELEASE
        ) {
          return i18n.t(
            '{app_name} is being enforced. Kandji will attempt to upgrade the Mac...',
            { app_name: this.app_name },
          );
        }
        return i18n.t(
          '{app_name} is missing. Kandji will attempt to reinstall...',
          { app_name: this.app_name },
        );
      }
    } else if (this.is_up_to_date) {
      app_status = i18n.t(
        '{app_installed_version_name} is installed and up to date.',
        { app_installed_version_name: this.app_installed_version_name },
      );
    } else {
      app_status = i18n.t('{app_installed_version_name} is installed.', {
        app_installed_version_name: this.app_installed_version_name,
      });
    }

    switch (this.version_enforcement) {
      case ManagedLibraryItemVersionEnforcement.ANY:
        if (this.is_up_to_date) {
          install_status = i18n.t(
            'Kandji is not set to enforce updates for {app_name}.',
            { app_name: this.app_name },
          );
        } else {
          install_status = i18n.t(
            'A newer version ({newest_version_display}) is available, but Kandji is not set to enforce updates for {app_name}.',
            {
              newest_version_display: this.newest_version_display,
              app_name: this.app_name,
            },
          );
        }
        break;
      case ManagedLibraryItemVersionEnforcement.NEWEST:
        const timeframe = this.enforcement_delay;

        if (this.is_up_to_date) {
          install_status = i18n.t(
            'Kandji is set to automatically enforce updates for {app_name} {timeframe} after they are released.',
            { app_name: this.app_name, timeframe },
          );
        } else {
          install_status = i18n.t(
            'A newer version ({newest_version_display}) of {app_name} is available. Kandji is set to automatically enforce updates ${timeframe} after they are released.',
            {
              newest_version_display: this.newest_version_display,
              app_name: this.app_name,
              timeframe,
            },
          );
        }
        if (this.is_newer) {
          app_status = i18n.t(
            '{app_installed_version_name} is installed, which is newer than the version being enforced.',
            { app_installed_version_name: this.app_installed_version_name },
          );
        }
        break;
      case ManagedLibraryItemVersionEnforcement.CUSTOM:
        const enforcement_date = this.enforcement_date;

        const is_enforcement_active =
          new Date() >= new Date(enforcement_date || '');

        if (this.is_up_to_date) {
          if (is_enforcement_active) {
            install_status = i18n.t(
              'Kandji is set to enforce a minimum version ({minimum_app_version_display}) of {app_name}.',
              {
                minimum_app_version_display: this.minimum_app_version_display,
                app_name: this.app_name,
              },
            );
          } else {
            install_status = i18n.t(
              'Kandji is set to enforce a minimum version ({minimum_app_version_display}) of {app_name} starting on {enforcement_date}.',
              {
                minimum_app_version_display: this.minimum_app_version_display,
                app_name: this.app_name,
                enforcement_date,
              },
            );
          }
        } else if (is_enforcement_active) {
          install_status = i18n.t(
            'A newer version ({newest_version_display}) of {app_name} is available. Kandji is set to enforce a minimum version ({minimum_app_version_display}) of {app_name}.',
            {
              newest_version_display: this.newest_version_display,
              app_name: this.app_name,
              minimum_app_version_display: this.minimum_app_version_display,
            },
          );
        } else {
          install_status = i18n.t(
            'A newer version ({newest_version_display}) of {app_name} is available. Kandji is set to enforce a minimum version ({minimum_app_version_display}) of {app_name} starting on {enforcement_date}.',
            {
              newest_version_display: this.newest_version_display,
              app_name: this.app_name,
              minimum_app_version_display: this.minimum_app_version_display,
              enforcement_date,
            },
          );
        }
        break;
      default:
        return i18n.t('No details.');
    }

    return `${app_status}\n${install_status}`;
  }
}

export interface ManagedLibraryItemMissingAuditLog {
  type: ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_MISSING_AUDIT;
  version: number;
  details: ManagedLibraryItemStatusDetails;
}

export interface ManagedLibraryItemCurrentAuditLog {
  type: ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_CURRENT_AUDIT;
  version: number;
  details: ManagedLibraryItemStatusDetails;
}

export interface ManagedLibraryItemOutdatedAuditLog {
  type: ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_OUTDATED_AUDIT;
  version: number;
  details: ManagedLibraryItemStatusDetails;
}

export interface ManagedLibraryItemInstallFailedDetails {
  num_attempts: number;
}

export interface ManagedLibraryItemInstallFailedAudit {
  type: ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_INSTALL_FAILED_AUDIT;
  version: number;
  details: ManagedLibraryItemInstallFailedDetails;
}

export type ManagedLibraryItemContentTypes =
  | ManagedLibraryItemMissingAuditLog
  | ManagedLibraryItemCurrentAuditLog
  | ManagedLibraryItemOutdatedAuditLog
  | ManagedLibraryItemInstallFailedAudit;
