import { i18n } from 'i18n';
import { LogDetailKeys } from '../../CommonStatus.types';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import { getLogDetailLabel } from '../../lib/buildLogFromStatusDetails';
import type { DeviceNameSettingsSuccessDetails } from '../DeviceName.types';

function serializerV1(details: DeviceNameSettingsSuccessDetails) {
  const { device_name, hostname } = details;
  const log = new LibraryItemStatusLog();

  if (device_name != null || hostname != null) {
    log.addRow(i18n.t('New device name settings successfully set.'));
  }

  if (device_name != null) {
    log.addRow(getLogDetailLabel(LogDetailKeys.DeviceName), device_name);
  }

  if (hostname != null) {
    log.addRow(getLogDetailLabel(LogDetailKeys.Hostname), hostname);
  }

  if (device_name == null && hostname == null) {
    log.addRow(i18n.t('No device name pattern configured. Nothing to do.'));
  }

  return log.toString();
}

export const DeviceNameSettingsSuccessSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
