import { ManagedLibraryItemStatusTypes } from '../ManagedLibraryItems.types';
import { CurrentAuditLog } from './CurrentAuditLog';
import { InstallFailedAudit } from './InstallFailedAudit';
import { MissingAuditLogSerializers } from './MissingAuditLogSerializers';
import { OutdatedAuditLog } from './OutdatedAuditLog';

export const ManagedLibraryItemSerializers = {
  [ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_INSTALL_FAILED_AUDIT]:
    InstallFailedAudit,
  [ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_OUTDATED_AUDIT]:
    OutdatedAuditLog,
  [ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_CURRENT_AUDIT]:
    CurrentAuditLog,
  [ManagedLibraryItemStatusTypes.MANAGED_LIBRARY_ITEM_MISSING_AUDIT]:
    MissingAuditLogSerializers,
};
