import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function successSerializerV1(_details: null | undefined | {}) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('Declarative configuration applied.'))
    .toString();
}

export const DeclarativeConfigurationSuccessAuditSerializers = {
  Default: successSerializerV1,
  1: successSerializerV1,
};

function pendingSerializerV1(_details: null | undefined | {}) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('Declarative configuration not yet applied.'))
    .toString();
}

export const DeclarativeConfigurationPendingAuditSerializers = {
  Default: pendingSerializerV1,
  1: pendingSerializerV1,
};

function errorSerializerV1(_details: null | undefined | {}) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('Declarative configuration failed to apply.'))
    .toString();
}

export const DeclarativeConfigurationErrorAuditSerializers = {
  Default: errorSerializerV1,
  1: errorSerializerV1,
};
