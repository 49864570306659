import { Button } from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
import { API_BASE } from 'app/api/domains';
import { colors } from 'app/common/constants';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateLocale } from 'src/app/_actions/account';
import { getInstructionStepImage } from 'src/assets/img/enrollment_portal/instructions';
import { i18n } from 'src/i18n';
import styled from 'styled-components';
import ComputerImage from '../../../assets/img/enrollment_portal/imac_kandji.svg';
import { StepCard } from '../MDMIntegrationPage';
import { downloadFromUrl } from '../common/helpers';
import { LineLoader } from '../interface/LineLoader';
import HeaderWithLogo from './HeaderWithLogo';
import { LanguageSelect } from './LanguageSelect';
import {
  KANDJI_ENROLL_CONFIG,
  downloadFromObjectUrl,
} from './utils/downloadFromObjectUrl';
import { getEnrollmentLocale } from './utils/getEnrollmentLocale';
import { populateLocaleSelection } from './utils/populateLocaleSelection';
import { validateAndDownloadConfig } from './utils/validateAndDownloadConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComputerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const HelperText = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1a1d25;
`;

const ButtonWrapper = styled.div`
  margin: 24px auto 74px;
`;

const InstructionsHeader = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 322px;
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #1a1d25;
  box-shadow: 0px 2px 0px 0px rgba(29, 33, 42, 1),
    inset 0px -1px 0px 0px rgba(29, 33, 42, 1);
`;

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 auto 20px auto;
  background-color: #b8bdca;
  border: 0 none;
`;

const InstructionsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  width: 983px;
  margin: 20px auto;
`;

class EnrollComputerPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAccessCodeValid: false,
      selectedBlueprint: null,
      validatedAccessCode: null,
      downloadUrl: null,
    };
    this.buttonRef = React.createRef();
    this.onDownload = this.onDownload.bind(this);
  }

  componentDidMount() {
    const { history, match } = this.props;
    const accessCode = get(match, 'params.accessCode');

    const init = async () => {
      const locale = populateLocaleSelection(
        this.props.updateLocale,
        this.props.userLocale,
      );

      /* istanbul ignore next */
      if (accessCode) {
        let redirected = true;
        try {
          const { downloadUrl, blueprintId, redirect } =
            await validateAndDownloadConfig(accessCode, locale);

          if (redirect) return;

          redirected = false;

          this.setState({
            isAccessCodeValid: true,
            selectedBlueprint: blueprintId,
            validatedAccessCode: accessCode,
            downloadUrl,
          });
        } catch {
          history.push('/enroll');
        } finally {
          if (!redirected) {
            this.setState({ isLoading: false });
          }
        }
      }
    };

    init();
  }

  componentWillUnmount(): void {
    const { downloadUrl } = this.state;
    URL.revokeObjectURL(downloadUrl);
  }

  onDownload() {
    const { selectedBlueprint, validatedAccessCode, downloadUrl } = this.state;
    if (!selectedBlueprint) {
      return;
    }

    if (downloadUrl != null) {
      downloadFromObjectUrl(downloadUrl);
      return;
    }

    downloadFromUrl(
      `${API_BASE}mdm/enroll/${selectedBlueprint}?code=${validatedAccessCode}`,
    );
  }

  scrollToRef() {
    window.scrollTo(0, this.buttonRef.current.offsetTop);
  }

  render() {
    const { isLoading, isAccessCodeValid } = this.state;
    /* istanbul ignore next */
    const locale = getEnrollmentLocale(
      this.props.userLocale ?? i18n.getLocale(),
    );

    if (isLoading) {
      return <LineLoader />;
    }
    if (!isAccessCodeValid) {
      /* istanbul ignore next */
      return <h1>{i18n.t('Access code is invalid')}</h1>;
    }

    return (
      <Container id="enroll">
        <HeaderWithLogo pageName={i18n.t('Enrollment Portal')} large />
        {
          /*istanbul ignore next */
          i18n.isEnabled() && (
            <Box css={{ margin: '$5 $5 0 auto' }}>
              <LanguageSelect
                value={locale}
                onChange={
                  /* istanbul ignore next */
                  async (value: string) => {
                    await i18n.configure({ locale: value });
                    this.props.updateLocale(value);
                  }
                }
              />
            </Box>
          )
        }
        <ComputerImageWrapper>
          <img
            role="presentation"
            alt={i18n.t(`computer`)}
            src={ComputerImage}
          />
        </ComputerImageWrapper>
        <Header>{i18n.t('Enroll your Mac into Kandji')}</Header>
        <HelperText>
          {i18n.t(
            `To enroll, click the button below to download and install Kandji`,
          )}
        </HelperText>
        <ButtonWrapper>
          <Button
            type="button"
            innerRef={this.buttonRef}
            onClick={() => {
              this.onDownload();
              this.scrollToRef();
            }}
            style={{ width: '210px', background: '#FFCF01', color: '#1A1D25' }}
          >
            {i18n.t('Download Kandji')}
          </Button>
        </ButtonWrapper>
        <InstructionsHeader>
          {i18n.t('Enrollment Instructions')}
        </InstructionsHeader>
        <Separator />
        <InstructionsWrapper>
          <StepCard
            icon={i18n.t('1')}
            title={i18n.t('Locate and open the Kandji enrollment profile.')}
            instruction={[
              getInstructionStepImage(1, 1, locale),
              getInstructionStepImage(1, 2, locale),
              getInstructionStepImage(1, 3, locale),
            ]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>
              {i18n.ut(
                `After clicking the Download Kandji button above, locate the
              <b>&nbsp;{KANDJI_ENROLL_CONFIG}</b> file in your
              Downloads folder and open it.
              <b>&nbsp;Note:</b> Clicking the &quot;Profile
              Download&quot; popup will not open System Settings for you.`,
                { KANDJI_ENROLL_CONFIG },
              )}
            </span>
          </StepCard>
          <StepCard
            icon={i18n.t('2')}
            title={i18n.t('Install the profile and authenticate.')}
            instruction={[
              getInstructionStepImage(2, 1, locale),
              getInstructionStepImage(2, 2, locale),
              getInstructionStepImage(2, 3, locale),
            ]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>
              {i18n.ut(
                `<b>macOS 15+:</b> Open System Settings &gt; General &gt; Device Management<br />
                <b>macOS 13-14:</b> Open System Settings &gt; Privacy & Security &gt; Profiles <br />
              <b>macOS 11/12:</b> Open System Preferences &gt; Profiles <br />
              <br />
              Then follow the steps to complete installation of the MDM
              enrollment profile. When prompted, authenticate with an
              administrator username and password.`,
              )}
            </span>
          </StepCard>
          <StepCard
            icon={i18n.t('3')}
            title={i18n.t("Congratulations! You're all done.")}
            instruction={[getInstructionStepImage(3, 1, locale)]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>
              {i18n.t('The Kandji icon will soon appear in your menu bar.')}
            </span>
          </StepCard>
        </InstructionsWrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLocale: updateLocale,
    },
    dispatch,
  );

const mapStateToProps = ({ account }) => ({
  userLocale: account?.user?.locale,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollComputerPage);
