import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Icon,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import deviceImagesMap from 'components/common/image-device/map';
import { getItemConfig as getLibraryItemConfig } from 'features/blueprint-flow/helpers';
import { paths } from 'features/blueprints/common';
import { default as integrationStrings } from 'features/integrations/generic-cards-view/cards-config';
import { getDeviceIconByFamily } from 'features/visibility/prism/utils/column-utils';
import { links } from 'src/app/common/constants';
import { useDebouncedState } from 'src/hooks/useDebouncedState';

import { useUniversalSearchQueries } from '../hooks/universal-search-queries';
import { NoResultsFoundIcon } from './NoResultsFoundIcon';

const COLLAPSED_TOTAL = 12;

const getDeviceIcon = (modelId: string) =>
  deviceImagesMap[modelId] ?? getDeviceIconByFamily(modelId);

const NavIcon = styled(Icon, {
  borderRadius: '2px',
  color: '$neutral0',
  backgroundColor: '$neutral50',
});

const NavText = styled(Text, {
  color: '$blue50',
  visibility: 'hidden',
  fontWeight: '$medium',
  fontSize: '$2',
});

const StyledSearchListItem = styled(Flex, {
  padding: '6px $5',
  gap: '$1',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$neutral0',
  '& [data-role="nav-icon"]': {
    color: '$neutral0',
  },
  '&:hover, &:focus': {
    backgroundColor: '$button_subtle_surface_hover',
    [`& ${NavIcon}`]: {
      color: '$blue50',
      backgroundColor: 'transparent',
    },
    [`& ${NavText}`]: {
      visibility: 'visible',
    },
  },
  '&[data-selected=true]': {
    backgroundColor: '$button_subtle_surface_hover',
  },
});

const ItemSummary = styled(Flex, {
  flexDirection: 'column',
  maxWidth: 'calc(100% - 210px)',
  overflow: 'hidden',
});

const LoadingIcon = styled('div', {
  height: 24,
  width: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
});

const LoadingBar = styled('div', {
  height: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
  variants: {
    size: {
      sm: {
        width: '30%',
      },
      md: {
        width: '50%',
      },
    },
  },
});

const ResourceSection = ({ title, children }) => {
  return (
    <Flex flow="column">
      <Flex css={{ padding: '6px $5', alignItems: 'center' }}>
        <Text size="1" weight="medium" variant="description">
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

const DeviceListItem = ({ device, onSelect }) => {
  const {
    asset_tag: assetTag,
    device_family: deviceFamily,
    name: deviceName,
    model: modelId,
    serial_number: serialNumber,
    user,
  } = device;
  const icon = getDeviceIcon(modelId);
  return (
    <StyledSearchListItem
      data-testid="device-list-item"
      css={{ paddingLeft: '20px' }}
      onClick={(e) => onSelect(device, e.metaKey)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          data-testid="device_icon_image"
          height="32"
          width="32"
          src={icon}
          alt={deviceFamily}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{deviceName}</Text>
        <Text css={{ fontSize: '$1' }}>
          {modelId} - {serialNumber}
          {assetTag && ' - '}
          {assetTag}
        </Text>
        <Flex
          alignItems="center"
          css={{
            gap: '2px',
            maxHeight: '16px',
            overflowX: 'hidden',
            fontSize: '$1',
          }}
        >
          {user?.name && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="user" />
              </Flex>
              <Text css={{ flex: 'none' }}>
                {user?.name} {user?.email && ' - '}
              </Text>
            </>
          )}
          {user?.email && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="envelope" />
              </Flex>
              <Text css={{ flex: 'none' }}>{user?.email}</Text>
            </>
          )}
          {!user?.name && !user?.email && (
            <Text css={{ fontStyle: 'italic' }}>User not assigned</Text>
          )}
        </Flex>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Device record')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const BlueprintListItem = ({ blueprint, onSelect }) => {
  return (
    <StyledSearchListItem
      data-testid="blueprint-list-item"
      onClick={(e) => onSelect(blueprint, e.metaKey)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <Icon size={20} name="kandji-blueprint" />
      </Flex>
      <ItemSummary>
        <Text>{blueprint.name}</Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Blueprint')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const LibraryItemListItem = ({ libraryItem, onSelect }) => {
  const itemConfig = getLibraryItemConfig(libraryItem);
  const [icon, setIcon] = useState(libraryItem.icon);

  return (
    <StyledSearchListItem
      data-testid="library-list-item"
      onClick={(e) => onSelect(libraryItem, e.metaKey)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={icon || itemConfig.icon}
          onError={() => setIcon(itemConfig.icon)}
          alt={libraryItem.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{libraryItem.name}</Text>
        <Text variant="secondary" css={{ fontSize: '$1' }}>
          {itemConfig.getName() || itemConfig.name}
          {libraryItem.instance_name ? ` - ${libraryItem.instance_name}` : ''}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Library Item')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

// istanbul ignore next
const IntegrationListItem = ({ integration, onSelect }) => {
  const { title, img } = integrationStrings[integration.type];
  const name =
    integration.category === 'Directory integrations'
      ? integration.name
      : title();
  const subtitle =
    integration.category === 'Directory integrations' ? title() : '';
  const separator = subtitle && integration.category ? ' - ' : '';
  return (
    <StyledSearchListItem
      data-testid="integration-list-item"
      onClick={(e) => onSelect(integration, e.metaKey)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={img}
          alt={integration.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{name}</Text>
        <Text variant="secondary" css={{ fontSize: '$1' }}>
          {`${subtitle}${separator}${integration.category ?? ''}`}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Integration')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const EmptyScreen = ({ search }) => {
  const message = search
    ? i18n.t('No results found')
    : i18n.t('Search for a device, Library items, Blueprints or integrations.');
  return (
    <Flex
      css={{
        padding: '$5',
        gap: '$3',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      data-testid="empty-screen"
    >
      <Text variant="secondary" size="1" weight="medium">
        {message}
      </Text>
      <NoResultsFoundIcon />
    </Flex>
  );
};

const LoadingItem = ({ size }: { size: 'sm' | 'md' }) => (
  <Flex gap="xs" css={{ padding: '6px $5', alignItems: 'center' }}>
    <LoadingIcon />
    <LoadingBar size={size} />
  </Flex>
);

const LoadingScreen = ({ length }) => (
  <Flex flow="column" gap="xs" data-testid="loading-screen">
    {Array.from({ length }).map((_, idx) => (
      <LoadingItem size={idx % 2 === 0 ? 'sm' : 'md'} />
    ))}
  </Flex>
);

export const UniversalSearchDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>('');
  const [expandedSections, setExpandedSections] = useState({
    devices: false,
    blueprints: false,
    libraryItems: false,
    integrations: false,
  });
  const { data, isPending } = useUniversalSearchQueries({ search });

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen, setSearch]);

  useEffect(() => {
    setExpandedSections({
      devices: false,
      blueprints: false,
      libraryItems: false,
      integrations: false,
    });
  }, [debouncedSearch]);

  const onDeviceClick = useCallback(
    (device, newTab) => {
      const path = `${links.devices}/${device.id}`;
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onBlueprintClick = useCallback(
    (blueprint, newTab) => {
      const path =
        blueprint.type === 'flow'
          ? paths.flowBlueprint(blueprint.id)
          : paths.blueprint(blueprint.id);
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onLibraryItemClick = useCallback(
    (libraryItem, newTab) => {
      const path = libraryItem.getUrl({ id: libraryItem.id });
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onIntegrationClick = useCallback(
    (integration, newTab) => {
      const path = `/integrations/${integration.type}`;
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const sectionData = useMemo(() => {
    const sections = {
      devices: { data: [], next: 'blueprints' },
      blueprints: { data: [], next: 'libraryItems' },
      libraryItems: { data: [], next: 'integrations' },
      integrations: { data: [], next: 'devices' },
    };
    if (search.trim() === '' || isPending) {
      return sections;
    }
    let totalCount =
      data.devices.length +
      data.blueprints.length +
      data.libraryItems.length +
      data.integrations.length;
    let selectedCount = 0;
    let currentSection = 'devices';
    while (selectedCount < COLLAPSED_TOTAL && totalCount > 0) {
      const itemIdx = sections[currentSection].data.length;
      const item = data[currentSection][itemIdx];
      if (item != null) {
        sections[currentSection].data.push(item);
        selectedCount++;
        totalCount--;
      }
      currentSection = sections[currentSection].next;
    }
    Object.keys(expandedSections).forEach((section) => {
      if (expandedSections[section]) {
        sections[section].data = [...data[section]];
      }
    });
    return sections;
  }, [data, expandedSections, isPending, search]);

  const Expander = useCallback(
    ({ section }) => {
      const remainingCount =
        data[section].length - sectionData[section].data.length;
      return (
        search.trim() &&
        (remainingCount > 0 || expandedSections[section]) && (
          <Flex
            css={{ padding: '6px $5', alignItems: 'center' }}
            data-testid="section-expander"
          >
            <Button
              variant="link"
              onClick={() =>
                setExpandedSections((prev) => ({
                  ...prev,
                  [section]: !prev[section],
                }))
              }
            >
              {expandedSections[section]
                ? i18n.t('See less')
                : i18n.t('See more ({count})', {
                    count: remainingCount,
                  })}
            </Button>
          </Flex>
        )
      );
    },
    [expandedSections, search, data, sectionData],
  );

  const totalResults =
    data.devices.length +
    data.blueprints.length +
    data.libraryItems.length +
    data.integrations.length;

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        hideClose
        animateOpen={false}
        data-testid="universal-search-dialog"
        onClose={onClose}
        css={{
          maxWidth: '800px',
          width: '70%',
          minWidth: '400px',
          height: 'auto',
          maxHeight: 'calc(100vh - 180px)',
          padding: '$4 0',
          position: 'fixed',
          top: '90px',
          transform: 'translate(-50%, 0)',
          boxShadow: '$elevation3',
          border: '1px solid $neutral20',
          borderRadius: '$rounded-lg',
        }}
      >
        <TextField
          showClearButton={debouncedSearch.length > 0}
          onClear={() => setSearch('')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n.t('Search for anything')}
          css={{ paddingLeft: '$5', paddingRight: '$5' }}
          componentCss={{
            input: { border: 'none', boxShadow: 'none !important' },
          }}
        />
        <Separator />
        {search.trim() === '' && <EmptyScreen search={debouncedSearch} />}
        {search.trim() && isPending && <LoadingScreen length={6} />}
        {search.trim() && !isPending && totalResults === 0 && (
          <EmptyScreen search={debouncedSearch} />
        )}
        {search.trim() && !isPending && totalResults !== 0 && (
          <Flex gap="md" flow="column" css={{ overflowY: 'scroll' }}>
            <Flex flow="column">
              {sectionData.devices.data.length > 0 && (
                <ResourceSection title={i18n.t('Devices')}>
                  {sectionData.devices.data.map((device) => (
                    <DeviceListItem
                      key={device.id}
                      device={device}
                      onSelect={onDeviceClick}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="devices" />
              {sectionData.blueprints.data.length > 0 && (
                <ResourceSection title={i18n.t('Blueprints')}>
                  {sectionData.blueprints.data.map((blueprint) => (
                    <BlueprintListItem
                      key={blueprint.id}
                      blueprint={blueprint}
                      onSelect={onBlueprintClick}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="blueprints" />
              {sectionData.libraryItems.data.length > 0 && (
                <ResourceSection title={i18n.t('Library Items')}>
                  {sectionData.libraryItems.data.map((libraryItem) => (
                    <LibraryItemListItem
                      key={libraryItem.id}
                      libraryItem={libraryItem}
                      onSelect={onLibraryItemClick}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="libraryItems" />
              {sectionData.integrations.data.length > 0 && (
                <ResourceSection title={i18n.t('Integrations')}>
                  {sectionData.integrations.data.map((integration) => (
                    <IntegrationListItem
                      key={integration.id}
                      integration={integration}
                      onSelect={onIntegrationClick}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="integrations" />
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
