import { RecoveryPasswordStatusTypes } from '../RecoveryPassword.types';
import { RecoveryPasswordRestartSerializers } from './RecoverPasswordRestartSerializers';
import { RecoveryPasswordExcludedSerializers } from './RecoveryPasswordExcludedSerializers';
import { RecoveryPasswordIncompatibleSerializers } from './RecoveryPasswordIncompatibleSerializers';
import { RecoveryPasswordPendingSerializers } from './RecoveryPasswordPendingSerializers';
import { RecoveryPasswordSetFailed } from './RecoveryPasswordSetFailed';
import { RecoveryPasswordSuccessSerializers } from './RecoveryPasswordSuccessSerializers';
import { RecoveryPasswordVerifyFailed } from './RecoveryPasswordVerifyFailed';
import { RecoveryPasswordVerifyLegacyFailed } from './RecoveryPasswordVerifyLegacyFailed';
import { RecoveryPasswordVerifyingLegacy } from './RecoveryPasswordVerifyingLegacy';

export const RecoveryPasswordStatusSerializers = {
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_SUCCESS_AUDIT]:
    RecoveryPasswordSuccessSerializers,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_EXCLUDED_AUDIT]:
    RecoveryPasswordExcludedSerializers,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_SET_FAILED_AUDIT]:
    RecoveryPasswordSetFailed,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_INCOMPATIBLE_AUDIT]:
    RecoveryPasswordIncompatibleSerializers,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_VERIFY_FAILED_AUDIT]:
    RecoveryPasswordVerifyFailed,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_RESTART_NEEDED_AUDIT]:
    RecoveryPasswordRestartSerializers,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_PENDING_COMMAND_AUDIT]:
    RecoveryPasswordPendingSerializers,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_LEGACY_VERIFY_FAILED_AUDIT]:
    RecoveryPasswordVerifyLegacyFailed,
  [RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_VERIFYING_LEGACY_PASSWORD_AUDIT]:
    RecoveryPasswordVerifyingLegacy,
};
