import { Setting } from 'features/library-items/template';
import React from 'react';
import { i18n } from 'src/i18n';
import MinimumOSVersionRow from '../minimum-os-version-row';
import ActivationLockRow from './activation-lock-row';
import SharedRow from './shared-row';
import SkipScreensRow from './skip-screens-row';

const IPad = (props) => {
  const {
    isDisabled,
    setting,
    update,
    isSaving,
    beforeEditModel,
    setModel,
    isSubmitted,
  } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('iPad')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          chipText={{ enabled: 'Managed', disabled: 'Not Managed' }}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Customize and configure iPad Setup Assistant screens, Activation Lock, and Shared iPad.',
          )}
        </p>
      </Setting.SubHeader>

      {setting.isEnabled && (
        <Setting.Rows>
          <SkipScreensRow
            ipad={setting}
            update={update}
            isDisabled={isDisabled}
            setting={setting}
            isSaving={isSaving}
          />

          <SharedRow
            ipad={setting}
            update={update}
            isDisabled={isDisabled}
            isSaving={isSaving}
            beforeEditModel={beforeEditModel}
            setModel={setModel}
            isSubmitted={isSubmitted}
          />

          <ActivationLockRow
            ipad={setting}
            update={update}
            isDisabled={isDisabled}
          />

          <MinimumOSVersionRow
            {...props}
            typeOS={i18n.t('iPadOS')}
            os="iPadOS"
          />
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default IPad;
