import { WallpaperStatusTypes } from '../Wallpaper.types';
import { WallpaperExcludedSerializers } from './WallpaperExcludedSerializers';
import { WallpaperFailureSerializers } from './WallpaperFailureSerializers';
import { WallpaperIncompatibleSerializers } from './WallpaperIncompatibleSerializers';
import { WallpaperPendingSerializers } from './WallpaperPendingSerializers';
import { WallpaperSuccessSerializers } from './WallpaperSuccessSerializers';

export const WallpaperStatusSerializers = {
  [WallpaperStatusTypes.WALLPAPER_EXCLUDED_AUDIT]: WallpaperExcludedSerializers,
  [WallpaperStatusTypes.WALLPAPER_SETTINGS_FAILURE_AUDIT]:
    WallpaperFailureSerializers,
  [WallpaperStatusTypes.WALLPAPER_SETTINGS_PENDING_AUDIT]:
    WallpaperPendingSerializers,
  [WallpaperStatusTypes.WALLPAPER_SETTINGS_SUCCESS_AUDIT]:
    WallpaperSuccessSerializers,
  [WallpaperStatusTypes.WALLPAPER_SETTINGS_INCOMPATIBLE_AUDIT]:
    WallpaperIncompatibleSerializers,
};
