import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type DeviceNameSettingsIncompatibleDetails,
  getDeviceNameIncompatibleReason,
} from '../DeviceName.types';

function serializerV1(details: DeviceNameSettingsIncompatibleDetails) {
  const { reason } = details;

  return new LibraryItemStatusLog()
    .addRow(getDeviceNameIncompatibleReason(reason))
    .toString();
}

export const DeviceNameSettingsIncompatibleSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
