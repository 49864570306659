import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type RecoveryPasswordCommonStatusDetails,
  getRecoveryPasswordType,
} from '../RecoveryPassword.types';

function serializerV1(details: RecoveryPasswordCommonStatusDetails) {
  const { password_type } = details;

  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        '{password_type} password was set, waiting for the device to restart.',
        {
          password_type: getRecoveryPasswordType(password_type),
        },
      ),
    )
    .toString();
}

export const RecoveryPasswordRestartSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
