/* istanbul ignore file - legacy code, moved */
import * as constants from 'src/constants';

export const getSubDomain = (hostname) => {
  const subDomain = hostname.replace(`.${constants.REACT_APP_BASE_DOMAIN}`, '');
  if (/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/i.test(subDomain)) {
    return subDomain;
  }
  return null;
};

const getBackendProtocol = (domain?: string) => {
  const isLocalhost =
    domain && (domain.includes('localhost:') || domain.includes('127.0.0.1:'));
  return isLocalhost
    ? 'http:'
    : constants.REACT_APP_BACKEND_PROTOCOL || window.location.protocol;
};

export const getBaseUrl = (baseDomain: string) => {
  const backendProtocol = getBackendProtocol(baseDomain);
  const subDomain = getSubDomain(window.location.hostname);
  return `${backendProtocol}//${subDomain}.${baseDomain}/`;
};

export const getBaseUrlCustomSubdomain = (
  baseDomain: string,
  subdomain?: string,
) => {
  const backendProtocol = getBackendProtocol(baseDomain);
  if (subdomain) {
    return `${backendProtocol}//${subdomain}.${baseDomain}/`;
  }
  return `${backendProtocol}//${baseDomain}/`;
};

export const getBackend = () => {
  const baseDomain = constants.REACT_APP_BACKEND_BASE_DOMAIN;
  const backendProtocol = getBackendProtocol(baseDomain);
  const subDomain = getSubDomain(window.location.hostname);
  const baseUrl = `${backendProtocol}//${subDomain}.${baseDomain}/`;

  if (
    constants.NODE_ENV === 'production' ||
    constants.REACT_APP_TEST_SUBDOMAIN
  ) {
    if (subDomain) {
      return baseUrl;
    }
    window.location.href = `${backendProtocol}//${constants.REACT_APP_BASE_DOMAIN}`;
  }

  if (baseDomain && baseDomain.startsWith(`${subDomain}.`)) {
    // be compatible with old and new style local testing
    return `${backendProtocol}//${baseDomain}/`;
  }
  return baseUrl;
};

export const getSubscriptionsApi = () =>
  getBaseUrlCustomSubdomain(constants.REACT_APP_SUBSCRIPTIONS_API_BASE_DOMAIN);
export const getProductUpdatesApi = () =>
  getBaseUrlCustomSubdomain(constants.REACT_APP_PRODUCT_UPDATES_DOMAIN, 'www');

export const getEnterpriseApi = () =>
  getBaseUrl(constants.REACT_APP_API_BASE_DOMAIN);
export const getVisibilityApi = () =>
  getBaseUrl(constants.REACT_APP_VISIBILITY_API_BASE_DOMAIN);
export const getIntegrationsBackendApi = () =>
  getBaseUrl(constants.REACT_APP_INTEGRATIONS_BACKEND_API_BASE_DOMAIN);
export const getIntegrationsDeviceTrustApi = () =>
  getBaseUrl(constants.REACT_APP_INTEGRATIONS_DEVICE_TRUST_API_BASE_DOMAIN);
export const getAgentLogsApi = () =>
  getBaseUrl(constants.REACT_APP_AGENT_LOGS_API_BASE_DOMAIN);

export const getApiGatewayBaseUrl = () =>
  getBaseUrl(constants.REACT_APP_WEB_GATEWAY_DOMAIN);
export const getDataPlatformApi = () =>
  `${getApiGatewayBaseUrl()}data-platform/api/v1/`;
export const getVulnerabilityApi = () =>
  `${getApiGatewayBaseUrl()}vulnerability-management/`;

// TODO: old references, need cleanup
export const API_BASE = `${getBackend()}app/v1/`;
export const LIBRARY_API_BASE = `${getBackend()}library/v1/`;
export const ENTERPRISE_API_EXTERNAL_BASE = `${getBackend()}api/v1/`;
export const ENTERPRISE_API_HOST = new URL(getEnterpriseApi()).host;
