import {
  Button,
  Flex,
  TextInput,
  asciiValidator,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { memo, useContext } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { UserRoles } from 'src/app/common/constants';
import { AccountContext, usePermissions } from 'src/contexts/account';
import RecoveryPasswordService from '../service/recovery-password-service';

const PasswordItem = memo((props) => {
  const { isDeletable, isDisabled, index, value, update, validationDep } =
    props;
  const permissions = usePermissions();

  const fieldName = `legacy-pass-${index}`;
  const fieldsToValidate = [fieldName];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const removeItem = () =>
    update('passwords', (p) => {
      const newPasswords = [...p];
      newPasswords.splice(index, 1);
      return newPasswords;
    });
  const onChange = (e) => {
    const val = e.target.value;
    update('passwords', (p) => {
      const newPasswords = [...p];
      newPasswords[index] = val;
      return newPasswords;
    });
  };

  const trigger = ['onBlur', validationDep];

  return (
    <div className="k-rp-mc__legacy-pass-cont">
      <p ref={refs[fieldName]} className="b-txt b-mb1">
        {i18n.t('Legacy password')}
      </p>
      <div className="k-rp-mc__legacy-pass">
        <TextInput
          value={value}
          onChange={onChange}
          placeholder={i18n.t('Enter password')}
          type="password"
          disabled={isDisabled || !permissions.canManageBlueprints}
          compact
          onInvalidate={onInvalidate(fieldName)}
          validator={(v) => [
            onEmptyBlurValidator(v, { message: i18n.t('Required'), trigger }),
            asciiValidator(v, {
              trigger,
              message: i18n.t(
                'Invalid character found. Please use low ASCII characters.',
              ),
            }),
            {
              invalid: () => v.length > 250,
              message: i18n.t('Max character limit = 250'),
              trigger,
            },
          ]}
        />
        {isDeletable && (
          <Button
            theme="error"
            kind="link"
            icon="trash-can"
            onClick={removeItem}
            disabled={isDisabled}
          />
        )}
      </div>
    </div>
  );
});

const LegacyCard = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const { userRole } = useContext(AccountContext);
  const isAuditor = userRole === UserRoles.auditor;

  const onAddItem = () => update('passwords', (p) => [...p, '']);

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">{i18n.t('Legacy Firmware passwords')}</h3>

        <Setting.HeaderToggle
          isEnabled={setting.isManaged}
          onChange={() => update('isManaged', (p) => !p)}
          isDisabled={isDisabled}
          chipText={{
            enabled: i18n.t('Managed'),
            disabled: i18n.t('Not managed'),
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Add any previously used firmware passwords that will be checked on devices.',
          )}
        </p>
      </Setting.SubHeader>
      {setting.isManaged && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t('Add legacy firmware passwords.')}
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Flex direction="col" gapType="gap2">
                {setting.passwords.map((pass, i) => (
                  <PasswordItem
                    key={i}
                    index={i}
                    value={pass}
                    isDeletable={setting.passwords.length > 1}
                    isDisabled={isDisabled}
                    update={update}
                    validationDep={validationDep}
                  />
                ))}
                {!isAuditor && (
                  <Button
                    isDisabled={
                      isDisabled ||
                      setting.passwords.length >=
                        RecoveryPasswordService.LEGACY_PASS_LIMIT
                    }
                    onClick={onAddItem}
                    kind="link"
                    icon="circle-plus"
                  >
                    {i18n.t('Add password')}
                  </Button>
                )}
              </Flex>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default memo(LegacyCard);
