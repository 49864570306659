/* istanbul ignore file */
import {
  Box,
  Button,
  Chip,
  Dialog,
  Flex,
  Select,
  Text,
  TextArea,
  TextField,
  Toaster_UNSTABLE as Toaster,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { useMutation } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { z } from 'zod';

import { api } from 'app/api/base';
import { URL_FEATURE_REQUEST_UPDATED } from 'app/api/urls';
import { useOnClickOutside } from 'src/app/components/common/hooks';
import { InterfaceContext } from 'src/contexts/interface';
import { TAGS_SORTED } from 'src/features/feature-request/constants';

type FeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (FeedbackModel) => Promise<void>;
  isPending?: boolean;
  title?: string;
  description: string;
  defaultCategory?: string;
  isCategoryDisabled?: boolean;
};

const FeedbackModel = z.object({
  category: z.string(),
  title: z.string().min(1).max(50),
  details: z.string().min(1).max(9999),
});

export const FeedbackModal = (props: FeedbackModalProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    isPending,
    title = 'Feedback',
    description,
    defaultCategory,
    isCategoryDisabled = true,
  } = props;
  const [model, setModel] = useState<z.infer<typeof FeedbackModel>>({
    category: defaultCategory || '',
    title: '',
    details: '',
  });
  const isModelValid = FeedbackModel.safeParse(model).success;

  useEffect(() => {
    if (isOpen) {
      setModel({ category: defaultCategory || '', title: '', details: '' });
    }
  }, [isOpen]);

  const content = (
    <Flex flow="column" gap="lg">
      <Text>{description}</Text>

      <Select
        placeholder="Select an option"
        label="Category"
        options={TAGS_SORTED()}
        onChange={(value) => setModel((prev) => ({ ...prev, category: value }))}
        value={model.category}
        disabled={isCategoryDisabled}
      />

      <TextField
        autoFocus
        label="Title"
        placeholder="Provide a brief and descriptive title"
        onChange={(e) =>
          setModel((prev) => ({ ...prev, title: e.target.value }))
        }
        value={model.title}
        hint={{
          charCounter: {
            count: model.title.length,
            max: 50,
          },
        }}
      />

      <TextArea
        label="Additional details"
        placeholder="Share as much detail as possible here..."
        onChange={(e) =>
          setModel((prev) => ({ ...prev, details: e.currentTarget.value }))
        }
        value={model.details}
        hint={{
          charCounter: {
            count: model.details.length,
            max: 9999,
          },
        }}
      />
    </Flex>
  );
  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onClose}>
        Cancel
      </Button>

      <Button
        variant="primary"
        onClick={() => onSubmit(model).finally(onClose)}
        disabled={isPending || !isModelValid}
      >
        Submit
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title={title}
      content={content}
      footer={footer}
      isOpen={isOpen}
      onOpenChange={onClose}
      css={{ width: '600px', zIndex: 2000 }}
    />
  );
};

type PreviewFeedbackProps = {
  modalProps: Pick<
    FeedbackModalProps,
    'title' | 'description' | 'defaultCategory' | 'isCategoryDisabled'
  >;
};

const PreviewFeedback = (props: PreviewFeedbackProps) => {
  const { modalProps } = props;
  const { sidebarOpened } = useContext(InterfaceContext);
  const [isTippyOpen, setIsTippyOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['post-feature-request'],
    mutationFn: async (data: any) =>
      api(URL_FEATURE_REQUEST_UPDATED).post(data),
    onSuccess: () =>
      toast({
        title: 'Successfully submitted feedback request!',
        variant: 'success',
        style: {
          left: sidebarOpened ? '268px' : '90px',
          bottom: '12px',
          position: 'absolute',
        },
      }),
  });

  useOnClickOutside(contentRef, (e) => {
    if (e.target.matches('#preview-button svg')) {
      return;
    }

    setIsTippyOpen(false);
  });

  return (
    <Box>
      <Tippy
        content={
          <Box ref={contentRef}>
            <Text variant="description">
              Test a preview of this feature and{' '}
              <Text
                variant="primary"
                onClick={() => {
                  setIsTippyOpen(false);
                  setIsFeedbackModalOpen(true);
                }}
                css={{ cursor: 'pointer' }}
              >
                provide your feedback.
              </Text>
            </Text>
          </Box>
        }
        interactive
        visible={isTippyOpen}
        theme="tippy"
      >
        <Chip
          id="preview-button"
          iconLeft={{
            icon: 'sparkles',
          }}
          iconRight={{
            onIconClick: () => setIsTippyOpen((prev) => !prev),
            icon: 'circle-info',
          }}
          label="Preview"
          css={{
            backgroundColor: '#f1ebff',
            color: '$purple80',
            // Chips don't support buttons, nectar design system only has icons.
            '& svg': {
              color: '$purple80',
              padding: '1px',
              width: '14px',
              height: '14px',
              '&:hover': {
                color: '$purple70',
                backgroundColor: '$button_subtle_background_hover',
                borderRadius: '$1',
                cursor: 'pointer',
              },
            },
          }}
        />
      </Tippy>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={({ category, title, details }) =>
          mutateAsync({ title, tags: [category], content: details })
        }
        isPending={isPending}
        {...modalProps}
      />
      <Toaster />
    </Box>
  );
};

export default PreviewFeedback;
