/* istanbul ignore file */
import { Select } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';

const HotCorner = (props) => {
  const { param, update, isDisabled } = props;
  const { details } = param;

  const methodOptions = [
    { label: i18n.t('Start screen saver'), value: 0 },
    { label: i18n.t('Sleep display'), value: 1 },
  ];

  const cornerOptions = [
    { label: i18n.t('Bottom-right corner'), value: 'wvous-br-corner' },
    { label: i18n.t('Bottom-left corner'), value: 'wvous-bl-corner' },
    { label: i18n.t('Top-right corner'), value: 'wvous-tr-corner' },
    { label: i18n.t('Top-left corner'), value: 'wvous-tl-corner' },
  ];

  useEffect(() => {
    if (!details) {
      update({
        details: {
          corner: cornerOptions[0].value,
          method: methodOptions[0].value,
        },
      });
    }
  }, [details]);

  return (
    <div className="b-flex-vg1" style={{ maxWidth: '65%' }}>
      <p className={`b-txt${isDisabled ? '-light' : ''}`}>{i18n.t('Method')}</p>
      <Select
        disabled={isDisabled}
        options={methodOptions}
        value={
          methodOptions.find(({ value }) => value === details?.method) ||
          methodOptions[0]
        }
        onChange={({ value }) =>
          update({ details: { ...(details || {}), method: value } })
        }
      />
      <p className={`b-txt${isDisabled ? '-light' : ''}`}>
        {i18n.t('If no hot corner is set, enable the')}
      </p>
      <Select
        disabled={isDisabled}
        options={cornerOptions}
        value={
          cornerOptions.find(({ value }) => value === details?.corner) ||
          cornerOptions[1]
        }
        onChange={({ value }) =>
          update({ details: { ...(details || {}), corner: value } })
        }
      />
    </div>
  );
};

export default HotCorner;
