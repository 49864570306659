import { i18n } from 'i18n';
import { LogDetailKeys } from '../../CommonStatus.types';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import { getLogDetailLabel } from '../../lib/buildLogFromStatusDetails';
import type { InstallAppCommandSuccessLogDetails } from '../IPA_VPP.types';

function serializerV1(details: InstallAppCommandSuccessLogDetails) {
  const {
    app_name,
    version,
    bundle_id,
    command_issued,
    command_completed,
    author,
  } = details;

  return new LibraryItemStatusLog()
    .addRow(i18n.t('App install command successful'))
    .addRow(i18n.t('Application'), app_name)
    .addRow(i18n.t('Version'), version)
    .addRow(i18n.t('Bundle Identifier'), bundle_id)
    .addRow(i18n.t('Developer'), author)
    .addRow(getLogDetailLabel(LogDetailKeys.CommandIssued), command_issued)
    .addRow(
      getLogDetailLabel(LogDetailKeys.CommandCompleted),
      command_completed,
    )
    .toString();
}

export const InstallAppSuccessSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
