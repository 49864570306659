import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import { type AppInstallPassedDetails, ConfigStatus } from '../IPA_VPP.types';
import { getDisplayVersion } from '../utils/getDisplayVersion';

function serializerV1(details: AppInstallPassedDetails) {
  const {
    app_name,
    version,
    config_status,
    desired_version,
    version_ext,
    desired_version_ext,
    is_update_allowed,
  } = details;

  const log = new LibraryItemStatusLog();
  const displayVersion = getDisplayVersion(version, version_ext);
  const displayDesiredVersion = getDisplayVersion(
    desired_version,
    desired_version_ext,
  );

  if (is_update_allowed) {
    log.addRow(
      i18n.t(
        '{app_name} {version} is installed, but it is not up to date. Kandji will automatically install version {desired_version} when able.',
        {
          app_name,
          version: displayVersion,
          desired_version: displayDesiredVersion,
        },
      ),
    );
  } else {
    log.addRow(
      i18n.t(
        '{app_name} {version} is installed, but it is not up to date. Version {desired_version} is available. Automatic app updates are turned off.',
        {
          app_name,
          version: displayVersion,
          desired_version: displayDesiredVersion,
        },
      ),
    );
  }

  if (config_status != null) {
    log.addRow(ConfigStatus(config_status));
  }

  return log.toString();
}

export const InstallLowerVersionPassed = {
  Default: serializerV1,
  1: serializerV1,
};
