/* istanbul ignore file */

import { updateComputerInfo } from 'app/_actions/computer';
import { usePermissions } from 'contexts/account';
import { i18n } from 'i18n';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { capitalizeFirstLetter } from 'src/features/visibility/prism/utils/column-utils';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';
import { setTabsStatus as callSetTabsStatus } from '../../../_actions/parameters';
import Collapsible from '../../interface/Collapsible';
import { SyncIcon } from '../../interface/IconsSVG';
import SimpleIconButton from '../../interface/buttons/SimpleIconButton';
import {
  ABMMapping,
  SecurityMapping,
  checkField,
  getAgentLastCheckIn,
  getDeviceGeneralInfo,
  getDeviceMdmCertificateInfo,
  getDeviceModel,
  getDeviceProfileInfo,
  getMDMEnabledUsers,
  iOSDeviceInformationMapping,
  tvOSDeviceInformationMapping,
  visionOSDeviceInformationMapping,
} from '../computerHelpers';

const getUniqKey = () => uuidv1();

const ProfilesItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -30px;
`;

const SimpleIconButtonStyled = styled(SimpleIconButton)`
  margin-top: -2px;
`;

const SyncInfo = styled('div')`
  font-family: var(--font-family-primary);
  display: flex;
  align-items: self-start;
  flex-direction: column;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
  color: #181c24;
  width: 600px;
`;

const SyncTitle = styled('div')`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 14px;
  padding-right: 16px;
`;

const BoldText = styled('b')`
  font-weight: 500;
`;

const onSyncClick = (computer, sectionName, setIsSyncing) => {
  setIsSyncing(true);
  updateComputerInfo(computer.id, [sectionName]).finally(() => {
    setIsSyncing(false);
  });
};

// Complete section
const renderGeneralSectionFor = (
  DeviceInformationMapping,
  computer: any = {},
  canSync = false,
  isSyncing = false,
  setIsSyncing = null,
) => {
  const canShowBatteryHealth =
    computer.device_family === 'iPhone' &&
    parseFloat(computer.os_version) >= 17;
  const consolidatedData = {
    ...computer.mdm_info,
    ...(canShowBatteryHealth && {
      BatteryHealth: computer.battery_health
        ? capitalizeFirstLetter(computer.battery_health)
        : i18n.t('Not available'),
    }),
  };

  return (
    <Row noGutters className="computer-details-section" key={getUniqKey()}>
      <div style={{ width: '275px' }}>
        <span className="section-name">Device Information</span>
        <SyncInfo>
          <SyncTitle>
            {i18n.t('Updated')}{' '}
            {i18n.format.datetime(computer.mdm_info_updated_at)}
          </SyncTitle>
          {canSync && (
            <SimpleIconButtonStyled
              disabled={isSyncing}
              name={isSyncing ? i18n.t('Updating') : i18n.t('Update now')}
              icon={SyncIcon}
              onClick={() =>
                onSyncClick(computer, 'DeviceInformation', setIsSyncing)
              }
            />
          )}
        </SyncInfo>
      </div>
      <div>
        {Object.keys(DeviceInformationMapping)
          .filter((key) => {
            if (key === i18n.t('Cellular')) {
              return computer.mdm_info.CellularTechnology !== 0;
            }
            if (key === i18n.t('Shared iPad')) {
              return (
                computer.device_family === 'iPad' &&
                consolidatedData.IsMultiUser
              );
            }
            return true;
          })
          .map((subSection) => (
            <>
              <div className="sub-section-name">{subSection.toUpperCase()}</div>
              <div className="section-with-data">
                {Object.keys(consolidatedData)
                  .filter((key) =>
                    Object.keys(DeviceInformationMapping[subSection]).includes(
                      key,
                    ),
                  )
                  .filter((key) => {
                    const dependency = get(
                      DeviceInformationMapping[subSection][key],
                      'dependency',
                      null,
                    );
                    if (dependency) {
                      return !DeviceInformationMapping[subSection][
                        key
                      ].wrongValueDependency.includes(
                        consolidatedData[dependency],
                      );
                    }
                    return true;
                  })
                  .sort((a, b) => {
                    if (
                      DeviceInformationMapping[subSection][a].weight >
                      DeviceInformationMapping[subSection][b].weight
                    ) {
                      return 1;
                    }
                    if (
                      DeviceInformationMapping[subSection][a].weight <
                      DeviceInformationMapping[subSection][b].weight
                    ) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((key) => (
                    <>
                      <Row
                        noGutters
                        key={getUniqKey()}
                        className="section-value"
                      >
                        <div className="section-value-key">
                          {DeviceInformationMapping[subSection][key].title}:
                        </div>
                        {!['multiLineOutput', 'multiLineListOutput'].includes(
                          DeviceInformationMapping[subSection][key].type,
                        ) && (
                          <span className="section-value-data">
                            {checkField(
                              key,
                              consolidatedData[key],
                              DeviceInformationMapping[subSection][key].type,
                            )}
                          </span>
                        )}
                      </Row>
                      {DeviceInformationMapping[subSection][key].type ===
                        'multiLineOutput' && (
                        <div className="section-second-value">
                          {Object.keys(
                            DeviceInformationMapping[subSection][key].list,
                          ).map((subKey) => (
                            <span>
                              <span className="section-value-key">
                                {
                                  DeviceInformationMapping[subSection][key]
                                    .list[subKey]
                                }
                                :
                              </span>
                              <span className="section-value-data">
                                {checkField(key, consolidatedData[key][subKey])}
                              </span>
                            </span>
                          ))}
                        </div>
                      )}
                      {DeviceInformationMapping[subSection][key].type ===
                        'multiLineListOutput' && (
                        <div>
                          {consolidatedData[key] !== null &&
                            consolidatedData[key].map((_, index) => (
                              <div className="section-second-value-list">
                                {Object.keys(
                                  DeviceInformationMapping[subSection][key]
                                    .list,
                                ).map((subKey) => (
                                  <span>
                                    <span className="section-value-key">
                                      {
                                        DeviceInformationMapping[subSection][
                                          key
                                        ].list[subKey]
                                      }
                                      :
                                    </span>
                                    <span className="section-value-data">
                                      {checkField(
                                        key,
                                        consolidatedData[key][index][subKey],
                                      )}
                                    </span>
                                  </span>
                                ))}
                              </div>
                            ))}
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </>
          ))}
      </div>
    </Row>
  );
};

const renderSecuritySectionForIOS = (
  sectionName,
  computer = {},
  canSync = false,
  isSyncing = false,
  setIsSyncing = null,
  securityMapping = SecurityMapping(),
) => (
  <Row noGutters className="computer-details-section" key={getUniqKey()}>
    <div style={{ width: '275px' }}>
      <span className="section-name">{sectionName}</span>
      <SyncInfo>
        <SyncTitle>
          {i18n.t('Updated')}{' '}
          {i18n.format.datetime(computer.mdm_security_info_updated_at)}
        </SyncTitle>
        {canSync && (
          <SimpleIconButtonStyled
            disabled={isSyncing}
            name={isSyncing ? i18n.t('Updating') : i18n.t('Update now')}
            icon={SyncIcon}
            onClick={() => onSyncClick(computer, 'SecurityInfo', setIsSyncing)}
          />
        )}
      </SyncInfo>
    </div>
    <div>
      <div className="section-with-data">
        {Object.keys(computer.mdm_security_info)
          .filter((key) => Object.keys(securityMapping).includes(key))
          .sort((a, b) => {
            if (securityMapping[a].weight > securityMapping[b].weight) {
              return 1;
            }
            if (securityMapping[a].weight < securityMapping[b].weight) {
              return -1;
            }
            return 0;
          })
          .map((key) => (
            <>
              <Row noGutters key={getUniqKey()} className="section-value">
                <div className="section-value-key">
                  {securityMapping[key].title}:
                </div>
                <span className="section-value-data">
                  {checkField(
                    key,
                    computer.mdm_security_info[key],
                    securityMapping[key].type,
                  )}
                </span>
              </Row>
            </>
          ))}
      </div>
    </div>
  </Row>
);

const renderABMSection = (
  sectionName,
  computer = {},
  isSyncing = false,
  setIsSyncing = null,
) => {
  const abmMapping = ABMMapping();
  return (
    <Row noGutters className="computer-details-section" key={getUniqKey()}>
      <div style={{ width: '275px' }}>
        <span className="section-name">{sectionName}</span>
      </div>
      <div>
        <div className="section-with-data">
          {has(computer.device, 'abm_data') &&
            computer.device.abm_data !== null &&
            Object.keys(computer.device.abm_data)
              .filter((key) => Object.keys(abmMapping).includes(key))
              .sort((a, b) => {
                if (abmMapping[a].weight > abmMapping[b].weight) {
                  return 1;
                }
                if (abmMapping[a].weight < abmMapping[b].weight) {
                  return -1;
                }
                return 0;
              })
              .map((key) => (
                <>
                  <Row noGutters key={getUniqKey()} className="section-value">
                    <div className="section-value-key">
                      {abmMapping[key].title}:
                    </div>
                    <span className="section-value-data">
                      {checkField(
                        key,
                        computer.device.abm_data[key],
                        abmMapping[key].type,
                      )}
                    </span>
                  </Row>
                </>
              ))}
        </div>
      </div>
    </Row>
  );
};

const renderAutoEnrollSection = (
  sectionName,
  computer = {},
  isSyncing = false,
  setIsSyncing = null,
) => (
  <Row noGutters className="computer-details-section" key={getUniqKey()}>
    <div style={{ width: '275px' }}>
      <span className="section-name">{sectionName}</span>
    </div>
    <div>
      <div className="section-with-data">
        <Row noGutters key={getUniqKey()} className="section-value">
          <div className="section-value-key">
            {i18n.t('Automated Device Enrollment Eligible')}:{' '}
          </div>
          <span className="section-value-data">
            {has(computer.device, 'abm_data') &&
            computer.device.abm_data !== null
              ? i18n.t('Yes')
              : i18n.t('No')}
          </span>
        </Row>
        <Row noGutters key={getUniqKey()} className="section-value">
          <div className="section-value-key">
            {i18n.t('Automated Device Enrolled')}:{' '}
          </div>
          <span className="section-value-data">
            {computer.enrollment_type === 4 ? i18n.t('Yes') : i18n.t('No')}
          </span>
        </Row>
      </div>
    </div>
  </Row>
);
// Complete section
const renderSection = (
  object,
  sectionName,
  computer = {},
  isSyncing = false,
  setIsSyncing = null,
) => (
  <Row noGutters className="computer-details-section" key={getUniqKey()}>
    <div style={{ width: '275px' }}>
      <span className="section-name">{sectionName}</span>
    </div>
    <div>
      {Object.keys(object).map((key) => (
        <React.Fragment key={key}>
          <Row noGutters key={getUniqKey()} className="section-value">
            <span className="section-value-key">{key}:</span>
            <span className="section-value-data">{object[key]}</span>
          </Row>
        </React.Fragment>
      ))}
    </div>
  </Row>
);
// TODO : refactor like complete section
const renderSectionWithDropdownList = (
  list,
  sectionName,
  computer,
  canSync,
  isSyncing,
  setIsSyncing,
) => {
  const keyRenameDict = {
    'Payload Content': i18n.t('Payload Content'),
    'Payload Display Name': i18n.t('Payload Display Name'),
  };

  return (
    <div className="d-flex computer-details-section" key={getUniqKey()}>
      <div style={{ width: '275px' }}>
        <span className="section-name">{sectionName}</span>
        {computer.device_family !== 'Mac' && (
          <SyncInfo>
            <SyncTitle>
              {i18n.t('Updated')}{' '}
              <BoldText>
                {i18n.format.datetime(computer.mdm_profile_list_updated_at)}
              </BoldText>
            </SyncTitle>
            {canSync && (
              <SimpleIconButtonStyled
                disabled={isSyncing}
                name={isSyncing ? i18n.t('Updating') : i18n.t('Update now')}
                icon={SyncIcon}
                onClick={() =>
                  onSyncClick(computer, 'ProfileList', setIsSyncing)
                }
              />
            )}
          </SyncInfo>
        )}
      </div>
      <div>
        <ProfilesItems>
          {list.map((_, index) => (
            <div key={getUniqKey()} style={{ marginBottom: 20 }}>
              <Collapsible
                header={
                  <div>
                    <span className="section-value-key">
                      {list[index]['Payload Display Name']
                        ? `${list[index]['Payload Display Name'].toUpperCase()}`
                        : null}
                    </span>
                  </div>
                }
                body={
                  <div>
                    {Object.keys(list[index]).map((key) => (
                      <span
                        className={
                          list[index]['Payload Display Name']
                            ? 'section-payload-value'
                            : 'section-value-key'
                        }
                      >
                        <span>
                          <span className="section-value-key">
                            {Object.keys(keyRenameDict).indexOf(key) >= 0
                              ? keyRenameDict[key]
                              : key}
                            :
                          </span>
                          {key === 'Payload Content'
                            ? list[index][key].map((lik, contentIndex) => (
                                <>
                                  <span
                                    className="section-payload-value"
                                    style={
                                      contentIndex ===
                                      list[index][key].length - 1
                                        ? { marginBottom: '-25px' }
                                        : null
                                    }
                                  >
                                    <span>
                                      {list[index][key][contentIndex][
                                        'Payload Display Name'
                                      ] || i18n.t('Unnamed')}
                                      :
                                    </span>
                                    {Object.keys(
                                      list[index][key][contentIndex],
                                    ).map((contentKey) => (
                                      <span className="section-payload-content">
                                        <span className="section-value-key">
                                          {Object.keys(keyRenameDict).indexOf(
                                            contentKey,
                                          ) >= 0
                                            ? keyRenameDict[contentKey]
                                            : contentKey}
                                          :
                                        </span>
                                        <span className="section-value-data">
                                          {
                                            list[index][key][contentIndex][
                                              contentKey
                                            ]
                                          }
                                        </span>
                                      </span>
                                    ))}
                                  </span>
                                </>
                              ))
                            : null}
                          <span className="section-value-data">
                            {typeof list[index][key] === 'object' ||
                            list[index][key] === 'array'
                              ? null
                              : list[index][key] || i18n.t('No info')}
                          </span>
                        </span>
                      </span>
                    ))}
                  </div>
                }
              />
            </div>
          ))}
        </ProfilesItems>
      </div>
    </div>
  );
};
// TODO : refactor like complete section
const renderSectionWithList = (
  list,
  sectionName,
  computer,
  canSync,
  isSyncing,
  setIsSyncing,
) => (
  <Row noGutters className="computer-details-section" key={getUniqKey()}>
    <div style={{ width: '275px' }}>
      <span className="section-name">{sectionName}</span>
      {computer.device_family !== 'Mac' && (
        <SyncInfo>
          <SyncTitle>
            {i18n.t('Updated')}{' '}
            <BoldText>
              {i18n.format.datetime(computer.mdm_certificate_list_updated_at)}
            </BoldText>
          </SyncTitle>
          {canSync && (
            <SimpleIconButtonStyled
              disabled={isSyncing}
              name={isSyncing ? i18n.t('Updating') : i18n.t('Update now')}
              icon={SyncIcon}
              onClick={() =>
                onSyncClick(computer, 'CertificateList', setIsSyncing)
              }
            />
          )}
        </SyncInfo>
      )}
    </div>
    <div>
      {list.map((_, index) => (
        <span className="section-value-key section-payload">
          {Object.keys(list[index]).map((key) => (
            <span className="section-value-key">
              <span>
                <span className="section-value-key">{key}:</span>
                <span className="section-value-data">
                  {list[index][key] || i18n.t('No info')}
                </span>
              </span>
            </span>
          ))}
        </span>
      ))}
    </div>
  </Row>
);
// TODO : refactor like complete section
const renderVolumesSection = (object, sectionName) => {
  // for the purpose of translations I am expanding the use of the keyRenameDict and adding valueRenameDict
  // the backend is just currently dumping the api_computerlastcheckin->>data column in the API and then that is straight rendered here with a little transformation
  const keyRenameDict = {
    format: i18n.t('Format'),
    percent: i18n.t('Percent Used'),
    bsd_name: i18n.t('Identifier'),
    capacity: i18n.t('Capacity'),
    available: i18n.t('Available'),
    encrypted: i18n.t('Encrypted'),
  };
  const valueRenameDict = {
    Yes: i18n.t('Yes'),
    No: i18n.t('No'),
  };
  return (
    <div className="d-flex computer-details-section" key={getUniqKey()}>
      <div style={{ width: '275px', flex: 'none' }}>
        <span className="section-name">{sectionName}</span>
      </div>
      <div className="d-flex flex-wrap volumes-container">
        {Object.keys(object).map((key) => (
          <div className="volumes-section" key={getUniqKey()}>
            <div>
              <span className="section-value-key">{key}</span>
            </div>
            {Object.keys(object[key]).map((name) => (
              <div key={getUniqKey()}>
                <span
                  className="section-value-data"
                  style={{ marginLeft: 0, textTransform: 'capitalize' }}
                >
                  {Object.keys(keyRenameDict).indexOf(name) >= 0
                    ? keyRenameDict[name]
                    : name}
                  :
                </span>
                <span className="section-value-data">
                  {object[key][name] &&
                  Object.keys(valueRenameDict).indexOf(object[key][name]) >= 0
                    ? valueRenameDict[object[key][name]]
                    : object[key][name] || i18n.t('No info')}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
// TODO : refactor like complete section
const renderReportProfilesSection = (profiles) => {
  const mapping = {
    name: i18n.t('Name'),
    uuid: i18n.t('UUID'),
    verified: i18n.t('Verified'),
    identifier: i18n.t('Identifier'),
    organization: i18n.t('Organization'),
    payload_types: i18n.t('Payload Types'),
    installation_date: i18n.t('Installation Date'),
  };
  return (
    <div className="d-flex computer-details-section" key={getUniqKey()}>
      <div style={{ width: '275px', flex: 'none' }}>
        <span className="section-name">{i18n.t('Profiles')}</span>
      </div>

      <ProfilesItems>
        {profiles.map((profile) => (
          <div key={getUniqKey()} style={{ marginBottom: 20 }}>
            <Collapsible
              header={
                <div>
                  <span className="section-value-key">
                    {profile.name.toUpperCase()}
                  </span>
                </div>
              }
              body={
                <div>
                  {Object.keys(profile).map((fieldName) => (
                    <div
                      className="d-flex flex-wrap flex-dir-row"
                      key={fieldName}
                    >
                      <div
                        className="section-value-key"
                        style={{ marginRight: 10 }}
                      >{`${mapping[fieldName]}: `}</div>
                      <div>
                        {fieldName === 'payload_types'
                          ? profile[fieldName].map((payloadType) => (
                              <div key={`${fieldName}-${payloadType}`}>
                                {payloadType}
                              </div>
                            ))
                          : profile[fieldName]}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </div>
        ))}
      </ProfilesItems>
    </div>
  );
};

const UsersContainer = styled('section')`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-row-gap: 40px;
  justify-content: start;
  width: 100%;
  padding: 20px 0;
`;
// TODO : refactor like complete section
const renderUserSection = (object, key, autoAdminShortNames = []) => {
  let admin = object[key].Admin;

  if (!admin) {
    admin = i18n.t('No info');
  } else if (!object.system_user && autoAdminShortNames.includes(key)) {
    admin += i18n.t(' (Auto Admin)');
  } else if (admin == 'Yes') {
    admin = i18n.t('Yes');
  } else if (admin == 'No') {
    admin = i18n.t('No');
  }

  return (
    <section key={`userSection-${key}`}>
      <div>
        <span className="section-value-key">{key}</span>
      </div>
      {object[key].uid && (
        <div>
          <span className="section-value-data" style={{ marginLeft: 0 }}>
            {i18n.t('UID')}:
          </span>
          <span className="section-value-data">{object[key].uid}</span>
        </div>
      )}
      <div>
        <span className="section-value-data" style={{ marginLeft: 0 }}>
          {i18n.t('Path')}:
        </span>
        <span className="section-value-data">
          {object?.[key]?.['Home Folder'] || i18n.t('No info')}
        </span>
      </div>
      <div>
        <span className="section-value-data" style={{ marginLeft: 0 }}>
          {i18n.t('Admin')}:
        </span>
        <span className="section-value-data">{admin}</span>
      </div>
    </section>
  );
};

// TODO : refactor like complete section
const renderUsersSection = (object, mdmInfo, sectionName) => {
  const regularUsers = Object.entries(object)
    .filter((user) => !user[1].system_user)
    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
  const systemUsers = Object.entries(object)
    .filter((user) => user[1].system_user)
    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});

  const autoAdminShortNames = get(mdmInfo, 'AutoSetupAdminAccounts', [])
    .filter((item) => get(item, 'shortName'))
    .map((item) => item.shortName);

  return (
    <div className="d-flex computer-details-section" key={getUniqKey()}>
      <div
        style={{
          width: '275px',
          flex: 'none',
        }}
      >
        <span className="section-name">{sectionName}</span>
      </div>
      <div className="d-flex flex-column w-100" style={{ marginLeft: '-30px' }}>
        <Collapsible
          collapsedInitial={false}
          header={
            <div>
              <span className="section-value-key">
                {i18n.t('Regular Users')}
              </span>
            </div>
          }
          body={
            <UsersContainer>
              {Object.keys(regularUsers).map((key) =>
                renderUserSection(regularUsers, key, autoAdminShortNames),
              )}
            </UsersContainer>
          }
        />
        {!!Object.keys(regularUsers).length &&
          !!Object.keys(systemUsers).length && (
            <Collapsible
              header={
                <div>
                  <span className="section-value-key">
                    {i18n.t('System Users')}
                  </span>
                </div>
              }
              body={
                <UsersContainer>
                  {Object.keys(systemUsers).map((key) =>
                    renderUserSection(systemUsers, key),
                  )}
                </UsersContainer>
              }
            />
          )}
      </div>
    </div>
  );
};

export const ComputerTechSpecsTab = ({ computer, setTabsStatus }) => {
  setTabsStatus(true);

  const [isSyncing, setIsSyncing] = useState(false);

  const computerInfo = { ...computer.info } || {};

  const generalSection = getDeviceGeneralInfo(computer);

  const ProfileSection = getDeviceProfileInfo(computer);

  const CertificateSection = getDeviceMdmCertificateInfo(computer);

  const permissions = usePermissions();

  const MDMSection = {
    [i18n.t('MDM Enabled')]: computer.is_mdm ? i18n.t('Yes') : i18n.t('No'),
    [i18n.t('Install Date')]: get(computer, 'device.install_date')
      ? i18n.format.datetime(get(computer, 'device.install_date'))
      : i18n.t('No info'),
    [i18n.t('Last Check-In')]: computer.last_checkins['mdm-command']
      ? i18n.format.datetime(computer.last_checkins['mdm-command'])
      : i18n.t('No info'),
    [i18n.t('MDM-enabled users')]: getMDMEnabledUsers(computer),
    [i18n.t('Supervised')]: computer.is_supervised
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('Remote Desktop Status')]: computer?.security_information
      ?.remote_desktop_enabled
      ? i18n.t('On')
      : i18n.t('Off'),
    [i18n.t('Bootstrap Token Escrowed')]: get(
      computer,
      'device.has_bootstrap_token',
    )
      ? i18n.t('Yes')
      : i18n.t('No'),
  };

  const KandjiSection = {
    [i18n.t('Agent Installed')]: computer.is_enrollment_finished
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('Install Date')]: computer.agentInstalledNotFormatted,
    [i18n.t('Last Check-in')]: getAgentLastCheckIn(computer)
      ? i18n.format.datetime(getAgentLastCheckIn(computer))
      : i18n.t('Not yet checked-in'),
    [i18n.t('Agent Version')]: computer.agent_version,
  };

  const canShowBatteryHealth =
    computer.device_family === 'Mac' &&
    /apple/i.test(computerInfo['Processor Name']) &&
    parseFloat(computer.os_version) >= 13.4;

  const hardwareSection = {
    [i18n.t('Model Name')]: getDeviceModel(computer),
    [i18n.t('Model Identifier')]: computerInfo['Model Identifier'],
    [i18n.t('Processor Name')]: computerInfo['Processor Name'],
    [i18n.t('Processor Speed')]: computerInfo['Processor Speed'],
    [i18n.t('Numbers Of Processors')]: computerInfo['Number of Processors'],
    [i18n.t('Total Numbers Of Cores')]: computerInfo['Total Number of Cores'],
    [i18n.t('Memory')]: computerInfo.Memory,
    [i18n.t('Serial Number')]: computer.serial,
    [i18n.t('Hardware UUID')]: computerInfo['Hardware UUID'],
    ...(canShowBatteryHealth && {
      [i18n.t('Battery Health')]: computer.battery_health
        ? capitalizeFirstLetter(computer.battery_health)
        : i18n.t('Not available'),
    }),
  };

  const networkSection = {
    [i18n.t('Local Hostname')]: computerInfo['Local Hostname'],
    [i18n.t('MAC Address')]: computerInfo['MAC address'],
    [i18n.t('IP Address')]:
      computerInfo['IP Address'] || computerInfo['IP address'],
  };

  const activationLockSection = {
    [i18n.t('Bypass Code Expired')]: computer.activation_lock
      ?.bypass_code_failed
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('User-based Activation Lock Status')]: computer.activation_lock
      ?.user_activation_lock_enabled
      ? i18n.t('Enabled')
      : i18n.t('Disabled'),
    [i18n.t('Device-based Activation Lock Status')]: computer.activation_lock
      ?.device_activation_lock_enabled
      ? i18n.t('Enabled')
      : i18n.t('Disabled'),
    [i18n.t('User-based Activation Lock Allowed')]: computer.activation_lock
      ?.activation_lock_allowed_while_supervised
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('Activation Lock Supported')]: computer.activation_lock
      ?.activation_lock_supported
      ? i18n.t('Yes')
      : i18n.t('No'),
  };

  const recoveryLockSection = {
    [i18n.t('Recovery Lock Status')]: computer.recovery_information
      ?.recovery_lock_enabled
      ? i18n.t('Enabled')
      : i18n.t('Disabled'),
    [i18n.t('Firmware Password Status')]: computer.recovery_information
      ?.firmware_password_exist
      ? i18n.t('Enabled')
      : i18n.t('Disabled'),
    [i18n.t('Firmware Password Pending Restart')]: computer.recovery_information
      ?.firmware_password_pending
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('Scheduled Password Rotation')]: computer.recovery_information
      ?.password_rotation_scheduled
      ? i18n.format.datetime(
          computer.recovery_information.password_rotation_scheduled,
        )
      : i18n.t('Not scheduled'),
  };

  const filevaultSection = {
    [i18n.t('FileVault Status')]: computer.filevault?.filevault_enabled
      ? i18n.t('Enabled')
      : i18n.t('Disabled'),
    [i18n.t('Recovery Key Type')]:
      computer.filevault?.filevault_recoverykey_type,
    [i18n.t('Personal Recovery Key Escrowed')]: computer.filevault
      ?.filevault_prk_escrowed
      ? i18n.t('Yes')
      : i18n.t('No'),
    [i18n.t('Scheduled Key Rotation')]: computer.filevault
      ?.filevault_next_rotation
      ? i18n.format.datetime(computer.filevault.filevault_next_rotation)
      : '',
    [i18n.t('Key Regeneration Required')]: computer.filevault
      ?.filevault_regen_required
      ? i18n.t('Yes')
      : i18n.t('No'),
  };

  return (
    <div className="computer-details">
      {/* AppleTV, iPhone, iPod, iPad */}
      {computer.device_family === 'AppleTV' &&
        renderGeneralSectionFor(
          tvOSDeviceInformationMapping(),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {['iPhone', 'iPod', 'iPad'].includes(computer.device_family) &&
        renderGeneralSectionFor(
          iOSDeviceInformationMapping(),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'RealityDevice' &&
        renderGeneralSectionFor(
          visionOSDeviceInformationMapping(),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family !== 'Mac' &&
        renderAutoEnrollSection(
          i18n.t('Automated Device Enrollment'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family !== 'Mac' &&
        renderSectionWithDropdownList(
          ProfileSection,
          i18n.t('Profiles'),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family !== 'Mac' &&
        renderSectionWithList(
          CertificateSection,
          i18n.t('Certificates'),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {['iPhone', 'iPod', 'iPad', 'RealityDevice'].includes(
        computer.device_family,
      ) &&
        renderSecuritySectionForIOS(
          i18n.t('Security'),
          computer,
          permissions.canManageDevices,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family !== 'Mac' &&
        has(computer.device, 'abm_data') &&
        computer.device.abm_data !== null &&
        renderABMSection(
          i18n.t('Apple Business Manager'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {/* Mac */}
      {computer.device_family === 'Mac' &&
        renderSection(
          generalSection,
          i18n.t('General'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'Mac' &&
        renderSection(
          MDMSection,
          i18n.t('MDM'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'Mac' &&
        renderAutoEnrollSection(
          i18n.t('Automated Device Enrollment'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'Mac' &&
        renderSection(
          KandjiSection,
          i18n.t('Kandji Agent'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'Mac' &&
        renderSection(
          hardwareSection,
          i18n.t('Hardware Overview'),
          computer,
          isSyncing,
          setIsSyncing,
        )}
      {computer.device_family === 'Mac' &&
        renderSection(
          networkSection,
          i18n.t('Network'),
          computer,
          isSyncing,
          setIsSyncing,
        )}

      {computerInfo.Volumes &&
        renderVolumesSection(computerInfo.Volumes, i18n.t('Volumes'))}

      {computerInfo.users &&
        !Array.isArray(computerInfo.users) &&
        renderUsersSection(
          computerInfo.users,
          computer.mdm_info,
          i18n.t('Users'),
        )}

      {/* TODO: move in to profile section */}
      {!isEmpty(computerInfo.profiles) &&
        renderReportProfilesSection(computerInfo.profiles)}

      {computer.device_family === 'Mac' &&
        has(computer.device, 'abm_data') &&
        computer.device.abm_data !== null &&
        renderABMSection(
          i18n.t('Apple Business Manager'),
          computer,
          isSyncing,
          setIsSyncing,
        )}

      {computer.device_family !== 'AppleTV' &&
        renderSection(activationLockSection, i18n.t('Activation Lock'))}

      {computer.device_family === 'Mac' &&
        renderSection(recoveryLockSection, i18n.t('Recovery Lock'))}

      {computer.device_family === 'Mac' &&
        renderSection(filevaultSection, i18n.t('FileVault'))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  computer: state.computerRecord,
  tabsIsLoaded: state.parameters.tabsIsLoaded,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setTabsStatus: callSetTabsStatus }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComputerTechSpecsTab);
