/* istanbul ignore file */
import { Flex, Icon, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { useActivityMonitor } from 'app/api/activity';
import { links } from 'app/common/constants';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageScroll } from 'src/app/_actions/computer';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import FooterItems from './FooterItems';
import SidebarItemLink from './SidebarItemLink';
import { startAlertMonitor, stopAlertMonitor } from './alerts';

const HotkeyIconContainer = styled(Flex, {
  visibility: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral70',
  backgroundColor: '$neutral00',
  borderRadius: '$1',
  width: '20px',
  height: '20px',
});

const StyledSearchItem = styled(Flex, {
  cursor: 'pointer',
  textTransform: 'uppercase',
  height: '50px',
  padding: '0 0.5rem 0 0.3rem',
  fontWeight: 700,
  fontSize: '12px',
  color: 'var(--color-neutral-50)',
  '&:hover': {
    color: '$neutral00',
    [`& ${HotkeyIconContainer}`]: {
      visibility: 'visible',
    },
  },
  '&[data-active=true]': {
    color: '$neutral00',
  },
  [`& ${Icon}`]: {
    minWidth: '28px',
  },
});

const SearchItem = ({ onClick, searchOpen }) => {
  const { sidebarOpened } = useContext(InterfaceContext);
  const item = (
    <StyledSearchItem
      data-testid="universal-search-item"
      onClick={onClick}
      data-active={searchOpen}
    >
      <Flex flex="1" alignItems="center" css={{ padding: '0 22px' }}>
        <Icon size={28} name="magnifying-glass" />

        {sidebarOpened && (
          <Flex alignItems="center" ml3 flex="1">
            {i18n.t('Search')}
            <Flex
              flex={1}
              justifyContent="end"
              gap="xs"
              alignItems="center"
              css={{ position: 'relative', right: '-8px' }}
            >
              <HotkeyIconContainer>
                <Icon name="command" size={12} />
              </HotkeyIconContainer>
              <HotkeyIconContainer>
                <Icon name="k" size={12} />
              </HotkeyIconContainer>
            </Flex>
          </Flex>
        )}
      </Flex>
    </StyledSearchItem>
  );

  return sidebarOpened ? (
    item
  ) : (
    <Tooltip
      side="right"
      content={
        <Flex gap="sm">
          {i18n.t('Search')}
          <Flex flex="1" gap="xs" alignItems="center">
            <HotkeyIconContainer
              css={{ visibility: 'visible', width: 16, height: 16 }}
            >
              <Icon name="command" size={8} />
            </HotkeyIconContainer>
            <HotkeyIconContainer
              css={{ visibility: 'visible', width: 16, height: 16 }}
            >
              <Icon name="k" size={8} />
            </HotkeyIconContainer>
          </Flex>
        </Flex>
      }
    >
      {item}
    </Tooltip>
  );
};

const Item = memo(({ item, withScrollBar, searchOpen }) => {
  const { sidebarOpened } = useContext(InterfaceContext);
  const currentParentPage = useLocation().pathname;

  const isActive = useMemo(() => {
    const parentTab = `/${get(item.link.split('/'), '[1]')}`;

    // wtf?
    return (
      (currentParentPage.indexOf(parentTab || item.link) === 0 &&
        item.link !== links.index) ||
      ([links.index, links.computers, links.devices].includes(
        currentParentPage,
      ) &&
        item.link === links.devices) ||
      (links.index === currentParentPage && item.link === links.index)
    );
  }, [item.link, currentParentPage]);

  return (
    <SidebarItemLink
      active={isActive && !searchOpen}
      sidebarOpened={sidebarOpened}
      withScrollBar={withScrollBar}
      {...item}
    />
  );
});

Item.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    badge: PropTypes.number,
  }).isRequired,
  withScrollBar: PropTypes.bool.isRequired,
  searchOpen: PropTypes.bool.isRequired,
};

const SidebarItems = ({ searchOpen, setSearchOpen }) => {
  const { currentCompany } = useContext(AccountContext);
  const {
    feature_configuration,
    billing_type,
    onboarding = {},
  } = currentCompany;
  const dispatch = useDispatch();
  const [alertCount, setAlertCount] = useState(0);
  const sidebarRef = useRef(null);
  const [withScrollbar, setWithScrollbar] = useState(false);
  const {
    'rd-plg-trial-dashboard': ffTrialDashboard,
    'vm_06252024_vuln-poc': ffVuln,
    'vm_01132025_vm-new-product-badge': ffVulnNewProductBadge,
    'edr_110824_edr-trial-enabled-ui': ffEdrTrial,
    'dexi-12042024-modern-users-page': ffModernUsersPage,
    'vis-12112024-universal-search': ffUniversalSearch,
  } = useFlags([
    'rd-plg-trial-dashboard',
    'vm_06252024_vuln-poc',
    'vm_01132025_vm-new-product-badge',
    'edr_110824_edr-trial-enabled-ui',
    'dexi-12042024-modern-users-page',
    'vis-12112024-universal-search',
  ]);
  const activityMonitor = useActivityMonitor();
  const isEdrSkuEnabled = !!feature_configuration?.edr?.enabled;
  const isVulnSkuEnabled =
    !!feature_configuration?.vulnerability_management?.enabled;

  useEffect(() => {
    const alertStopper = startAlertMonitor(setAlertCount);
    activityMonitor.start();

    return () => {
      stopAlertMonitor(alertStopper);
      activityMonitor.stop();
    };
  }, [activityMonitor]);

  // check if sidebar has scrollbar
  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      const hasVerticalScrollbar = sidebar.scrollHeight > sidebar.clientHeight;
      if (hasVerticalScrollbar) {
        setWithScrollbar(true);
      }
    }
  }, []);

  // const isTrial =
  //   billing_type === 'automated_trial' && Object.keys(onboarding).length > 0;
  const isTrial =
    billing_type === 'automated_trial' || billing_type === 'trial';

  const items = useMemo(() => {
    const tabs = [
      ...(isTrial && ffTrialDashboard
        ? [
            {
              link: links.trial,
              name: i18n.t('Trial'),
              icon: 'trophy',
            },
          ]
        : []),
      {
        link: links.devices,
        name: i18n.t('Devices'),
        icon: 'laptop-mobile',
        /* istanbul ignore next */
        onClick() {
          dispatch(setPageScroll(0));
        },
      },
      {
        link: links.blueprints,
        name: i18n.t('Blueprints'),
        icon: 'kandji-blueprint',
      },
      {
        link: links.library,
        name: i18n.t('Library'),
        icon: 'grid-2',
      },
      {
        link: ffModernUsersPage
          ? links.users
          : links['integration-users'].withDevices,
        name: i18n.t('Users'),
        icon: 'user-group',
      },
      {
        link: links.activity,
        name: i18n.t('Activity'),
        icon: 'wave-pulse',
      },
      {
        link: links.alerts.active,
        name: i18n.t('Alerts'),
        icon: 'bell',
        badge: alertCount,
      },
      ...((!isEdrSkuEnabled && ffEdrTrial) || isEdrSkuEnabled
        ? [
            {
              link: links.threat,
              name: i18n.t('Threats'),
              icon: 'shield-halved',
            },
          ]
        : []),
      ...(ffVuln
        ? [
            {
              link: links.vulnerability,
              name: i18n.t('Vulnerabilities'),
              icon: 'shield-virus',
              isNew: !isVulnSkuEnabled && ffVulnNewProductBadge,
            },
          ]
        : []),
    ];

    return tabs;
  }, [
    alertCount,
    dispatch,
    ffEdrTrial,
    ffVuln,
    ffTrialDashboard,
    ffModernUsersPage,
    isEdrSkuEnabled,
    isTrial,
  ]);

  return (
    <div className="sidebar-nav" ref={sidebarRef}>
      <div>
        <div>
          {ffUniversalSearch && (
            <SearchItem
              onClick={() => setSearchOpen(true)}
              searchOpen={searchOpen}
            />
          )}
          {items.map((item) => (
            <Item
              key={item.name}
              item={item}
              withScrollBar={withScrollbar}
              searchOpen={searchOpen}
            />
          ))}
        </div>
        <div>
          <FooterItems />
        </div>
      </div>
    </div>
  );
};

export default memo(SidebarItems);
