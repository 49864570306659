/* istanbul ignore file */
import { i18n } from 'i18n';
import type { LibraryItemStatusContent } from '../CommonStatus.types';

export enum ProfileStatusTypes {
  INSTALL_PROFILE_SUCCESS_AUDIT = 'install_profile_success_audit',
  INSTALL_PROFILE_PENDING_AUDIT = 'install_profile_pending_audit',
  PROFILE_MISSING_AUDIT = 'profile_missing_audit',
  PROFILE_BLOCKED_AUDIT = 'profile_blocked_audit',
  PROFILE_EXCLUDED_AUDIT = 'profile_excluded_audit',
  INSTALL_PROFILE_SUCCESS = 'install_profile_success',
  INSTALL_PROFILE_FAILURE = 'install_profile_failure',
}

interface ProfileStatusContent extends LibraryItemStatusContent {
  type: ProfileStatusTypes;
}

export interface ProfileCommonStatusDetails {
  profile_name?: string;
  profile_uuid?: string;
  payload_identifier?: string;
  payload_uuid?: string;
}

export interface CommandErrorDetails {
  error_domain: string;
  error_code: number;
  localized_description: string;
  us_english_description?: string;
}

export interface InstallProfileSuccessDetails
  extends ProfileCommonStatusDetails {
  command_issued: string;
  command_completed: string;
}

export interface InstallProfileSuccessLog extends ProfileStatusContent {
  type: ProfileStatusTypes.INSTALL_PROFILE_SUCCESS;
  details: InstallProfileSuccessDetails;
}

export interface InstallProfileFailureDetails
  extends ProfileCommonStatusDetails {
  command_issued: string;
  command_failed: string;
  error_chain?: CommandErrorDetails[];
}

export interface InstallProfileFailureLog extends ProfileStatusContent {
  type: ProfileStatusTypes.INSTALL_PROFILE_FAILURE;
  details: InstallProfileFailureDetails;
}

export interface InstallProfileSuccessAuditLog extends ProfileStatusContent {
  type: ProfileStatusTypes.INSTALL_PROFILE_SUCCESS_AUDIT;
  details: ProfileCommonStatusDetails;
}

export interface PendingInstallProfileStatusAuditLog
  extends ProfileStatusContent {
  type: ProfileStatusTypes.INSTALL_PROFILE_PENDING_AUDIT;
  details: ProfileCommonStatusDetails;
}

export interface MissingProfileStatusAuditLog extends ProfileStatusContent {
  type: ProfileStatusTypes.PROFILE_MISSING_AUDIT;
  details: ProfileCommonStatusDetails;
}

export enum BlockedReasonCodes {
  APPLE_SILICON_BOOTSTRAP_TOKEN_REQUIRED = 'apple_silicon_bootstrap_token_required',
}

export interface BlockedProfileDetails {
  reason_code: BlockedReasonCodes;
}

export function getBlockedReasonDescription(reason_code: BlockedReasonCodes) {
  const DESCRIPTIONS = {
    [BlockedReasonCodes.APPLE_SILICON_BOOTSTRAP_TOKEN_REQUIRED]: i18n.t(
      'Device is Apple silicon; waiting on Bootstrap Token before installing KEXT profile.',
    ),
  };

  return DESCRIPTIONS[reason_code] ? DESCRIPTIONS[reason_code] : '';
}

export interface BlockedProfileStatusAuditLog extends ProfileStatusContent {
  type: ProfileStatusTypes.PROFILE_BLOCKED_AUDIT;
  details: BlockedProfileDetails;
}

export interface ExcludedProfileStatusAuditLog extends ProfileStatusContent {
  type: ProfileStatusTypes.PROFILE_EXCLUDED_AUDIT;
}

export type ProfileContentTypes =
  | InstallProfileSuccessLog
  | InstallProfileFailureLog
  | InstallProfileSuccessAuditLog
  | PendingInstallProfileStatusAuditLog
  | MissingProfileStatusAuditLog
  | BlockedProfileStatusAuditLog
  | ExcludedProfileStatusAuditLog;
