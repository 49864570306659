import {
  Button,
  Icon,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { AccountContext } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';

import { useContext, useState } from 'react';
import RequestTrialDialog from './RequestTrialDialog';
import HeroImage from './assets/hero.png';
import SidePanelImage from './assets/side-panel.png';
import {
  ColumnLeft,
  ColumnLeftContent,
  ColumnLeftContentDescription,
  ColumnLeftContentFooter,
  ColumnLeftContentHeader,
  ColumnLeftContentHeading,
  ColumnLeftHeader,
  ColumnLeftHeaderSubheading,
  ColumnRight,
  SidePanelAnimated,
  SidePanelWrapper,
  Wrapper,
  heroImageCss,
} from './hero-section-styles';
import useRequestEdrTrial from './useRequestEdrTrial';

const SIDEBAR_DOCKED_OFFSET = 256;
const SIDEBAR_CLOSE_OFFSET = 78;
const KANDJI_EDR_URL = 'https://www.kandji.io/endpoint-detection-response/';
const HUBSPOT_PORTAL_ID = '5058330';
const HUBSPOT_FORM_ID = '356b8ff3-95b3-49fd-a67d-74325e21aa56';

const HeroSection = () => {
  const { currentCompany, userEmail } = useContext(AccountContext);

  const isEdrTrialRequested = localStorage.getItem('threatEdrTrialRequested');
  const [isRequested, setIsRequested] = useState<boolean>(
    Boolean(isEdrTrialRequested) || false,
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast();

  const tenantSubdomain: string = currentCompany?.subdomain || '';
  const tenantName: string = currentCompany?.name || '';
  const email: string = userEmail || '';
  const pageName = 'edr-trial';

  const { mutateAsync: requestTrial, isPending } = useRequestEdrTrial(
    HUBSPOT_PORTAL_ID,
    HUBSPOT_FORM_ID,
  );

  const handleRequest = () => {
    setIsRequested(true);

    const toastMessage = {
      duration: 10000,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute' as any,
      },
    };

    requestTrial({
      tenantSubdomain,
      tenantName,
      userEmail: email,
      pageName,
    })
      .then(() => {
        handleDialogOpen();
        localStorage.setItem('threatEdrTrialRequested', 'true');
      })
      .catch(() => {
        toast({
          ...toastMessage,
          title: i18n.t('Failed to send request. Please try again.'),
          variant: 'error',
        });

        setIsRequested(false);
      });
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  return (
    <Wrapper>
      <ColumnLeft>
        <ColumnLeftHeader>
          <Icon name="shield-halved" style={{ width: 14, height: 14 }} />
          <ColumnLeftHeaderSubheading>
            {i18n.t('Threats')}
          </ColumnLeftHeaderSubheading>
        </ColumnLeftHeader>
        <ColumnLeftContent>
          <ColumnLeftContentHeader>
            <ColumnLeftContentHeading as="h1" size="1">
              {i18n.t('Endpoint Detection & Response')}
            </ColumnLeftContentHeading>
            <ColumnLeftContentDescription>
              {i18n.t(
                'Stop the latest threats in real-time with fully integrated endpoint protection on Mac.',
              )}
            </ColumnLeftContentDescription>
          </ColumnLeftContentHeader>
          <ColumnLeftContentFooter>
            <Button
              variant="primary"
              disabled={isRequested && !isPending}
              onClick={handleRequest}
              loading={isPending}
            >
              {isRequested
                ? isPending
                  ? i18n.t('Requesting Trial...')
                  : i18n.t('Trial Requested')
                : i18n.t('Request Trial')}
            </Button>
            <Button
              variant="link"
              style={{ backgroundColor: 'transparent' }}
              onClick={
                /* istanbul ignore next */ () =>
                  window.open(KANDJI_EDR_URL, '_blank')
              }
            >
              {i18n.t('Learn more')}
            </Button>
          </ColumnLeftContentFooter>
        </ColumnLeftContent>
      </ColumnLeft>
      <ColumnRight>
        <img
          src={HeroImage}
          className={heroImageCss().className}
          alt="one-agent"
        />
        <SidePanelWrapper>
          <SidePanelAnimated className="side-panel">
            <img src={SidePanelImage} style={{ width: 354 }} alt="side-panel" />
          </SidePanelAnimated>
        </SidePanelWrapper>
      </ColumnRight>
      <RequestTrialDialog isOpen={isDialogOpen} onClose={handleDialogClose} />
    </Wrapper>
  );
};

export default HeroSection;
