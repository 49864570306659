import React, { useEffect, useState, useContext, useCallback } from 'react';

import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Select,
  Text,
  TextArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';

import { i18n } from 'src/i18n';
import {
  TAGS_SORTED,
  USER_TAG_CATEGORY,
  descriptionCharCount,
  subcategoryCharCount,
} from './constants';
import { Context } from './context';

const initial = {
  title: '',
  content: '',
  tags: [],
};

const LabelText = styled(Text, {
  color: '$neutral70',
  fontWeight: '$medium',
});

const Header = () => (
  <Dialog.Header>
    <Dialog.Title>{i18n.t('Feature request')}</Dialog.Title>
  </Dialog.Header>
);

export const Content = () => {
  const { form, setForm, update, hasError, setHasError } = useContext(Context);

  const [subcategoryError, setSubcategoryError] = useState(false);
  const [hasTitleError, setTitleError] = useState(false);

  useEffect(() => {
    setForm(initial);
    return () => setForm({});
  }, []);

  const handleText = useCallback(
    (e, property, charCount, error) => {
      const { length } = e.target.value;
      if (length > charCount) {
        error(true);
        return;
      }
      if (length === 0) {
        error(true);
      } else {
        error(false);
      }
      update(property, e.target.value);
    },
    [update],
  );

  const handleSubCategory = useCallback(
    (e) => {
      const { length } = e.target.value;
      if (length === 0) {
        setSubcategoryError(true);
      } else {
        setSubcategoryError(false);
      }
      update(
        'tags',
        [form.tags[0], e.target.value].filter((v) => v),
      );
    },
    [form.tags, update],
  );

  return (
    <Flex p5 flow="column" gap="lg">
      <Text css={{ marginBottom: 16 }}>
        {i18n.t(
          'Share a feature request or suggest general improvements to the web app.',
        )}
      </Text>
      <Select
        css={{ marginBottom: 16 }}
        label={<LabelText>{i18n.t('Category')}</LabelText>}
        onChange={(value) => {
          if (form?.tags?.[0] !== value) {
            update('tags', [value]);
          }
        }}
        options={TAGS_SORTED()}
        size="sm"
        testId="tags"
      />
      {form?.tags?.[0] === USER_TAG_CATEGORY() && (
        <TextField
          label={
            <LabelText>
              {i18n.t('Please provide a category description')}
            </LabelText>
          }
          onChange={handleSubCategory}
          maxLength={subcategoryCharCount}
          hint={{
            ...(subcategoryError && { label: 'Required' }),
            charCounter: {
              count: form?.tags[1]?.length || 0,
              max: subcategoryCharCount,
            },
          }}
          state={subcategoryError ? 'error' : 'default'}
          testId="subcategory"
        />
      )}
      <TextField
        label={<LabelText>{i18n.t('Title')}</LabelText>}
        onChange={(e) =>
          handleText(e, 'title', subcategoryCharCount, setTitleError)
        }
        maxLength={subcategoryCharCount}
        hint={{
          ...(hasTitleError && { label: 'Required' }),
          charCounter: {
            count: form?.title?.length || 0,
            max: subcategoryCharCount,
          },
        }}
        state={hasTitleError ? 'error' : 'default'}
        testId="title"
        value={form.title}
        placeholder={i18n.t('Provide a brief and descriptive title')}
      />
      <TextArea
        label={<LabelText>{i18n.t('Additional details')}</LabelText>}
        minHeight={210}
        maxHeight={210}
        isOptional={false}
        placeholder={i18n.t('Share as much detail as possible here ...')}
        hint={{
          ...(hasError && { label: 'Required' }),
          charCounter: {
            count: form?.content?.length || 0,
            max: descriptionCharCount,
          },
        }}
        state={hasError ? 'error' : 'default'}
        value={form.content}
        onChange={(e) =>
          handleText(e, 'content', descriptionCharCount, setHasError)
        }
      />
    </Flex>
  );
};

export const Footer = () => {
  const { form, isWorking, handleSave, onHome, onClose } = useContext(Context);

  const disabled =
    isWorking || !form?.content || !form?.title || form?.tags?.length === 0;
  const subcategoryDisabled =
    form?.tags?.[0] === USER_TAG_CATEGORY() && form.tags.length === 1;

  return (
    <>
      <div
        role="separator"
        aria-orientation="horizontal"
        style={{ height: 1, backgroundColor: '#d7e1ed', margin: 0 }}
      ></div>
      <Flex
        css={{
          padding: '16px 12px',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="subtle" compact disabled={isWorking} onClick={onHome}>
          {i18n.t('Back')}
        </Button>
        <Flex css={{ gap: 4 }}>
          <Button variant="subtle" disabled={isWorking} onClick={onClose}>
            {i18n.t('Cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={disabled || subcategoryDisabled}
            loading={isWorking}
            iconLeft
            icon={isWorking ? 'arrows-rotate' : undefined}
            onClick={handleSave}
          >
            {i18n.t('Submit')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default {
  header: <Header />,
  content: <Content />,
  footer: <Footer />,
};
