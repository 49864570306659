import { i18n } from 'i18n';
import type {
  LibraryItemExcludedStatusContent,
  LibraryItemStatusContent,
} from '../CommonStatus.types';

export enum DeviceNameStatusTypes {
  DEVICE_NAME_EXCLUDED_AUDIT = 'device_name_excluded_audit',
  DEVICE_NAME_SETTINGS_FAILURE_AUDIT = 'device_name_settings_failure',
  DEVICE_NAME_SETTINGS_SUCCESS_AUDIT = 'device_name_settings_success',
  DEVICE_NAME_SETTINGS_PENDING_AUDIT = 'device_name_settings_pending',
  DEVICE_NAME_SETTINGS_INCOMPATIBLE_AUDIT = 'device_name_settings_incompatible',
}

export enum DeviceNameIncompatibleReasons {
  UNSUPERVISED = 'unsupervised',
}

export function getDeviceNameIncompatibleReason(
  reason: DeviceNameIncompatibleReasons,
) {
  switch (reason) {
    case DeviceNameIncompatibleReasons.UNSUPERVISED:
      return i18n.t('Device is incompatible due to lack of supervision');
  }
}

export interface DeviceNameSettingsCommonStatusDetails {
  device_name?: string;
  hostname?: string;
}

export interface ExcludedDeviceNameStatusAuditLog
  extends LibraryItemExcludedStatusContent {
  type: DeviceNameStatusTypes.DEVICE_NAME_EXCLUDED_AUDIT;
  version: number;
}

export interface DeviceNameSettingsIncompatibleDetails {
  reason: DeviceNameIncompatibleReasons;
}

export interface DeviceNameSettingsIncompatibleStatusAuditLog
  extends LibraryItemStatusContent {
  type: DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_INCOMPATIBLE_AUDIT;
  version: number;
  details: DeviceNameSettingsIncompatibleDetails;
}

export interface DeviceNameSettingsPendingDetails
  extends DeviceNameSettingsCommonStatusDetails {}

export interface DeviceNameSettingsPendingStatusAuditLog
  extends LibraryItemStatusContent {
  type: DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_PENDING_AUDIT;
  version: number;
  details: DeviceNameSettingsPendingDetails;
}

export interface DeviceNameSettingsSuccessDetails
  extends DeviceNameSettingsCommonStatusDetails {}

export interface DeviceNameSettingsSuccessAuditLog
  extends LibraryItemStatusContent {
  type: DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_SUCCESS_AUDIT;
  version: number;
  details: DeviceNameSettingsSuccessDetails;
}

export interface DeviceNameSettingsFailureDetails
  extends DeviceNameSettingsCommonStatusDetails {
  missing_values?: string[];
}

export interface DeviceNameSettingsFailureAuditLog
  extends LibraryItemStatusContent {
  type: DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_FAILURE_AUDIT;
  version: number;
  details: DeviceNameSettingsFailureDetails;
}

export type DeviceNameContentTyps =
  | ExcludedDeviceNameStatusAuditLog
  | DeviceNameSettingsIncompatibleStatusAuditLog
  | DeviceNameSettingsPendingStatusAuditLog
  | DeviceNameSettingsSuccessAuditLog
  | DeviceNameSettingsFailureAuditLog;
