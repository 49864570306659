// istanbul ignore file
import { Flex, Heading, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { constants } from '../../common';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import type {
  BehavioralThreatClassification,
  BehavioralThreatDetail,
} from '../../threat.types';

const SplitViewClassificationIcon = styled(Flex, {
  width: 40,
  height: 40,
  padding: 6,
  borderRadius: '50%',
  svg: { width: 26, height: 26 },
  justifyContent: 'center',
  alignItems: 'center',
});

const ThreatNameHeading = styled(Heading, {
  fontSize: 20,
  lineHeight: '32px',
  letterSpacing: '-0.8px',
  fontWeight: '$medium',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 220,
});

const getClassificationLabel = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_LABELS(classification);

const getClassifactionIcon = (classification: BehavioralThreatClassification) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getClassifactionColor = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_COLOR[classification];

type ThreatListSplitViewProps = {
  threat: BehavioralThreatDetail;
  onReleaseThreat: () => void;
  onRecheckStatus: (threat: BehavioralThreatDetail) => void;
  isThreatBeingChecked: boolean;
};

const ThreatListSplitViewHeader = (props: ThreatListSplitViewProps) => {
  const { threat } = props;
  const color = getClassifactionColor(threat.classification);

  return (
    <Flex flow="column" gap="xl" wFull>
      <Flex
        flow="row"
        css={{
          alignItems: 'center',
          gap: '$2',
          justifyContent: 'space-between',
        }}
      >
        <SplitViewClassificationIcon css={{ bg: `$${color}10` }}>
          <Icon
            color={`var(--color-${color}-60)`}
            name={getClassifactionIcon(threat.classification)}
          />
        </SplitViewClassificationIcon>
        <Flex flex={1} flow="column" justifyContent="start" alignItems="start">
          <ThreatNameHeading as="h3">
            <ThreatListTextCopyButton
              text={threat.threat_id}
              tooltip={threat.threat_id}
            />
          </ThreatNameHeading>
          <Text variant="description">
            {getClassificationLabel(threat.classification)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ThreatListSplitViewHeader;
