import { DeviceNameStatusTypes } from '../DeviceName.types';
import { DeviceNameExcludedSerializers } from './DeviceNameExcludedSerializers';
import { DeviceNameSettingsFailureSerializers } from './DeviceNameSettingsFailureSerializers';
import { DeviceNameSettingsIncompatibleSerializers } from './DeviceNameSettingsIncompatibleSerializers';
import { DeviceNameSettingsPendingSerializers } from './DeviceNameSettingsPendingSerializers';
import { DeviceNameSettingsSuccessSerializers } from './DeviceNameSettingsSuccessSerializers';

export const DeviceNameStatusSerializers = {
  [DeviceNameStatusTypes.DEVICE_NAME_EXCLUDED_AUDIT]:
    DeviceNameExcludedSerializers,
  [DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_FAILURE_AUDIT]:
    DeviceNameSettingsFailureSerializers,
  [DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_SUCCESS_AUDIT]:
    DeviceNameSettingsSuccessSerializers,
  [DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_PENDING_AUDIT]:
    DeviceNameSettingsPendingSerializers,
  [DeviceNameStatusTypes.DEVICE_NAME_SETTINGS_INCOMPATIBLE_AUDIT]:
    DeviceNameSettingsIncompatibleSerializers,
};
