/* istanbul ignore file */

import { i18n } from 'src/i18n';

const ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM = 'device_lookup';

const paths = {
  root: '/blueprints',
  blueprint: (id) => `/blueprints/${id}`,
  flowBlueprint: (id, tab = 'assignments') => `/blueprints/maps/${id}/${tab}`,
  parameters: (id) => `/blueprints/${id}/parameters`,
  library: (id) => `/blueprints/${id}/library`,
  devices: (id) => `/blueprints/${id}/devices`,
  activity: (id) => `/blueprints/${id}/activity`,
  notes: (id) => `/blueprints/${id}/notes`,
  getBlueprintRouteByType: (blueprint) => {
    if (blueprint.type === 'flow') {
      return paths.flowBlueprint(blueprint.id);
    }

    return paths.blueprint(blueprint.id);
  },
};

const blueprintLibraryCategories = {
  ENROLLMENT: 'Enrollment',
  INSTALLERS_SCRIPTS: 'Installers & Scripts',
  PROFILES: 'Profiles',
  SECURITY: 'Endpoint Security',
} as const;

const translateBlueprintCategories = i18n.createMap({
  Enrollment: () => i18n.t('Enrollment'),
  Profiles: () => i18n.t('Profiles'),
  'Installers & Scripts': () => i18n.t('Installers & Scripts'),
  'Endpoint Security': () => i18n.t('Endpoint Security'),
});

export {
  paths,
  blueprintLibraryCategories,
  ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM,
  translateBlueprintCategories,
};
