import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { RecoveryPasswordCommonStatusDetails } from '../RecoveryPassword.types';

function serializerV1(
  details: RecoveryPasswordCommonStatusDetails & { left: number },
) {
  const { left } = details;

  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        'Looking for a valid password in legacy Firmware passwords. Passwords left: {remaining_passwords}',
        {
          remaining_passwords: i18n.format.number(left),
        },
      ),
    )
    .toString();
}

export const RecoveryPasswordVerifyingLegacy = {
  Default: serializerV1,
  1: serializerV1,
};
