import { Button, Flex, styled } from '@kandji-inc/nectar-ui';
import { libraryRoute } from 'features/library-items/library/common';
import useAddBodyClass from 'features/util/hooks/use-add-body-class';
import { i18n } from 'i18n';
import { useHistory } from 'react-router-dom';
import ThreatEmptyIcon from '../../assets/threat-empty-unnassigned.png';
import useGetAssignedBlueprint from '../hooks/use-get-assigned-blueprint';
import ThreatListEmptyTable from './ThreatListEmptyTable';

const Container = styled(Flex, {
  position: 'fixed',
  height: '100%',
  width: 'calc(100% - 256px)',
  minHeight: 570,
  backgroundColor: 'white',
  zIndex: 10,

  '.full-width-sidebar-closed &': {
    width: 'calc(100% - 56px)',
  },
});

const ThreatListUnassignedEmptyState = () => {
  const history = useHistory();
  const { isAssigned: isAvertAssignedToBlueprint, isLoading } =
    useGetAssignedBlueprint();
  useAddBodyClass('no-scroll', !isAvertAssignedToBlueprint);

  if (isLoading || isAvertAssignedToBlueprint) {
    return null;
  }

  return (
    <Container>
      <ThreatListEmptyTable
        icon={ThreatEmptyIcon}
        iconWidth={88}
        header={i18n.t('Get started with Kandji EDR')}
        body={
          <>
            {i18n.t(
              'As threat actors pay increasing attention to Mac, Kandji EDR—built exclusively for Mac—can help minimize the risk exposure across your fleet.',
            )}
            <br />
            <br />
            {i18n.t(
              'Assign a new or existing Avert Library Item to one or more Blueprints to start detecting and protecting against threats.',
            )}
            <br />
            <br />
            <a
              href="https://support.kandji.io/support/solutions/articles/72000600371-getting-started-with-endpoint-detection-response-edr-"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              {i18n.t('Learn more')}
            </a>
          </>
        }
        slot={
          <Flex gap="md">
            <Button
              variant="primary"
              onClick={() => history.push(libraryRoute.library)}
            >
              {i18n.t('Go to Library')}
            </Button>
          </Flex>
        }
      />
    </Container>
  );
};

export default ThreatListUnassignedEmptyState;
