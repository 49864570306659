import cn from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import { useContext, useEffect, useMemo, useState } from 'react';
import Sidebar from 'react-sidebar';
import { useFlags } from 'src/config/feature-flags';
import { UniversalSearchDialog } from 'src/features/universal-search/views/UniversalSearchDialog';
import ProfileDropdown from './ProfileDropdown';
import SidebarHeader from './SidebarHeader';
import SidebarItems from './SidebarItems';

import './Sidebar.css';

const MAGIC_WIDTH = 1199;

const SidebarWrap = () => {
  const { sidebarDocked, sidebarOpened, setSidebarOpened } =
    useContext(InterfaceContext);
  const [searchOpen, setSearchOpen] = useState(false);
  const { 'vis-12112024-universal-search': ffUniversalSearch } = useFlags([
    'vis-12112024-universal-search',
  ]);

  useEffect(() => {
    if (!ffUniversalSearch) {
      return;
    }
    const hotkeyListener = (e) => {
      if (e.key === 'k' && e.metaKey) {
        // istanbul ignore next
        e.preventDefault();
        setSearchOpen((prev) => !prev);
      }
    };
    window.addEventListener('keydown', hotkeyListener);
    return () => {
      window.removeEventListener('keydown', hotkeyListener);
    };
  }, [ffUniversalSearch]);

  const sidebar = useMemo(
    () => (
      <div
        id="sidebar"
        data-testid="sidebar"
        className={cn('sidebar', { opened: sidebarOpened })}
        onMouseEnter={() => {
          if (
            !sidebarOpened &&
            !sidebarDocked &&
            window.innerWidth > MAGIC_WIDTH
          ) {
            setSidebarOpened(true);
          }
        }}
        onMouseLeave={() => {
          if (!sidebarDocked && window.innerWidth > MAGIC_WIDTH) {
            setSidebarOpened(false);
          }
        }}
      >
        <SidebarHeader />
        <SidebarItems searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        <ProfileDropdown />
      </div>
    ),
    [sidebarOpened, sidebarDocked, searchOpen, setSidebarOpened],
  );

  return (
    <Sidebar
      sidebar={sidebar}
      open
      docked={sidebarDocked}
      onSetOpen={setSidebarOpened}
      shadow={false}
      rootClassName={cn('kandji-sidebar', {
        'kandji-sidebar--opened': sidebarOpened,
        'kandji-sidebar--over': !sidebarDocked && sidebarOpened,
      })}
      sidebarClassName={cn('kandji-sidebar__sidebar', {
        'kandji-sidebar__sidebar--over': !sidebarDocked && sidebarOpened,
      })}
      contentClassName="kandji-sidebar__content"
      overlayClassName="kandji-sidebar__overlay"
    >
      <div className="d-none" />
      {ffUniversalSearch && (
        <UniversalSearchDialog
          isOpen={searchOpen}
          onClose={() => setSearchOpen(false)}
        />
      )}
    </Sidebar>
  );
};

export default SidebarWrap;
