import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: {} | null | undefined) {
  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        'The Self-Service app is installed and managed, but the license is missing.',
      ),
    )
    .toString();
}

export const LicenseMissingAudit = {
  Default: serializerV1,
  1: serializerV1,
};
