import { TextInput, useInvalidations } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { parse } from 'uri-js';

import { i18n } from 'src/i18n';
import LogoUploader from '../../logo-uploader/logo-uploader';
import screenFormDefaultValidations from '../screen-form-default-validations';
import './link-config-panel.css';

const noop = () => {};

const SuccessLinkConfigForm = (props) => {
  const { link, model, setModel, onValidations = noop } = props;

  const updateLink = (field, value) => {
    const copy = { ...model };
    const idx = copy.successScreen.links.findIndex((l) => l.uuid === link.uuid);
    const sl = copy.successScreen.links[idx];
    if (sl) {
      sl[field] = value;
    }

    setModel(copy);
  };

  const { invalidations, onInvalidate } = useInvalidations({
    inputs: 3,
  });

  const validator = (maxLength) => (v) =>
    screenFormDefaultValidations(v, maxLength);

  /* provide isInvalid form to the parent */
  useEffect(() => {
    onValidations({
      uuid: link.uuid,
      isInvalid: invalidations.some(Boolean),
    });
  }, [invalidations]);

  return (
    <div className="k-ks-link-config">
      <h3 className="b-h3 b-mb2">
        {link.title ? link.title : i18n.t('Title')}
      </h3>
      <p className="b-txt" style={{ marginBottom: 'var(--b-gap-micro' }}>
        {i18n.t(`Icon`)}
      </p>
      <p className="b-txt-light b-mb1">
        {i18n.t(`A square 96x96 pixel PNG file with a transparent background is
        recommended.`)}
      </p>
      <LogoUploader
        className="k-ks-comp-link-preview"
        smallPreview
        icon={link.iconUrl || link.icon}
        onImage={(img) => {
          updateLink('icon', img);
          updateLink('iconUrl', '');
        }}
      />
      <div className="b-mt2">
        <div className="k-ks-link-config-form">
          <TextInput
            value={link.title}
            placeholder={link.titlePlaceholder}
            label={i18n.t('Title')}
            maxLength={24}
            validator={validator(24)}
            onInvalidate={onInvalidate(0)}
            fieldsGrid
            onChange={(e) => updateLink('title', e.target.value)}
          />
          <TextInput
            value={link.helperText}
            placeholder={link.helperTextPlaceholder}
            style={{ minHeight: '85px' }}
            textArea
            label={i18n.t('Subtitle')}
            maxLength={64}
            validator={validator(64)}
            onInvalidate={onInvalidate(1)}
            fieldsGrid
            onChange={(e) => {
              if (!e.target.value.includes('\n')) {
                updateLink('helperText', e.target.value);
              }
            }}
          />
          <TextInput
            value={link.buttonText}
            placeholder={link.buttonTextPlaceholder}
            label={i18n.t('Button text')}
            maxLength={12}
            validator={validator(12)}
            onInvalidate={onInvalidate(2)}
            fieldsGrid
            onChange={(e) => updateLink('buttonText', e.target.value)}
          />
          <p className="b-txt b-mb-micro">{i18n.t(`Button URL`)}</p>
          <p className="b-txt-light b-mb1">
            {i18n.t(`Any URL scheme recognized by macOS can be used, including https://,
            mailto://, and file://.`)}
          </p>
          <TextInput
            value={
              link.type === 'self-service'
                ? i18n.t('Self Service')
                : link.buttonUrl
            }
            disabled={link.type === 'self-service'}
            placeholder={link.buttonUrlPlaceholder}
            validator={validator(100)}
            onInvalidate={onInvalidate(3)}
            onChange={(e) => updateLink('buttonUrl', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessLinkConfigForm;
