/* istanbul ignore file */
import { ManagedOSStatusTypes } from '../ManagedOS.types';
import { ManagedOSAuditSerializers } from './ManagedOSAuditSerializers';
import { ManagedOSCommandSerializers } from './ManagedOSCommandSerializers';
import { ManagedOSDDMSerializers } from './ManagedOSDDMSerializers';
import { ManagedOSErrorSerializers } from './ManagedOSErrorSerializers';
import { ManagedOSIncompatibleSerializers } from './ManagedOSIncompatibleSerializers';
import { ManagedOSPassSerializers } from './ManagedOSPassSerializers';
import { ManagedOSUpdateSerializers } from './ManagedOSUpdateSerializers';
import { ManagedOSPendingSerializers } from './MangedOSPendingSerializers';

export const ManagedOSStatusSerializers = {
  [ManagedOSStatusTypes.MANAGED_OS_PASS]: ManagedOSPassSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_ERROR]: ManagedOSErrorSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_COMMAND]: ManagedOSCommandSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_PENDING]: ManagedOSPendingSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_INCOMPATIBLE]:
    ManagedOSIncompatibleSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_UPDATE]: ManagedOSUpdateSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_DDM_AUDIT]: ManagedOSDDMSerializers,
  [ManagedOSStatusTypes.MANAGED_OS_AUDIT]: ManagedOSAuditSerializers,
};
