import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import { type AppInstallPassedDetails, ConfigStatus } from '../IPA_VPP.types';
import { getDisplayVersion } from '../utils/getDisplayVersion';

function serializerV1(details: AppInstallPassedDetails) {
  const {
    app_name,
    version,
    version_ext,
    config_status,
    desired_version,
    desired_version_ext,
  } = details;

  const log = new LibraryItemStatusLog().addRow(
    i18n.t(
      '{app_name} {version} is installed and higher than the library item {desired_version}.',
      {
        app_name,
        version: getDisplayVersion(version, version_ext),
        desired_version: getDisplayVersion(
          desired_version,
          desired_version_ext,
        ),
      },
    ),
  );

  if (config_status != null) {
    log.addRow(ConfigStatus(config_status));
  }

  return log.toString();
}

export const InstallHigherVersionPassed = {
  Default: serializerV1,
  1: serializerV1,
};
