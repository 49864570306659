import type { LibraryItemStatusContent } from '../CommonStatus.types';

export enum DeclarativeStatusTypes {
  DECLARATIVE_CONFIGURATION_SUCCESS_AUDIT = 'declarative_configuration_success_audit',
  DECLARATIVE_CONFIGURATION_PENDING_AUDIT = 'declarative_configuration_pending_audit',
  DECLARATIVE_CONFIGURATION_ERROR_AUDIT = 'declarative_configuration_error_audit',
}

export interface DeclarativeConfigurationSuccessAuditLog
  extends LibraryItemStatusContent {
  type: DeclarativeStatusTypes.DECLARATIVE_CONFIGURATION_SUCCESS_AUDIT;
  version: number;
}

export interface DeclarativeConfigurationPendingAuditLog
  extends LibraryItemStatusContent {
  type: DeclarativeStatusTypes.DECLARATIVE_CONFIGURATION_PENDING_AUDIT;
  version: number;
}

export interface DeclarativeConfigurationErrorAuditLog
  extends LibraryItemStatusContent {
  type: DeclarativeStatusTypes.DECLARATIVE_CONFIGURATION_ERROR_AUDIT;
  version: number;
}

export type DeclarativeContentTypes =
  | DeclarativeConfigurationSuccessAuditLog
  | DeclarativeConfigurationPendingAuditLog
  | DeclarativeConfigurationErrorAuditLog;
