import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Button,
  Dialog,
  Flex,
  Label,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { i18n } from 'i18n';
import { getComputer } from 'src/app/_actions/computer';
import { lockDevice } from 'src/app/components/computer/api';

const ComputerIosLock = (props) => {
  const { info, onHide } = props;
  const { computerId, deviceType } = info;
  const deviceTypeDisplay =
    deviceType === 'RealityDevice' ? 'Vision' : deviceType;

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const handleOnMessageChange = (e) => setMessage(e.target.value);
  const handleOnPhoneNumberChange = (e) => setPhoneNumber(e.target.value);

  const onLockDevice = () => {
    setIsLoading(true);
    lockDevice(computerId, message, phoneNumber)
      .then(() => {
        getComputer(computerId);
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        {i18n.t(
          'The device will immediately lock. The user will have to enter their passcode to unlock device. You can provide an optional message and phone number that will display on the device.',
        )}
      </Text>
      <TextArea
        aria-label="message"
        label={<Label optional>{i18n.t('Message')}</Label>}
        resize
        onChange={handleOnMessageChange}
      />
      <TextField
        aria-label="phone-number"
        label={<Label optional>{i18n.t('Phone number')}</Label>}
        onChange={handleOnPhoneNumberChange}
      />
    </Flex>
  );

  const realityDeviceContent = (
    <Flex flow="column" gap="lg">
      <Text>
        {i18n.t(
          'The device will immediately lock. The user will have to enter their passcode to unlock device.',
        )}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        {i18n.t('Cancel')}
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onLockDevice}>
        {i18n.t('Lock device')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={i18n.t('Lock {deviceTypeDisplay}', { deviceTypeDisplay })}
      content={deviceType === 'RealityDevice' ? realityDeviceContent : content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerIosLock;
