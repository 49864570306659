import { Button, Flex } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import { i18n } from 'src/i18n';
import EDRDetectionDateFilter from '../../common/components/Filters/EDRDetectionDateFilter';
import EDRSearchFilter from '../../common/components/Filters/EDRSearchFilter';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import ClassificationFilter from '../common/components/ThreatListFilters/ClassificationFilter';
import MultiselectFilter from '../common/components/ThreatListFilters/MultiselectFilter';
import constants from '../common/constants';
import type { FiltersState, OnClearAll } from '../threat.types';

type ThreatListFiltersProps = {
  filters: FiltersState;
  onApply: OnApply;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  malwareCount: number | undefined;
  pupCount: number | undefined;
  isEmpty: boolean;
  isLoading: boolean;
  onExport: () => void;
};

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    filters,
    onApply,
    onClear,
    onClearAll,
    isFiltering,
    malwareCount,
    pupCount,
    isEmpty,
    isLoading,
    onExport,
  } = props;

  return (
    <Flex justifyContent="space-between" flex={1}>
      <Flex wrap="wrap" gap="md">
        <EDRSearchFilter
          name="query"
          value={filters.query}
          onChange={onApply}
          onClear={onClear}
        />
        <EDRDetectionDateFilter
          filter={filters.detectionDate}
          onChange={onApply}
          onClear={onClear}
          options={constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        />
        <MultiselectFilter
          name="status"
          label={i18n.t('Status')}
          values={filters.status}
          onChange={onApply}
          onClear={onClear}
          options={constants.THREAT_STATUS_LIST.map((value) => ({
            key: value,
            value,
            label: constants.THREAT_STATUS_DISPLAY_NAME_MAP(value),
          }))}
        />
        <ClassificationFilter
          value={filters.classification}
          onChange={onApply}
          onClear={onClear}
          malwareCount={malwareCount}
          pupCount={pupCount}
        />
        {isFiltering && (
          <Button variant="subtle" compact onClick={onClearAll}>
            {i18n.t('Clear all')}
          </Button>
        )}
      </Flex>
      {!isLoading && !isEmpty && <ThreatListExportButton onClick={onExport} />}
    </Flex>
  );
};

export default ThreatListFilters;
