import type { CSS } from '@kandji-inc/nectar-ui';
import { Box, Button, Flex, styled } from '@kandji-inc/nectar-ui';
import { useContext, useEffect, useRef, useState } from 'react';
import { InterfaceContext } from 'src/contexts/interface';

import './sidePanel.scss';
import { useOnClickOutside } from 'src/app/components/common/hooks';

const Container = styled(Flex, {
  position: 'fixed',
  inset: '$0',
  zIndex: 1,
  justifyContent: 'flex-end',
  alignItems: 'end',
});

const SidePanelMain = styled(Flex, {
  bg: '$neutral0',
  boxShadow: '$2',
  padding: 20,
  pb: '$0',
  gap: 10,
  flexDirection: 'column',
  flex: 1,
});

const SidePanelHeader = styled(Flex, {
  gap: '$2',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const SidePanelBody = styled(Flex, {
  overflowY: 'auto',
  overflowX: 'hidden',
  flex: 1,
  gap: 10,
  flexFlow: 'column',
  marginInline: -20,
  paddingInline: 20,
  bb: '$neutral20',
});

const SidePanelFooter = styled(Flex, {
  pb: 10,
});

const SidePanelBackdrop = styled(Box, {
  position: 'absolute',
  zIndex: -1,
  inset: '$0',
  bg: 'rgba(31, 39, 47, 0.64)',
  flex: 1,
});

type SidePanelProps = {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  outsideContent?: React.ReactNode;
  css?: { containerCss?: CSS; contentCss?: CSS };
  onClose: () => void;
};

const EDRSidePanel = (props: SidePanelProps) => {
  const { header, body, footer, outsideContent, css = {}, onClose } = props;

  const { containerCss = {}, contentCss = {} } = css;

  const { bannerTopOffset } = useContext(InterfaceContext);

  useEffect(() => {
    document.body.classList.add('side-panel-body', 'side-panel-body-open');
    return () => {
      document.body.classList.remove('side-panel-body');
    };
  }, []);

  const handleClose = () => {
    document.body.classList.remove('side-panel-body-open');
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const contentRef = useRef(null);
  const appDiv = document.getElementById('app');
  useOnClickOutside(contentRef, handleClose, appDiv);

  return (
    <Container
      data-testid="side-panel"
      className="side-panel"
      css={containerCss}
    >
      <Flex
        className="side-panel-content"
        ref={contentRef}
        css={{ width: 'calc(max(60vw, 814px))' }}
      >
        <SidePanelMain
          css={{
            height: `calc(100vh - ${bannerTopOffset}px)`,
            ...contentCss,
          }}
        >
          <SidePanelHeader>
            {header}
            <Button
              compact
              variant="subtle"
              onClick={handleClose}
              icon={{ name: 'fa-xmark-large-close' }}
              css={{
                svg: { width: 20, height: 20 },
                padding: '$2',
                alignSelf: 'start',
              }}
              data-testid="side-panel-close-button"
            />
          </SidePanelHeader>
          <SidePanelBody>{body}</SidePanelBody>
          {footer && <SidePanelFooter>{footer}</SidePanelFooter>}
        </SidePanelMain>
        {outsideContent}
      </Flex>
      <SidePanelBackdrop className="side-panel-backdrop" />
    </Container>
  );
};

export { EDRSidePanel };
