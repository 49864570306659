/* istanbul ignore file */
import React, { memo, useEffect, useState } from 'react';

import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import { useBlueprints } from 'src/features/visibility/prism/hooks/use-blueprints-query';
import { i18n } from 'src/i18n';
import { v4 as uuid } from 'uuid';
import type {
  GeneralModelSetting,
  GeneralProps,
} from '../../app-blocking.types';
import ImportParameter from './ImportParameter';
import SingleEntry from './SingleEntry';

const APP_BLOCKING_PARAM_ID = '1bf63aac-9033-49b4-93f1-0ac857afb8a8';

const General = (props: GeneralProps) => {
  const { update, isDisabled, isSubmitted, isAdding, settings } = props;
  const [isShowImportScreen, setIsShowImportScreen] = useState(isAdding);

  const { data: allBlueprints, isLoading: isLoadingAllBlueprints } =
    useBlueprints();

  const blueprintsWithParameter =
    allBlueprints?.filter(({ params }) => params[APP_BLOCKING_PARAM_ID]) || [];

  const messageFieldsToValidate = ['message', 'button_title', 'url'];
  const fieldsToValidate = [...messageFieldsToValidate, 'noBlocks'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    messageFieldsToValidate.forEach((v, idx) => {
      onInvalidate(idx)(settings.message_customization[v] ? '' : 'Required');
    });
  }, [settings.message_customization]);

  useEffect(() => {
    if (!isLoadingAllBlueprints && !blueprintsWithParameter.length) {
      setIsShowImportScreen(false);
    }
  }, [isLoadingAllBlueprints, blueprintsWithParameter]);

  const addEntry = (key, dataKey) =>
    update(key, [
      ...settings[key],
      {
        id: uuid(),
        [dataKey]: '',
        type: 'contains',
      },
    ]);

  const updateEntry = (dataPath, entryId) => (key, value) => {
    const index = settings[dataPath].findIndex((item) => item.id === entryId);
    update(dataPath, [
      ...settings[dataPath].slice(0, index),
      {
        ...settings[dataPath][index],
        [key]: value,
      },
      ...settings[dataPath].slice(index + 1),
    ]);
  };

  const importParameter = (param) => {
    const details = param.details;
    if (details) {
      const { message_customization, ...rest } = details;
      Object.keys(rest).forEach((key) => {
        update(
          key as keyof GeneralModelSetting,
          details[key]?.map((by) => ({ ...by, id: uuid() })) || [],
        );
      });
      update('message_customization', message_customization || {});
    }
    setIsShowImportScreen(false);
  };

  const requiredValidator = (v) => ({
    message: i18n.t('Required'),
    invalid: !v,
  });

  const pathValidator = (v) => ({
    message: i18n.t('Path must start with /'),
    invalid: !v.startsWith('/'),
  });

  const bundleValidator = (v) => ({
    message: i18n.t('Invalid bundle id'),
    invalid: !v.match(/^[a-zA-Z0-9-.]+$/i),
  });

  const messageError = isSubmitted && invalidations[0];
  const buttonTitleError = isSubmitted && invalidations[1];
  const urlError = isSubmitted && invalidations[2];
  const isNoBlockError = isSubmitted && invalidations[3];

  if (isShowImportScreen) {
    return (
      <Setting.Card>
        <ImportParameter
          blueprints={blueprintsWithParameter}
          onAddSettings={() => setIsShowImportScreen(false)}
          onImport={({ params }) =>
            importParameter(params[APP_BLOCKING_PARAM_ID])
          }
        />
      </Setting.Card>
    );
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('By Process')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Text variant="description">
              {i18n.t('Add a process to block')}
            </Text>
          </Setting.Helpers>
          <Setting.Controls>
            <Flex flow="column" gap="md">
              {settings.by_process.map((entry) => (
                <SingleEntry
                  key={entry.id}
                  title={i18n.t('Process identifier')}
                  dataKey="process_name"
                  entry={entry}
                  updateEntry={updateEntry('by_process', entry.id)}
                  updateValidation={update}
                  validator={[requiredValidator]}
                  onDelete={() =>
                    update(
                      'by_process',
                      settings.by_process.filter(
                        (item) => item.id !== entry.id,
                      ),
                    )
                  }
                  isDisabled={isDisabled}
                  isSubmitted={isSubmitted}
                />
              ))}
              <Box css={{ alignSelf: 'start' }}>
                <Button
                  compact
                  variant="primary"
                  onClick={() => addEntry('by_process', 'process_name')}
                  disabled={isDisabled}
                >
                  {i18n.t('Add Process')}
                </Button>
              </Box>
            </Flex>
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('By Path')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Text variant="description">{i18n.t('Add a path to block')}</Text>
          </Setting.Helpers>
          <Setting.Controls>
            <Flex flow="column" gap="md">
              {settings.by_path.map((entry) => (
                <SingleEntry
                  key={entry.id}
                  title={i18n.t('Full path to application')}
                  dataKey="full_path_to_app"
                  entry={entry}
                  updateEntry={updateEntry('by_path', entry.id)}
                  updateValidation={update}
                  validator={[
                    requiredValidator,
                    entry.type !== 'regex' && pathValidator,
                  ].filter(Boolean)}
                  isDisabled={isDisabled}
                  isSubmitted={isSubmitted}
                  onDelete={() =>
                    update(
                      'by_path',
                      settings.by_path.filter((item) => item.id !== entry.id),
                    )
                  }
                />
              ))}
              <Box css={{ alignSelf: 'start' }}>
                <Button
                  compact
                  variant="primary"
                  onClick={() => addEntry('by_path', 'full_path_to_app')}
                  disabled={isDisabled}
                >
                  {i18n.t('Add Path')}
                </Button>
              </Box>
            </Flex>
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('By Developer ID')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Text variant="description">
              {i18n.t('Add a Developer ID to block')}
            </Text>
          </Setting.Helpers>
          <Setting.Controls>
            <Flex flow="column" gap="md">
              {settings.by_developer_id.map((entry) => (
                <SingleEntry
                  key={entry.id}
                  title={i18n.t('Developer ID')}
                  dataKey="developer_id"
                  entry={entry}
                  updateEntry={updateEntry('by_developer_id', entry.id)}
                  updateValidation={update}
                  validator={[requiredValidator]}
                  isDisabled={isDisabled}
                  isSubmitted={isSubmitted}
                  withMatchType={false}
                  onDelete={() =>
                    update(
                      'by_developer_id',
                      settings.by_developer_id.filter(
                        (item) => item.id !== entry.id,
                      ),
                    )
                  }
                />
              ))}
              <Box css={{ alignSelf: 'start' }}>
                <Button
                  compact
                  variant="primary"
                  onClick={() => addEntry('by_developer_id', 'developer_id')}
                  disabled={isDisabled}
                >
                  {i18n.t('Add Developer ID')}
                </Button>
              </Box>
            </Flex>
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('By Bundle ID')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Text variant="description">
              {i18n.t('Add a Bundle ID to block')}
            </Text>
          </Setting.Helpers>
          <Setting.Controls>
            <Flex flow="column" gap="md">
              {settings.by_bundle_id.map((entry) => (
                <SingleEntry
                  key={entry.id}
                  title={i18n.t('Bundle identifier')}
                  dataKey="bundle_identifier"
                  entry={entry}
                  updateEntry={updateEntry('by_bundle_id', entry.id)}
                  updateValidation={update}
                  validator={[
                    requiredValidator,
                    entry.type !== 'regex' && bundleValidator,
                  ].filter(Boolean)}
                  isDisabled={isDisabled}
                  isSubmitted={isSubmitted}
                  onDelete={() =>
                    update(
                      'by_bundle_id',
                      settings.by_bundle_id.filter(
                        (item) => item.id !== entry.id,
                      ),
                    )
                  }
                />
              ))}
              <Box css={{ alignSelf: 'start' }}>
                <Button
                  compact
                  variant="primary"
                  onClick={() => addEntry('by_bundle_id', 'bundle_identifier')}
                  disabled={isDisabled}
                >
                  {i18n.t('Add Bundle ID')}
                </Button>
              </Box>
            </Flex>
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('Message')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Box ref={refs[0]}>
              <Text variant="description">
                {i18n.t('Message to display when an application is blocked.')}
              </Text>
            </Box>
          </Setting.Helpers>
          <Setting.Controls>
            <TextArea
              value={settings.message_customization.message}
              onChange={(e) =>
                update('message_customization', {
                  ...settings.message_customization,
                  message: e.currentTarget.value as string,
                })
              }
              css={{
                width: '500px',
                '& textarea': {
                  height: '100px',
                },
              }}
              disabled={isDisabled}
              state={messageError ? 'error' : 'default'}
              hint={{
                ...(messageError && {
                  label: messageError,
                }),
              }}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('Button title')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Box ref={refs[1]}>
              <Text variant="description">
                {i18n.t('Title used on the More Info button.')}
              </Text>
            </Box>
          </Setting.Helpers>
          <Setting.Controls>
            <TextField
              value={settings.message_customization.button_title}
              onChange={(e) =>
                update('message_customization', {
                  ...settings.message_customization,
                  button_title: e.target.value,
                })
              }
              css={{ width: '400px' }}
              disabled={isDisabled}
              state={buttonTitleError ? 'error' : 'default'}
              hint={{
                ...(buttonTitleError && {
                  label: buttonTitleError,
                }),
              }}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Text>{i18n.t('Button URL')}</Text>
          </Setting.Title>
          <Setting.Helpers>
            <Box ref={refs[2]}>
              <Text variant="description">
                {i18n.t(
                  'The URL that is navigated to when the More Info button is clicked.',
                )}
              </Text>
            </Box>
          </Setting.Helpers>
          <Setting.Controls>
            <TextField
              value={settings.message_customization.url}
              onChange={(e) =>
                update('message_customization', {
                  ...settings.message_customization,
                  url: e.target.value,
                })
              }
              css={{ width: '500px' }}
              disabled={isDisabled}
              state={urlError ? 'error' : 'default'}
              hint={{
                ...(urlError && {
                  label: urlError,
                }),
              }}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(General);
