import uuidv4 from 'uuid/v4';

import { i18n } from 'i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SafariExtensionsService extends NewLibraryItemService {
  static stateTypes = {
    ALLOWED: 'Allowed',
    ALWAYS_ON: 'AlwaysOn',
    ALWAYS_OFF: 'AlwaysOff',
  } as const;

  static get stateOptions() {
    return [
      {
        label: i18n.t('Allowed'),
        value: SafariExtensionsService.stateTypes.ALLOWED,
      },
      {
        label: i18n.t('Always On'),
        value: SafariExtensionsService.stateTypes.ALWAYS_ON,
      },
      {
        label: i18n.t('Always Off'),
        value: SafariExtensionsService.stateTypes.ALWAYS_OFF,
      },
    ];
  }

  static generateExtension = () => ({
    id: uuidv4(),
    BundleId: '',
    TeamId: '',
    State: this.stateTypes.ALLOWED,
    PrivateBrowsing: this.stateTypes.ALLOWED,
    AllowedDomains: [],
    DeniedDomains: [],
  });

  static generateDomain = () => ({
    id: uuidv4(),
    value: '',
  });
}

export const safariExtensionsService = new SafariExtensionsService();

export default SafariExtensionsService;
