import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  DateCell,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getTransparencyDatabaseColumns = ({
  transparencyDatabaseSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = transparencyDatabaseSchema.schema;
  const transparencyDatabaseColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.application`],
      {
        id: `${transparencyDatabaseSchema.uri}.application`,
        meta: {
          displayName: properties.application.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.service`],
      {
        id: `${transparencyDatabaseSchema.uri}.service`,
        meta: {
          displayName: properties.service.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.status`],
      {
        id: `${transparencyDatabaseSchema.uri}.status`,
        meta: {
          displayName: properties.status.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            transparencyDatabaseSchema.schema?.definitions.Status.enum || [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.status_reason`],
      {
        id: `${transparencyDatabaseSchema.uri}.status_reason`,
        meta: {
          displayName: properties.status_reason.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            transparencyDatabaseSchema.schema?.definitions.StatusReason.enum ||
              [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.local_user`],
      {
        id: `${transparencyDatabaseSchema.uri}.local_user`,
        meta: {
          displayName: properties.local_user.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.client_type`],
      {
        id: `${transparencyDatabaseSchema.uri}.client_type`,
        meta: {
          defaultHidden: true,
          displayName: properties.client_type.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            transparencyDatabaseSchema.schema?.definitions.ClientType.enum ||
              [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    transparencyDatabaseColumnHelper.accessor(
      (row) => row[`${transparencyDatabaseSchema.uri}.last_modified`],
      {
        id: `${transparencyDatabaseSchema.uri}.last_modified`,
        cell: DateCell,
        meta: {
          defaultHidden: true,
          displayName: properties.last_modified.title,
          displayIcon: getIconName(transparencyDatabaseSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(transparencyDatabaseSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
