import validator from 'validator';

import { i18n } from 'src/i18n';

export const bundleValidator = (v) =>
  v && !/^[A-Z0-9.-]+\.[A-Z0-9.-]+\.[A-Z0-9.-]+$/i.test(v)
    ? i18n.t('Invalid Bundle Identifier')
    : undefined;

export const devIDValidator = (v) =>
  v && !/^[A-Z0-9]+$/i.test(v)
    ? i18n.t('Invalid Developer ID format')
    : undefined;

export const fullPathValidator = (v) => {
  if (v && !v.startsWith('/')) {
    return i18n.t('Path must start with a /');
  }
  return undefined;
};

export const onEmptyBlurValidator = (
  v,
  { message = i18n.t('Required'), trigger = ['onBlur', 'onInput'] } = {},
) => ({
  invalid: () => typeof v !== 'string' || !v.length,
  message,
  trigger,
});

const integerValidator = (
  v,
  /* istanbul ignore next */ {
    message = i18n.t('Must be a whole number'),
    trigger = ['onBlur', 'onInput'],
  } = {},
) => ({
  invalid: /* istanbul ignore next */ () => !validator.isInt(String(v)),
  message,
  trigger,
});

export const getMinMaxValidator =
  ({ min, max, trigger = ['onBlur', 'onInput'] }: any) =>
  (v: any) => {
    const isGreaterThanMax = Number(v) > max;
    const isLessThanMin = Number(v) < min;
    const isLessThanMax = Number(v) < max;
    const r1 = onEmptyBlurValidator(v, { trigger });
    const r2 = integerValidator(v, { trigger });
    const r3 = isLessThanMax
      ? {
          message: i18n.t(`Must be ${min} or greater`, { min }),
          invalid: () => isLessThanMin,
          trigger,
        }
      : {
          message: i18n.t(`Must be ${max} or less`, { max }),
          invalid: () => isGreaterThanMax,
          trigger,
        };
    return [r1, r2, r3];
  };
