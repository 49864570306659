import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { RecoveryPasswordCommonStatusDetails } from '../RecoveryPassword.types';

function serializerV1(_details: RecoveryPasswordCommonStatusDetails) {
  return new LibraryItemStatusLog()
    .addRow(
      i18n.t("Couldn't find a valid password in legacy Firmware passwords."),
    )
    .toString();
}

export const RecoveryPasswordVerifyLegacyFailed = {
  Default: serializerV1,
  1: serializerV1,
};
