/* istanbul ignore file - tested by the class tests */
import { ManagedOSErrorDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSErrorDetails) {
  const {
    cause,
    grace_period,
    fallback,
    fallback_after_grace_period,
    exception_message,
    command_error,
    library_item_name,
  } = details;

  return new ManagedOSErrorDetails(
    cause,
    grace_period,
    fallback,
    fallback_after_grace_period,
    exception_message,
    command_error,
    library_item_name,
  ).get_message();
}

export const ManagedOSErrorSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
