import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { AppIncompatibleAuditLogDetails } from '../IPA_VPP.types';

function serializerV1(details: AppIncompatibleAuditLogDetails) {
  const { app_name, os_type, minimum, current } = details;

  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        '{app_name} requires {os_type} {minimum} or higher.\nThis device is currently running {os_type} {current}.',
        { app_name, os_type, minimum, current },
      ),
    )
    .toString();
}

export const IncompatibleAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
