import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { AppLicenseFailedAuditDetails } from '../IPA_VPP.types';

function serializerV1(details: AppLicenseFailedAuditDetails) {
  const { error } = details;
  return new LibraryItemStatusLog().addRow(error).toString();
}

export const LicenseFailedAudit = {
  Default: serializerV1,
  1: serializerV1,
};
