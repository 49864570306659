import type {
  LibraryItemLogSerializer,
  LibraryItemStatusSerializerMap,
  LibraryItemStatusTypes,
} from '../CommonStatus.types';
import { DeviceNameStatusSerializers } from '../DeviceName/serializers';
import { IPA_VPPStatusSerializers } from '../IPA_VPP/serializers';
import { ManagedLibraryItemSerializers } from '../ManagedLibraryItems/serializers';
import { ManagedOSStatusSerializers } from '../ManagedOS/serializers';
import { ProfileStatusSerializers } from '../Profiles/serializers';
import { RecoveryPasswordStatusSerializers } from '../RecoveryPassword/serializers';
import { VPPStatusSerializers } from '../VPP/serializers';
import { WallpaperStatusSerializers } from '../Wallpaper/serializers';

const LibraryItemLogSerializers: Record<
  string,
  LibraryItemStatusSerializerMap
> = {
  ...ProfileStatusSerializers,
  ...DeviceNameStatusSerializers,
  ...IPA_VPPStatusSerializers,
  ...ManagedOSStatusSerializers,
  ...RecoveryPasswordStatusSerializers,
  ...VPPStatusSerializers,
  ...WallpaperStatusSerializers,
  ...ManagedLibraryItemSerializers,
};

export function getStatusSerializer(
  type: LibraryItemStatusTypes,
  version: number,
): LibraryItemLogSerializer | null {
  const serializers = LibraryItemLogSerializers[type];

  if (serializers == null) return null;

  const serializer = serializers[version] ?? serializers.Default;

  return serializer;
}
