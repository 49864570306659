import { Button, Select, TextInput, Toggle } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { i18n } from 'src/i18n';
import uuid from 'uuid';

const defaultUser = {
  account_name: '',
  admin: 'standard',
  full_name: '',
  hidden_account: false,
  home_folder: '/private/var',
  id: '',
  password: '',
  repeatPassword: '',
  isNew: true,
  isInvalid: false,
};

const folderOptions = [
  { label: '/private/var', value: '/private/var' },
  { label: '/Users', value: '/Users' },
];

const CreateUserAccounts = (props) => {
  const { param, update, isDisabled, validations } = props;

  const { details } = param;
  const [users, setUsers] = useState(
    details?.map((user) => ({ ...user, isNew: false })) || [],
  );
  const [invalids, setInvalids] = useState(
    new Array(details?.length || 0).fill(0),
  );

  const accountOptions = [
    { label: i18n.t('Standard'), value: 'standard' },
    { label: i18n.t('Administrator'), value: 'admin' },
  ];

  const {
    CreateUserAccounts: validateParam,
    CreateUserAccounts_shortname: validateShortName,
    CreateUserAccounts_password: validatePassword,
    CreateUserAccounts_repeatPassword: validateRepeatPassword,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setUsers(details?.map((user) => ({ ...user, isNew: false })) || []);
      setInvalids(new Array(details?.length || 0).fill(0));
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        if (!users || !users.length) {
          update({ isInvalid: true });
        } else {
          update({
            details: users,
            isInvalid:
              users.some(
                (user) =>
                  !user.account_name ||
                  !user.password ||
                  (user.isNew && !user.repeatPassword),
              ) || invalids.some(Boolean),
          });
        }
      }, 250);
      return () => clearTimeout(time);
    }
  }, [users, isDisabled]);

  const onAdd = () => {
    setUsers((prev) => [...prev, deepcopy({ ...defaultUser, id: uuid() })]);
    setInvalids((prev) => [...prev, 0]);
  };

  const onTrash = (id) => {
    const idx = users.findIndex((user) => user.id === id);
    setUsers((prev) => prev.filter((user) => user.id !== id));
    setInvalids((prev) => prev.filter((_, i) => i !== idx));
  };

  return (
    <div>
      {users.map((config, idx) => {
        return (
          <div key={config.id} className="b-flex-vg1 b-mb1">
            <div className="b-flex-vc">
              <p className={`b-txt-${isDisabled ? 'light' : 'bold'} b-mr1`}>
                {i18n.t(`Account #{idxCount}`, { idxCount: idx + 1 })}
              </p>
              <Button
                disabled={isDisabled || users.length <= 1}
                size="small"
                onClick={() => onTrash(config.id)}
                kind="link"
                theme="error"
                icon="trash-can"
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'repeat(3, 1fr) auto',
                gridGap: 'var(--b-gap1)',
                gridRowGap: 'var(--b-gap2)',
              }}
            >
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label={i18n.t('Full name')}
                value={config.full_name}
                onChange={(e) => {
                  const val = e.target.value;
                  setUsers((prev) => {
                    const i = prev.findIndex((user) => user.id === config.id);
                    prev[i] = { ...prev[i], full_name: val };
                    return [...prev];
                  });
                }}
              />
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label={i18n.t('Short name (Account name)')}
                value={config.account_name}
                onChange={(e) => {
                  const val = e.target.value;
                  setUsers((prev) => {
                    const i = prev.findIndex((user) => user.id === config.id);
                    prev[i] = { ...prev[i], account_name: val };
                    return [...prev];
                  });
                }}
                validator={(v) => [
                  {
                    message: i18n.t('Required'),
                    invalid: () => !v,
                    trigger: ['onBlur'],
                  },
                ]}
                onInvalidate={(isInvalid) =>
                  setInvalids((prev) => {
                    prev[idx] = isInvalid;
                    return [...prev];
                  })
                }
                {...validateShortName.syncInvalid(invalids[idx], {
                  key: `user-${idx}`,
                })}
              />
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label={i18n.t('Password')}
                type="password"
                removeIcon={true}
                value={config.password}
                validator={(v) => [
                  {
                    message: i18n.t('Required'),
                    invalid: () => !v,
                    trigger: ['onBlur'],
                  },
                ]}
                onFocus={() => {
                  if (!config.isNew) {
                    setUsers((prev) => {
                      const i = prev.findIndex((user) => user.id === config.id);
                      prev[i] = {
                        ...prev[i],
                        password: '',
                        repeatPassword: '',
                        isNew: true,
                      };
                      return [...prev];
                    });
                  }
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  setUsers((prev) => {
                    const i = prev.findIndex((user) => user.id === config.id);
                    prev[i] = { ...prev[i], password: val, isNew: true };
                    return [...prev];
                  });
                }}
                onInvalidate={(isInvalid) =>
                  setInvalids((prev) => {
                    prev[idx] = isInvalid;
                    return [...prev];
                  })
                }
                {...validatePassword.syncInvalid(invalids[idx], {
                  key: `user-${idx}`,
                })}
              />
              {config.isNew && (
                <TextInput
                  fieldsGrid
                  disabled={isDisabled}
                  label={i18n.t('Repeat password')}
                  type="password"
                  removeIcon={true}
                  value={config.repeatPassword}
                  onChange={(e) => {
                    const val = e.target.value;
                    setUsers((prev) => {
                      const i = prev.findIndex((user) => user.id === config.id);
                      prev[i] = { ...prev[i], repeatPassword: val };
                      return [...prev];
                    });
                  }}
                  validator={(v) => [
                    {
                      message: i18n.t('Required'),
                      invalid: () => !v,
                      trigger: ['onBlur'],
                    },
                    {
                      message: i18n.t('Passwords must match'),
                      invalid: () => v !== config.password,
                      trigger: ['onBlur'],
                    },
                  ]}
                  onInvalidate={(isInvalid) =>
                    setInvalids((prev) => {
                      prev[idx] = isInvalid;
                      return [...prev];
                    })
                  }
                  {...validateRepeatPassword.syncInvalid(invalids[idx], {
                    key: `user-${idx}`,
                  })}
                />
              )}
              <div className="b-flex-vg1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
                  {i18n.t('Path to home folder')}
                </p>
                <Select
                  disabled={isDisabled}
                  options={folderOptions}
                  value={
                    folderOptions.find(
                      ({ value }) => value === config.home_folder,
                    ) || folderOptions[0]
                  }
                  onChange={({ value }) => {
                    setUsers((prev) => {
                      const i = prev.findIndex((user) => user.id === config.id);
                      prev[i] = { ...prev[i], home_folder: value };
                      return [...prev];
                    });
                  }}
                />
              </div>
              <div className="b-flex-vg1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
                  {i18n.t('User type')}
                </p>
                <Select
                  disabled={isDisabled}
                  options={accountOptions}
                  value={
                    accountOptions.find(
                      ({ value }) => value === config.admin,
                    ) || accountOptions[0]
                  }
                  onChange={({ value }) => {
                    setUsers((prev) => {
                      const i = prev.findIndex((user) => user.id === config.id);
                      prev[i] = { ...prev[i], admin: value };
                      return [...prev];
                    });
                  }}
                />
              </div>
              {!config.isNew && <div />}
              <div className="b-flex-vc">
                <Toggle
                  className="b-mr1"
                  disabled={isDisabled}
                  checked={config.hidden_account}
                  onToggle={(v) => {
                    setUsers((prev) => {
                      const i = prev.findIndex((user) => user.id === config.id);
                      prev[i] = { ...prev[i], hidden_account: v };
                      return [...prev];
                    });
                  }}
                />
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
                  {i18n.t('Create hidden sub-500 user account')}
                </p>
              </div>
            </div>
          </div>
        );
      })}

      <Button disabled={isDisabled} onClick={onAdd} icon="plus">
        {i18n.t('Add')}
      </Button>
      {validateParam.displayInvalid(param.isInvalid && !users.length)}
    </div>
  );
};

export default CreateUserAccounts;
