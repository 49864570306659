/* istanbul ignore file - tested by the class tests */
import { ManagedOSUpdateDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSUpdateDetails) {
  const { action, is_connected_to_power, battery_remaining, scheduled_time } =
    details;

  return new ManagedOSUpdateDetails(
    action,
    is_connected_to_power,
    battery_remaining,
    scheduled_time,
  ).get_message();
}

export const ManagedOSUpdateSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
