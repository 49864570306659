import { DndContext, DragOverlay } from '@dnd-kit/core';
import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { memo, useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import uuidv4 from 'uuid/v4';

import { Setting } from 'src/features/library-items/template';
import { addMissingApps } from '../../service/transformers';
import Preview from '../Preview';
import {
  createRestrictToRefElementModifier,
  getDropContainerAtMax,
  keyboardDragStartCssTransform,
  useHomeScreenLayoutDnd,
} from '../common';
import AppIcon from '../common/AppIcon';
import ApplicationList from '../common/ApplicationList';
import ArrangementLayout from '../common/ArrangementLayout';

import type {
  AppTypeKind,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  IDeviceProps,
} from '../../home-screen-layout.types';

const deviceDisplayNameMap = {
  ipad: () => i18n.t('iPad'),
  iphone: () => i18n.t('iPhone'),
};

const Device = (props: IDeviceProps) => {
  const { kind, update, isDisabled, settings, apps, blueprintOptions } = props;
  const {
    Pages,
    Dock,
    isEnabled,
    currentPage,
    currentFolderId,
    currentFolderPage,
  } = settings;

  const [deviceApps, setDeviceApps] = useState(
    apps?.list({ deviceType: kind }),
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTypes, setSelectedTypes] = useState<
    ReadonlyArray<AppTypeKind>
  >([]);
  const [selectedBlueprints, setSelectedBlueprints] = useState<
    ReadonlyArray<string>
  >([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const layoutRef = useRef<HTMLDivElement>();
  const combinedPreviewRefs = useRef({
    pageContainerRef: null,
    droppableContainerRef: null,
  });

  useEffect(() => {
    setDeviceApps(apps?.list({ deviceType: kind }));
  }, [apps]);

  const {
    activeItem,
    overItem,
    selectedItems,
    onSelect,
    dragActivationEvent,
    onDragStart,
    onDragCancel,
    onDragOver,
    onDragEnd,
    cancelDrop,
    sensors,
  } = useHomeScreenLayoutDnd({
    update,
    currentPage,
    currentFolder: currentFolderId,
    currentFolderPage,
    kind,
  });

  const restrictToRefElement = createRestrictToRefElementModifier(layoutRef);

  const updateFilters = (changedFilters = {}) => {
    setDeviceApps(
      apps?.list({
        deviceType: kind,
        appName: searchTerm,
        appType: selectedTypes,
        blueprints: selectedBlueprints,

        // Overwrite any of the above that changed:
        ...changedFilters,
      }),
    );
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    updateFilters({ appName: e.target.value });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    updateFilters({ appName: '' });
  };

  const handleTypeChange = (newTypesSelection: ReadonlyArray<AppTypeKind>) => {
    setSelectedTypes(newTypesSelection);
    updateFilters({ appType: newTypesSelection });
  };

  const handleBlueprintSelection = (
    newSelectedBlueprints: ReadonlyArray<string>,
  ) => {
    setSelectedBlueprints(newSelectedBlueprints);
    updateFilters({ blueprints: newSelectedBlueprints });
  };

  const handleClearAllFilters = () => {
    setSearchTerm('');
    setSelectedTypes([]);
    setSelectedBlueprints([]);
    updateFilters({ appType: [], appName: '', blueprints: [] });
  };

  const handleRemove = handleRemoveItemFactory(update, kind, Dock);

  const handleAddPage = () => {
    update('Pages', (prevPages) => {
      const newPages = Array.from(prevPages);
      newPages.splice(currentPage + 1, 0, []);
      return newPages;
    });

    update('currentPage', currentPage + 1);
  };

  const handleChangePage = (change: number) => {
    update('currentPage', (prevCurrentPage) => prevCurrentPage + change);
  };

  const removeCurrentPage = () => {
    update('Pages', (prevPages) => {
      const newPages = Array.from(prevPages);

      newPages.splice(currentPage, 1);

      return addMissingApps(kind, newPages, Dock);
    });
    update('currentPage', (prevCurrentPage) => prevCurrentPage - 1);
  };

  const handleRemovePage = () => {
    if (Pages[currentPage].length > 0) {
      setIsDeleteDialogOpen(true);
    } else {
      removeCurrentPage();
    }
  };

  const handleMovePage = (positionChange) => {
    update('Pages', (prevPages) => {
      const currPage = prevPages[currentPage];
      const newPages = Array.from(prevPages);
      // Remove current page:
      newPages.splice(currentPage, 1);

      // Insert current page at new location:
      newPages.splice(currentPage + positionChange, 0, currPage);

      return newPages;
    });
  };

  /* istanbul ignore next - wip functionality */
  const handleCreateFolder = (folderApps) => {
    // Remove the apps from the page before adding them to the new folder:
    folderApps.forEach((folderApp) =>
      handleRemove(folderApp, {
        context: 'page',
      }),
    );

    update('Pages', (prevPages) =>
      prevPages.map((page, idx) => {
        if (idx === currentPage) {
          return [
            ...page,
            {
              id: uuidv4(),
              Type: 'Folder' as const,
              DisplayName: i18n.t('Folder'),
              Pages: [[...folderApps]],
            },
          ];
        }

        return page;
      }),
    );
  };

  const deviceDisplayName = deviceDisplayNameMap[kind]();

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">
          {i18n.t('Customize')} {deviceDisplayName}
        </h3>
        <Setting.HeaderToggle
          isEnabled={isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          chipText={{ enabled: i18n.t('Active'), disabled: i18n.t('Inactive') }}
          isDisabled={isDisabled}
        />
      </Setting.Header>
      {isEnabled && (
        <>
          <Setting.SubHeader>
            <p className="b-txt">
              {i18n.t(
                "Arrange Apps on the page for {deviceName}. Since this Library Item automatically deploys an Allowed Apps Restriction payload to match your desired layout, no other Apps will be visible on the Home Screen, or in the {deviceName}'s App Library. Adding your own Allowed App list with the Restrictions Library Item will yield unpredictable results.",
                { deviceName: deviceDisplayNameMap[kind]() },
              )}
              <a
                className="b-alink"
                href="https://support.kandji.io/support/solutions/articles/72000616725"
                rel="noreferrer noopener"
                target="_blank"
              >
                {' '}
                {i18n.t('Learn more')}
              </a>
            </p>
          </Setting.SubHeader>
          <Setting.Rows>
            <ArrangementLayout
              ref={layoutRef}
              css={{
                $$focusedAppShadow:
                  '0 0 0 2px $colors$blue30, 0px 2px 8px 0px rgba(15, 19, 23, 0.20)',
              }}
            >
              <DndContext
                onDragStart={onDragStart}
                onDragCancel={onDragCancel}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                sensors={sensors}
                cancelDrop={cancelDrop}
                modifiers={[restrictToRefElement]}
                autoScroll={false}
              >
                <ApplicationList
                  kind={kind}
                  apps={deviceApps}
                  pages={Pages}
                  dock={Dock}
                  currentPage={currentPage}
                  currentFolderId={currentFolderId}
                  activeId={activeItem.id}
                  searchTerm={searchTerm}
                  selectedType={selectedTypes}
                  handleSearchChange={handleSearchChange}
                  handleClearSearch={handleClearSearch}
                  handleSelectedTypeChange={handleTypeChange}
                  handleClearAllFilters={handleClearAllFilters}
                  handleSelectedBlueprintChange={handleBlueprintSelection}
                  isDisabled={isDisabled}
                  blueprintOptions={blueprintOptions}
                  selectedBlueprints={selectedBlueprints}
                  onSelect={onSelect}
                  selectedItems={selectedItems}
                />

                <Preview
                  kind={kind}
                  pages={Pages}
                  dock={Dock}
                  onRemove={handleRemove}
                  onAddPage={handleAddPage}
                  disabled={isDisabled}
                  layoutRef={layoutRef}
                  combinedRefs={combinedPreviewRefs}
                  currentPage={currentPage}
                  onChangePage={handleChangePage}
                  onRemovePage={handleRemovePage}
                  onMovePage={handleMovePage}
                  onCreateFolder={handleCreateFolder}
                  currentFolderPage={currentFolderPage}
                  update={update}
                />

                <DragOverlay
                  dropAnimation={
                    overItem
                      ? null
                      : {
                          duration: 150,
                          easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
                        }
                  }
                >
                  {!!activeItem.id && (
                    <AppIcon
                      data-testid={`hsl-drag-overlay-app-${kind}`}
                      app={
                        apps.getAppEntry(
                          selectedItems.length > 0
                            ? selectedItems[selectedItems.length - 1].id
                            : activeItem.id,
                        ) || {
                          id: activeItem.id,
                          Type: activeItem.props.data.current.item.Type,
                          name: '',
                          icon: '',
                        }
                      }
                      disabled={isDisabled}
                      multi={selectedItems.length > 1}
                      count={selectedItems.length}
                      variant={
                        getDropContainerAtMax({
                          over: overItem,
                          selectedItems,
                        })
                          ? 'noDrop'
                          : 'dragging'
                      }
                      css={{
                        transform: keyboardDragStartCssTransform({
                          active: activeItem.props,
                          droppableContainerNode:
                            combinedPreviewRefs.current.droppableContainerRef,
                          dragActivationEvent,
                        }),
                      }}
                    />
                  )}
                </DragOverlay>
              </DndContext>
            </ArrangementLayout>
          </Setting.Rows>
        </>
      )}

      <Dialog
        title="Delete page and remove assets?"
        isOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        content={
          <p>
            {i18n.t(
              'This will will remove any assets (including folders) from this page. You will need to add them again to include in the Home Screen Layout. Are you sure you want to do this?',
            )}
          </p>
        }
        footer={
          <Flex justifyContent="end" gap="xs">
            <Button
              compact
              variant="default"
              onClick={
                /* istanbul ignore next */ () => setIsDeleteDialogOpen(false)
              }
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              compact
              variant="danger"
              onClick={() => {
                setIsDeleteDialogOpen(false);
                removeCurrentPage();
              }}
            >
              {i18n.t('Yes, Delete')}
            </Button>
          </Flex>
        }
        css={{ width: '560px' }}
      />
    </Setting.Card>
  );
};

/**
 * This factory function allows us to easily test this functionality
 */
export function handleRemoveItemFactory(updateFn, kind, dock = []) {
  return (
    item: HomeScreenLayoutItem,
    {
      context,
    }: {
      context: HomeScreenLayoutPreviewContextKind;
    },
  ) => {
    /* istanbul ignore next - need to cover this branch later */
    if (context === 'dock') {
      updateFn('Dock', (prevDock) => {
        const newDock = prevDock.filter(({ id }) => id !== item.id);
        return newDock;
      });
    }

    if (context === 'folder') {
      return updateFn('Pages', (prevPages) => {
        const newPages = prevPages.map((page) => {
          const newPage = page.map((pageItem) => {
            // Look through all folders to remove the provided app/item:
            if (pageItem.Type === 'Folder') {
              // Look through all folder's pages to remove the app/item:
              const newFolderPages = pageItem.Pages.map((folderPage) => {
                const newFolderPage = folderPage.filter(
                  (folderPageItem) => folderPageItem.id !== item.id,
                );

                return newFolderPage;
              });

              return {
                ...pageItem,
                Pages: newFolderPages,
              };
            }

            // Simply return non-folder items:
            return pageItem;
          });

          return newPage;
        });

        return newPages;
      });
    }

    return updateFn('Pages', (prevPages) => {
      const newPages = prevPages.map((page) => {
        const newPage = page.filter(({ id }) => id !== item.id);
        return newPage;
      });

      // When deleting a folder, make sure to add any missing built-in apps back to the pages:
      if (item.Type === 'Folder') {
        return addMissingApps(kind, newPages, dock);
      }

      return newPages;
    });
  };
}

export default memo(Device);
