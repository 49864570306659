import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: {} | null | undefined) {
  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        'Cannot overwrite existing application via MDM as the bundle identifiers do not match. Please remove the app locally.',
      ),
    )
    .toString();
}

export const BundleIDMismatch = {
  Default: serializerV1,
  1: serializerV1,
};
