/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { i18n } from 'i18n';
import { useDynamicImport } from 'src/hooks/useDynamicImport';
import { getGlobalVars } from '../GlobalVars';

interface LockScreenLexicalProps {
  handleEditorChange: (newEditorState: string) => void;
  isDisabled?: boolean;
  lockScreenFootnote: string;
  isValid: boolean;
}

function LexicalEditorComponent({
  Lexical,
  handleEditorChange,
  isDisabled,
  lockScreenFootnote,
  globalVariables,
  isValid,
}) {
  const {
    LexicalEditor,
    LexicalMentionsTriggers,
    LexicalMentionNode,
    LexicalMentionsPlugin,
    LexicalMentionDelimiters,
    buildMentionDelimiter,
    LexicalDisabledPlugin,
    LexicalInitialStatePlugin,
    LexicalValidationPlugin,
    LexicalSingleLine,
    PlaceholderPlugin,
    PlaceholderNode,
  } = Lexical;

  const initialConfig = {
    namespace: 'LockScreenLexical',
    nodes: [LexicalMentionNode, PlaceholderNode],
    editable: !isDisabled,
  };

  const mentionItems = {
    [LexicalMentionsTriggers.DOLLAR]: globalVariables,
  };

  const handleChange = (newEditorState: string) => {
    handleEditorChange(newEditorState);
  };

  const helpMessage = i18n.t('Required');

  return (
    <div>
      <LexicalEditor
        initialConfig={initialConfig}
        placeholder={i18n.t(
          'Enter lock screen message. Type “$” to access variables.',
        )}
        editorCss={{
          height: '96px',
          resize: 'none',
          overflow: 'auto',
        }}
        onEditorChange={handleChange}
      >
        <LexicalMentionsPlugin
          mentionItems={mentionItems}
          menuCss={{ maxHeight: '200px' }}
        />
        <LexicalDisabledPlugin isDisabled={isDisabled} />
        <LexicalInitialStatePlugin
          value={lockScreenFootnote}
          mentions={buildMentionDelimiter(
            [LexicalMentionDelimiters.DOLLAR],
            globalVariables,
          )}
        />
        <LexicalValidationPlugin isValid={isValid} helpMessage={helpMessage} />
        <PlaceholderPlugin />
        <LexicalSingleLine />
      </LexicalEditor>
    </div>
  );
}

export function LockScreenLexical({
  handleEditorChange,
  lockScreenFootnote,
  isDisabled,
  isValid,
}: LockScreenLexicalProps) {
  const [Lexical, isLoading, hasError] = useDynamicImport(
    () => import('@kandji-inc/nectar-ui/lexical'),
  );

  const {
    data,
    error,
    isLoading: isQueryLoading,
    isFetching,
  } = useQuery({
    queryKey: ['globalVars'],
    queryFn: getGlobalVars,
  });

  if (isLoading || isQueryLoading || isFetching || hasError || error || !data) {
    return null;
  }

  const globalVariables: string[] = data.map((item) => item.name);

  return (
    <LexicalEditorComponent
      Lexical={Lexical}
      key={`lock-screen-lexical:isValid[${isValid}]:isDisabled[${isDisabled}]`}
      handleEditorChange={handleEditorChange}
      globalVariables={globalVariables}
      lockScreenFootnote={lockScreenFootnote}
      isDisabled={isDisabled}
      isValid={isValid}
    />
  );
}
