/* istanbul ignore file */
import React, { useContext, useCallback, useEffect, useMemo } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import { useToast_UNSTABLE as useToast } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'src/contexts/interface';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { i18n } from 'i18n';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { getRunsOnFromModel } from '../../data-service/library-item/devices';
import { deviceTypes } from '../../library/common';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import DeviceSection from './sections/device';
import { setupAssistantService } from './service/setup-assistant-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSetupAssistantService from './service/use-setup-assistant-service';
import type { SetupAssistantProps } from './setup-assistant.types';

const SetupAssistantPage = (props: SetupAssistantProps) => {
  const { model, setModel, pageState, setPageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const { toast } = useToast();
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext<{ sidebarDocked: boolean }>(
    InterfaceContext,
  );

  const macUpdate = useCallback(update('mac', setModel), []);
  const iphoneUpdate = useCallback(update('iphone', setModel), []);
  const ipadUpdate = useCallback(update('ipad', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  const isGoingToBeNoSectionsEnabled = useMemo(() => {
    const runsOn = getRunsOnFromModel(model) as any;
    const enabledSections = {
      mac: model.mac.isEnabled,
      iphone: model.iphone.isEnabled,
      ipad: model.ipad.isEnabled,
    };
    const sectionsThatWillTurnOff = Object.keys(enabledSections).filter(
      (key) => !runsOn[`runs_on_${key}`] && enabledSections[key],
    );
    sectionsThatWillTurnOff.forEach((key) => {
      enabledSections[key] = false;
    });
    return Object.keys(enabledSections).every((key) => !enabledSections[key]);
  }, [model]);

  useEffect(() => {
    macUpdate('invalidationsMap', (p) => ({
      ...p,
      isAnySectionEnabled: { isInvalid: isGoingToBeNoSectionsEnabled },
    }));
  }, [isGoingToBeNoSectionsEnabled]);

  useEffect(() => {
    if (
      pageState.isSaving &&
      model.mac?.invalidationsMap?.isAnySectionEnabled?.isInvalid
    ) {
      setPageState((p) => ({ ...p, isSaving: false }));
      toast({
        title: i18n.t(
          'One or more device families set to Install On needs to be configured before saving.',
        ),
        variant: 'error',
        style: {
          left: /* istanbul ignore next */ sidebarDocked
            ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
            : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
          bottom: '12px',
          position: 'absolute',
        },
      });
    }
  }, [pageState, model]);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={setupAssistantService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
      supportsInstallOn
    >
      <DeviceSection
        device={i18n.t('Mac')}
        setting={model.mac}
        isDisabled={pageState.isDisabled}
        isSaving={pageState.isSaving}
        update={macUpdate}
        validationDep={validationDep}
        isDeviceInstallOnSupported={Boolean(
          model.devices.find(({ value }) => value === deviceTypes.MAC),
        )}
      />
      <DeviceSection
        device={i18n.t('iPhone')}
        setting={model.iphone}
        isDisabled={pageState.isDisabled}
        isSaving={pageState.isSaving}
        update={iphoneUpdate}
        validationDep={validationDep}
        isDeviceInstallOnSupported={Boolean(
          model.devices.find(({ value }) => value === deviceTypes.IPHONE),
        )}
      />
      <DeviceSection
        device={i18n.t('iPad')}
        setting={model.ipad}
        isDisabled={pageState.isDisabled}
        isSaving={pageState.isSaving}
        update={ipadUpdate}
        validationDep={validationDep}
        isDeviceInstallOnSupported={Boolean(
          model.devices.find(({ value }) => value === deviceTypes.IPAD),
        )}
      />
    </LibraryItemPage>
  );
};

const SetupAssistant = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: useSetupAssistantService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <SetupAssistantPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="setup-assistant-v2"
    />
  );
};

export default SetupAssistant;
