/* istanbul ignore file - tested by the class tests */
import { ManagedOSDDMAuditDetail } from '../ManagedOS.types';

function serializerV1(details: ManagedOSDDMAuditDetail) {
  const { ddm_state } = details;

  return new ManagedOSDDMAuditDetail(ddm_state).get_message();
}

export const ManagedOSDDMSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
