import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { WallpaperSettingsFailureDetails } from '../Wallpaper.types';

function serializerV1(details: WallpaperSettingsFailureDetails) {
  const { error_chain } = details;

  const log = new LibraryItemStatusLog().addRow(
    i18n.t('Wallpaper settings failed to apply:'),
  );

  if (error_chain == null) {
    log.addRow(i18n.t('No details provided by the device.'));
    return log.toString();
  }

  error_chain.forEach((error) => {
    const { localized_description, us_english_description } = error;
    const description = localized_description ?? us_english_description;

    log.addRow(`${description}`);
  });

  return log.toString();
}

export const WallpaperFailureSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
