import {
  Checkbox,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useIsChanged,
} from '@kandji-inc/bumblebee';
import React from 'react';
import './drawer-content.css';

import { Setting } from 'features/library-items/template';
import AddableContent from 'src/features/library-items/common/addable-content';
import { i18n } from 'src/i18n';
import uuid from 'uuid';
import WifiService from '../../../service/wifi-service';
import { keySizeOptions } from '../../protocols-card.model';
import SanRaw from '../configure-scep/san-raw';

const keys = WifiService.keys.authentication.adcs;

const DrawerContent = ({
  setting,
  update,
  refs,
  onInvalidate,
  validationDep,
  triggerValidate,
  adcsServers,
}) => {
  const isSubmitted = useIsChanged(triggerValidate);
  const showValidation = useIsChanged(validationDep);

  return (
    <Setting.Card>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">
              Certificate name&nbsp;
              <span className="b-txt-light"> (optional) </span>
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">The display name of the certificate.</p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextInput
              value={setting[keys.name]}
              onChange={(e) => update(keys.name, e.target.value)}
              placeholder="Enter the certificate name"
              compact
              data-testid="cert-name"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p ref={refs[keys.subject]} className="b-txt">
              Certificate subject
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">Specify the certificate subject.</p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextInput
              value={setting[keys.subject]}
              onChange={(e) => update(keys.subject, e.target.value)}
              placeholder="Enter the certificate subject"
              compact
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  message: i18n.t('Required'),
                  trigger: ['onBlur', validationDep, triggerValidate],
                }),
              ]}
              onInvalidate={onInvalidate(keys.subject)}
              data-testid="adcs-subject"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.withSan]}
              label="Specify Subject Alternative Names (SAN)"
              onChange={() => update(keys.withSan, (p) => !p)}
              testId="adcs-san"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Choose to include additional host names by specifying a SAN
              attribute and providing the subject alternative name.
            </p>
          </Setting.Helpers>
          {setting[keys.withSan] && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <div>
                  <AddableContent
                    max={25}
                    items={setting[keys.san]}
                    onChange={(newItems) => update(keys.san, newItems)}
                    getEmptyItem={() => ({
                      _id: uuid(),
                      type: null,
                      value: '',
                    })}
                    rowClassName="k-wifi-li__san-row"
                    renderItem={(index) => {
                      const updateSan = (prop, value) => {
                        const newSan = [...setting[keys.san]];
                        newSan[index] = {
                          ...newSan[index],
                          [prop]: value,
                        };

                        update(keys.san, newSan);
                      };

                      return (
                        <SanRaw
                          setting={setting[keys.san][index]}
                          updateValidation={update}
                          validationDep={triggerValidate}
                          update={updateSan}
                          refs={refs}
                        />
                      );
                    }}
                    keyField="_id"
                  />
                </div>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p ref={refs[keys.template]} className="b-txt">
              Template name
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">Specify the AD CS template to use.</p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextInput
              value={setting[keys.template]}
              onChange={(e) => update(keys.template, e.target.value)}
              placeholder="Enter the template name"
              compact
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  message: i18n.t('Required'),
                  trigger: ['onBlur', validationDep, triggerValidate],
                }),
              ]}
              onInvalidate={onInvalidate(keys.template)}
              data-testid="adcs-template"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p ref={refs.server} className="b-txt">
              AD CS server
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">Select the established AD CS server.</p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              options={adcsServers}
              placeholder="Select the AD CS server"
              compact
              onChange={(v) => update(keys.server, v.value)}
              value={adcsServers.find(
                (option) => option.value === setting[keys.server],
              )}
              errorText={
                (showValidation || isSubmitted) && !setting[keys.server]
                  ? 'Required'
                  : ''
              }
              testId="adcs-server"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Key size</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify the key size for the certificate.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              options={keySizeOptions}
              compact
              onChange={(v) => update(keys.keySize, v.value)}
              value={keySizeOptions.find(
                (option) => option.value === setting[keys.keySize],
              )}
              testId="adcs-keysize"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.allowAccessToApps]}
              label="Allow apps to access the private key"
              onChange={() => update(keys.allowAccessToApps, (p) => !p)}
              testId="ccr-allow-apps"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              If enabled, all apps will have access to the private key.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting[keys.preventExtraction]}
              label="Prevent the private key data from being extracted in the keychain"
              onChange={() => update(keys.preventExtraction, (p) => !p)}
              testId="ccr-key-export"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              By default, private key data is extractable in the keychain.
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default DrawerContent;
