import { i18n } from 'i18n';
import queryString from 'query-string';
import { getEnrollmentLocale } from './getEnrollmentLocale';

export function populateLocaleSelection(
  updateLocale: any,
  userLocale: string | null | undefined,
) {
  const queryParams = queryString.parse(window.location.search);
  const locale = getEnrollmentLocale(
    userLocale ?? (queryParams.locale as string) ?? i18n.getLocale(),
  );

  if (locale) {
    updateLocale(locale);
  }

  return locale;
}
