import { Button, Dialog, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';

const RequestTrialDialog = ({
  isOpen,
  onClose,
}: { isOpen: boolean; onClose: () => void }) => {
  const title = <Heading size="3">{i18n.t('Trial Requested')}</Heading>;

  const content = (
    <Flex flow="column" gap="lg" css={{ width: '512px' }}>
      <Text size="1">
        {i18n.t(
          "Thank you for requesting a trial of our EDR solution! We're excited for you to experience its capabilities firsthand. Our Customer Success team will be reaching out shortly to help you get started and ensure you have all the support needed to make the most of your trial.",
        )}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button variant="primary" onClick={onClose}>
        {i18n.t('Close')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      title={title}
      content={content}
      footer={footer}
    />
  );
};

export default RequestTrialDialog;
