/* istanbul ignore file */
import { Select, TextInput } from '@kandji-inc/bumblebee';
import RTFFileSelect from 'components/common/upload/rtf-file-select';
import RTFFileViewer from 'components/common/upload/rtf-file-viewer';

import { AccountContext } from 'contexts/account';
import deepcopy from 'deepcopy';
import React, { useContext, useEffect, useState } from 'react';
import { i18n } from 'src/i18n';

const fileDefault = {
  base64: '',
  extension: '',
  filename: '',
  type: 'file',
};

const plainDefault = (value = '') => ({
  value,
  type: 'text',
});

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

const CustomPolicyBanner = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const { currentCompany } = useContext(AccountContext);
  const companyName = currentCompany?.name || '';
  const defaultBanner = i18n.t(
    `{companyName}\nThis system is reserved for authorized use only, and the use of this system may be monitored.`,
    { companyName },
  );
  const [value, setValue] = useState(
    details || deepcopy(plainDefault(defaultBanner)),
  );

  const options = [
    { label: i18n.t('Enter plain text'), value: 'text' },
    { label: i18n.t('Use .rtf or .rtfd.zip file'), value: 'file' },
  ];

  const {
    CustomPolicyBanner_bannerText: validateBannerText,
    CustomPolicyBanner_bannerFile: validateBannerFile,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details || deepcopy(plainDefault(defaultBanner)));
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({
          details: value,
          isInvalid:
            (value.type === 'file' && !value.base64) ||
            (value.type === 'text' && !value.value),
        });
      }, 250);

      return () => clearTimeout(time);
    }
  }, [value]);

  const isFileRtfd = (file) => {
    return (file.extension || file.name).includes('rtfd.zip');
  };

  const hasFile = value?.base64 || value?.filename;
  return (
    <div className="b-mb1">
      <Select
        className="b-mb1"
        disabled={isDisabled}
        options={options}
        value={options.find((d) => d.value === value.type)}
        onChange={(v) =>
          setValue(
            deepcopy(
              v.value === 'file' ? fileDefault : plainDefault(defaultBanner),
            ),
          )
        }
        inputId="custom-policy-banner-select"
      />
      {value.type === 'text' ? (
        <TextInput
          fieldsGrid
          textArea
          id="policy-banner"
          disabled={isDisabled}
          label={i18n.t('Policy banner text')}
          value={value.value}
          onChange={(e) => {
            const value = e.target.value;
            setValue((prev) => ({ ...prev, value }));
          }}
          validator={(v) => [
            {
              message: i18n.t('Required'),
              invalid: () => v === '',
              trigger: ['onBlur'],
            },
          ]}
          {...validateBannerText.syncInvalid(param.isInvalid)}
        />
      ) : (
        <div className="">
          {!hasFile ? (
            <RTFFileSelect
              disabled={isDisabled}
              allowedFileTypes={['rtf', 'rtfd.zip']}
              onlyShowAcceptedFileTypes={false}
              onFileSelect={([file]) => {
                toBase64(file).then((base64) => {
                  // Not all browsers interpret .rtfd files the same way so to ensure
                  // consistent behavior we require the user zip up all rtfd files
                  setValue((prev) => ({
                    ...prev,
                    base64,
                    filename: file.name.replace(/\.rtfd\.zip|\.rtf/, ''),
                    extension: isFileRtfd(file)
                      ? 'rtfd.zip'
                      : file.name.split('.')?.pop(),
                  }));
                });
              }}
            />
          ) : (
            <RTFFileViewer
              canDownload={false}
              isDisabled={isDisabled}
              file={{
                name: `${value.filename}.${value.extension}`,
              }}
              onTrash={() => setValue(deepcopy(fileDefault))}
            />
          )}
          {validateBannerFile.displayInvalid(param.isInvalid)}
        </div>
      )}
    </div>
  );
};

export default CustomPolicyBanner;
