import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: null | undefined) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('Wallpaper setting deployment initiated'))
    .toString();
}

export const WallpaperPendingSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
