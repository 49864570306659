/* istanbul ignore file - tested by the class tests */
import { ManagedOSPendingDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSPendingDetails) {
  const { reason } = details;

  return new ManagedOSPendingDetails(reason).get_message();
}

export const ManagedOSPendingSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
