import {
  Button,
  Checkbox,
  LazyScriptEditor,
  Select,
  mapInvalidIndexToField,
  useInvalidations,
  useRefs,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

import { Setting } from 'features/library-items/template';
import { useFlags } from 'src/config/feature-flags';
import { i18n } from 'src/i18n';

import { AddableTextField, MultipleTextField } from './common';
import { Credential, Kerberos, Redirect } from './extensions';
import { extensionTypeOptions, extensionTypes } from './extensions/constants';

const extensionTypeToCpt = {
  [extensionTypes.credential]: Credential,
  [extensionTypes.redirect]: Redirect,
  [extensionTypes.kerberos]: Kerberos,
};

const Details = (props) => {
  const { setting, update, validationDep, isDisabled, isSubmitted } = props;
  const { 'DC-WWDC24-SSO-changes': LD_wwdc24_SSO_changes } = useFlags([
    'DC-WWDC24-SSO-changes',
  ]);
  const [isTouched, setIsTouched] = useState(false);

  const fieldsToValidate = ['extensionDetails'];
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });

  const hasValidConfigValue = /^<dict>.*<\/dict>$/s.test(
    setting.extension_details,
  );

  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );
  const isExtensionDetails = setting.isExtensionDetails;

  const validate = () => {
    if (isExtensionDetails && !hasValidConfigValue) {
      onInvalidate(0)(i18n.t('Configuration must be wrapped with <dict>'));
    } else {
      onInvalidate(0)(false);
    }
  };

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  useEffect(() => {
    validate();
  }, [setting.value, isExtensionDetails, hasValidConfigValue, onInvalidate]);

  useEffect(() => {
    if (isDisabled || isSubmitted) {
      setIsTouched(false);
    }
  }, [isDisabled, isSubmitted]);

  useEffect(() => {
    setIsTouched(false);
    onInvalidate(0)(false);
  }, [setting.type]);

  const RestDetailsComponent = extensionTypeToCpt[setting.type];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`Extension details`)}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title className="b-txt">
            {i18n.t(`Extension type`)}
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            <p>{i18n.t(`The type of sign-on authorization being used.`)}</p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              options={extensionTypeOptions()}
              onChange={({ value }) => update('type', value)}
              value={extensionTypeOptions().find(
                ({ value }) => value === setting.type,
              )}
              disabled={isDisabled}
              compact
            />
          </Setting.Controls>
        </Setting.Row>
        <RestDetailsComponent
          setting={setting}
          update={update}
          validationDep={validationDep}
          isDisabled={isDisabled}
        />
        {setting.type && setting.type !== extensionTypes.kerberos && (
          <>
            {LD_wwdc24_SSO_changes && (
              <Setting.Row>
                <Setting.Title className="b-txt">
                  {i18n.t(`Denied Bundle Indentifiers`)}
                </Setting.Title>
                <Setting.Helpers className="b-txt-light">
                  <p>
                    {i18n.t(`Specify bundle identifiers of apps that shouldn’t use SSO
                    provided by this extension. Applies to iOS and iPadOS 15 and
                    later, and macOS 12 and later.`)}
                  </p>
                </Setting.Helpers>
                <Setting.Controls>
                  <MultipleTextField
                    validationDep={validationDep}
                    isDisabled={isDisabled}
                    update={update}
                    items={setting.DeniedBundleIdentifiers || []}
                    settingKey="DeniedBundleIdentifiers"
                    placeholder={i18n.t('Enter Bundle Identifier')}
                    addButtonText={i18n.t('Add Bundle Identifier')}
                  />
                </Setting.Controls>
              </Setting.Row>
            )}
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label={i18n.t('Set additional extension configuration')}
                  checked={isExtensionDetails}
                  isDisabled={isDisabled}
                  onChange={() => update('isExtensionDetails', (p) => !p)}
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                <p>
                  {i18n.t(`Optionally specify any additional configuration data required
                  by the extension.`)}
                </p>
              </Setting.Helpers>
              {isExtensionDetails && (
                <Setting.SecondaryControls className="k-autoapp-config__controls">
                  <div className="k-vpp2-config">
                    <p ref={refs[0]} className="b-txt b-mb1">
                      {i18n.t(`Configuration dictionary`)}
                    </p>
                    <div
                      onBlur={() => {
                        setIsTouched(true);
                        validate();
                      }}
                    >
                      <LazyScriptEditor
                        value={setting.extension_details}
                        onChange={(v) => update('extension_details', v)}
                        language="python"
                        placeholder="<dict><key>ExtensionConfigKey</key><true/></dict>"
                        error={
                          ((isSubmitted || isTouched) && invalidations[0]) || ''
                        }
                        options={{ readOnly: isDisabled }}
                        className={
                          isDisabled && 'k-mobile-config-info__preview'
                        }
                      />
                    </div>
                  </div>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Details;
