import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { InstallAppCommandFailureDetails } from '../IPA_VPP.types';

function serializerV1(details: InstallAppCommandFailureDetails) {
  const { reject_reason, error_chain } = details;

  const log = new LibraryItemStatusLog();

  log.addRow(i18n.t('Install Failed.'));

  if (reject_reason) {
    log.addRow(i18n.t('Rejection Reason'), reject_reason);
  }

  log.addRow(i18n.t('Details:'));

  if (error_chain) {
    error_chain.forEach((error) => {
      const {
        error_domain,
        error_code,
        localized_description,
        us_english_description,
      } = error;

      const description = localized_description ?? us_english_description;

      log.addRow(`${description} (${error_domain}): ${error_code}`);
    });
  } else {
    log.addRow('No Details');
  }

  return log.toString();
}

export const InstallAppFailureSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
