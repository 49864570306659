import {
  Flex,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { i18n } from 'src/i18n';
import './san-raw.css';

import WifiService from '../../../service/wifi-service';

const SanRaw = (props) => {
  const {
    allSettings,
    setting,
    updateValidation,
    validationDep,
    isDisabled,
    update,
    refs: drawerRefs,
    isVisible,
    allowOnlySingleNtPrincipal,
  } = props;

  const sanTypeOptions = [
    {
      value: WifiService.subjectAlternativeNameTypes.DNS,
      label: i18n.t('DNS Name'),
    },
    {
      value: WifiService.subjectAlternativeNameTypes.RFC,
      label: i18n.t('RFC 822 Name'),
    },
    {
      value: WifiService.subjectAlternativeNameTypes.URI,
      label: i18n.t('Uniform Resource Identifier'),
    },
    {
      value: WifiService.subjectAlternativeNameTypes.UPN,
      label: i18n.t('NT Principal Name'),
    },
  ];

  const { type, value, _id } = setting;
  const fieldsToValidate = [`san-type_${_id}`, `san-value_${_id}`];
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );
  useEffect(() => {
    onInvalidate(fieldsToValidate[0])(!type);
  }, [type]);

  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);
  const isSubmitted = useIsChanged(validationDep, isVisible);

  const selectedTypeOption = sanTypeOptions.find(
    (option) => option.value === type,
  );

  const isSelectErrorNtPrincipal =
    allowOnlySingleNtPrincipal &&
    isSubmitted &&
    type === WifiService.subjectAlternativeNameTypes.UPN &&
    allSettings?.filter(
      ({ type: optionType }) =>
        optionType === WifiService.subjectAlternativeNameTypes.UPN,
    )?.length > 1;

  return (
    <div ref={refs[0]} className="k-wifi-li__san-inputs">
      <div
        className="b-txt b-mb1"
        ref={
          drawerRefs &&
          type === WifiService.subjectAlternativeNameTypes.UPN &&
          WifiService.keys.authentication.scep.sanUpn in drawerRefs
            ? drawerRefs[WifiService.keys.authentication.scep.sanUpn]
            : null
        }
      >
        {i18n.t(`SAN type`)}
      </div>
      <Flex className="react-select-test" gapType="gap2">
        <Flex direction="col" className="b-flex1">
          <Select
            isDisabled={isDisabled}
            options={sanTypeOptions}
            compact
            placeholder={i18n.t('Select the SAN type')}
            onChange={(v) => update('type', v.value)}
            value={selectedTypeOption}
            errorText={
              isSubmitted && !type
                ? i18n.t('Required')
                : isSelectErrorNtPrincipal
                  ? i18n.t('Note: Only one NT Principal name is supported.')
                  : ''
            }
            isOptionDisabled={(option) =>
              allowOnlySingleNtPrincipal &&
              option.value === WifiService.subjectAlternativeNameTypes.UPN &&
              allSettings?.filter(
                ({ type: optionType }) =>
                  optionType === WifiService.subjectAlternativeNameTypes.UPN,
              )?.length
            }
            testId="san-raw"
          />
          {allowOnlySingleNtPrincipal &&
            type === WifiService.subjectAlternativeNameTypes.UPN &&
            !isSelectErrorNtPrincipal && (
              <p className="b-txt-light">
                {i18n.t(`Note: Only one NT Principal name is supported.`)}
              </p>
            )}
        </Flex>
        <TextInput
          disabled={isDisabled}
          value={value}
          onChange={(e) => update('value', e.target.value)}
          compact
          placeholder={i18n.t('Enter the subject alternative name')}
          validator={(v) => [
            onEmptyBlurValidator(v, {
              message: i18n.t('Required'),
              trigger: ['onBlur', validationDep],
            }),
          ]}
          onInvalidate={onInvalidate(fieldsToValidate[1])}
          data-testid="san-value"
        />
      </Flex>
    </div>
  );
};

export default SanRaw;
