/* istanbul ignore file */
import { i18n } from 'i18n';
import type { LibraryItemStatusContent } from '../CommonStatus.types';
import type { CommandErrorDetails } from '../Profiles/ProfileStatus.types';

export enum OSTypes {
  macOS = 'macOS',
  iOS = 'iOS',
  iPadOS = 'iPadOS',
  tvOS = 'tvOS',
  watchOS = 'watchOS',
  audioOS = 'audioOS',
  xrOS = 'xrOS',
}

export enum AppConfigAuditStatuses {
  PASS = 'pass',
  ERROR = 'error',
  OUTDATED = 'OUTDATED',
}

export enum RejectionReasons {
  APP_ALREADY_INSTALLED = 'AppAlreadyInstalled',
  APP_ALREADY_QUEUED = 'AppAlreadyQueued',
  APP_STORE_DISABLED = 'AppStoreDisabled',
  COULD_NOT_VERIFY_APP_ID = 'CouldNotVerifyAppID',
  MANAGEMENT_CHANGE_NOT_SUPPORTED = 'ManagementChangeNotSupported',
  NOT_AN_APP = 'NotAnApp',
  NOT_SUPPORTED = 'NotSupported',
  PURCHASE_METHOD_NOT_SUPPORTED = 'PurchaseMethodNotSupported',
  PURCHASE_METHOD_NOT_SUPPORTED_IN_MULTIUSER = 'PurchaseMethodNotSupportedInMultiUser',
  OTHER = 'Other',
}

export enum IPA_VPPStatusTypes {
  LIBRARY_ITEM_AVAILABLE_IN_SELF_SERVICE_AUDIT = 'library_item_available_in_self_service_audit',
  APP_INCOMPATIBLE_AUDIT = 'app_incompatible_audit',
  INSTALL_APP_DOWNLOADING_AUDIT = 'install_app_downloading_audit',
  INSTALL_APP_FAILED_AUDIT = 'install_app_failed_audit',
  APP_INSTALL_IN_PROGRESS_AUDIT = 'install_app_installing_audit',
  APP_INSTALL_STUCK_AUDIT = 'install_app_stuck_audit',
  APP_INSTALL_FAILED_AUDIT = 'app_install_failed_audit',
  APP_INSTALL_PASSED_AUDIT = 'app_install_passed_audit',
  APP_INSTALL_HIGHER_VERSION_PASSED_AUDIT = 'app_install_higher_version_passed_audit',
  APP_INSTALL_LOWER_VERSION_PASSED_AUDIT = 'app_install_lower_version_passed_audit',
  APP_MISSING_AUDIT = 'app_missing_audit',
  APP_UNAVAILABLE_AUDIT = 'app_unavailable_audit',
  APP_BUNDLE_ID_MISMATCH_AUDIT = 'app_bundle_id_mismatch_audit',
  APP_LICENSE_MISSING_AUDIT = 'app_license_missing_audit',
  APP_LICENSE_FAILED_AUDIT = 'app_license_failed_audit',
  INSTALL_APP_SUCCESS = 'install_app_success',
  INSTALL_APP_FAILURE = 'install_app_failure',
}

export interface InstallAppCommandSuccessLogDetails {
  app_name: string;
  version: string | null;
  bundle_id: string;
  author: string | null;
  command_issued: string;
  command_completed: string;
}

export interface InstallAppCommandSuccessLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.INSTALL_APP_SUCCESS;
  version: number;
  details: InstallAppCommandSuccessLogDetails;
}

export interface InstallAppCommandFailureDetails {
  reject_reason: RejectionReasons | null;
  error_chain: CommandErrorDetails[] | null;
}

export interface InstallAppCommandFailureLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.INSTALL_APP_FAILURE;
  version: number;
  details: InstallAppCommandFailureDetails;
}

export interface AppIncompatibleAuditLogDetails {
  app_name: string;
  os_type: OSTypes;
  minimum: string;
  current: string;
}

export interface AppIncompatibleAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INCOMPATIBLE_AUDIT;
  version: number;
  details: AppIncompatibleAuditLogDetails;
}

export interface InstallAppFailedAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.INSTALL_APP_FAILED_AUDIT;
  version: number;
  details: null;
}

export interface InstallAppDownloadingAuditLog
  extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.INSTALL_APP_DOWNLOADING_AUDIT;
  version: number;
  details: null;
}

export interface AppAppleStatusDetails {
  app_status: string;
}

export interface AppInstallInProgressAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_IN_PROGRESS_AUDIT;
  version: number;
  details: AppAppleStatusDetails;
}

export interface AppInstallStuckAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_STUCK_AUDIT;
  version: number;
  details: null;
}

export interface AppInstallFailedAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_FAILED_AUDIT;
  version: number;
  details: AppAppleStatusDetails;
}

export interface AppInstallPassedDetails extends AppAppleStatusDetails {
  app_name: string;
  version: string | null;
  version_ext: number | null;
  desired_version: string | null;
  desired_version_ext: number | null;
  is_update_allowed: boolean;
  config_status: AppConfigAuditStatuses | null;
}

export interface AppInstallPassedAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_PASSED_AUDIT;
  version: number;
  details: AppInstallPassedDetails;
}

export interface AppInstallHigherVersionPassedAuditLog
  extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_HIGHER_VERSION_PASSED_AUDIT;
  version: number;
  details: AppInstallPassedDetails;
}

export interface AppInstallLowerVersionPassedAuditLog
  extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_INSTALL_LOWER_VERSION_PASSED_AUDIT;
  version: number;
  details: AppInstallPassedDetails;
}

export interface AppMissingDetails {
  app_name: string;
  bundle_id: string;
  version: string | null;
}

export interface AppMissingAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_MISSING_AUDIT;
  version: number;
  details: AppMissingDetails;
}

export interface AppUnavailableAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_UNAVAILABLE_AUDIT;
  version: number;
  details: null;
}

export interface AppBundleIdMismatchAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_BUNDLE_ID_MISMATCH_AUDIT;
  version: number;
  details: null;
}

export interface AppLicenseMissingAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_LICENSE_MISSING_AUDIT;
  version: number;
  details: null;
}

export interface AppLicenseFailedAuditDetails {
  error: string;
}

export interface AppLicenseFailedAuditLog extends LibraryItemStatusContent {
  type: IPA_VPPStatusTypes.APP_LICENSE_FAILED_AUDIT;
  version: number;
  details: AppLicenseFailedAuditDetails;
}

export const ManagedAppStatuses = (status: string) => {
  const ManagedAppStatusDescriptions = {
    NEEDS_REDEMPTION: i18n.t(
      'The app is scheduled for installation but needs a redemption code to complete the transaction.',
    ),
    REDEEMING: i18n.t('The device is redeeming the redemption code.'),
    PROMPTING: i18n.t('The user is being prompted for app installation.'),
    PROMPTING_FOR_LOGIN: i18n.t(
      'The user is being prompted for App Store credentials.',
    ),
    VALIDATING_PURCHASE: i18n.t('An app purchase is being validated.'),
    PROMPTING_FOR_UPDATE: i18n.t('The user is being prompted for an update.'),
    PROMPTING_FOR_UPDATE_LOGIN: i18n.t(
      'The user is being prompted for App Store credentials for an update.',
    ),
    PROMPTING_FOR_MANAGEMENT: i18n.t(
      'The user is being prompted to change an installed app to be managed.',
    ),
    VALIDATING_UPDATE: i18n.t('An app update is being validated.'),
    UPDATING: i18n.t('The app is being updated.'),
    INSTALLING: i18n.t('The app is being installed.'),
    MANAGED: i18n.t('The app is installed and managed.'),
    MANAGED_BUT_UNINSTALLED: i18n.t(
      'The app is managed but has been removed by the user. When the app is installed again (even by the user), it will be managed once again.',
    ),
    UNKNOWN: i18n.t('The app state is unknown.'),
    USER_INSTALLED_APP: i18n.t(
      'The user has installed the app before managed app installation could take place.',
    ),
    USER_REJECTED: i18n.t('The user rejected the offer to install the app.'),
    UPDATE_REJECTED: i18n.t('The user rejected the offer to update the app.'),
    MANAGEMENT_REJECTED: i18n.t(
      'The user rejected management of an already installed app.',
    ),
    FAILED: i18n.t('The app installation has failed.'),
    QUEUED: i18n.t('The app installation is queued.'),
  };

  return ManagedAppStatusDescriptions[status];
};

export function ConfigStatus(status: string) {
  const ConfigStatusDescriptions = {
    pass: i18n.t('App configuration is present and up to date.'),
    error: i18n.t("App configuration can't be set."),
    outdated: i18n.t(
      'App configuration is outdated. Kandji will re-set the configuration.',
    ),
  };

  return ConfigStatusDescriptions[status];
}
