/* istanbul ignore file */
import {
  $t,
  ProviderNames,
  getJSLocale,
  getLanguage,
  getLocale,
  getShouldUseRelativeDates,
  getTimeZone,
  setLocale as originalSetLocale,
  setProvider as originalSetProvider,
  render,
  setShouldUseRelativeDates,
  setTimeZone,
  t,
  ut,
} from '@kandji-inc/nectar-i18n';
import FeatureFlags from 'src/config/feature-flags';
import { getEnv } from 'src/util';
import { common } from './common';
import { format } from './format';

type SupportedLocales = readonly ['en_US', 'en_GB', 'es_419', 'de', 'ja_JP'];

type SupportedLocaleRecord = {
  [LocaleKey in SupportedLocales[number]]: LocaleKey;
};

export type LocaleLanguageOption<
  LocaleKey extends
    keyof typeof LOCALE_LANGUAGE_DESCRIPTORS = keyof typeof LOCALE_LANGUAGE_DESCRIPTORS,
> = {
  value: LocaleKey;
  label: (typeof LOCALE_LANGUAGE_DESCRIPTORS)[LocaleKey];
};

export type LocaleLanguageOptionList = {
  [LocaleKey in Exclude<
    keyof SupportedLocales,
    keyof string[]
  >]: LocaleLanguageOption<SupportedLocales[LocaleKey]>;
} & Array<LocaleLanguageOption<SupportedLocales[number]>>;

const LOCALES = {
  en_US: 'en_US',
  en_GB: 'en_GB',
  es_419: 'es_419',
  de: 'de',
  ja_JP: 'ja_JP',
} as const satisfies SupportedLocaleRecord;

/**
 * Human-readable locale names, used for displaying locale names in the
 * user preferences language selection and should not be translated to show
 * recognizable language choices to the user.
 */
const LOCALE_LANGUAGE_DESCRIPTORS = {
  en_US: 'English (US)',
  en_GB: 'English (UK)',
  es_419: 'Español (Latinoamérica)',
  de: 'Deutsch',
  ja_JP: '日本語 (日本)',
} as const;

const createMap =
  <TMap extends Record<string, () => string>>(map: TMap) =>
  (variable: keyof TMap) => {
    if (variable in map) {
      const translationFn = map[variable];
      if (translationFn) {
        return translationFn();
      }
    }

    const isDev = getEnv() === 'dev';
    if (isDev) {
      throw new Error(`Missing translation for variable: ${String(variable)}`);
    }
    console.error('Missing translation for variable: ', variable);

    return variable;
  };

let i18nEnabled = false;

const configure = async ({
  locale,
  timeZone = 'UTC',
  shouldUseRelativeDates = false,
  legacyLocale = LOCALES.en_US, // when i18n is not enabled, use this locale
}: {
  locale: string;
  timeZone?: string;
  shouldUseRelativeDates?: boolean;
  legacyLocale?: string;
}) => {
  // set provider
  const providerOverride = localStorage.getItem('i18n-provider');
  const providerFlag = FeatureFlags.getFlag('i18n-provider');
  const providerName =
    providerOverride || providerFlag || i18n.ProviderNames.noop;

  if (providerName !== ProviderNames.noop) {
    i18nEnabled = true;
  }

  originalSetProvider(providerName);

  // formatter settings
  setTimeZone(timeZone);
  setShouldUseRelativeDates(shouldUseRelativeDates);

  // set locale
  const localeOverride = localStorage.getItem('i18n-locale');
  if (localeOverride) {
    await originalSetLocale(localeOverride);
  } else if (!i18nEnabled) {
    await originalSetLocale(legacyLocale);
  } else {
    await originalSetLocale(locale);
  }

  document.documentElement.lang = getJSLocale();
};

export const i18n = {
  // temporary until we enable i18n globally
  isEnabled: () => i18nEnabled,
  configure,
  // wrapped to consolidate imports
  getLocale,
  getTimeZone,
  getShouldUseRelativeDates,
  getJSLocale,
  getLanguage,
  render,
  t,
  ut,
  $t,
  // extra helpers
  createMap,
  common,
  format,
  // env
  ProviderNames,
  LOCALES,
  LOCALE_LANGUAGE_DESCRIPTORS,
};
