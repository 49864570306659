// istanbul ignore file
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';
import { ThreatService } from '../../../data-service';
import type {
  FiltersState,
  GetThreatDetailsResponse,
  PaginationState,
  SortColumnState,
} from '../../../threat.types';
import toRequestFormat from './toRequestFormat';

const ThreatsSchema = z.object({
  previous: z.string().url().nullable(),
  next: z.string().url().nullable(),
  count: z.number(),
  malware_count: z.number(),
  pup_count: z.number(),
  results: z.unknown(),
});

type UseGetThreats = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
  deviceId?: string,
  enabled?: boolean,
) => {
  data: GetThreatDetailsResponse | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: string | undefined;
  refetch: () => Promise<void>;
};

/**
 * Retrieves threat details based on the provided filters, sort, pagination, and computer information.
 *
 * @param filters - The filters to apply to the threat details.
 * @param sort - The sorting options for the threat details.
 * @param pagination - The pagination options for the threat details.
 * @param deviceId - The device id.
 * @param enabled - Boolean to enable or disable the query.
 * @returns An object containing the threat details data and a boolean indicating if the data is currently being loaded.
 */
const useGetThreats: UseGetThreats = (
  filters,
  sort,
  pagination,
  deviceId,
  enabled = true,
) => {
  const threatService = useMemo(() => new ThreatService(), []);
  const {
    data,
    refetch,
    isPlaceholderData,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ['threats', window?.location?.search, deviceId],
    queryFn: async () => {
      const data = await threatService.getThreats(
        toRequestFormat(filters, sort, pagination, deviceId),
      );

      if (!ThreatsSchema.safeParse(data?.data).success) {
        throw new Error('Invalid schema');
      }

      return data;
    },
    placeholderData: keepPreviousData,
    retry: 2,
    retryDelay: 1000,
    enabled,
  });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isFetching,
    isError,
    error: error?.message,
    refetch: refetch as unknown as () => Promise<void>,
  };
};

export default useGetThreats;
