import {
  Flex,
  SelectContents,
  SelectV2,
  SelectV2Primitives,
  styled,
} from '@kandji-inc/nectar-ui';
import DE from '../../../assets/img/de.png';
import EN_US from '../../../assets/img/en_US.png';
import ES_419 from '../../../assets/img/es_419.png';
import JA_JP from '../../../assets/img/ja_JP.png';

const FlagIcon = styled('img', {
  width: 20,
  border: '0.2px solid $neutral20',
});

const LanguageMetadaRegex = /(\(.*\))/;

export const LANGUAGE_OPTIONS = [
  {
    label: 'English (United States)',
    value: 'en_US',
    iconElement: () => <FlagIcon src={EN_US} alt="English (United States)" />,
  },
  {
    label: 'Español (Latinoamèrica)',
    value: 'es_419',
    iconElement: () => <FlagIcon src={ES_419} alt="Español (Latinoamèrica)" />,
  },
  {
    label: 'Deutsch',
    value: 'de',
    iconElement: () => <FlagIcon src={DE} alt="Deutsch" />,
  },
  {
    label: '日本語 (日本)',
    value: 'ja_JP',
    iconElement: () => <FlagIcon src={JA_JP} alt="日本語 (日本)" />,
  },
];

interface LanguageSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export function LanguageSelect({ value, onChange }: LanguageSelectProps) {
  const option = LANGUAGE_OPTIONS.find((option) => option.value === value);
  return (
    <SelectV2.Default
      value={value}
      onValueChange={onChange}
      options={LANGUAGE_OPTIONS}
      triggerProps={{
        variant: 'subtle',
        value: option.label.replace(LanguageMetadaRegex, ''),
        css: {
          background: 'transparent',
          '&:focus': {
            background: 'transparent',
          },
        },
      }}
      content={() => (
        <SelectContents.Default css={{ width: 220 }}>
          {LANGUAGE_OPTIONS.map((option) => (
            <SelectV2Primitives.Item value={option.value}>
              <Flex alignItems="center" gap="xs">
                {option.iconElement()}
                {option.label}
              </Flex>
            </SelectV2Primitives.Item>
          ))}
        </SelectContents.Default>
      )}
    />
  );
}
