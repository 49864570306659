/* istanbul ignore file */
import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect } from 'react';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';

import ActivityTab from 'features/library-items/common/activity-tab';
import { populateSelectedDevices } from 'features/library-items/data-service/library-item/devices';
import config from 'features/library-items/library/library-item-configurations/items/profile';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import initialState from './initial-state';
import { GeneralConfig } from './sections';
import { WebClipProvider } from './sections/WebClipContext';
import useService from './service/use-webclip-service';
import { webclipService } from './service/webclip-service';
import {
  transformFromApi,
  transformToApi,
} from './service/webclip-transformers';

const WebClipPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const itemConfig = config.WebClip;

  useEffect(
    () => populateSelectedDevices(model, setModel, itemConfig),
    [model, itemConfig],
  );

  const updateGeneral = useCallback(update('general', setModel), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      service={webclipService}
      defaultIcon={itemConfig.icon}
      supportsDuplication
      supportsRules
      supportsInstallOn
    >
      <WebClipProvider>
        <GeneralConfig
          setting={model.general}
          devices={model.devices}
          isDisabled={pageState.isDisabled}
          update={updateGeneral}
          validationDep={validationDep}
        />
      </WebClipProvider>
    </LibraryItemPage>
  );
};

const WebClip = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <WebClipPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="webclip"
    />
  );
};

export default WebClip;
