import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { DeviceNameSettingsPendingDetails } from '../DeviceName.types';

function serializerV1(details: DeviceNameSettingsPendingDetails) {
  const { device_name } = details;

  return new LibraryItemStatusLog()
    .addRow(
      i18n.t(
        'The command to set the new device name {device_name} initiated.',
        { device_name },
      ),
    )
    .toString();
}

export const DeviceNameSettingsPendingSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
