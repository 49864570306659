/* istanbul ignore file - tested by the class tests */
import { ManagedOSAuditDetails } from '../ManagedOS.types';

function serializerV1(details: ManagedOSAuditDetails) {
  const {
    human_readable_os_version,
    target_version,
    up_to_date,
    newer_version_installed,
    library_item_name,
    should_pre_cache,
    enforcement_delay,
    enforce_after,
    enforcement_time,
    enforcement_timezone,
  } = details;

  return new ManagedOSAuditDetails(
    human_readable_os_version,
    target_version,
    up_to_date,
    newer_version_installed,
    library_item_name,
    should_pre_cache,
    enforcement_delay,
    enforce_after,
    enforcement_time,
    enforcement_timezone,
  ).get_message();
}

export const ManagedOSAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
