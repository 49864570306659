import { i18n } from 'i18n';

export function getEnrollmentLocale(
  i18nLocale: string,
  // This should ensure i18n is only used at runtime but
  // will still need to be used under the provider
  locales = i18n.LOCALES,
): string {
  switch (i18nLocale) {
    // We want to override en_GB for the enrollment portal
    case locales.en_GB:
      return locales.en_US;
    default:
      return i18nLocale;
  }
}
