import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';

function serializerV1(_details: null | undefined) {
  return new LibraryItemStatusLog()
    .addRow(
      i18n.t('The command to set wallpaper is not supported by the device.'),
    )
    .toString();
}

export const WallpaperIncompatibleSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
