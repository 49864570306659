import { TextInput, Toggle } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { i18n } from 'src/i18n';

const TimeMachineStatus = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;

  const {
    TimeMachineStatus: { syncInvalid },
  } = validations;

  const [value, setValue] = useState(
    details || {
      alert_no_backups: false,
      days_between_backups: 14,
    },
  );
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      setValue(
        details || {
          alert_no_backups: false,
          days_between_backups: 14,
        },
      );
      setInvalid(false);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({
          details: {
            ...value,
            days_between_backups: parseInt(value.days_between_backups),
          },
          isInvalid: invalid,
        });
      }, 250);

      return () => clearTimeout(time);
    }
  }, [value, invalid]);

  return (
    <div className="b-mb1" style={{ maxWidth: '65%' }}>
      <div className="b-flex-vc b-mb1">
        <Toggle
          className="b-mr1"
          checked={value.alert_no_backups}
          onToggle={(c) =>
            setValue((prev) => ({ ...prev, alert_no_backups: c }))
          }
          disabled={isDisabled}
        />
        <p className={`b-txt${isDisabled ? '-light' : ''}`}>
          {i18n.t('Alert if Time Machine backups are not enabled')}
        </p>
      </div>
      <TextInput
        fieldsGrid
        type="number"
        disabled={isDisabled}
        label={i18n.t(
          'Days allowed between backups before an alert is triggered',
        )}
        value={value.days_between_backups}
        onChange={(e) => {
          const val = e.target.value;
          setValue((prev) => ({
            ...prev,
            days_between_backups: val,
          }));
        }}
        validator={(v) => [
          {
            message: i18n.t('Value not in range. Min: 1 - Max: 99999'),
            invalid: () => v < 1 || v > 99999,
            trigger: ['onBlur', 'onInput'],
          },
        ]}
        onInvalidate={(isInvalid) => setInvalid(isInvalid)}
        {...syncInvalid(invalid)}
      />
    </div>
  );
};

export default TimeMachineStatus;
