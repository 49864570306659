import { i18n } from 'i18n';
import type {
  LibraryItemExcludedStatusContent,
  LibraryItemStatusContent,
} from '../CommonStatus.types';

export enum RecoveryPasswordStatusTypes {
  RECOVERY_PASSWORD_EXCLUDED_AUDIT = 'recovery_password_excluded_audit',
  RECOVERY_PASSWORD_INCOMPATIBLE_AUDIT = 'recovery_password_incompatible_audit',
  RECOVERY_PASSWORD_PENDING_COMMAND_AUDIT = 'recovery_password_pending_command_audit',
  RECOVERY_PASSWORD_RESTART_NEEDED_AUDIT = 'recovery_password_restart_needed_audit',
  RECOVERY_PASSWORD_VERIFYING_LEGACY_PASSWORD_AUDIT = 'recovery_password_verifying_legacy_password_audit',
  RECOVERY_PASSWORD_SUCCESS_AUDIT = 'recovery_password_success_audit',
  RECOVERY_PASSWORD_SET_FAILED_AUDIT = 'recovery_password_set_failed_audit',
  RECOVERY_PASSWORD_VERIFY_FAILED_AUDIT = 'recovery_password_verify_failed_audit',
  RECOVERY_PASSWORD_LEGACY_VERIFY_FAILED_AUDIT = 'recovery_password_legacy_verify_failed_audit',
}

export enum RecoveryPasswordTypes {
  RECOVERY_LOCK = 'recovery_lock',
  FIRMWARE = 'firmware',
}

export function getRecoveryPasswordType(password_type: RecoveryPasswordTypes) {
  switch (password_type) {
    case RecoveryPasswordTypes.FIRMWARE:
      return i18n.t('Firmware');
    case RecoveryPasswordTypes.RECOVERY_LOCK:
      return i18n.t('Recovery Lock');
  }
}

export interface RecoveryPasswordCommonStatusDetails {
  password_type: RecoveryPasswordTypes;
}

export interface RecoveryPasswordExcludedStatusAuditLog
  extends LibraryItemExcludedStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_EXCLUDED_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordIncompatibleStatusAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_INCOMPATIBLE_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordPendingCommandDetails
  extends RecoveryPasswordCommonStatusDetails {
  is_rotate: boolean;
}

export interface RecoveryPasswordPendingCommandStatusAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_PENDING_COMMAND_AUDIT;
  version: number;
  details: RecoveryPasswordPendingCommandDetails;
}

export interface RecoveryPasswordRestartNeededStatusAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_RESTART_NEEDED_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordVerifyingLegacyDetails
  extends RecoveryPasswordCommonStatusDetails {
  left: number;
}

export interface RecoveryPasswordVerifyingLegacyStatusAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_VERIFYING_LEGACY_PASSWORD_AUDIT;
  version: number;
  details: RecoveryPasswordVerifyingLegacyDetails;
}

export interface RecoveryPasswordSuccessAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_SUCCESS_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordSetFailedAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_SET_FAILED_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordVerifyFailedAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_VERIFY_FAILED_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export interface RecoveryPasswordLegacyVerifyFailedAuditLog
  extends LibraryItemStatusContent {
  type: RecoveryPasswordStatusTypes.RECOVERY_PASSWORD_LEGACY_VERIFY_FAILED_AUDIT;
  version: number;
  details: RecoveryPasswordCommonStatusDetails;
}

export type RecoveryPasswordStatusAuditLog =
  | RecoveryPasswordExcludedStatusAuditLog
  | RecoveryPasswordIncompatibleStatusAuditLog
  | RecoveryPasswordPendingCommandStatusAuditLog
  | RecoveryPasswordRestartNeededStatusAuditLog
  | RecoveryPasswordVerifyingLegacyStatusAuditLog
  | RecoveryPasswordSuccessAuditLog
  | RecoveryPasswordSetFailedAuditLog
  | RecoveryPasswordVerifyFailedAuditLog
  | RecoveryPasswordLegacyVerifyFailedAuditLog;
