import {
  Chip,
  TextInput,
  onEmptyBlurValidator,
  urlValidator,
} from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../../service/ethernet-service';

const keys = EthernetService.keys.authentication.scep;

export const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-ethernetv2-protocols-general__tag"
    key={tag}
  />
);

const General = ({
  update,
  setting,
  triggerValidate,
  validationDep,
  onInvalidate,
  refs,
}) => {
  const urlTags = [
    i18n.t('macOS 10.11+'),
    i18n.t('iPadOS 13+'),
    i18n.t('tvOS 9+'),
    i18n.t('iOS 4.0+'),
    'CIS',
    'NIST',
    'STIG',
    i18n.t('Supervised'),
  ];

  const nameTags = urlTags;
  const challengeTags = urlTags;
  const fingerprintTags = urlTags;
  const restartInstallTags = urlTags;

  return (
    <>
      <Setting.Header
        testId="scep-general"
        className="--no-padding --no-shadow"
      >
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">{i18n.t('URL')}</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t('The base URL for the SCEP server')}
          </p>
          <div className="b-flex-wrap-gtiny">{urlTags.map(renderTag)}</div>
        </Setting.Helpers>
        <Setting.Controls>
          <div ref={refs[keys.url]}>
            <TextInput
              name="scep_url"
              value={setting[keys.url]}
              onChange={(e) => update(keys.url, e.target.value)}
              compact
              placeholder={i18n.t('URL')}
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  message: i18n.t('Required'),
                  trigger: ['onBlur', triggerValidate],
                }),
                urlValidator(v, {
                  trigger: ['onBlur', triggerValidate],
                  options: {
                    allow_underscores: true,
                  },
                }),
              ]}
              onInvalidate={onInvalidate(keys.url)}
              removeValidationOnUnmount
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">
            {i18n.t('Name')}
            <span className="b-txt-light2"> {i18n.t('(optional)')}</span>
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              "A string that's understood by the SCEP server; for example, a domain name like example.org. If a certificate authority has multiple CA certificates, this field can be used to distinguish which is required.",
            )}
          </p>
          <div className="b-flex-wrap-gtiny">{nameTags.map(renderTag)}</div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <TextInput
              name="scep_name"
              value={setting[keys.name]}
              onChange={(e) => update(keys.name, e.target.value)}
              compact
              isOptional
              placeholder={i18n.t('Name')}
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">
            {i18n.t('Challenge')}
            <span className="b-txt-light2"> {i18n.t('(optional)')}</span>
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t('A pre-shared secret used for automatic enrollment.')}
          </p>
          <div className="b-flex-wrap-gtiny">
            {challengeTags.map(renderTag)}
          </div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <TextInput
              name="scep_challenge"
              value={setting[keys.challenge]}
              onChange={(e) => update(keys.challenge, e.target.value)}
              compact
              isOptional
              placeholder={i18n.t('Challenge')}
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">
            {i18n.t('Fingerprint')}
            <span className="b-txt-light2"> {i18n.t('(optional)')}</span>
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'The fingerprint (hex string) of the Certificate Authority certificate.',
            )}
          </p>
          <div className="b-flex-wrap-gtiny">
            {fingerprintTags.map(renderTag)}
          </div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <TextInput
              name="scep_fingerprint"
              value={setting[keys.fingerprint]}
              onChange={(e) => update(keys.fingerprint, e.target.value)}
              compact
              isOptional
              placeholder={i18n.t('Fingerprint')}
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
    </>
  );
};

export default General;
