import { pick } from 'lodash';
import {
  ALL_INTEGRATIONS_FILTER_OPTION,
  INT_CATEGORIES,
  INT_TYPES,
} from '../../constants';
import cardConfigurations from '../../generic-cards-view/cards-config';

/**
 *
 * @param {Array<Object>} fullDataset
 * @return {Object}
 */
function getCounts(fullDataset) {
  const counts = {};
  const counted = {};

  fullDataset.forEach((intData) => {
    const { category, type } = intData;

    if (counts[category] == null) {
      counts[category] = 0;
    }

    const isCommunication = category === INT_CATEGORIES.communication;
    if (!isCommunication || !counted[type]) {
      counts[category]++;
      counted[type] = true;
    }
  });

  counts[ALL_INTEGRATIONS_FILTER_OPTION.label] = Object.values(counts).reduce(
    (res, v) => res + v,
    0,
  );

  return counts;
}

function shouldSuggestMarketplace(counts) {
  return !Object.values(counts).reduce((res, cnt) => res + cnt, 0);
}

/**
 *
 * @param {Array<Object>} filteredDataset
 * @param {Array<Object>} fullDataset
 * @return {Array<{title: string, items: Array<Object>}>}
 */
function getItems(filteredDataset, fullDataset, mapCategoryType) {
  const itemsByCategories = {};
  const amounts = mapCategoryType[INT_CATEGORIES.communication].reduce(
    (res, type) => ({
      ...res,
      [type]: fullDataset.filter((int) => int.type === type).length,
    }),
    {},
  );
  const included = {};

  filteredDataset.forEach((intData) => {
    const { category, type } = intData;

    if (!itemsByCategories[category]) {
      itemsByCategories[category] = [];
    }

    const isCommunication = category === INT_CATEGORIES.communication;
    if (!isCommunication || !included[type]) {
      itemsByCategories[category].push(intData);
      included[type] = true;
    }
  });

  return Object.entries(itemsByCategories).map(([title, items]) => ({
    title,
    items:
      title === INT_CATEGORIES.communication
        ? items.map((i) => {
            const amount = amounts[i.type];
            const extraData = amount === 1 ? i : pick(i, ['type', 'category']);
            return { amount, ...extraData };
          })
        : items,
  }));
}

const TYPE_ORDERING = [
  INT_TYPES.msTeams,
  INT_TYPES.slack,
  INT_TYPES.office365,
  INT_TYPES.gsuite,
  INT_TYPES.scim,
  INT_TYPES.adcs,
];

/**
 *
 * @param {Array<Object>} list
 * @param {{ category: string | null, search: string }} filters
 * @return {Array<Object>}
 */
function applyFiltersAndOrdering(list, filters) {
  let orderedList = [...list];
  orderedList.sort(
    (a, b) => TYPE_ORDERING.indexOf(a.type) - TYPE_ORDERING.indexOf(b.type),
  );

  if (filters.search !== '') {
    const search = filters.search.toLowerCase();

    const found = (item) => {
      const { title } = cardConfigurations[item.type];
      return (
        item.name?.toLowerCase().includes(search) ||
        title()?.toLowerCase().includes(search)
      );
    };

    orderedList = orderedList.filter(found);
  }

  return filters.category == null
    ? orderedList
    : orderedList.filter((item) => filters.category === item.category);
}

export {
  getCounts,
  getItems,
  applyFiltersAndOrdering,
  shouldSuggestMarketplace,
};
