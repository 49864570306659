import { getCode } from 'country-list';
/* istanbul ignore file */
import deepcopy from 'deepcopy';
import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { getSsoConnections } from 'features/library-items/data-service/sso/use-sso-service';
import { omit } from 'lodash';

import featureFlags from 'src/config/feature-flags';
import { DEFAULT_VALUES } from '../initial-state';
import { userValues } from '../ipad/shared-row';
import {
  APPLE_TV_SKIP_SCREENS,
  IPAD_SKIP_SCREENS,
  IPHONE_SKIP_SCREENS,
  MAC_SKIP_SCREENS,
  screensById,
} from '../skip-screens/skip-screen-constants';

const DATA_SKIP_KEYS = {
  GENERAL: [
    'isContactsOverridden',
    'isMdmRemovable',
    'orgDetails',
    'ssoConnectionId',
    'ssoConnections',
    'ssoEnabled',
    'ssoSyncUserWithDevice',
    'invalidationsMap',
    'defaultOrgDetails',
  ],
  MAC: [
    'isAutoAdvance',
    'screens',
    'hideAccount',
    'fullName',
    'shortName',
    'password',
    'accountType',
    'provisionLocalAdminAccount',
    'verifyPassword',
    'invalidationsMap',
  ],
  IPHONE: ['screens', 'invalidationsMap'],
  IPAD: ['screens', 'isShared', 'maximumSharedResidents', 'invalidationsMap'],
  APPLE_TV: ['isAutoAdvance', 'screens', 'invalidationsMap'],
};

export const transformSkipItemsFromApi = (skipObj, deviceScreens) =>
  Object.entries(deviceScreens).map(([key, value]) => ({
    ...value,
    isSelected: skipObj[key],
  }));

export const transformMacSkipItemsFromApi = (skipObj, deviceScreens) => {
  const newSkipObj = {
    // * Adding in new entries if missing, will be overwritten if present
    TermsOfAddress: false,
    EnableLockdownMode: false,
    ...skipObj,
  };

  return transformSkipItemsFromApi(newSkipObj, deviceScreens);
};

export const transformSkipItemsToApi = (
  skipScreens,
  isSkipAll,
  returnAllOfScreen,
) => {
  if (isSkipAll && returnAllOfScreen) {
    return returnAllOfScreen.reduce(
      (a, c) => ({
        ...a,
        [c.id]: true,
      }),
      {},
    );
  }

  if (isSkipAll) {
    return {};
  }
  return skipScreens.reduce((skipObj, screen) => {
    skipObj[screen.id] = screen.isSelected;
    return skipObj;
  }, {});
};

const isAnyValueTrue = (obj) => Object.values(obj).some((val) => val === true);

const getBackwardsCompatibleValuesForV1 = (apiData) => {
  const defaultValues = {
    iphone: {
      isEnabled: false,
      canSkip: false,
    },
    ipad: {
      isEnabled: false,
      canSkip: false,
      sharedUserConfiguration: '',
    },
    appleTv: {
      isEnabled: false,
      canSkip: false,
    },
    mac: {
      isEnabled: false,
      canSkip: false,
    },
    vision: {
      isEnabled: false,
      canSkip: false,
    },
  };

  if (isAnyValueTrue(apiData.skip_setup_items.iOS)) {
    defaultValues.iphone.isEnabled = true;
    defaultValues.iphone.canSkip = true;
  }

  if (isAnyValueTrue(apiData.skip_setup_items.iPadOS)) {
    defaultValues.ipad.isEnabled = true;
    defaultValues.ipad.canSkip = true;
  }
  if (apiData.is_multi_user) {
    defaultValues.ipad.isEnabled = true;
    defaultValues.ipad.sharedUserConfiguration = userValues.USERS;
  }

  if (
    apiData.auto_advance_setup_tv_os ||
    isAnyValueTrue(apiData.skip_setup_items.tvOS)
  ) {
    defaultValues.appleTv.isEnabled = true;
    defaultValues.appleTv.canSkip = true;
  }

  if (
    apiData.auto_advance_setup_mac_os ||
    isAnyValueTrue(apiData.skip_setup_items.MacOS)
  ) {
    defaultValues.mac.isEnabled = true;
    defaultValues.mac.canSkip = true;
  } else if (
    apiData.mac_os_account.hidden ||
    apiData.mac_os_account.fullName ||
    apiData.mac_os_account.password ||
    apiData.mac_os_account.shortName ||
    apiData.mac_os_account.isAccountConfigured ||
    apiData.mac_os_account.accountType !== 'admin'
  ) {
    defaultValues.mac.isEnabled = true;
  }

  return defaultValues;
};

/**
 * Necessary transformations on the data from the api.
 * @param {Object} data - The DEP profile data received from the api.
 */
const transformFromApi = async (
  apiData,
  defaultOrgDetails = DEFAULT_VALUES().ORG_DETAILS,
) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const ssoConnections = await getSsoConnections();
    const defaults = getBackwardsCompatibleValuesForV1(apiData);

    /* `isPreventLock` supported for transfromer from API for backwards
     * compatibility with new `userActivationLockAllowed` key change for
     * `mac`, `iphone`, and `ipad` sections */
    const transformed = {
      ...apiData,
      data: {
        ...apiData.data,
        selectedBlueprints,
        excludedBlueprints,
        isAllBlueprints: apiData.is_all_blueprints,
        isActive: apiData.active,
        name: apiData.name,
        id: apiData.id,
        template: apiData.template,
        general: {
          ssoEnabled: apiData.custom_web_view?.sso?.enabled || false,
          ssoConnectionId:
            apiData.custom_web_view?.sso?.auth0_connection_id || '',
          ssoConnections,
          ssoSyncUserWithDevice:
            apiData.custom_web_view?.sso?.sync_user_with_device || false,
          prefill: apiData.data?.general?.prefill || false,
          lockPrefilled: apiData.data?.general?.lockPrefilled || false,
          isMdmRemovable: apiData.is_mdm_removable,
          isContactsOverridden: apiData.is_contacts_overridden,
          orgDetails: {
            email: apiData.email || '',
            location: apiData.department || '',
            phoneNumber: apiData.phone || '',
          },
          defaultOrgDetails,
          invalidationsMap: {},
        },
        mac: {
          isEnabled: apiData.data?.mac?.isEnabled ?? defaults.mac.isEnabled,
          provisionLocalAdminAccount:
            apiData.mac_os_account.isAccountConfigured,
          isAutoAdvance: apiData.auto_advance_setup_mac_os,
          canSkip: apiData.data?.mac?.canSkip ?? defaults.mac.canSkip,
          accountType: apiData.mac_os_account.accountType,
          fullName: apiData.mac_os_account.fullName || '',
          shortName: apiData.mac_os_account.shortName || '',
          password: apiData.mac_os_account.password,
          verifyPassword: apiData.mac_os_account.password,
          hideAccount: apiData.mac_os_account.hidden,
          makeAutoAdmin: apiData.data?.mac?.makeAutoAdmin || false,
          userActivationLockAllowed:
            apiData.data?.mac?.userActivationLockAllowed ||
            apiData.data?.mac?.isPreventLock ||
            false,
          installPackagesBeforeExit:
            apiData.data?.mac?.installPackagesBeforeExit || false,
          packages: apiData.data?.mac?.packages,
          screens: transformMacSkipItemsFromApi(
            apiData.skip_setup_items.MacOS,
            screensById.mac(),
          ),
          invalidationsMap: {},
          // min OS version
          isMinimumOSVersionSet:
            apiData?.data?.mac?.isMinimumOSVersionSet || false,
          minimumOSVersion:
            apiData?.data?.mac?.minimumOSVersion ||
            DEFAULT_VALUES().MINIMUM_OS_VERSION_MAC,
          isRegionSet: apiData.data?.mac?.isRegionSet || false,
          region:
            apiData.data?.mac?.region || getCode('United States of America'),
          isLanguageSet: apiData.data?.mac?.isLanguageSet || false,
          language: apiData.data?.mac?.language || 'en',
        },
        iphone: {
          canSkip: apiData.data?.iphone?.canSkip ?? defaults.iphone.canSkip,
          isSkipAll: false,
          isEnabled:
            apiData.data?.iphone?.isEnabled ?? defaults.iphone.isEnabled,
          userActivationLockAllowed:
            apiData.data?.iphone?.userActivationLockAllowed ||
            apiData.data?.iphone?.isPreventLock ||
            false,
          isEnableOrgActivationLock:
            apiData.data?.iphone?.isEnableOrgActivationLock || false,
          screens: transformSkipItemsFromApi(
            apiData.skip_setup_items.iOS,
            screensById.iPhone(),
          ),
          invalidationsMap: {},
          // min OS version
          isMinimumOSVersionSet:
            apiData?.data?.iphone?.isMinimumOSVersionSet || false,
          minimumOSVersion:
            apiData?.data?.iphone?.minimumOSVersion ||
            DEFAULT_VALUES().MINIMUM_OS_VERSION_IPHONE,
        },
        ipad: {
          isEnabled: apiData.data?.ipad?.isEnabled ?? defaults.ipad.isEnabled,
          canSkip: apiData.data?.ipad?.canSkip ?? defaults.ipad.canSkip,
          isSkipAll: false,
          userActivationLockAllowed:
            apiData.data?.ipad?.userActivationLockAllowed ||
            apiData.data?.ipad?.isPreventLock ||
            false,
          isEnableOrgActivationLock:
            apiData.data?.ipad?.isEnableOrgActivationLock || false,

          isShared: apiData.is_multi_user,
          sharedUserConfiguration:
            apiData.data?.ipad?.sharedUserConfiguration ??
            defaults.ipad.sharedUserConfiguration,

          maximumSharedResidents: apiData.resident_users,
          maximumSharedSize:
            apiData.data?.ipad?.maximumSharedSize ||
            DEFAULT_VALUES().USER_QUOTA_SIZE,
          isGuestSessionTimeout:
            apiData.data?.ipad?.isGuestSessionTimeout || false,
          guestSessionTimeout:
            apiData.data?.ipad?.guestSessionTimeout ||
            DEFAULT_VALUES().GUEST_SESSION_TIMEOUT,
          isPasscodeLockPeriod:
            apiData.data?.ipad?.isPasscodeLockPeriod || false,
          passcodeLockPeriod:
            apiData.data?.ipad?.passcodeLockPeriod ||
            DEFAULT_VALUES().PASSCODE_LOCK_PERIOD,
          isAutomaticUserLogout:
            apiData.data?.ipad?.isAutomaticUserLogout || false,
          automaticUserLogout:
            apiData.data?.ipad?.automaticUserLogout ||
            DEFAULT_VALUES().AUTOMATIC_USER_LOGOUT,
          screens: transformSkipItemsFromApi(
            apiData.skip_setup_items.iPadOS,
            screensById.iPad(),
          ),
          invalidationsMap: {},
          // min OS version
          isMinimumOSVersionSet:
            apiData?.data?.ipad?.isMinimumOSVersionSet || false,
          minimumOSVersion:
            apiData?.data?.ipad?.minimumOSVersion ||
            DEFAULT_VALUES().MINIMUM_OS_VERSION_IPAD,
        },
        appleTv: {
          isEnabled:
            apiData.data?.appleTv?.isEnabled ?? defaults.appleTv.isEnabled,
          isAutoAdvance: apiData.auto_advance_setup_tv_os,
          canSkip: apiData.data?.appleTv?.canSkip ?? defaults.appleTv.canSkip,
          isRegionSet: apiData.data?.appleTv?.isRegionSet || false,
          region:
            apiData.data?.appleTv?.region ||
            getCode('United States of America'),
          isLanguageSet: apiData.data?.appleTv?.isLanguageSet || false,
          language: apiData.data?.appleTv?.language || 'en',
          screens: transformSkipItemsFromApi(
            apiData.skip_setup_items.tvOS,
            screensById.appleTv(),
          ),
          invalidationsMap: {},
        },
        vision: {
          isEnabled:
            apiData.data?.vision?.isEnabled ?? defaults.vision.isEnabled,
          userActivationLockAllowed:
            apiData.data?.vision?.userActivationLockAllowed || false,
          isEnableOrgActivationLock:
            apiData.data?.vision?.isEnableOrgActivationLock || false,
        },
      },
    };

    return transformed;
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

/**
 * Necessary transformations on the data to send to the api.
 * @param {Object} data - The library item data model to send to the api.
 */
const transformToApi = (data) => {
  const d1 = deepcopy(data);
  const blueprints = d1.selectedBlueprints.map((blueprint) => blueprint.value);

  const { general, mac, iphone, ipad, appleTv, vision } = getDefaultValues(d1);
  const d2 = {
    name: d1.name,
    isActive: d1.isActive,
    general,
    mac,
    iphone,
    ipad,
    appleTv,
    vision,
  };

  const toSend = {
    type: 'dep-profile',
    active: d2.isActive,
    blueprints,
    is_all_blueprints: d1.isAllBlueprints,
    name: d2.name,
    is_mdm_removable: d2.general.isMdmRemovable,
    is_contacts_overridden: d2.general.isContactsOverridden,

    auto_advance_setup_tv_os: d2.appleTv.isAutoAdvance,
    auto_advance_setup_mac_os: d2.mac.isAutoAdvance,
    is_mandatory: true,

    custom_web_view: {
      enabled: d2.general.ssoEnabled,
      sso: {
        enabled: d2.general.ssoEnabled,
        auth0_client_id: '',
        auth0_connection_id: d2.general.ssoConnectionId,
        auth0_connection_name: '',
        sync_user_with_device: d2.general.ssoSyncUserWithDevice,
      },
    },

    skip_setup_items: {
      MacOS: {
        ...MAC_SKIP_SCREENS().reduce((a, c) => ({ ...a, [c.id]: false }), {}),
        ...transformSkipItemsToApi(
          d2.mac.screens,
          d2.mac.isAutoAdvance,
          MAC_SKIP_SCREENS(),
        ),
      },
      iOS: {
        ...IPHONE_SKIP_SCREENS().reduce(
          (a, c) => ({ ...a, [c.id]: false }),
          {},
        ),
        ...transformSkipItemsToApi(
          d2.iphone.screens,
          false,
          IPHONE_SKIP_SCREENS(),
        ),
      },
      iPadOS: {
        ...IPAD_SKIP_SCREENS().reduce((a, c) => ({ ...a, [c.id]: false }), {}),
        ...transformSkipItemsToApi(d2.ipad.screens, false, IPAD_SKIP_SCREENS()),
      },
      tvOS: {
        ...APPLE_TV_SKIP_SCREENS().reduce(
          (a, c) => ({ ...a, [c.id]: false }),
          {},
        ),
        ...transformSkipItemsToApi(
          d2.appleTv.screens,
          d2.appleTv.auto_advance_setup_tv_os,
        ),
      },
    },

    mac_os_account: {
      hidden: d2.mac.hideAccount,
      fullName: d2.mac.fullName.trim(),
      password: d2.mac.password,
      shortName: d2.mac.shortName.trim(),
      accountType: d2.mac.accountType,
      isAccountConfigured: d2.mac.provisionLocalAdminAccount,
    },

    email: d2.general.orgDetails.email,
    phone: d2.general.orgDetails.phoneNumber,
    department: d2.general.orgDetails.location,
    is_multi_user: d2.ipad.isShared,
    resident_users: +d2.ipad.maximumSharedResidents,

    data: resetCanSkipOffForDisabledDeviceFamilies({
      general: omit(d2.general, DATA_SKIP_KEYS.GENERAL),
      mac: omit(d2.mac, DATA_SKIP_KEYS.MAC),
      iphone: omit(d2.iphone, DATA_SKIP_KEYS.IPHONE),
      ipad: omit(d2.ipad, DATA_SKIP_KEYS.IPAD),
      appleTv: omit(d2.appleTv, DATA_SKIP_KEYS.APPLE_TV),
      vision,
    }),
    // TODO: Uncomment this when the dep-profiles are also moved to new api,
    //  or when the old dep-profile api is configured to accept these flags
    // reassign_lib_item_to_bp: true
  };

  if (d1.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = d1.skip_blueprint_conflict;
  }

  return toSend;
};

function getDefaultValues(data) {
  const setDefaultValue = (condition, originalVal) => (defaultVal) => {
    if (condition) {
      return defaultVal;
    }

    return originalVal;
  };

  const defaultSsoSyncUserWithDevice = setDefaultValue(
    !data.general.ssoEnabled,
    data.general.ssoSyncUserWithDevice,
  );

  const defaultPrefill = setDefaultValue(
    !data.general.ssoEnabled || !data.general.ssoSyncUserWithDevice,
    data.general.prefill,
  );
  const defaultLockPrefilled = setDefaultValue(
    !data.general.ssoEnabled ||
      !data.general.ssoSyncUserWithDevice ||
      !data.general.prefill,
    data.general.lockPrefilled,
  );
  const defaultSsoConnectionId = setDefaultValue(
    !data.general.ssoEnabled,
    data.general.ssoConnectionId,
  );
  const defaultOrgDetailsWithoutOverride = setDefaultValue(
    !data.general.isContactsOverridden,
    data.general.orgDetails,
  );
  const defaultIphoneOrgActivationLock = setDefaultValue(
    data.iphone.userActivationLockAllowed,
    data.iphone.isEnableOrgActivationLock,
  );
  const defaultIpadOrgActivationLock = setDefaultValue(
    data.ipad.userActivationLockAllowed,
    data.ipad.isEnableOrgActivationLock,
  );
  const defaultVisionOrgActivationLock = setDefaultValue(
    data.vision.userActivationLockAllowed,
    data.vision.isEnableOrgActivationLock,
  );
  const defaultIpadMaxResidentUsers = setDefaultValue(
    !data.ipad.isShared ||
      data.ipad.sharedUserConfiguration !== userValues.USERS,
    data.ipad.maximumSharedResidents,
  );
  const defaultIpadMaxSharedSize = setDefaultValue(
    !data.ipad.isShared ||
      data.ipad.sharedUserConfiguration !== userValues.SIZE,
    data.ipad.maximumSharedSize,
  );
  const defaultIpadGuestSessionTimeout = setDefaultValue(
    !data.ipad.isShared || !data.ipad.isGuestSessionTimeout,
    data.ipad.guestSessionTimeout,
  );
  const defaultIpadPasscodeLockPeriod = setDefaultValue(
    !data.ipad.isShared ||
      data.ipad.sharedUserConfiguration === userValues.GUEST ||
      !data.ipad.isPasscodeLockPeriod,
    data.ipad.passcodeLockPeriod,
  );
  const defaultIpadAutoUserLogout = setDefaultValue(
    !data.ipad.isShared ||
      data.ipad.sharedUserConfiguration === userValues.GUEST ||
      !data.ipad.isAutomaticUserLogout,
    data.ipad.automaticUserLogout,
  );
  const defaultIpadSharedUserConfig = setDefaultValue(
    !data.ipad.isShared,
    data.ipad.sharedUserConfiguration,
  );
  const defaultIpadIsGuestSessionTimeout = setDefaultValue(
    !data.ipad.isShared,
    data.ipad.isGuestSessionTimeout,
  );
  const defaultIpadIsPasscodeLockPeriod = setDefaultValue(
    !data.ipad.isShared,
    data.ipad.isPasscodeLockPeriod,
  );
  const defaultIpadIsAutoAutoUserLogout = setDefaultValue(
    !data.ipad.isShared,
    data.ipad.isAutomaticUserLogout,
  );

  const general = {
    ...data.general,
    ssoSyncUserWithDevice: defaultSsoSyncUserWithDevice(false),
    prefill: defaultPrefill(false),
    lockPrefilled: defaultLockPrefilled(false),
    ssoConnectionId: defaultSsoConnectionId(''),
    orgDetails: defaultOrgDetailsWithoutOverride(
      data.general.defaultOrgDetails,
    ),
  };

  const mac = {
    ...data.mac,
  };

  const iphone = {
    ...data.iphone,
    isEnableOrgActivationLock: defaultIphoneOrgActivationLock(false),
  };

  const ipad = {
    ...data.ipad,
    isEnableOrgActivationLock: defaultIpadOrgActivationLock(false),
    maximumSharedResidents: defaultIpadMaxResidentUsers(
      DEFAULT_VALUES().MAX_RESIDENT_USERS,
    ),
    maximumSharedSize: defaultIpadMaxSharedSize(
      DEFAULT_VALUES().USER_QUOTA_SIZE,
    ),
    guestSessionTimeout: defaultIpadGuestSessionTimeout(
      DEFAULT_VALUES().GUEST_SESSION_TIMEOUT,
    ),
    passcodeLockPeriod: defaultIpadPasscodeLockPeriod(
      DEFAULT_VALUES().PASSCODE_LOCK_PERIOD,
    ),
    automaticUserLogout: defaultIpadAutoUserLogout(
      DEFAULT_VALUES().AUTOMATIC_USER_LOGOUT,
    ),
    sharedUserConfiguration: defaultIpadSharedUserConfig(''),
    isGuestSessionTimeout: defaultIpadIsGuestSessionTimeout(false),
    isPasscodeLockPeriod: defaultIpadIsPasscodeLockPeriod(false),
    isAutomaticUserLogout: defaultIpadIsAutoAutoUserLogout(false),
  };

  const appleTv = {
    ...data.appleTv,
  };

  const vision = {
    ...data.vision,
    isEnableOrgActivationLock: defaultVisionOrgActivationLock(false),
  };

  return {
    general,
    mac,
    iphone,
    ipad,
    appleTv,
    vision,
  };
}

export function resetCanSkipOffForDisabledDeviceFamilies(
  initialTransformedData,
) {
  const deviceFamilyKeys = ['mac', 'iphone', 'ipad', 'appleTv', 'vision'];
  return Object.entries(initialTransformedData).reduce((acc, [key, value]) => {
    if (deviceFamilyKeys.includes(key) && !value.isEnabled) {
      return {
        ...acc,
        [key]: {
          ...value,
          canSkip: false,
        },
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

export { getDefaultValues, transformFromApi, transformToApi };
