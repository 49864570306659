import moment from 'moment';
import React from 'react';
import uuidv4 from 'uuid/v4';

import type { Row } from '@tanstack/react-table';

import {
  apiTypes,
  displayLibraryCategory,
} from 'src/features/library-items/library/common';

import { i18n } from 'i18n';
import {
  paramsToHideIfUnconfigured,
  paramsToLibraryItemType,
} from '../blueprints/blueprint/blueprint-parameters/parameter-details';
import type { LibraryApiTypeKind } from '../library-items/library-items.types';
import type { LibraryItemStatusContent } from './LibraryItemStatusLogs/CommonStatus.types';
import { formatStatusLogs } from './LibraryItemStatusLogs/lib/formatStatusLogs';
import {
  ALLOWED_TYPES,
  OTHER_PROFILE_TYPES,
  OTHER_SECURITY_TYPES,
  PENDING_STATUSES,
  RECURRING_RUN_DATE_COLUMN_NAME,
  SINGLE_RUN_DATE_COLUMN_NAME,
  STATUS_MAP,
  STATUS_TYPES,
  STATUS_TYPE_SEVERITY,
} from './constants';
import type {
  ComputerRecord,
  Status,
  StatusRow,
  StatusTypeKind,
} from './device-status-tab.types';
import {
  LibraryItemLog,
  LogProfileWithControl,
  ParameterLog,
  StandardLog,
  VppAppLog,
} from './expanded-details';

type QueryParamHistoryResult = { parameter_id: string };

type ParameterStatus = {
  blueprint_id: string;
  first_for_enrollment: { enrolled_at: string };
  parameter_id: string;
  parameter_name: string;
  run: string;
};

/**
 * Constructs a list of readable parameter status data
 * @param blueprintNames - an object mapping blueprint ID to blueprint name
 * @param parameterMetadata - an object mapping parameter ID to an object with parameter data
 * @param results - a list of parameter status objects
 * @returns a list of parameter statuses with formatted data
 */
const annotateParameterResults = (
  blueprintNames: object,
  parameterMetadata: object,
  results: Array<ParameterStatus>,
) => {
  const totalResults = results || [];

  return totalResults.map((item) => {
    const {
      blueprint_id,
      first_for_enrollment,
      parameter_id,
      parameter_name,
      run,
    } = item;

    const momentObj = moment(run);
    const paramObject = parameterMetadata[parameter_id];
    const nameVerbose = parameter_name || paramObject.name || 'No name';

    return {
      ...item,
      runVerbose: run
        ? i18n.format.datetime(run, { relative: true })
        : 'Not Yet Run',
      runTimestamp: momentObj.unix(),
      nameVerbose,
      blueprintName: blueprintNames[blueprint_id],
      enrollmentDate: first_for_enrollment
        ? i18n.format.datetime(first_for_enrollment.enrolled_at, {
            relative: true,
          })
        : null,
    };
  });
};

/**
 * Constructs a query object to use when getting parameters on the current device
 * @param computer - a computer record with information about the device
 * @returns a query object with current device informatioin
 */
const getParameterStatusesRequestQuery = (computer: ComputerRecord) => ({
  parameterid: null,
  computerid: computer.id,
  blueprintid: computer.blueprint_id,
  displayType: 'LAST_STATE',
  ordering: '-ended_at',
  for_device: true,
});

/**
 * Constructs a list of parameters that are on the current blueprint but that
 * have not yet run on the current device and therefore have no status data
 * @param blueprintParameters - a mapping of parameter ID to parameter data for
 *                          all parameters on the device's blueprint
 * @param results - a list of parameters on this device that have status data
 * @returns a list of objects representing the parameters that are on the
 *          blueprint but do not yet have status data
 */
const getParametersWithoutStatuses = (
  blueprintParameters: object,
  results: Array<QueryParamHistoryResult>,
) => {
  const parametersWithoutStatuses = [];

  Object.keys(blueprintParameters).forEach((paramId) => {
    if (
      !results?.find(
        (res: QueryParamHistoryResult) => res.parameter_id === paramId,
      )
    ) {
      parametersWithoutStatuses.push({
        id: uuidv4(),
        run: null,
        parameter_id: paramId,
        status: 'NOT_YET_RUN',
      });
    }
  });

  return parametersWithoutStatuses;
};

/**
 * Construct a list of all the allowed types and status objects that have been fetched
 * @param libraryItemStatuses - a list of Library Item status objects
 * @param parameterStatuses - a list of Parameter status objects
 * @returns a list of all the allowed types and status objects that have been fetched
 */
const allowStatusesByType = (
  libraryItemStatuses: Array<Status>,
  parameterStatuses: Array<Status>,
) => {
  const isParametersAllowed = ALLOWED_TYPES.includes('parameter');

  const libraryItemStatusesFromParams = libraryItemStatuses
    .filter((status) =>
      Object.values(paramsToLibraryItemType).includes(
        status.type as LibraryApiTypeKind,
      ),
    )
    .map((status) => status.type);

  const filteredParameterStatuses = parameterStatuses.filter(
    ({ parameter_id }) =>
      !paramsToHideIfUnconfigured.includes(parameter_id) ||
      (paramsToHideIfUnconfigured.includes(parameter_id) &&
        !libraryItemStatusesFromParams.includes(
          paramsToLibraryItemType[parameter_id] as StatusTypeKind,
        )),
  );

  // Only include status types that are whitelisted (explicitly defined within STATUS_TYPES and OTHER_PROFILE_TYPES)
  const allowedStatuses = [
    ...libraryItemStatuses.filter(({ type }) => ALLOWED_TYPES.includes(type)),
    ...(isParametersAllowed ? filteredParameterStatuses : []),
  ];

  // A list of allowed types, without duplicates, and sorted alphabetically
  const allowedTypes = [
    ...new Set(
      allowedStatuses.map(({ parameter_id, type }) => {
        // Profiles that do not have an explicit 'profile' type, i.e. 'ssh'
        const isProfileWithoutProfileType = OTHER_PROFILE_TYPES.includes(type);
        const isSecurity = OTHER_SECURITY_TYPES.includes(type);
        const isParameter = !!parameter_id;

        if (isProfileWithoutProfileType) {
          return 'profile';
        }
        if (isSecurity) {
          return apiTypes.THREAT_SECURITY_POLICY;
        }
        if (isParameter) {
          return 'parameter';
        }
        return type;
      }),
    ),
  ].sort();

  return { allowedStatuses, allowedTypes };
};

/**
 * Construct an object mapping Library Item / Parameter type to a list of statuses belonging to that type
 * as well as a list of all the allowed types and status objects
 * @param statuses - a list of Library Item / Parameter status objects
 * @returns an object mapping Library Item / Parameter type to a list of statuses belonging to that type
 *          as well as a list of all the allowed types and status objects
 */
const groupStatusesByType = (statuses: Array<Status>) => {
  const groupedStatuses: Partial<Record<StatusTypeKind, Array<Status>>> = {};

  const profileTypes = ['profile', ...OTHER_PROFILE_TYPES];

  statuses.forEach((_status) => {
    const status = formatStatusLogs(_status as Status);
    const { parameter_id, type: _type } = status;

    let type = _type;
    const isProfile = profileTypes.includes(type);
    const isSecurity = OTHER_SECURITY_TYPES.includes(type);
    const isParameter = !!parameter_id;
    const isIpa = type === apiTypes.IPA_APP || type === apiTypes.IPA_APP_V2;

    if (isIpa) {
      type = apiTypes.IPA_APP;
    }

    // All Profiles, even those without an explicit 'profile' type, belong in a broader 'Profile' category
    // All 'Parameters' should be placed in their own category
    // All Security types should be placed in their own category
    if (isProfile) {
      if (!('profile' in groupedStatuses)) {
        groupedStatuses['profile'] = [];
      }
      groupedStatuses['profile'].push(status);
    } else if (isParameter) {
      if (!('parameter' in groupedStatuses)) {
        groupedStatuses['parameter'] = [];
      }
      groupedStatuses['parameter'].push(status);
    } else if (isSecurity) {
      if (!(apiTypes.THREAT_SECURITY_POLICY in groupedStatuses)) {
        groupedStatuses[apiTypes.THREAT_SECURITY_POLICY] = [];
      }

      groupedStatuses[apiTypes.THREAT_SECURITY_POLICY].push(status);
    } else if (type in groupedStatuses) {
      groupedStatuses[type].push(status);
    } else {
      groupedStatuses[type] = [status];
    }
  });

  return groupedStatuses;
};

/**
 * Formats a string to have sentence casing
 * @param str - a string value
 * @returns a string formatted to have sentence casing
 */
const formatStatus = (str: string) => {
  let s = str;

  if (!s) {
    return '';
  }

  // Remove whitespace and capitalization
  s = s.toLowerCase().trim();

  // Capitalize the first letter
  s = s.charAt(0).toUpperCase() + s.slice(1);

  // Replace underscores with a space (i.e., 'Changes_pending' to 'Changes pending')
  s = s.replace(/[_]+/g, ' ');

  return s;
};

/**
 * Maps a "status" to its display attributes
 * @param status - a string value from API
 * @returns the label and color values of the status with fallbacks
 */
const mapStatusToAttributes = (status: string) => {
  const STATUS = status?.toUpperCase();

  const label = STATUS_MAP[STATUS]?.label || formatStatus(STATUS);
  const color = STATUS_MAP[STATUS]?.color || 'neutral';

  return { label, color };
};

/**
 * Constructs a list of options for the "Status" filter
 * @param statuses - a list of Library Item / Parameter status objects
 * @returns a list of objects representing the options for the "Status" filter
 */
const getStatusOptions = (statuses: Array<Status>) => {
  const sortStatusOptions = (statusOptionA, statusOptionB) => {
    const statusOptionSeverityA =
      STATUS_TYPE_SEVERITY[statusOptionA.badgeColor];
    const statusOptionSeverityB =
      STATUS_TYPE_SEVERITY[statusOptionB.badgeColor];

    // If the status "grouping" (color) is the same, sort alphabetically
    // Note: statusOptionA is the 'compared' status as ascending sort is red --> green and Z --> A
    if (statusOptionA.badgeColor === statusOptionB.badgeColor) {
      return statusOptionB.label.localeCompare(statusOptionA.label);
    }

    // If the status "groupings" (colors) differ, sort by severity
    if (statusOptionSeverityA > statusOptionSeverityB) {
      return 1;
    }

    if (statusOptionSeverityA < statusOptionSeverityB) {
      return -1;
    }

    return 0;
  };

  // A list of all statuses used, without duplicates, and sorted alphabetically
  const statusValues = [
    ...new Set(statuses.map(({ status }) => status?.toUpperCase()).sort()),
  ];

  const statusOptions = statusValues
    .map((value) => {
      const { label, color } = mapStatusToAttributes(value);

      return { label, value, badgeColor: color };
    })
    .sort(sortStatusOptions);

  const defaultStatusFilterOptions = statusOptions.filter(
    ({ label }) => label !== STATUS_MAP.EXCLUDED.label,
  );

  return { statusValues, statusOptions, defaultStatusFilterOptions };
};

/**
 * Constructs a list of options for the "Type" filter
 * @param allowedTypes - a list of allowed status types
 * @returns a list of objects representing the options for the "Type" filter
 */
const getTypeOptions = (allowedTypes: Array<string>) => {
  const options = [];

  Object.keys(STATUS_TYPES).forEach((type) => {
    const isAllowed = allowedTypes.includes(type);

    if (isAllowed) {
      options.push({
        label: displayLibraryCategory(STATUS_TYPES[type].name),
        value: type,
      });
    }
  });

  return options;
};

/**
 * Determines whether or not a status row can expand with more details
 * @param statusItem - the status row item
 * @param type - the type of status, ex. 'auto-app', 'profile', 'parameter'
 * @returns whether or not a status row can expand with more details
 */
const getCanExpand = (
  statusItem: {
    date?: string;
    details?: string;
    log?: string;
    last_audit_run?: string;
    last_audit_log?: string;
    last_audit_log_content?: LibraryItemStatusContent;
    status?: string;
    type?: string; // The specific LI type, ex. 'ssh', 'profile', 'automatic-app'
  },
  // The type used to bucket LIs into tables
  type: string,
) => {
  const {
    date,
    details,
    log,
    last_audit_run,
    last_audit_log,
    last_audit_log_content,
    status,
    type: specificType,
  } = statusItem;

  switch (STATUS_TYPES[type].expandedDetailsComponent) {
    case LibraryItemLog:
      return last_audit_run || last_audit_log || date || log;
    case VppAppLog:
      return last_audit_run || last_audit_log || date || log;
    case StandardLog:
      return date || log;
    case LogProfileWithControl:
      // This `if` logic is pulled from the old StatusTab component - previously `isExpandableProfileRow()`
      if (specificType === 'profile') {
        return (
          ['success', 'pass', 'failed', 'installing', 'incompatible'].includes(
            status?.toLowerCase(),
          ) ||
          (status?.toLowerCase() === 'pending' && !!last_audit_log) ||
          (status?.toLowerCase() === 'pending' && !!last_audit_log_content)
        );
      }
      return last_audit_run || last_audit_log || date || log;
    case ParameterLog:
      return details?.length > 0;
    default:
      return false;
  }
};

/**
 * Gets the expanded details component if the row is able to expand
 * @param statusItem - the status row item
 * @param type - the type of status, ex. 'auto-app', 'profile', 'parameter'
 * @returns the expanded details component
 */
const getExpandedDetails = (statusItem: any, type: string) => {
  if (getCanExpand(statusItem, type)) {
    const Component = STATUS_TYPES[type].expandedDetailsComponent;
    return <Component {...statusItem} />;
  }

  return null;
};

/**
 * Sort two rows based on their name and instance name
 * @param rowA - the first row to sort
 * @param rowB - the second row to sort
 * @returns a negative number if rowA occurs before rowB, a positive number
 *          if rowB occurs before rowA, and 0 if they are equivalent
 */
const nameColumnSort = (rowA: Row<StatusRow>, rowB: Row<StatusRow>) => {
  const rowAFull = `${rowA.original.name}${rowA.original.instanceName}`;
  const rowBFull = `${rowB.original.name}${rowB.original.instanceName}`;

  return rowAFull.localeCompare(rowBFull);
};

/**
 * Sorts two rows based on the status (first by severity grouping, then alphabetically)
 * @param rowA - the first row to sort
 * @param rowB - the second row to sort
 * @returns a negative number if rowA occurs before rowB, a positive number
 *          if rowB occurs before rowA, and 0 if they are equivalent
 */
const statusColumnSort = (rowA: Row<StatusRow>, rowB: Row<StatusRow>) => {
  const { label: rowALabel, color: rowAColor } = mapStatusToAttributes(
    rowA.original.status,
  );
  const rowAStatusSeverity = STATUS_TYPE_SEVERITY[rowAColor];

  const { label: rowBLabel, color: rowBColor } = mapStatusToAttributes(
    rowB.original.status,
  );
  const rowBStatusSeverity = STATUS_TYPE_SEVERITY[rowBColor];

  // If the status "grouping" (color) is the same, sort alphabetically
  // Note: rowA is the 'compared' status as ascending sort is red --> green and Z --> A
  if (rowAColor === rowBColor) {
    return rowBLabel.localeCompare(rowALabel);
  }

  // If the status "groupings" (colors) differ, sort by severity
  if (rowAStatusSeverity > rowBStatusSeverity) {
    return 1;
  }

  if (rowAStatusSeverity < rowBStatusSeverity) {
    return -1;
  }

  return 0;
};

/**
 * Sort two rows based on date
 * @param rowA - the first row to sort
 * @param rowB - the second row to sort
 * @returns a negative number if rowA occurs before rowB, a positive number
 *          if rowB occurs before rowA, and 0 if they are equivalent
 */
const dateColumnSort = (rowA: Row<StatusRow>, rowB: Row<StatusRow>) => {
  const rowAHasDate =
    rowA.original.date !== undefined &&
    !PENDING_STATUSES.includes(formatStatus(rowA.original.status));

  const rowBHasDate =
    rowB.original.date !== undefined &&
    !PENDING_STATUSES.includes(formatStatus(rowB.original.status));

  // If both items have a valid date, compare the two directly
  if (rowAHasDate && rowBHasDate) {
    const rowADate = new Date(rowA.original.date);
    const rowBDate = new Date(rowB.original.date);

    if (rowADate > rowBDate) {
      return 1;
    }

    if (rowADate < rowBDate) {
      return -1;
    }

    return 0;
  }

  // If one item does not have a valid date, the one that _does_ have a valid date occurs first
  if (rowAHasDate && !rowBHasDate) {
    return 1;
  }

  if (!rowAHasDate && rowBHasDate) {
    return -1;
  }

  // Neither item has a valid date - they are the same
  return 0;
};

/* istanbul ignore next */
const translateStatusLabel = i18n.createMap({
  [STATUS_MAP.STOPPED.label]: () => i18n.t('Stopped'),
  [STATUS_MAP.ERROR.label]: () => i18n.t('Error'),
  [STATUS_MAP.WARNING.label]: () => i18n.t('Warning'),
  [STATUS_MAP.FAILED.label]: () => i18n.t('Failed'),
  [STATUS_MAP.MISSING.label]: () => i18n.t('Missing'),
  [STATUS_MAP.MUTE.label]: () => i18n.t('Mute'),
  [STATUS_MAP.INCOMPATIBLE.label]: () => i18n.t('Incompatible'),
  [STATUS_MAP.EXCLUDED.label]: () => i18n.t('Excluded'),
  [STATUS_MAP.INSTALLING.label]: () => i18n.t('Installing'),
  [STATUS_MAP.AVAILABLE.label]: () => i18n.t('Available'),
  [STATUS_MAP.PENDING.label]: () => i18n.t('Pending'),
  [STATUS_MAP.CHANGES_PENDING.label]: () => i18n.t('Changes pending'),
  [STATUS_MAP.NOT_YET_RUN.label]: () => i18n.t('Not yet run'),
  [STATUS_MAP.REMEDIATED.label]: () => i18n.t('Remediated'),
  [STATUS_MAP.INSTALLED.label]: () => i18n.t('Installed'),
  [STATUS_MAP.PASS.label]: () => i18n.t('Pass'),
  [STATUS_MAP.SUCCESS.label]: () => i18n.t('Success'),
  [STATUS_MAP.CACHED.label]: () => i18n.t('Cached'),
  [STATUS_MAP.DOWNLOADING.label]: () => i18n.t('Downloading'),
});

const translateDateColumnName = i18n.createMap({
  [SINGLE_RUN_DATE_COLUMN_NAME]: () => i18n.t('Last ran'),
  [RECURRING_RUN_DATE_COLUMN_NAME]: () => i18n.t('Checked in'),
});

export {
  allowStatusesByType,
  annotateParameterResults,
  dateColumnSort,
  formatStatus,
  getCanExpand,
  getExpandedDetails,
  getParameterStatusesRequestQuery,
  getParametersWithoutStatuses,
  getStatusOptions,
  getTypeOptions,
  groupStatusesByType,
  mapStatusToAttributes,
  nameColumnSort,
  statusColumnSort,
  translateDateColumnName,
  translateStatusLabel,
};
