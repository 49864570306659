import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type BlockedProfileDetails,
  getBlockedReasonDescription,
} from '../ProfileStatus.types';

function serializerV1(details: BlockedProfileDetails) {
  const { reason_code } = details;

  return new LibraryItemStatusLog()
    .addRow(getBlockedReasonDescription(reason_code))
    .toString();
}

export const BlockedProfileAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
