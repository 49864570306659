import { i18n } from 'src/i18n';
import slug from './slug';

export default {
  [slug.GENERAL]: {
    product: 'Kandji',
    label: () => i18n.t('Feature request'),
    description: () =>
      i18n.t(
        'Share a feature request or suggest general web app improvements.',
      ),
    icon: 'wrench',
    slug: slug.GENERAL,
  },
  [slug.AUTO_APP]: {
    product: 'Auto Apps',
    label: () => i18n.t('Auto App request'),
    description: () =>
      i18n.t(
        'Request a new Auto App that would be valuable to your end users.',
      ),
    icon: 'grid-2',
    slug: slug.AUTO_APP,
  },
};
