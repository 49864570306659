/* istanbul ignore file */
import {
  formatDateTime,
  formatNumber,
  getJSLocale,
  getShouldUseRelativeDates,
  getTimeZone,
  t,
} from '@kandji-inc/nectar-i18n';
import { DateTime } from 'luxon';

type UnknownDate = Date | number | string;

interface DisplayOptions {
  relative?: boolean;
}

const formatDateTimeString = (
  unk: UnknownDate,
  displayOptions: DisplayOptions = { relative: false },
  formatOptions: Intl.DateTimeFormatOptions = {},
) => {
  let dt: DateTime;

  if (unk instanceof Date) {
    dt = DateTime.fromJSDate(unk, { zone: 'utc' });
  } else if (typeof unk === 'number') {
    dt = DateTime.fromMillis(unk, { zone: 'utc' });
  } else if (typeof unk === 'string') {
    dt = DateTime.fromISO(unk, { zone: 'utc' });
    if (!dt.isValid) {
      dt = DateTime.fromISO(unk.replace(' ', 'T'), { zone: 'utc' });
    }
  }

  if (!dt || !dt.isValid) {
    return t('Invalid timestamp');
  }

  dt = dt.setLocale(getJSLocale());
  dt = dt.setZone(getTimeZone());

  if (displayOptions.relative && getShouldUseRelativeDates()) {
    return dt.toRelative();
  }

  const jsDate = dt.toJSDate();

  return formatDateTime(jsDate, {
    ...formatOptions,
    timeZone: dt.zoneName,
  });
};

export const format = {
  number: (num: number) => formatNumber(num),
  date: (date: UnknownDate, options?: DisplayOptions) =>
    formatDateTimeString(date, options, {
      dateStyle: 'short',
    }),
  datetime: (date: UnknownDate, options?: DisplayOptions) =>
    formatDateTimeString(date, options, {
      dateStyle: 'short',
      timeStyle: 'medium',
    }),
  time: (date: UnknownDate, options?: DisplayOptions) =>
    formatDateTimeString(date, options, {
      timeStyle: 'short',
    }),
};
