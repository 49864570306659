import { IPA_VPPStatusTypes } from '../IPA_VPP.types';
import { AppMissingAudit } from './AppMissingAudit';
import { AppUnavailableAudit } from './AppUnavailableAudit';
import { BundleIDMismatch } from './BundleIDMismatch';
import { IncompatibleAuditSerializers } from './IncompatibleAudit';
import { InstallAppDownloadingAudit } from './InstallAppDownloadingAudit';
import { InstallAppFailedAudit } from './InstallAppFailedAudit';
import { InstallAppFailureSerializers } from './InstallAppFailure';
import { InstallAppStuckAudit } from './InstallAppStuckAudit';
import { InstallAppSuccessSerializers } from './InstallAppSuccess';
import { InstallFailedAuditSerializers } from './InstallFailedAudit';
import { InstallHigherVersionPassed } from './InstallHigherVersionPassed';
import { InstallInProgressAudit } from './InstallInProgressAudit';
import { InstallLowerVersionPassed } from './InstallLowerVersionPassed';
import { InstallPassedAudit } from './InstallPassedAudit';
import { LicenseFailedAudit } from './LicenseFailedAudit';
import { LicenseMissingAudit } from './LicenseMissingAudit';
import { SelfServiceSerializers } from './SelfServiceSerializers';

export const IPA_VPPStatusSerializers = {
  [IPA_VPPStatusTypes.APP_INSTALL_STUCK_AUDIT]: InstallAppStuckAudit,
  [IPA_VPPStatusTypes.APP_INSTALL_PASSED_AUDIT]: InstallPassedAudit,
  [IPA_VPPStatusTypes.APP_MISSING_AUDIT]: AppMissingAudit,
  [IPA_VPPStatusTypes.INSTALL_APP_FAILURE]: InstallAppFailureSerializers,
  [IPA_VPPStatusTypes.INSTALL_APP_SUCCESS]: InstallAppSuccessSerializers,
  [IPA_VPPStatusTypes.APP_UNAVAILABLE_AUDIT]: AppUnavailableAudit,
  [IPA_VPPStatusTypes.APP_INCOMPATIBLE_AUDIT]: IncompatibleAuditSerializers,
  [IPA_VPPStatusTypes.APP_INSTALL_FAILED_AUDIT]: InstallFailedAuditSerializers,
  [IPA_VPPStatusTypes.APP_LICENSE_FAILED_AUDIT]: LicenseFailedAudit,
  [IPA_VPPStatusTypes.INSTALL_APP_FAILED_AUDIT]: InstallAppFailedAudit,
  [IPA_VPPStatusTypes.APP_LICENSE_MISSING_AUDIT]: LicenseMissingAudit,
  [IPA_VPPStatusTypes.APP_BUNDLE_ID_MISMATCH_AUDIT]: BundleIDMismatch,
  [IPA_VPPStatusTypes.APP_INSTALL_IN_PROGRESS_AUDIT]: InstallInProgressAudit,
  [IPA_VPPStatusTypes.INSTALL_APP_DOWNLOADING_AUDIT]:
    InstallAppDownloadingAudit,
  [IPA_VPPStatusTypes.APP_INSTALL_LOWER_VERSION_PASSED_AUDIT]:
    InstallLowerVersionPassed,
  [IPA_VPPStatusTypes.APP_INSTALL_HIGHER_VERSION_PASSED_AUDIT]:
    InstallHigherVersionPassed,
  [IPA_VPPStatusTypes.LIBRARY_ITEM_AVAILABLE_IN_SELF_SERVICE_AUDIT]:
    SelfServiceSerializers,
};
