import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { DeviceNameSettingsFailureDetails } from '../DeviceName.types';

function serializerV1(details: DeviceNameSettingsFailureDetails) {
  const { device_name, missing_values } = details;
  const log = new LibraryItemStatusLog();

  log.addRow(
    i18n.t('The new device name {device_name} failed to set.', {
      device_name,
    }),
  );

  missing_values.forEach((value: string) => {
    log.addRow(i18n.t('Unable to retrieve {value}', { value }));
  });

  return log.toString();
}

export const DeviceNameSettingsFailureSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
